
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const CopyLink2 = ({ crewDetail }) => {
    return (
        <>
            <div className="mycrew-post-inside">
                <img
                    src="/images/yourReferralLink.svg"
                    alt="yourReferralLink"
                />
                Your Connect Link
            </div>
            <div className="mycrew-post-inside-text">
                <CopyToClipboard
                    text={`${window.location.protocol}//${window.location.host}/register?connector=${crewDetail?.referral_code}`}
                    onCopy={() => {
                        toast.success('Copied Successfully.');
                    }}>

                    <span className="pointer">{window.location.protocol}//{window.location.host}/register?connector={crewDetail?.referral_code}</span>
                </CopyToClipboard>
                <CopyToClipboard
                    text={`${window.location.protocol}//${window.location.host}/register?connector=${crewDetail?.referral_code}`}
                    onCopy={() => {
                        toast.success('Copied Successfully.');
                    }}>
                    <button className="boscrew-f-btn">Copy</button>
                </CopyToClipboard>
            </div>
        </>
    )
}

export default CopyLink2