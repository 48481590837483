import { useContext, useEffect, useState } from 'react';
import AccountContext from '../context/private/accountContext';
import PrivateContext from '../context/private/privateContext';
import UserInfoWrapper from './UserInfoWrapper';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Flex } from '../styles/Styles';
import { getToken } from '../services/authService';
import { parseJwt } from '../utils/tools';
import UserForceAgreementModal from './UserForceAgreementModal';
import { Link } from 'react-router-dom';

function Header({ title, bubble }: { title: string, bubble?: string }) {
  const { setIsOpen, flowState,
    loadAgreementModal } = useContext(PrivateContext);
  const { loadUserInfo, userInfo } = useContext(AccountContext);
  const [isLock, setIsLock] = useState(false)

  useEffect(() => {
    let intervalUserInfo: any;

    if (!isLock) {
      if (flowState?.step === 17) {
        setIsLock(true)
        loadUserInfo();
        loadAgreementModal();
        intervalUserInfo = setInterval(() => {
          loadUserInfo();
        }, 30000);
      }
    }

    return () => {
      clearInterval(intervalUserInfo);
    };
  }, [flowState]);

  const [isReadOnly, setIsReadOnly] = useState(false);
  useEffect(() => {
    const result = parseJwt(getToken())
    setIsReadOnly(result?.readOnly)
  }, [flowState]);

  function getDaysUntilTimestamp(expireTime) {
    const now = Date.now();
    const timeDifference = expireTime - now;
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const daysRemaining = Math.floor(timeDifference / millisecondsInADay);
    return daysRemaining;
  }


  return (
    <>
      {isReadOnly && <div>
        <div className="warning-message-deposit">
          You are currently viewing the user panel in read-only mode.
        </div>
      </div>}
      {userInfo && userInfo?.is_action_limit === 0 && (userInfo?.membershipStatus === 1 && userInfo?.pendingMembershipRequest === 0) && <div className="membership-fee-message"><span></span>
        <div className="membership-fee-message1"><span>
          Your membership will expire in {getDaysUntilTimestamp(Number(userInfo?.membership_expiration_date))} days. <Link to={'/membership'} className='link-click-here'>Click here</Link>  to renew your membership.
        </span>
        </div>
      </div>}

      <div className="header">
        <button onClick={() => setIsOpen(true)} className="open-nav-mobile" />
        {bubble ? <Flex y="center" gap={0.5}>
          <h2>{title}</h2>
          <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{
            left: 0,
            width: 20,
            margin: 'auto 4px 23px',
          }}>
            <AiOutlineInfoCircle className="tooltip-info-icon" />
            <div className="tooltip-info-text">
              <span>
                {bubble}
              </span>
            </div>
          </div>

        </Flex> : <h2>{title}</h2>}

        {(flowState.step === 17 && userInfo
          && userInfo?.membershipStatus !== 2) && <UserInfoWrapper />}
        {/* <>
          {(flowState.step === 17 && userInfo 
 && userInfo?.membershipStatus !== 2) && console.log("Cookie", document.cookie)}
        </> */}
        {(flowState.step === 17 && userInfo
          && userInfo?.membershipStatus !== 2) && <UserForceAgreementModal />}
      </div>
    </>
  );
}

export default Header;
