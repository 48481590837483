import { Route, Routes } from 'react-router-dom';
import { Login, Register, NewPassword, ResetPassword } from '../../components';
import { LoginContextProvider } from '../../context/public/loginContext';
import { RegisterContextProvider } from '../../context/public/registerContext';

const PublicRoutes = () => {
  return (
    
      <LoginContextProvider>
        <RegisterContextProvider>
          <Routes>
            <Route path='/new-password' element={<NewPassword />} />
            <Route path='/forget-password' element={<ResetPassword />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
          </Routes>
        </RegisterContextProvider>
      </LoginContextProvider>
    
  )
}

export default PublicRoutes