import React from 'react'
import config from '../config'
import dictionary from '../locals/login.json'

const CopyrightPublic = () => {
    return (
        <>
            <div className="copyright">
                <div className="boscontact">Contact Support: <a href="mailto:hello@blueoceansociety.club">hello@blueoceansociety.club</a></div>
                <span>{dictionary['LOGIN-title6']}</span>                
                <div className='version'>{dictionary['LOGIN-title7']}: {config.version}</div>
            </div>
        </>
    )
}

export default CopyrightPublic