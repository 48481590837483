import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosClose } from 'react-icons/io';
import { numberWithCommas } from '../../../utils/tools';

type TModal = {
  onChange?: (e: any) => void;
  onClick?: () => void;
  min?: number;
  max?: number | string;
  image?: string;
  title?: string;
  isOpen?: boolean;
  setIsOpen?: (e: boolean) => void;
  value?: any;
  setValue?: any;
  balance?: any;
};

const Modal: React.FC<TModal> = ({
  onChange,
  onClick,
  min,
  max,
  image,
  title,
  isOpen,
  setIsOpen,
  value,
  setValue,
  balance
}: any) => {


  return (
    <>
      <div
        onClick={() => setIsOpen(false)}
        className={`bg-black bg-opacity-[0.65] inset-0 z-[150] fixed w-full h-full transition duration-300 backdrop-blur-[3px] ${isOpen ? 'visible opacity-100' : 'hidden invisible opacity-0'
          }`}
      ></div>
      <div
        className={`bg-white fixed left-1/2 top-1/2 w-full p-4 lg:p-8 rounded-2xl max-w-2xl -translate-y-1/2 transition duration-300 -translate-x-1/2 z-[155] ${isOpen ? 'visible opacity-100' : 'invisible opacity-0'
          }`}
      >
        <div className="flex justify-between items-center mb-5 border-b border-gray-400 pb-5">
          <span className="font-bold text-lg lg:text-[22px] ">{title}</span>
          <div
            onClick={() => setIsOpen(false)}
            className="p-1 cursor-pointer rounded-full bg-[#e9f1f599] hover:rotate-360 transition"
          >
            <IoIosClose className="text-5xl opacity-50" />
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <img src={image} className="w-full h-60 object-contain" />
          {balance ? balance : null}
          <input
            type="number"
            className="bg-[#f5f5f5] border border-[#eee] h-[52px] px-2 lg:px-4 rounded-xl placeholder:text-gray-500"
            min={0}
            max={max}
            onChange={onChange}
            value={value?.amount}
            placeholder="Enter an amount"
          />
          <div className="flex justify-between w-full items-center">
            {min ? (
              <div
                className="flex gap-x-2 items-center cursor-pointer"
                onClick={() => setValue({ ...value, amount: Math.floor(min*100)/100 })}
              >
                <span className="text-2xl">Min:</span>
                <span>${numberWithCommas(min)}</span>
              </div>
            ) : null}
            {max ? (
              <div
                className="flex gap-x-2 items-center cursor-pointer"
                onClick={() => setValue({ ...value, amount: Math.floor(max * 100) / 100 })}
              >
                <span className="text-2xl">Max:</span>
                <span>${numberWithCommas(max)}</span>
              </div>
            ) : null}
          </div>
          <button
            onClick={(e) => {
              if (min === undefined || min === null || value?.amount >= min) {
                onClick(e);
              } else {
                toast.error('The minimum amount is $' + min);
              }
            }}
            className="bg-[#153458] w-full h-[54px] text-2xl font-extrabold text-white md:text-[24px] rounded-2xl mt-10"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Modal;
