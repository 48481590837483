import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config';
import PrivateContext from '../context/private/privateContext';
import TreasuryContext from '../context/private/treasuryContext';
import { Container, Flex } from '../styles/Styles';
import { numberWithCommas, timestampToLocalDaily, timestampToLocalHour, timeToYYYYMMDD } from '../utils/tools';
import NotFoundImage from './../assets/images/not-found.png';
import Header from './Header';
import Layout from './layout/Index';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import dictionary from '../locals/my-treasures.json';
import Pagination from 'react-responsive-pagination';
import AccountContext from '../context/private/accountContext';

const MyTreasures = (): JSX.Element => {
  const { flowState } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);
  const navigate = useNavigate();

  const { treasuresListData, loadTreasures, transactionChartData } =
    useContext(TreasuryContext);

  const [filteredIslands, setFilteredIslands] = useState([])

  const [typeFilter, setTypeFilter] = useState('all')
  const [statusFilter, setStatusFilter] = useState('open')
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [currentLimitPage, setCurrentLimitPage] = useState<any>(10)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      typeFilter && statusFilter && loadTreasures(searchQuery, statusFilter, typeFilter, currentPage, currentLimitPage)
    }
  }, [searchQuery, typeFilter, statusFilter, currentPage, flowState, currentLimitPage])


  const search = (filterTerm: any, terms: any) => {
    filterTerm = filterTerm.toLowerCase().trim();
    return terms.filter((v: any) => v.title.toLowerCase().includes(filterTerm))
  };

  // useEffect(() => {
  //   if (searchQuery.length > 0) {
  //     const result = search(searchQuery, treasuresListData)
  //     setFilteredIslands(result)
  //   }
  // }, [searchQuery])

  if (flowState?.step === 17 && userInfo
    && userInfo?.membershipStatus !== 2)
    return (
      <Layout className="myt">
        <Container x={'center'} marginT={1} className="container myt-container">
          <Flex gap={1.7} x={'center'} y={'start'} className="myt-flex">
            <div className="myt-b">
              {/* header*/}
              <div className="myt-h">
                <Header title={dictionary['PAGE-title']} bubble={dictionary['PAGE-desc']} />
                <div className="fir-tr-combo-header">
                  {/* <div className='drop-down-style'>
                    <select
                      className="mymap-s-selector drop-down-select-style type"
                      value={typeFilter}
                      onChange={(e) => setTypeFilter(e.target.value)}
                    >
                      <option value="all">All Type</option>
                      <option value="notParticipated">
                        Unparticipated Islands
                      </option>
                      <option value="participated">
                        Participated Islands
                      </option>
                    </select>
                  </div> */}

                  <div className="fir-tr-combo-header-input pagination-dynamic-input">
                    <span className='span-first'>Display</span>
                    {/* <input
                      type="number"
                      min={1}
                      className="fir-tr-combo-header-input-search input-dynamic"
                      placeholder="Items"
                      value={currentLimitPage}
                      onChange={(e) => ((Number(e.target.value) > 0) || (e.target.value.trim() == "")) && setCurrentLimitPage(e.target.value)}
                    /> */}

                    <select
                      className="drop-down-select-style pagination-dynamic"
                      value={currentLimitPage}
                      onChange={(e) => ((Number(e.target.value) > 0) || (e.target.value.trim() == "")) && setCurrentLimitPage(e.target.value)}
                    >
                      <option value="2">2</option>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option defaultChecked value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                  </div>

                  <div className='drop-down-style'>
                    <select
                      className="mymap-s-selector  drop-down-select-style status"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      <option value="all">All Status</option>
                      <option value="open" selected>
                        Open Islands
                      </option>
                      <option value="closed">
                        Closed Islands
                      </option>
                    </select>
                  </div>
                  <div className="fir-tr-combo-header-input">
                    <img
                      width="20"
                      height="20"
                      src="/images/searchLogo.svg"
                      alt="search Logo"
                    />


                    <input
                      className="fir-tr-combo-header-input-search"
                      type="text"
                      placeholder="Search for island"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/*body*/}
              <div className="myt-body">
                {treasuresListData?.msg?.length === 0 ? (
                  <img src={NotFoundImage} />
                ) : treasuresListData ? (
                  treasuresListData.msg?.map((item: any, index: number) => (
                    <div key={index} className="myt-body-border">
                      <div className="myt-body-border-image">
                        <img src={config.server + item?.image} alt="nature" />
                      </div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <Link to={`/my-treasure/island?id=${item?.id}`}>
                              <div className="myt-body-header-t">
                                {item?.title}
                              </div>
                            </Link>
                            <div className="myt-body-r">
                              <div className="myt-body-r-box">
                                <div className="myt-body-r-box-title">{dictionary['TREASURE-ITEM-date1']}</div>
                                <div className="myt-body-r-box-val">
                                  {item?.start == -1
                                    ? 'Unlimited'
                                    : item?.start ? `${timestampToLocalDaily(Number(+new Date(item?.start)) / 1000)}, ${timestampToLocalHour(Number(+new Date(item?.start)) / 1000)}` : '◾️◾️◾️'}
                                </div>
                              </div>
                              <div className="myt-body-r-box">
                                <div className="myt-body-r-box-title">{dictionary['TREASURE-ITEM-date2']}</div>
                                <div className="myt-body-r-box-val">
                                  {item?.end == -1
                                    ? 'Unlimited'
                                    : item?.end ? `${timestampToLocalDaily(Number(+new Date(item?.end)) / 1000)}, ${timestampToLocalHour(Number(+new Date(item?.end)) / 1000)}` : '◾️◾️◾️'}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            {item?.short_description}
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div className="myt-body-r-box-flex">


                              <img
                                src="/images/totaldepositLogo.svg"
                                alt="total deposit"
                              />
                              <div className="myt-body-r-box-title">
                                {dictionary['TREASURE-ITEM-title1']}:
                              </div>
                              <div className="myt-body-r-box-val">
                                {item?.totalDeposit
                                  ? `$${numberWithCommas(item?.totalDeposit, 2)}`
                                  : '$0'}
                              </div>

                            </div>
                            <div className="myt-body-r-box-flex">
                              <Flex y="center" gap={0.5}>
                                <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{
                                  left: 0,
                                  width: 20,
                                  margin: 'auto 5px 9px',
                                }}>
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-ITEM-desc2']}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src="/images/availableLogo.svg"
                                  alt="available Logo"
                                />

                                <div className="myt-body-r-box-title">
                                  {dictionary['TREASURE-ITEM-title2']}:
                                </div>
                                <div className="myt-body-r-box-val">
                                  {item?.available_balance
                                    ? `$${numberWithCommas(
                                      item?.available_balance,
                                      2
                                    )}`
                                    : '$0'}
                                </div>


                              </Flex>
                            </div>
                          </div>
                          <div>
                            <div className="myt-body-r-box-flex">
                              <Flex y="center" gap={0.5}>
                                <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{
                                  left: 0,
                                  width: 20,
                                  margin: 'auto 5px 9px',
                                }}>
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-ITEM-desc3']}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src="/images/totalWithdrawnLogo.svg"
                                  alt="total Withdrawn Logo"
                                />
                                <div className="myt-body-r-box-title">
                                  {dictionary['TREASURE-ITEM-title3']}:
                                </div>
                                <div className="myt-body-r-box-val">
                                  {item?.totalWithdtraw
                                    ? `$${numberWithCommas(
                                      item?.totalWithdtraw,
                                      2
                                    )}`
                                    : '$0'}
                                </div>


                              </Flex>

                            </div>
                            <div className="myt-body-r-box-flex">
                              <Flex y="center" gap={0.5}>
                                <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{
                                  left: 0,
                                  width: 20,
                                  margin: 'auto 5px 9px',
                                }}>
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-ITEM-desc4']}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src="/images/totalcrewLogo.svg"
                                  alt="totalcrewLogo"
                                />
                                <div className="myt-body-r-box-title">
                                  {dictionary['TREASURE-ITEM-title4']}:
                                </div>
                                <div className="myt-body-r-box-val">
                                  {item?.crew_count}
                                </div>


                              </Flex>
                            </div>
                          </div>
                          <div className="myt-body-r-box-r">
                            {(item?.is_active === 2) || ((item?.is_active !== 0 && item?.balance < item?.minimum_invest) && (item?.exception === 0)) ? <button className="myt-body-r-box-r-btn deactive">
                              {dictionary['TREASURE-ITEM-button-title2']}
                            </button> : null}
                            <Link to={`/my-treasure/island?id=${item?.id}`}>
                              <button className="myt-body-r-box-r-btn">
                                {dictionary['TREASURE-ITEM-button-title1']}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="myt-body-border">
                      <div className="myt-body-border-image skeleton-box"></div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div
                              className="myt-body-header-t skeleton-box"
                              style={{ width: '30%' }}
                            ></div>
                            <div className="myt-body-r">
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            <span
                              className="skeleton-box"
                              style={{ width: '80%' }}
                            ></span>
                            <span
                              className="skeleton-box"
                              style={{ width: '60%' }}
                            ></span>
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn skeleton-box"
                              style={{ backgroundColor: '#DDDBDD' }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myt-body-border">
                      <div className="myt-body-border-image skeleton-box"></div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div
                              className="myt-body-header-t skeleton-box"
                              style={{ width: '30%' }}
                            ></div>
                            <div className="myt-body-r">
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            <span
                              className="skeleton-box"
                              style={{ width: '80%' }}
                            ></span>
                            <span
                              className="skeleton-box"
                              style={{ width: '60%' }}
                            ></span>
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn skeleton-box"
                              style={{ backgroundColor: '#DDDBDD' }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myt-body-border">
                      <div className="myt-body-border-image skeleton-box"></div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div
                              className="myt-body-header-t skeleton-box"
                              style={{ width: '30%' }}
                            ></div>
                            <div className="myt-body-r">
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            <span
                              className="skeleton-box"
                              style={{ width: '80%' }}
                            ></span>
                            <span
                              className="skeleton-box"
                              style={{ width: '60%' }}
                            ></span>
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn skeleton-box"
                              style={{ backgroundColor: '#DDDBDD' }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {treasuresListData?.totalPage ? <div className='island-list-pagination'
                style={{ marginTop: '1rem' }}
              >
                <Pagination
                  className="pagination"
                  total={treasuresListData?.totalPage}
                  current={treasuresListData?.current_page}
                  maxWidth={300}
                  a11yActiveLabel=""
                  previousLabel=" "
                  nextLabel=" "
                  renderNav={false}
                  ariaPreviousLabel={"Prev"}
                  ariaNextLabel={"Next"}
                  onPageChange={(page: any) => setCurrentPage(page)}
                /></div> : null}
            </div>
          </Flex>
        </Container>
      </Layout>
    );
  else
    return (<>{navigate('/')}</>)
};

export default MyTreasures;
