import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";

class MembershipService {
    private _httpService = HttpService('Membership');

    async payFee(formData: any) {
        try {
            const response = await this._httpService.post<any>('/PayMemebershipFee', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async payFeeRenew(formData: any) {
        try {
            const response = await this._httpService.post<any>('/RenewMemebershipFee', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async payFeeByBalance() {
        try {
            const response = await this._httpService.get<any>('/MembershipMigrated');
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getPaymentInfo() {
        try {
            const responseWire = await this._httpService.post<any>('/GetBOSWallet', { type: 0 });
            const responseCrypto = await this._httpService.post<any>('/GetBOSWallet', { type: 1 });
            if (responseWire.data.status && responseWire.data.status) return { wire: responseWire.data.message, crypto: responseCrypto.data.message }
            !responseWire.data.status && reporter(responseWire)
            !responseCrypto.data.status && reporter(responseCrypto)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getCheckUserMigrated() {
        try {
            const response = await this._httpService.get<any>('/CheckUserMigrated');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

}

export default new MembershipService()