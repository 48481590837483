import { useContext, useEffect, useState } from 'react';
import PrivateContext from '../../../context/private/privateContext';
import WizardContext from '../../../context/private/wizardContext';
import { Flex } from '../../../styles/Styles';
import AccountContext from '../../../context/private/accountContext';
const LoadingAnimation = () => {
  return (
    <Flex x="center" y="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        viewBox="0 0 100 100"
        overflow="visible"
        fill="#ff5463"
      >
        <defs>
          {' '}
          <circle
            id="spinner"
            r={4}
            cx={50}
            cy={50}
            transform="translate(0 -30)"
          />{' '}
        </defs>{' '}
        <use xlinkHref="#spinner" transform="rotate(0 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(30 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(60 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(90 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(120 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(150 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(180 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(210 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(240 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(270 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(300 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(330 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>{' '}
      </svg>
    </Flex>
  );
};

const Pending = () => {
  const { loadUserSteps, flowState } = useContext(PrivateContext);
  const { backToPayment } = useContext(WizardContext);
  const { userInfo } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadUserSteps();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <Flex
      gap={1.7}
      h="unset"
      x={'center'}
      y="center"
      style={{
        height: flowState.status == 10 && flowState.step == 15 ? '100%' : null,
        padding:
          flowState.status == 10 && flowState.step == 15 ? '0rem 0' : '0rem 0'
      }}
    >
      <div
        className={'pen-flex'}
        style={{
          padding:
            flowState.status == 10 && flowState.step == 15 ? '0rem 0' : '6rem 0'
        }}
      >
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            {flowState.status == 10 && flowState.step == 15 && (
              <div className="pend-body pending-flex">
                <div
                  className="pend-title"
                  style={{
                    color: 'red'
                  }}
                >
                  Rejected
                </div>
                <div className="pend-p">
                  <b
                    style={{
                      color: 'red'
                    }}
                  >
                    Your transaction is rejected by the team.
                  </b>
                  <p>For more information, check your email inbox.</p>
                  <p
                    style={{
                      color: '#5f5f5f',
                      marginTop: '5px'
                    }}
                  >
                    Support:{' '}
                    <a href="mailto:hello@blueoceansociety.club">
                      hello@blueoceansociety.club
                    </a>
                  </p>
                </div>
                <button
                  className="kvc-btn pointer"
                  onClick={() => backToPayment()}
                >
                  Back to Payment
                </button>
              </div>
            )}
            {flowState.status == 3 && flowState.step == 15 && (
              <div className="pend-body pending-flex">
                <div className="pend-body-head">
                  <img src="/images/wine.svg" alt="wine" />
                </div>
                <div className="pend-title">
                  Membership <br />
                  Payment Pending
                </div>
                <div className="pend-p">
                  Thank you for your payment, your transaction is now under review.
                  <br />
                  <br />
                  <p style={{ textAlign: 'left' }}>
                    <b>Cryptocurrency</b><br />
                    If you opted to pay your membership fee by way of cryptocurrency, you will receive an email once the transaction has been reviewed. If approved, you will be granted access to the Dashboard.
                    <br />
                    <br />
                    <b>Bank Transfer</b><br />
                    If your membership fee was paid by way of bank transfer, keep an eye out for an email acknowledging that your bank transfer is in progress.  Depending on the bank, it can take anywhere from 2 to 14 days to process a bank transfer.  If the transfer fails, please let us know right away. Otherwise, we’ll let you know by email once the bank transfer has cleared and access to the Dashboard has been granted.
                    <br />
                    <br />                    
                    *Please note, upon approval, you may need to refresh your page to proceed into the BOS Dashboard.
                    <br />
                    <br />
                    If we require more information to approve your BOS membership, our BOS Support team will reach out to you via your registered email.
                  </p>
                </div>
              </div>
            )}

            {userInfo && userInfo?.pendingMembershipRequest === 1 && flowState.step == 17 && (
              <div className="pend-body pending-flex">
                <div className="pend-body-head">
                  <img src="/images/wine.svg" alt="wine" />
                </div>
                <div className="pend-title">
                  Membership Renewal<br />
                  Payment Pending
                </div>
                <div className="pend-p">
                  Thank you for your renewal payment, your transaction is now under review.
                  <br />
                  <br />
                  <p style={{ textAlign: 'left' }}>
                    <b>Cryptocurrency</b><br />
                    If you opted to pay your membership renewal fee by way of cryptocurrency, you will receive an email once the transaction has been reviewed. If approved, you will be granted access to the Dashboard.
                    <br />
                    <br />
                    <b>Bank Transfer</b><br />
                    If your membership renewal fee was paid by way of bank transfer, keep an eye out for an email acknowledging that your bank transfer is in progress.  Depending on the bank, it can take anywhere from 2 to 14 days to process a bank transfer.  If the transfer fails, please let us know right away. Otherwise, we’ll let you know by email once the bank transfer has cleared and access to the Dashboard has been granted.
                    <br />
                    <br />
                    <b>BOS Main Balance</b><br />
                    If you paid your membership renewal fee from your BOS Main Balance, you will receive an email shortly, confirming your payment and granting you access to the Dashboard. Inside the Dashboard you will see your BOS Main Balance is now showing a balance minus USD $200 to reflect the membership fee deduction.
                    <br />
                    <br />
                    *Please note, upon approval, you may need to refresh your page to proceed into the BOS Dashboard.
                    
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Flex>
  );
};

export default Pending;
