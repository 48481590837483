import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import WizardContext from '../../../../context/private/wizardContext';
import { countries } from '../../../../context/public/countries';
import { Flex } from '../../../../styles/Styles';
import { timeToYYYYMMDD } from '../../../../utils/tools';
import wazardValidation from '../../../../validations/wazardValidation';
import DickPickerInput from './date-picker';
import TooltipInfo from '../../../TooltipInfo';

import DropZone from './DropZone';
import FileTile from './FileTile';


interface FileData {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  percent: number;
}


const LoadingAnimation = () => {
  return (
    <Flex x="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        viewBox="0 0 100 100"
        overflow="visible"
        fill="#ff5463"
      >
        <defs>
          {' '}
          <circle
            id="spinner"
            r={4}
            cx={50}
            cy={50}
            transform="translate(0 -30)"
          />{' '}
        </defs>{' '}
        <use xlinkHref="#spinner" transform="rotate(0 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(30 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(60 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(90 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(120 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(150 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(180 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(210 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(240 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(270 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(300 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(330 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>{' '}
      </svg>
    </Flex>
  );
};

const Input = () => {
  const [currentCountry, setCurrentCountry] = useState(null);
  const {
    loadCountries,
    countriesList,
    applicationFormData,
    setApplicationFormData,
    setErrorsApplicationForm,
    errorsApplicationForm
  } = useContext(WizardContext);
  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (applicationFormData) setCurrentCountry(applicationFormData.country_id);
  }, [applicationFormData, countriesList]);


  return (
    <div className="appf-type-info">
      {countries && countries.length > 0 && (
        <select
          className="appf-type-selector"
          onChange={(e) => {
            setApplicationFormData({
              ...applicationFormData,
              country_id: e.target.value
            });

            const error = wazardValidation.personalRegisterPendingProperty(
              'country_id',
              e.target.value
            );

            setErrorsApplicationForm({
              ...errorsApplicationForm,
              country_id: error
            });
          }}

          value={applicationFormData?.country_id}
        >
          <option value={''} selected={true}>Select Country</option>
          {countries.map((type: { id: number; name: string }, index: number) => {
            return <option key={index} value={type.id}>{type.name}</option>;
          })}
        </select>
      )}
      {errorsApplicationForm && (
        <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
          {errorsApplicationForm?.country_id}
        </p>
      )}
    </div>
  );
};

const Input2 = () => {
  const [currentCountry, setCurrentCountry] = useState(null);
  const {
    loadCountries,
    countriesList,
    applicationFormData,
    setApplicationFormData,
    errorsApplicationForm,
    setErrorsApplicationForm
  } = useContext(WizardContext);
  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
    if (applicationFormData)
      setCurrentCountry(applicationFormData.organization_country_id);
  }, [applicationFormData, countriesList]);

  return (
    <div className="appf-type-info">
      {countries && countries.length > 0 && (
        <select
          className="appf-type-selector"
          onChange={(e) => {
            // localStorage.setItem('organization_country_id', e.target.value)
            setApplicationFormData({
              ...applicationFormData,
              organization_country_id: e.target.value
            });


          }}
          value={applicationFormData?.organization_country_id}
        >
          <option value={''} selected={true}>Select Country</option>
          {countries.map((type: { id: number; name: string }, index: number) => {
            return <option key={index} value={type.id}>{type.name}</option>;
          })}
        </select>
      )}

    </div>
  );
};

const MultiUploadFiles = ({ isTrust }: any) => {
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [uploadFiles, setUploadFiles] = useState<any[]>([]);

  const { attachmentUploadHandle, isUploaded, setIsUploaded } = useContext(WizardContext);


  const handleFile = async (file: File, id: string) => {
    const { name, size, type, lastModified } = file;
    console.log("filez", file)
    const percent = 100;
    setUploadFiles([
      ...uploadFiles,
      {
        name,
        size,
        type,
        lastModified,
        file: file
      },
    ]);
  };

  const handleDropFiles = (files: File[]) => {
    files.forEach((f, i) => handleFile(f, `${new Date().getTime()}-${i}`));
  };

  const updateProgress = (p: number) => {
    const interval = setTimeout(() => {
      console.log(p);
      if (p > 100) {
        console.log('cleared');
        clearInterval(interval);
        return;
      }

      const updatedFiles = uploadFiles.map((f) => ({ ...f, percent: p }));
      setUploadPercent(p + 1);
      setUploadFiles(updatedFiles);
      updateProgress(p + 1);
    }, 100);
  };

  const handleSubmit = () => {
    setUploadPercent(0);
    updateProgress(uploadPercent);

    const formDataw = new FormData();
    uploadFiles.map((file) => formDataw.append(`file`, file.file));
    attachmentUploadHandle(formDataw);
  };

  const handleRemove = (f: FileData) => {
    setUploadFiles(uploadFiles.filter((x) => x.name !== f.name));
  };

  return (
    isUploaded ?
      <>
        <span className='uploader-message'>Your documents have been successfully uploaded.</span>
        <p>
          <button className='uploader-btn-again' onClick={() => {
            setIsUploaded(false)
            setUploadFiles([])
          }}>Upload again</button>
        </p>
      </>
      :
      <div className="multi-upload-container">
        <h3>Organization documentary evidence</h3>
        {isTrust ? <p> </p> : <p> </p>}
        <p>
        </p>
        {uploadFiles.length >= 5 ? null : <DropZone files={uploadFiles} onDropFiles={handleDropFiles} />}
        <p />
        <div className="file-list">
          <label>Selected Files</label>
          {uploadFiles.map((f, i) => (
            <FileTile key={i} file={f} onRemove={handleRemove} />
          ))}
        </div>

        <p>
          <button className='uploader-btn'
            onClick={() => handleSubmit()}
          // onClick={() => setIsUploaded(true)}
          >Upload selected files</button>
        </p>
      </div>
  );
}

const AccountInformationForm = () => {
  const {
    isCheckRejection,
    setIsCheckRejection,
    loadUserType,
    userType,
    registerPendingDescription,
    errorsApplicationForm,
    setErrorsApplicationForm,
    backToFirstStepApplicationForm,
    applicationFormData,
    setApplicationFormData,
    applicationFormHandle,
    datePickerRegisterPending,
    isLockedBtnApplicationForm,
    loadRegisterPendingFormData,
    isUploaded
  } = useContext(WizardContext);

  const submitHandle = () => {
    const formData = new FormData();
    formData.append('user_type', userType);
    formData.append('second_register', applicationFormData.second_register);

    formData.append('first_name', applicationFormData.first_name);
    formData.append('middle_name', applicationFormData.middle_name);
    formData.append('last_name', applicationFormData.last_name);
    formData.append('birthday', timeToYYYYMMDD(datePickerRegisterPending));

    formData.append('street_address', applicationFormData.street_address);
    formData.append('street_address2', applicationFormData.street_address2);
    formData.append('city', applicationFormData.city);
    formData.append('state_provence', applicationFormData.state_provence);
    formData.append('zip_code', applicationFormData.zip_code);
    formData.append('residential_country_id', applicationFormData.country_id);

    formData.append('telegram_username', applicationFormData.telegram_username);
    formData.append('social_x_link', applicationFormData.social_x_link);
    formData.append('social_linkdin_link', applicationFormData.social_linkdin_link);
    formData.append('social_facebook_link', applicationFormData.social_facebook_link);

    switch (Number(userType)) {
      case 1:
        formData.append(
          'organization_name',
          applicationFormData.organization_name
        );
        formData.append(
          'organization_identification',
          applicationFormData.organization_identification
        );
        formData.append(
          'organization_street_address',
          applicationFormData.organization_street_address
        );
        formData.append(
          'organization_street_address_2',
          applicationFormData.organization_street_address_2
        );
        formData.append(
          'organization_city',
          applicationFormData.organization_city
        );
        formData.append(
          'organization_state',
          applicationFormData.organization_state
        );
        formData.append(
          'organization_zip_code',
          applicationFormData.organization_zip_code
        );
        formData.append(
          'organization_country_id',
          applicationFormData?.organization_country_id ? applicationFormData.organization_country_id : 0
        );
        break;

      case 2:
        formData.append(
          'organization_name',
          applicationFormData.organization_name
        );
        formData.append(
          'organization_street_address',
          applicationFormData.organization_street_address
        );
        formData.append(
          'organization_street_address_2',
          applicationFormData.organization_street_address_2
        );
        formData.append(
          'organization_city',
          applicationFormData.organization_city
        );
        formData.append(
          'organization_state',
          applicationFormData.organization_state
        );
        formData.append(
          'organization_zip_code',
          applicationFormData.organization_zip_code
        );
        formData.append(
          'organization_country_id',
          applicationFormData?.organization_country_id ? applicationFormData.organization_country_id : 0
        );
        break;

      case 3:
        formData.append(
          'organization_name',
          applicationFormData.organization_name
        );
        formData.append(
          'organization_identification',
          applicationFormData.organization_identification
        );
        formData.append(
          'organization_street_address',
          applicationFormData.organization_street_address
        );
        formData.append(
          'organization_street_address_2',
          applicationFormData.organization_street_address_2
        );
        formData.append(
          'organization_city',
          applicationFormData.organization_city
        );
        formData.append(
          'organization_state',
          applicationFormData.organization_state
        );
        formData.append(
          'organization_zip_code',
          applicationFormData.organization_zip_code
        );
        formData.append(
          'organization_country_id',
          applicationFormData?.organization_country_id ? applicationFormData.organization_country_id : 0
        );
        break;

      case 4:
        formData.append(
          'organization_name',
          applicationFormData.organization_name
        );
        formData.append(
          'organization_identification',
          applicationFormData.organization_identification
        );
        formData.append(
          'organization_street_address',
          applicationFormData.organization_street_address
        );
        formData.append(
          'organization_street_address_2',
          applicationFormData.organization_street_address_2
        );
        formData.append(
          'organization_city',
          applicationFormData.organization_city
        );
        formData.append(
          'organization_state',
          applicationFormData.organization_state
        );
        formData.append(
          'organization_zip_code',
          applicationFormData.organization_zip_code
        );
        formData.append(
          'organization_country_id',
          applicationFormData?.organization_country_id ? applicationFormData.organization_country_id : 0
        );
        break;

      case 5:
        break;

      default:
        break;
    }

    formData.append('beneficiary_name', applicationFormData.beneficiary_name);
    formData.append('beneficiary_email', applicationFormData.beneficiary_email);

    formData.append('front', applicationFormData.front);
    formData.append('back', applicationFormData.back);
    formData.append('proof', applicationFormData.proof);

    if (userType !== 5) {
      if (isUploaded) {
        applicationFormHandle(formData, applicationFormData);
      } else {
        toast.error("Uploading a legal identity document is mandatory.")
      }
    } else {
      applicationFormHandle(formData, applicationFormData);
    }

  };

  useEffect(() => {
    loadRegisterPendingFormData();
  }, []);

  useEffect(() => {
    loadUserType();
  }, []);

  return (
    <div className="appf-form">
      <div className="appf-form-h">Information</div>

      <p className="appf-form-cap">
        {userType !== 2
          ? 'Please provide the full legal name of the person or entity that is registered as the owner of the BOS Account'
          : 'Full legal name of the Trustee who is the BOS Account holder'}
      </p>
      {registerPendingDescription && registerPendingDescription.length > 0 && (
        <p
          className="appf-form-cap"
          style={{
            color: 'red'
          }}
        >
          Reason for rejection: {registerPendingDescription}
        </p>
      )}
      {isCheckRejection ? (
        <div className="appf-form-parent">
          <div className="appf-form-input-p">
            <label className="appf-form-input-label">Account Holder:</label>
            <div className="appf-form-input-combo" style={{ gap: 'unset' }}>
              <input
                type="text"
                placeholder="First name *"
                className="appf-form-input"
                value={applicationFormData.first_name}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    first_name: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'first_name',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    first_name: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.first_name}
                </p>
              )}

              <input
                type="text"
                placeholder="Middle name"
                className="appf-form-input"
                value={applicationFormData.middle_name}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    middle_name: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'middle_name',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    middle_name: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.middle_name}
                </p>
              )}

              <input
                type="text"
                placeholder="Last name *"
                className="appf-form-input"
                value={applicationFormData.last_name}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    last_name: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'last_name',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    last_name: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.last_name}
                </p>
              )}

              {/* <input
              type="text"
              placeholder="Date of birth"
              className="appf-form-input"
              value={applicationFormData.birthday}
              onChange={(e) => {
                setApplicationFormData({ ...applicationFormData, birthday: e.target.value })
                window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
              }
              }
            /> */}

              <DickPickerInput />
            </div>
          </div>


          <div className="appf-form-input-p">
            <label className="appf-form-input-label">
              Residential address:
            </label>

            <input
              type="text"
              placeholder="Street address 1 *"
              className="appf-form-input"
              value={applicationFormData.street_address}
              onChange={(e) => {
                setApplicationFormData({
                  ...applicationFormData,
                  street_address: e.target.value
                });
                // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                const error = wazardValidation.personalRegisterPendingProperty(
                  'street_address',
                  e.target.value
                );
                setErrorsApplicationForm({
                  ...errorsApplicationForm,
                  street_address: error
                });
              }}
            />
            {errorsApplicationForm && (
              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                {errorsApplicationForm?.street_address}
              </p>
            )}

            <input
              type="text"
              placeholder="Street address 2"
              className="appf-form-input"
              value={applicationFormData.street_address2}
              onChange={(e) => {
                setApplicationFormData({
                  ...applicationFormData,
                  street_address2: e.target.value
                });
                // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                const error = wazardValidation.personalRegisterPendingProperty(
                  'street_address2',
                  e.target.value
                );
                setErrorsApplicationForm({
                  ...errorsApplicationForm,
                  street_address2: error
                });
              }}
            />
            {errorsApplicationForm && (
              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                {errorsApplicationForm?.street_address2}
              </p>
            )}

            <input
              type="text"
              placeholder="City *"
              className="appf-form-input"
              value={applicationFormData.city}
              onChange={(e) => {
                setApplicationFormData({
                  ...applicationFormData,
                  city: e.target.value
                });
                // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                const error = wazardValidation.personalRegisterPendingProperty(
                  'city',
                  e.target.value
                );
                setErrorsApplicationForm({
                  ...errorsApplicationForm,
                  city: error
                });
              }}
            />
            {errorsApplicationForm && (
              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                {errorsApplicationForm?.city}
              </p>
            )}

            <input
              type="text"
              placeholder="State / Province *"
              className="appf-form-input"
              value={applicationFormData.state_provence}
              onChange={(e) => {
                setApplicationFormData({
                  ...applicationFormData,
                  state_provence: e.target.value
                });
                // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                const error = wazardValidation.personalRegisterPendingProperty(
                  'state_provence',
                  e.target.value
                );
                setErrorsApplicationForm({
                  ...errorsApplicationForm,
                  state_provence: error
                });
              }}
            />
            {errorsApplicationForm && (
              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                {errorsApplicationForm?.state_provence}
              </p>
            )}

            <input
              type="text"
              placeholder="Zip code *"
              className="appf-form-input"
              value={applicationFormData.zip_code}
              onChange={(e) => {
                setApplicationFormData({
                  ...applicationFormData,
                  zip_code: e.target.value
                });
                // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                const error = wazardValidation.personalRegisterPendingProperty(
                  'zip_code',
                  e.target.value
                );
                setErrorsApplicationForm({
                  ...errorsApplicationForm,
                  zip_code: error
                });
              }}
            />
            {errorsApplicationForm && (
              <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                {errorsApplicationForm?.zip_code}
              </p>
            )}

            <Input />
          </div>

          <div className="appf-form-input-p">
            <label className="appf-form-input-label">Telegram Handle:</label>
            <div className="appf-form-input-p" style={{ position: 'relative' }}>
              <TooltipInfo
                isInInput={true}
                text={<>
                  If you have Telegram, please fill in your Telegram Handle. If you don't use Telegram, leave this field empty.
                  <hr />
                  BOS features its own Social Hub, which serves as our primary platform for community engagement.
                </>}
              />
              <input
                type="text"
                placeholder="Telegram"
                className="appf-form-input"
                value={applicationFormData.telegram_username}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    telegram_username: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'telegram_username',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    telegram_username: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.telegram_username}
                </p>
              )}
            </div>
          </div>
          <div className="appf-form-input-p" >
            <label className="appf-form-input-label">Social Media:</label>
            <p className="appf-form-cap">To ensure a quick approval process please provide your social media links below. </p>
            <div className="appf-form-input-p" style={{ position: 'relative' }}>
              <input
                type="text"
                placeholder="Facebook"
                className="appf-form-input"
                value={applicationFormData.social_facebook_link}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    social_facebook_link: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'social_facebook_link',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    social_facebook_link: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.social_facebook_link}
                </p>
              )}
            </div>
            <div className="appf-form-input-p" style={{ position: 'relative' }}>
              <input
                type="text"
                placeholder="LinkedIn"
                className="appf-form-input"
                value={applicationFormData.social_linkdin_link}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    social_linkdin_link: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'social_linkdin_link',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    social_linkdin_link: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.social_linkdin_link}
                </p>
              )}
            </div>
            <div className="appf-form-input-p" style={{ position: 'relative' }}>
              <input
                type="text"
                placeholder="X (formerly Twitter)"
                className="appf-form-input"
                value={applicationFormData.social_x_link}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    social_x_link: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'social_x_link',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    social_x_link: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.social_x_link}
                </p>
              )}
            </div>
          </div>

          <div className="appf-form-input-p">
            <label className="appf-form-input-label">Secondary Contact:</label>
            <div className="appf-form-input-combo">
              <TooltipInfo
                isInInput={true}
                text="Your secondary contact isn't the individual who inherits your assets, but instead serves as your delegate if you're unable to express your wishes. BOS has designed protocols to assist beneficiaries under unexpected events or incapacitation. If such circumstances arise, individuals can turn to our support team for the required guidance. We kindly urge you to provide the relevant person with your login information and necessary instructions."
              />
              <input
                type="text"
                placeholder="Full name of the secondary contact"
                className="appf-form-input"
                value={applicationFormData.beneficiary_name}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    beneficiary_name: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'beneficiary_name',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    beneficiary_name: error
                  });
                }}
              />
              {/* {errorsApplicationForm && <p style={{ 'color': 'red', 'margin': '0 0 1rem 0' }}>{errorsApplicationForm?.beneficiary_name}</p>} */}

              <input
                type="text"
                placeholder="The secondary contact's email address"
                className="appf-form-input"
                value={applicationFormData.beneficiary_email}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    beneficiary_email: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error =
                    wazardValidation.personalRegisterPendingProperty(
                      'beneficiary_email',
                      e.target.value
                    );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    beneficiary_email: error
                  });
                }}
              />
              {/* {errorsApplicationForm && <p style={{ 'color': 'red', 'margin': '0 0 1rem 0' }}>{errorsApplicationForm?.beneficiary_email}</p>} */}
            </div>
          </div>

          {Number(userType) !== 5 && (
            <div className="appf-form-input-p">
              <label className="appf-form-input-label">
                {Number(userType) === 1 && 'Company, PTY LTD or LLC:'}
                {Number(userType) === 2 && 'Trust:'}
                {Number(userType) === 3 && 'SuperFund or SMSF:'}
                {Number(userType) === 4 && 'IRA, UNA:'}
              </label>

              <input
                type="text"
                placeholder="Organization Name"
                className="appf-form-input"
                value={applicationFormData.organization_name}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    organization_name: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  const error = wazardValidation.trustRegisterPendingProperty(
                    'organization_name',
                    e.target.value
                  );
                  setErrorsApplicationForm({
                    ...errorsApplicationForm,
                    organization_name: error
                  });
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.organization_name}
                </p>
              )}

              <>
                {Number(userType) !== 2 && (
                  <>
                    <input
                      type="text"
                      placeholder="Unique Organization Identification Number"
                      className="appf-form-input"
                      value={applicationFormData.organization_identification}
                      onChange={(e) => {
                        setApplicationFormData({
                          ...applicationFormData,
                          organization_identification: e.target.value
                        });
                        // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                        const error =
                          wazardValidation.othersRegisterPendingProperty(
                            'organization_identification',
                            e.target.value
                          );
                        setErrorsApplicationForm({
                          ...errorsApplicationForm,
                          organization_identification: error
                        });
                      }}
                    />
                    {errorsApplicationForm && (
                      <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                        {errorsApplicationForm?.organization_identification}
                      </p>
                    )}
                  </>
                )}
              </>
              <input
                type="text"
                placeholder="Organization Street Address 1"
                className="appf-form-input"
                value={applicationFormData.organization_street_address}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    organization_street_address: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  // const error = wazardValidation.trustRegisterPendingProperty("organization_street_address", e.target.value)
                  // setErrorsApplicationForm({ ...errorsApplicationForm, "organization_street_address": error })
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>{ }</p>
              )}

              <input
                type="text"
                placeholder="Organization Street Address 2"
                className="appf-form-input"
                value={applicationFormData.organization_street_address_2}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    organization_street_address_2: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  // const error = wazardValidation.trustRegisterPendingProperty("organization_street_address_2", e.target.value)
                  // setErrorsApplicationForm({ ...errorsApplicationForm, "organization_street_address_2": error })
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>{ }</p>
              )}

              <input
                type="text"
                placeholder="Organization City"
                className="appf-form-input"
                value={applicationFormData.organization_city}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    organization_city: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  // const error = wazardValidation.othersRegisterPendingProperty("organization_city", e.target.value)
                  // setErrorsApplicationForm({ ...errorsApplicationForm, "organization_city": error })
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>{ }</p>
              )}

              <input
                type="text"
                placeholder="Organization State"
                className="appf-form-input"
                value={applicationFormData.organization_state}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    organization_state: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  // const error = wazardValidation.othersRegisterPendingProperty("organization_state", e.target.value)
                  // setErrorsApplicationForm({ ...errorsApplicationForm, "organization_state": error })
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>{ }</p>
              )}

              <input
                type="text"
                placeholder="Organization Zip code"
                className="appf-form-input"
                value={applicationFormData.organization_zip_code}
                onChange={(e) => {
                  setApplicationFormData({
                    ...applicationFormData,
                    organization_zip_code: e.target.value
                  });
                  // window.localStorage.setItem('applicationFormData', JSON.stringify(applicationFormData));
                  // const error = wazardValidation.othersRegisterPendingProperty("organization_zip_code", e.target.value)
                  // setErrorsApplicationForm({ ...errorsApplicationForm, "organization_zip_code": error })
                }}
              />
              {errorsApplicationForm && (
                <p style={{ color: 'red', margin: '0 0 1rem 0' }}>
                  {errorsApplicationForm?.organization_zip_code}
                </p>
              )}

              <Input2 />

              {(Number(userType) === 1 || Number(userType) === 2 || Number(userType) === 3 || Number(userType) === 4) && <MultiUploadFiles isTrust={Number(userType) === 2} />}
            </div>
          )}

          <button
            className="appf-form-submit pointer"
            onClick={() =>
              !isLockedBtnApplicationForm ? submitHandle() : null
            }
          >
            Submit
          </button>
        </div>
      ) : (
        <LoadingAnimation />
      )}
    </div>
  );
};

export default AccountInformationForm;
