import { useContext, useEffect, useState } from 'react';
import Faq from 'react-faq-component';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Loader from './Loader';
import Layout from './layout/Index';
import { TbMessage2Question } from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import { BiDislike, BiLike, BiSolidDislike, BiSolidLike } from 'react-icons/bi';
import { AsideItems, SearchBox, SearchWrapper } from './FaqsList';

const FaqsPage = () => {
  const {
    loadFaq,
    faqsData,
    faqsSectionData,
    loadFaqSection,
    faqsTopicData,
    loadFaqTopic,
    faqsQuestionData,
    loadFaqQuestion,
    popularQuestionsData,
    loadPopularQuestions,
    faqsQuestionByIdData,
    loadFaqQuestionById,
    voteHandle,
    searchQuery
  } = useContext(PublicContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({
    title: 'FAQs',
    rows: []
  });

  useEffect(() => {
    if (faqsData) {
      data.rows = faqsData;
      setData({
        ...data,
        rows: faqsData
      });
    }
  }, [faqsData]);

  const [topicID, setTopicID] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const targetParam = urlParams.get('id');
    const targetParam2 = urlParams.get('topic-id');
    setTopicID(targetParam2);

    if (Boolean(searchQuery && searchQuery.length > 0)) {
    } else {
      if (targetParam) {
        loadFaqQuestionById(targetParam, targetParam2);
      } else {
        // loadFaqQuestion();
      }
    }

  }, [location.search, searchQuery]);

  useEffect(() => {
    loadPopularQuestions();
  }, [])

  return (
    <Layout className="parent">
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title="Frequently Asked Questions" />

            <SearchBox />

            <div className="faq-system-wrapper">
              <SearchWrapper>
                <div className="faq-list-content">
                  <div className="faq-topic-list">
                    <h2 className="faq-topic-list-title pointer">
                      <Link
                        to={
                          faqsQuestionByIdData
                            ? `/faq-system/questions?id=${faqsQuestionByIdData?.Topic?.[0]?.id}&topic-id=${topicID}`
                            : ''
                        }
                      >
                        {faqsQuestionByIdData ? (
                          faqsQuestionByIdData?.Topic?.[0]?.title
                        ) : (
                          <div className="skeleton-element-item skeleton-box"></div>
                        )}{' '}
                      </Link>
                      /{' '}
                      {faqsQuestionByIdData ? (
                        faqsQuestionByIdData?.FAQ?.[0]?.question
                      ) : (
                        <div className="skeleton-element-item skeleton-box"></div>
                      )}
                    </h2>
                    {faqsQuestionByIdData ? (
                      <div
                        className="faq-topic-list-content"
                        dangerouslySetInnerHTML={{
                          __html: faqsQuestionByIdData?.FAQ?.[0]?.answer
                        }}
                      ></div>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                    <div className="faq-topic-list-like-dislike">
                      <span>Was this article helpful?</span>
                      <BiSolidLike
                        className="faq-like-dislike-icon pointer"
                        color="#979797"
                        size={'1.2em'}
                        onClick={() =>
                          voteHandle(0, faqsQuestionByIdData?.FAQ?.[0]?.id)
                        }
                      />
                      <BiDislike
                        className="faq-like-dislike-icon pointer"
                        color="#979797"
                        size={'1.2em'}
                        onClick={() =>
                          voteHandle(1, faqsQuestionByIdData?.FAQ?.[0]?.id)
                        }
                      />
                    </div>
                  </div>
                </div>
              </SearchWrapper>
              <AsideItems />
            </div>
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

export default FaqsPage;
