import React, { useContext, useEffect, useState } from 'react'
import TreasuryContext from '../../../context/private/treasuryContext'
import Tab from './tab'
import { Link } from 'react-router-dom'
import useGetDataFromUrl from '../../../hooks/useGetDataFromUrl'
import PrivateContext from '../../../context/private/privateContext'

const Tabs = () => {
  const { setUpdateTimer } = useContext(PrivateContext);
  const { currentTab, setCurrentTab, islandsDetailData, setModalIslandTransferToIsland } = useContext(TreasuryContext)
  const tabs = ['Overview', 'My Crew', 'Transactions']

  useEffect(() => {
    const id = window.location.hash
    if (id === '#tx') setCurrentTab(2)

  }, [])

  useEffect(() => {
    setUpdateTimer(+new Date)
  }, [currentTab])


  return (
    <div className="over-nav">
      {tabs.map((tab, i) => <Tab key={i} title={tab} currentTab={currentTab} setCurrentTab={setCurrentTab} index={i} />)}
      {/* {islandsDetailData?.transfer_out == 1 && <span onClick={() => setModalIslandTransferToIsland(true)}><Tab title={'Transfer'} currentTab={currentTab}
        setCurrentTab={setCurrentTab} index={3} /></span>} */}
      <Link to={`/island-page?id=${window.location.search.split('?id=')[1]}`}>
        <Tab title={'Island Info'} currentTab={currentTab}
          setCurrentTab={setCurrentTab} index={tabs.length + 2} />
      </Link>
    </div>
  )
}
export default Tabs