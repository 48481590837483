import React, { useContext, useEffect, useState } from 'react';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Layout from './layout/Index';
import Loader from './Loader';
import PrivateContext from '../context/private/privateContext';

import { IoMdClose } from 'react-icons/io';
import Pagination from 'react-responsive-pagination';
import { timeToYYYYMMDD, timestampToLocalHour, timestampToLocalHour2 } from '../utils/tools';
import { useNavigate } from 'react-router-dom';
import dictionary from "../locals/my-account.json"
// import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import MapContext from '../context/private/mapContext';
import AccountContext from '../context/private/accountContext';


const Logs = () => {




  const { flowState } = useContext(PrivateContext);
  const navigate = useNavigate();

  const { loadPrivacyPolicy, privacyPolicy } = useContext(PublicContext);
  const { settings } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);

  const {
    removeNotificationHandle,
    removeAllNotificationHandle,
    notificationAllHandle,
    currentPageNotification,
    setCurrentPageNotification,
    getNotificationSingle,
    notificationModal,
    loadNotification,
    notificationHandle,
    notification,
    loadHistoryLog, historyLog, allNotificationHandle,
    handshake, setUpdateTimer, submitAgreementForm
  } = useContext(PrivateContext);

  const [isMore, setIsMore] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: '',
    description: ''
  });
  const [isHaveNotification, setIsHaveNotification] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0);
    loadHistoryLog()
  }, []);
  useEffect(() => {
    setUpdateTimer(+new Date)
    loadHistoryLog(currentPageNotification)
  }, [currentPageNotification]);


  const [modal2, setModal2] = useState(false);
  const [isAccept, setIsAccept] = useState(false);


  if (flowState?.step === 17 && userInfo
    && userInfo?.membershipStatus !== 2)
    return (
      <>
        {modal && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">{modalData?.title}</p>
                  <button className="mod-box-btn" onClick={() => setModal(false)}>
                    <IoMdClose />
                  </button>
                </div>

                <div className="mod-inside">
                  <div className="mod-inside-body">
                    <p className="modal-description"
                      dangerouslySetInnerHTML={{
                        __html: modalData?.description
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="blur-bg" onClick={() => setModal(false)}></div>
          </Flex>
        )}

        {modal2 && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border w-full">
                <div className="mod-box">
                  <p className="mod-box-p">{notificationModal?.[0]?.title}</p>
                  {((notificationModal?.[0].has_close === true) || (notificationModal?.[0]?.acknowledge)) ? <button className="mod-box-btn" onClick={() => {
                    !(notificationModal?.[0]?.acknowledge) && submitAgreementForm(notificationModal?.[0]?.id, 1);
                    setModal2(false);
                    setIsAccept(false)
                  }}>
                    <IoMdClose />
                  </button> : null}
                </div>


                <div className="boscrew-t-row">
                  <div
                    className="boscrew-t-cap"
                    dangerouslySetInnerHTML={{
                      __html: notificationModal?.[0]?.content
                    }}
                  ></div>

                  <div className='leading-[0.8] w-full'><p className='font-normal'>If you have questions about this notification please contact</p> <br /><span className='text-md'><a href="mailto:hello@blueoceansociety.club">hello@blueoceansociety.club</a></span></div>
                  {!(notificationModal?.[0]?.acknowledge) && <div className="boscrew-t-end">
                    <div className="boscrew-t-line">
                      <div className="boscrew-t-combo">
                        <input
                          type="checkbox"
                          id="horns"
                          name="horns"
                          checked={isAccept}
                          onChange={(e) => setIsAccept(e.target.checked)}
                        />
                        <label
                          className="boscrew-t-label"
                          htmlFor="horns"
                        >
                          I acknowledge that I have read and understand this notification
                        </label>
                      </div>
                      <div className="boscrew-t-for-btn">
                        <button
                          className={
                            isAccept
                              ? 'intr-last-row-right-btn'
                              : 'intr-last-row-right-btn disable'
                          }
                          disabled={!isAccept}
                          onClick={() => {
                            isAccept ? submitAgreementForm(notificationModal?.[0]?.id, 0) : null
                            isAccept ? setModal2(false) : null
                            isAccept ? setIsAccept(false) : null
                          }
                          }
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>}
                </div>

              </div>
            </div>
            <div className="blur-bg" onClick={() => (notificationModal?.[0]?.acknowledge) && setModal2(false)}></div>
          </Flex>
        )}

        <Layout className="parent">
          <Container
            x={'center'}
            marginT={1}
            className="container container-boscrew"
          >



            <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
              <div className="boscrew" style={{ width: '100%' }}>
                {/* header*/}

                <Header title={dictionary['PAGE-title4']} />

                {settings ? (
                  <div className="explorer-body">
                    <div className="explorer-content" style={{ gap: 0 }}>
                      {historyLog ? <h4>{dictionary['MY-ACCOUNT-title128']}</h4> : <h4><div className='skeleton-element-item  ck-editor-title skeleton-box'></div></h4>}
                      <div className='flex flex-row justify-between w-full' style={{
                        justifyContent: 'space-between',
                        marginBottom: '-2rem',
                        marginTop: '2rem',

                      }}>
                        <div className="notification-title">Description</div>
                        <div className="notification-title">Date & Time</div>
                      </div>
                      <div
                        className="content notification-holder"
                      >
                        <div className="notification-container">
                          <div className="notification">

                            {historyLog ? historyLog?.msg?.length ? (
                              <>
                                <div className="notification notification-list">
                                  {historyLog?.msg.map((item: any, index: any) => (<>

                                    <div className="mymap-f-row-bottom" style={{ cursor: 'pointer' }} onClick={() => {
                                      if (item?.type) {
                                        getNotificationSingle(item?.id);
                                        setModal2(true)
                                      }
                                    }}>
                                      <div className="mymap-f-row-bottom-cap">{item?.type ? item?.title : item?.description}</div>
                                      <div className="mymap-f-row-bottom-right">
                                        {item?.timestamp ? <div className="mymap-f-row-bottom-right-td">
                                          <div className="mymap-f-row-bottom-right-detail">
                                            Date:
                                          </div>
                                          <div className="mymap-f-row-bottom-right-financial">
                                            {timeToYYYYMMDD(+new Date(item?.timestamp))}{', '}{timestampToLocalHour2(+new Date(item?.timestamp))}
                                          </div>
                                        </div> : null}
                                      </div>
                                    </div>
                                  </>))}
                                </div>

                                {historyLog?.total_page ? <Pagination
                                  className="pagination"
                                  total={historyLog?.total_page}
                                  current={historyLog?.current_page}
                                  maxWidth={80}
                                  renderNav={false}
                                  ariaPreviousLabel=" "
                                  ariaNextLabel=" "
                                  a11yActiveLabel=""
                                  onPageChange={(page) => setCurrentPageNotification(page)}
                                /> : null}
                              </>
                            ) :
                              <p className="notification-empty">Not found</p>
                              : <>
                                <div className='skeleton-element-item ck-editor-content skeleton-box'></div>
                              </>}
                          </div></div>

                      </div>
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </Flex>
          </Container>
        </Layout>
      </>
    );
  else
    return (<>{navigate('/')}</>)

};

export default Logs;
