import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import WalletContext from '../../../context/private/walletContext';
import { numberWithCommas } from '../../../utils/tools';
import PrivateContext from '../../../context/private/privateContext';
import { ModalMethods, SuccessModal } from '../Success';
import { Flex } from '../../../styles/Styles';
import info from '../assets/info.svg';
import confirmedIcon from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';

import dictionary from '../../../locals/my-wallet.json';
import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import AccountContext from '../../../context/private/accountContext';

const Input = ({
  setSelectedItem,
  selectedWalletType,
  setSelectedWalletType
}) => {
  const { loadAccounts, allAccounts, withdrawFormData, setWithdrawFormData } =
    useContext(WalletContext);
  const [current, setCurrent] = useState(null);
  useEffect(() => {
    loadAccounts();
  }, []);
  useEffect(() => {
    if (current === null || current === undefined) {
      setCurrent(allAccounts?.default);
      setWithdrawFormData({
        ...withdrawFormData,
        account_id: allAccounts?.default
      });
    }
  }, [allAccounts]);

  return (
    <div
      className="appf-type-info"
      style={{
        width: '100%',
        marginTop: '1rem !important'
      }}
    >
      <label
        className="appf-type-label"
        style={{
          color: '#00000099',
          fontFamily: 'Acumin Pro-Regular, Helvetica',
          fontSize: '16.6px',
          fontWeight: 400,
          letterSpacing: 0
        }}
      >
        My Financial Account:
      </label>
      {allAccounts && allAccounts?.data.length > 0 && (
        <select
          style={{
            background: '#F5F5F5',
            border: '0.5px solid #EEEEEE'
          }}
          className="appf-type-selector"
          onChange={(e) => {
            setSelectedItem(Number(e.target.value));
            setWithdrawFormData({
              ...withdrawFormData,
              account_id: e.target.value
            });

            allAccounts?.data.find((i) => i.id === +e.target.value)?.type ==
              0 && setSelectedWalletType('Bank');
            allAccounts?.data.find((i) => i.id === +e.target.value)?.type ==
              1 && setSelectedWalletType('Crypto');
            e.target.value === '' && setSelectedWalletType('-');
          }}
        >
          <option value={''} selected={true}>
            Select a wallet
          </option>
          {allAccounts?.data.map((type, index) => {
            return (
              <option key={index} value={type.id}>
                {type.account_name}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export const WithdrawModal = () => {
  const screenWidth = useWindowWidth();

  const {
    balances,
    loadWalletInfoDepositModal,
    walletInfoDepositModal,
    walletListCrypto,
    loadCryptoWallet,
    walletListBank,
    loadBankWallet,
    allAccountsCrypto,
    allAccountsWire,
    loadAccounts,
    depositHandle,
    datePickerWireTransfer,
    datePickerWireExp,
    datePickerCryptoTransfer,
    withdrawFormData,
    sendEmailCodeForWithdrawHandle,
    setWithdrawFormData,
    withdrawHandle,
    modalDeposit,
    setModalDeposit,
    depositWireFormData,
    setDepositWireFormData,
    depositCryptoFormData,
    setDepositCryptoFormData,
    balancesNew,
    transferHandle,
    setDatePickerCryptoTransfer,
    depositSuccessData,
    setDepositSuccessData,
    modal,
    setModal,
    transferSuccessData,
    setTransferSuccessData,
    modalWithdraw,
    setModalWithdraw,
    withdrawSuccessData,
    setWithdrawSuccessData,
    allAccounts,
    emailModalCode,
    setEmailModalCode
  } = useContext(WalletContext);

  const { is2faShow, check2FaStatus, setUpdateTimer, settings } =
    useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);
  const [selectedItem, setSelectedItem] = useState(NaN);

  const [selectedWalletType, setSelectedWalletType] = useState(null);
  useEffect(() => {
    check2FaStatus();
  }, []);

  useEffect(() => {
    !modalWithdraw && setSelectedItem(NaN);
    !modalWithdraw &&
      setWithdrawFormData({
        ...withdrawFormData,
        amount: '',
        request_description: '',
        token: '',
        otp_code: '',
        ultimo_wallet: 0
      });

    setUpdateTimer(+new Date());
    modalWithdraw === false && setWithdrawSuccessData(null);
  }, [modalWithdraw]);

  const [isAccept, setIsAccept] = useState(null);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [whichCurrency, setWhichCurrency] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (isAccept === 'yes') {
      if (whichCurrency === 'usdt') {
        setWithdrawFormData({
          ...withdrawFormData,
          ultimo_wallet: 1
        });
      } else if (whichCurrency === 'usdc') {
        setWithdrawFormData({
          ...withdrawFormData,
          ultimo_wallet: 2
        });
      }
    }
    if (isAccept === 'no') {
      setWithdrawFormData({
        ...withdrawFormData,
        ultimo_wallet: 3
      });
    }
  }, [whichCurrency, isAccept]);

  useEffect(() => {
    if (selectedWalletType === 'Crypto' || selectedWalletType === 'Bank') {
      setWhichCurrency(null);
      setIsAcceptTerms(false);
      setIsAccept(null);
    }
  }, [selectedWalletType]);

  return (
    <>
      {modalIsOpen && <div className="blur-bg" style={{ zIndex: 202 }}></div>}

      {modalIsOpen && (
        <div className="mod" style={{ alignItems: 'center', zIndex: 203 }}>
          <div className="mod-border ultimo-modal">
            <div className="mod-box">
              <p className="mod-box-p">Withdrawal Wallet Confirmation</p>
              <button
                className="mod-box-btn"
                onClick={() => {
                  if (
                    (isAccept === 'yes' && !whichCurrency) ||
                    (isAccept === 'no' && isAcceptTerms == false)
                  ) {
                    setIsAccept(null);
                    setIsAcceptTerms(false);
                    setWhichCurrency(null);
                  }
                  setModalIsOpen(false);
                }}
              >
                X
              </button>
            </div>

            <div className="mod-inside">
              <div className="mod-inside-body">
                <p className="modal-description">
                  <div style={{ textAlign: 'left', width: '100%' }}>
                    <b style={{ marginBottom: '1rem' }}>
                      This is an Ultimo wallet?
                    </b>
                    <div
                      className={
                        isAccept === 'yes'
                          ? 'ultimo-modal-selected-box'
                          : 'ultimo-modal-box'
                      }
                    >
                      <div
                        className="intr-last-row pointer"
                        onClick={(e) => {
                          setIsAcceptTerms(false);
                          setIsAccept('yes');
                        }}
                        style={{ margin: '1rem 0 0.5rem 0' }}
                      >
                        <input
                          className=" pointer"
                          type="radio"
                          id="yes"
                          name="modalAccepting"
                          value="yes"
                          checked={isAccept === 'yes'}
                          onChange={(e) => console.log('e.target.value')}
                        />
                        <label
                          className="intr-last-row-label pointer"
                          htmlFor="horns"
                        >
                          Yes{' '}
                        </label>
                      </div>
                      {
                        <div
                          style={
                            isAccept === 'yes'
                              ? { padding: '1rem' }
                              : { padding: '1rem', opacity: 0.5 }
                          }
                        >
                          Please confirm the wallet can only accept the token
                          <div
                            className="intr-last-row pointer pointer"
                            onChange={(e) => setWhichCurrency('usdt')}
                            style={{ margin: '1rem 0 0.5rem 0' }}
                          >
                            <input
                              type="radio"
                              id="usdt"
                              name="currency"
                              className="pointer"
                              checked={whichCurrency === 'usdt'}
                            />
                            <label
                              className="intr-last-row-label pointer"
                              htmlFor="usdt"
                            >
                              USDT{' '}
                            </label>
                          </div>
                          <div
                            className="intr-last-row pointer"
                            onChange={(e) => setWhichCurrency('usdc')}
                            style={{ margin: '0' }}
                          >
                            <input
                              type="radio"
                              id="usdc"
                              name="currency"
                              className="pointer"
                              checked={whichCurrency === 'usdc'}
                            />
                            <label
                              className="intr-last-row-label pointer"
                              htmlFor="usdc"
                            >
                              USDC{' '}
                            </label>
                          </div>
                        </div>
                      }
                    </div>
                    <div
                      className={
                        isAccept === 'no'
                          ? 'ultimo-modal-selected-box'
                          : 'ultimo-modal-box'
                      }
                    >
                      <div
                        className="intr-last-row pointer"
                        onChange={(e) => {
                          setIsAccept('no');
                          setWhichCurrency(null);
                        }}
                        style={{ margin: '1rem 0 0.5rem 0' }}
                      >
                        <input
                          type="radio"
                          id="no"
                          name="modalAccepting"
                          className="pointer"
                          checked={isAccept === 'no'}
                        />
                        <label
                          className="intr-last-row-label pointer"
                          htmlFor="no"
                        >
                          No{' '}
                        </label>
                      </div>

                      {
                        <div
                          style={
                            isAccept === 'no'
                              ? { paddingLeft: '1rem', fontSize: '14px' }
                              : {
                                  paddingLeft: '1rem',
                                  fontSize: '14px',
                                  opacity: 0.5
                                }
                          }
                        >
                          <p>
                            You have confirmed that this is not an Ultimo
                            wallet, this means you should be able to receive
                            USDT & USDC on this wallet. We will distribute the
                            withdrawal amount in the token that is earliest
                            available for your withdrawal to make sure your
                            withdrawal is processed as soon as possible.
                          </p>

                          <p>
                            Note: If your wallet can not receive both erc-20
                            tokens then please first contact support before
                            adding the withdrawal request.
                          </p>
                        </div>
                      }

                      {isAccept === 'no' && (
                        <>
                          <div className="boscrew-t-combo">
                            <input
                              type="checkbox"
                              id="horns"
                              name="horns"
                              checked={isAcceptTerms}
                              onChange={(e) =>
                                setIsAcceptTerms(e.target.checked)
                              }
                            />
                            <label className="boscrew-t-label" htmlFor="horns">
                              By checking the checkbox you confirm the above.
                            </label>
                          </div>

                          {!isAcceptTerms && (
                            <div
                              className="boscrew-t-combo boscrew-t-label"
                              style={{ color: 'red' }}
                            >
                              Warning: Please confirm that you acknowledge the
                              above statement about your wallet.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </p>
              </div>
              <Flex x="center" gap="1">
                <button
                  className="mod-inside-body-btn pointer"
                  style={{ width: '100%' }}
                  onClick={() =>
                    isAccept
                      ? isAccept === 'yes' && whichCurrency
                        ? setModalIsOpen(false)
                        : isAccept === 'no'
                        ? isAcceptTerms == true
                          ? setModalIsOpen(false)
                          : toast.error(
                              'Please confirm that you acknowledge the above statement about your wallet.'
                            )
                        : toast.error(
                            'Selecting an option for "yes" is mandatory.'
                          )
                      : null
                  }
                >
                  Save
                </button>
              </Flex>
            </div>
          </div>
        </div>
      )}

      {!withdrawSuccessData && modalWithdraw && (
        <div className="blur-bg" onClick={() => setModalWithdraw(false)}></div>
      )}

      {!withdrawSuccessData && modalWithdraw && (
        <>
          <div className="withdrawal-modal">
            <div
              className="modal-mobile"
              style={{
                height:
                  screenWidth < 1037
                    ? '1294px'
                    : screenWidth >= 1037
                    ? '704px'
                    : undefined,
                overflow: screenWidth < 1037 ? 'hidden' : undefined,
                width:
                  screenWidth < 1037
                    ? '564px'
                    : screenWidth >= 1037
                    ? '1037px'
                    : undefined
              }}
            >
              <div
                className="content"
                style={{
                  alignItems:
                    screenWidth < 1037
                      ? 'flex-start'
                      : screenWidth >= 1037
                      ? 'center'
                      : undefined,
                  flexDirection: screenWidth < 1037 ? 'column' : undefined,
                  gap: screenWidth < 1037 ? '20px' : undefined,
                  justifyContent:
                    screenWidth >= 1037 ? 'space-between' : undefined,
                  width:
                    screenWidth < 1037
                      ? '524px'
                      : screenWidth >= 1037
                      ? '989px'
                      : undefined
                }}
              >
                {screenWidth < 1037 && (
                  <>
                    <div className="left">
                      <Input
                        setSelectedItem={setSelectedItem}
                        selectedWalletType={selectedWalletType}
                        setSelectedWalletType={setSelectedWalletType}
                      />
                      <div className="details">
                        <div className="payment-detail">
                          <div className="text-wrapper-2">BOS Balance</div>
                          <div className="text-wrapper-3">
                            {balancesNew?.main_balance ||
                            balancesNew?.bos_interest ||
                            balancesNew?.main_balance === 0 ||
                            balancesNew?.bos_interest === 0 ? (
                              `$${numberWithCommas(
                                Number(balancesNew?.main_balance) +
                                  Number(balancesNew?.bos_interest),
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                        <div className="payment-detail">
                          <div className="text-wrapper-2">
                            Select Wallet Type
                          </div>
                          <div className="text-wrapper-3">
                            {balances?.gratitute_balance ||
                            balances?.gratitute_balance === 0 ? (
                              `$${numberWithCommas(
                                balances?.gratitute_balance,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                        <div className="payment-detail">
                          <Flex
                            width="fit-content"
                            y="center"
                            x="end"
                            gap={0.5}
                          >
                            <div className="text-wrapper-2">BOS Fee</div>
                            <div
                              className="tooltip-info tooltip-info-normal"
                              style={{ right: 0, top: '10px' }}
                            >
                              <div
                                className="tooltip-info-icon"
                                style={{
                                  height: 26
                                }}
                              >
                                <img className="info" alt="Info" src={info} />
                              </div>
                              <div
                                className="tooltip-info-text"
                                style={{ top: '30px' }}
                              >
                                <span>
                                  This indicates the BOS fee, which is required
                                  to cover all aspects of the transaction,
                                  including management and exchange costs.
                                </span>
                              </div>
                            </div>
                          </Flex>
                          <div className="text-wrapper-3">
                            {userInfo?.withdraw_fee_status == 1
                              ? userInfo.withdraw_fee
                              : settings?.withdraw_bos_fee}
                            %
                          </div>
                        </div>
                      </div>
                      <div className="enterance">
                        <input
                          type="text"
                          placeholder="Enter an amount"
                          className="input mod-inside-body-input"
                          value={withdrawFormData.amount}
                          onChange={(e) =>
                            Number(balancesNew?.main_balance) +
                              Number(balancesNew?.bos_interest) >=
                              +e.target.value &&
                            setWithdrawFormData({
                              ...withdrawFormData,
                              amount: e.target.value
                            })
                          }
                        />
                        <div className="options">
                          <p
                            className="min pointer"
                            onClick={(e) =>
                              setWithdrawFormData({
                                ...withdrawFormData,
                                amount: settings?.minimum_withdraw
                              })
                            }
                          >
                            <span className="span">Min:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {settings
                                ? `$${numberWithCommas(
                                    settings?.minimum_withdraw
                                  )}`
                                : ''}
                            </span>
                          </p>
                          <p
                            className="max pointer"
                            onClick={(e) =>
                              setWithdrawFormData({
                                ...withdrawFormData,
                                amount:
                                  Number(balancesNew?.main_balance) +
                                  Number(balancesNew?.bos_interest)
                              })
                            }
                          >
                            <span className="span">Max:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {balancesNew?.main_balance ||
                              balancesNew?.bos_interest === 0 ? (
                                `$${numberWithCommas(
                                  Number(balancesNew?.main_balance) +
                                    Number(balancesNew?.bos_interest),
                                  0
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      {selectedWalletType === 'Crypto' && (
                        <div
                          style={{
                            textAlign: 'left',
                            width: '100%',
                            paddingLeft: '2rem',
                            marginTop: '1rem'
                          }}
                        >
                          <b>This is an Ultimo wallet?</b>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '1rem 0',
                              gap: '1rem'
                            }}
                          >
                            <div
                              className="intr-last-row pointer"
                              onClick={(e) => {
                                setIsAccept('yes');
                                setIsAcceptTerms(false);
                                setModalIsOpen(true);
                              }}
                              style={{ margin: '0' }}
                            >
                              <input
                                className="pointer"
                                type="radio"
                                id="yes"
                                name="accepting"
                                checked={isAccept === 'yes'}
                              />
                              <label
                                className="intr-last-row-label pointer"
                                htmlFor="horns"
                              >
                                Yes{' '}
                                {isAccept === 'yes'
                                  ? whichCurrency === 'usdt'
                                    ? '(USDT)'
                                    : whichCurrency === 'usdc'
                                    ? '(USDC)'
                                    : ''
                                  : ''}
                              </label>
                            </div>
                            <div
                              className="intr-last-row pointer"
                              onClick={(e) => {
                                setIsAccept('no');
                                setModalIsOpen(true);
                                setWhichCurrency(null);
                              }}
                              style={{ margin: '0' }}
                            >
                              <input
                                className="pointer"
                                type="radio"
                                id="no"
                                name="accepting"
                                checked={isAccept === 'no'}
                              />
                              <label
                                className="intr-last-row-label pointer"
                                htmlFor="no"
                              >
                                No{' '}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="description">
                        <div className="overlap-group">
                          <textarea
                            name=""
                            id=""
                            className="mod-inside-input"
                            style={{ height: '100%' }}
                            placeholder="Description"
                            cols={30}
                            rows={10}
                            value={withdrawFormData.request_description}
                            onChange={(e) =>
                              setWithdrawFormData({
                                ...withdrawFormData,
                                request_description: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="header">
                        <div className="success-icon">
                          <div className="overlap">
                            <div className="vuesax-bold-tick">
                              <div className="tick-circle-wrapper">
                                <div className="tick-circle">
                                  <div className="overlap-group-2">
                                    <div className="rectangle" />
                                    <img
                                      className="vector"
                                      alt="Vector"
                                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                    />
                                  </div>
                                  <img
                                    className="img"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="header-text">
                          <div className="text-wrapper-10">
                            Your Requested Amount
                          </div>
                          <div className="text-wrapper-11">
                            ${numberWithCommas(withdrawFormData.amount, 0)}
                          </div>
                        </div>
                      </div>
                      <div className="frame">
                        <div className="group">
                          <div className="overlap-group-3">
                            <div className="rectangle-2" />
                            <div className="payment-details">
                              <div className="div-2">
                                <div className="payment-detail">
                                  <div className="text-wrapper-2">
                                    Payment Method
                                  </div>
                                  <div className="text-wrapper-3">Withdraw</div>
                                </div>
                                <div className="payment-detail">
                                  <div className="text-wrapper-2">
                                    Requested Amount
                                  </div>
                                  <div className="text-wrapper-3">
                                    ${numberWithCommas(withdrawFormData.amount)}
                                  </div>
                                </div>
                              </div>
                              <img
                                className="line"
                                alt="Line"
                                src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                              />
                              <div className="div-2">
                                <div className="payment-detail">
                                  <Flex
                                    width="fit-content"
                                    y="center"
                                    x="end"
                                    gap={0.5}
                                  >
                                    <div className="text-wrapper-2">
                                      BOS Fee
                                    </div>
                                    <div
                                      className="tooltip-info tooltip-info-normal"
                                      style={{ right: 0, top: '10px' }}
                                    >
                                      <div
                                        className="tooltip-info-icon"
                                        style={{
                                          height: 26
                                        }}
                                      >
                                        <img
                                          className="info"
                                          alt="Info"
                                          src={info}
                                        />
                                      </div>
                                      <div
                                        className="tooltip-info-text"
                                        style={{ top: '30px' }}
                                      >
                                        <span>
                                          This indicates the BOS fee, which is
                                          required to cover all aspects of the
                                          transaction, including management and
                                          exchange costs.
                                        </span>
                                      </div>
                                    </div>
                                  </Flex>
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      withdrawFormData.amount *
                                        Number(
                                          userInfo?.withdraw_fee_status == 1
                                            ? userInfo.withdraw_fee / 100
                                            : settings?.withdraw_bos_fee / 100
                                        )
                                    )}
                                  </div>
                                </div>
                                <div className="payment-detail">
                                  <div className="text-wrapper-2">
                                    Received Amount
                                  </div>
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(withdrawFormData.amount) -
                                        withdrawFormData.amount *
                                          Number(
                                            userInfo?.withdraw_fee_status == 1
                                              ? userInfo.withdraw_fee / 100
                                              : settings?.withdraw_bos_fee / 100
                                          )
                                    )}
                                  </div>
                                </div>
                                <div className="payment-detail">
                                  <p className="text-wrapper-2">
                                    Your BOS Balance will be
                                  </p>
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(balancesNew?.main_balance) +
                                        Number(balancesNew?.bos_interest) -
                                        Number(withdrawFormData.amount)
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {is2faShow === 1 && (
                          <Flex direction="col" h="unset">
                            <input
                              type="number"
                              placeholder="2FA Code"
                              className="mod-inside-body-input"
                              style={{
                                background: 'whitesmoke',
                                border: '1px solid #EEEEEE'
                              }}
                              value={withdrawFormData?.token}
                              onChange={(e) =>
                                e.target.value.length <= 6 &&
                                setWithdrawFormData({
                                  ...withdrawFormData,
                                  token: e.target.value
                                })
                              }
                            />
                          </Flex>
                        )}
                        {is2faShow === 0 && (
                          <div
                            className="warning-message-deposit"
                            style={{
                              marginBottom: '-6px'
                            }}
                          >
                            <p>{dictionary['MY-WALLET-title77']}</p>
                            <p>
                              <b>{dictionary['MY-WALLET-title72']}</b> {'->'}{' '}
                              <Link to="/Profile">
                                <b className="link">
                                  {dictionary['MY-WALLET-title78']}
                                </b>
                              </Link>
                            </p>
                          </div>
                        )}
                        <div className="item">
                          <div
                            className="div-wrapper pointer"
                            onClick={() => {
                              if (isNaN(selectedItem)) {
                                toast.error(dictionary['MY-WALLET-title83']);
                              } else {
                                if (
                                  (isAccept === 'yes' && whichCurrency) ||
                                  (isAccept === 'no' &&
                                    isAcceptTerms == true) ||
                                  selectedWalletType === 'Bank'
                                ) {
                                  if (
                                    withdrawFormData.amount <=
                                    Number(balancesNew?.main_balance) +
                                      Number(balancesNew?.bos_interest)
                                  ) {
                                    sendEmailCodeForWithdrawHandle();
                                  } else {
                                    toast.error(
                                      dictionary['MY-WALLET-title82']
                                    );
                                  }
                                } else {
                                  toast.error(
                                    'Answering this question "Is this an Ultimo wallet?" is mandatory.'
                                  );
                                }
                              }
                            }}
                          >
                            <div className="text-wrapper-12">Submit</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-2"
                        alt="Line"
                        src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                      />
                    </div>
                  </>
                )}

                {screenWidth >= 1037 && (
                  <>
                    <div className="left-2">
                      <Input
                        setSelectedItem={setSelectedItem}
                        selectedWalletType={selectedWalletType}
                        setSelectedWalletType={setSelectedWalletType}
                      />
                      <div className="details">
                        <div className="payment-detail">
                          <div className="text-wrapper-2">BOS Balance</div>
                          <div className="text-wrapper-3">
                            {balancesNew?.main_balance ||
                            balancesNew?.bos_interest ||
                            balancesNew?.main_balance === 0 ||
                            balancesNew?.bos_interest === 0 ? (
                              `$${numberWithCommas(
                                Number(balancesNew?.main_balance) +
                                  Number(balancesNew?.bos_interest),
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                        <div className="payment-detail">
                          <div className="text-wrapper-2">
                            Select Wallet Type
                          </div>
                          <div className="text-wrapper-3">
                            {selectedWalletType ? selectedWalletType : `-`}
                          </div>
                        </div>
                        <div className="payment-detail">
                          <Flex
                            width="fit-content"
                            y="center"
                            x="end"
                            gap={0.5}
                          >
                            <div className="text-wrapper-2">BOS Fee</div>
                            <div
                              className="tooltip-info tooltip-info-normal"
                              style={{ right: 0, top: '10px' }}
                            >
                              <div
                                className="tooltip-info-icon"
                                style={{
                                  height: 26
                                }}
                              >
                                <img className="info" alt="Info" src={info} />
                              </div>
                              <div
                                className="tooltip-info-text"
                                style={{ top: '30px' }}
                              >
                                <span>
                                  This indicates the BOS fee, which is required
                                  to cover all aspects of the transaction,
                                  including management and exchange costs.
                                </span>
                              </div>
                            </div>
                          </Flex>
                          <div className="text-wrapper-3">
                            {userInfo?.withdraw_fee_status == 1
                              ? userInfo.withdraw_fee
                              : settings?.withdraw_bos_fee}
                            %
                          </div>
                        </div>
                      </div>
                      <div className="enterance">
                        <input
                          type="text"
                          placeholder="Enter an amount"
                          className="input mod-inside-body-input"
                          value={withdrawFormData.amount}
                          onChange={(e) =>
                            Number(balancesNew?.main_balance) +
                              Number(balancesNew?.bos_interest) >=
                              +e.target.value &&
                            setWithdrawFormData({
                              ...withdrawFormData,
                              amount: e.target.value
                            })
                          }
                        />
                        <div className="options">
                          <p
                            className="min pointer"
                            onClick={(e) =>
                              setWithdrawFormData({
                                ...withdrawFormData,
                                amount: settings?.minimum_withdraw
                              })
                            }
                          >
                            <span className="span">Min:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {settings
                                ? `$${numberWithCommas(
                                    settings?.minimum_withdraw
                                  )}`
                                : ''}
                            </span>
                          </p>
                          <p
                            className="max pointer"
                            onClick={(e) =>
                              setWithdrawFormData({
                                ...withdrawFormData,
                                amount:
                                  Number(balancesNew?.main_balance) +
                                  Number(balancesNew?.bos_interest)
                              })
                            }
                          >
                            <span className="span">Max:</span>
                            <span className="text-wrapper-4">&nbsp;</span>
                            <span className="text-wrapper-5">
                              {balancesNew?.main_balance ||
                              balancesNew?.bos_interest === 0 ? (
                                `$${numberWithCommas(
                                  Number(balancesNew?.main_balance) +
                                    Number(balancesNew?.bos_interest),
                                  0
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      {selectedWalletType === 'Crypto' && (
                        <div
                          style={{
                            textAlign: 'left',
                            width: '100%',
                            paddingLeft: '2rem',
                            marginTop: '1rem'
                          }}
                        >
                          <b>This is an Ultimo wallet?</b>
                          <div
                            className="intr-last-row pointer"
                            onClick={(e) => {
                              setIsAccept('yes');
                              setIsAcceptTerms(false);
                              setModalIsOpen(true);
                            }}
                            style={{ margin: '1rem 0 0.5rem 0' }}
                          >
                            <input
                              className="pointer"
                              type="radio"
                              id="yes"
                              name="accepting"
                              checked={isAccept === 'yes'}
                            />
                            <label
                              className="intr-last-row-label pointer"
                              htmlFor="horns"
                            >
                              Yes{' '}
                              {isAccept === 'yes'
                                ? whichCurrency === 'usdt'
                                  ? '(USDT)'
                                  : whichCurrency === 'usdc'
                                  ? '(USDC)'
                                  : ''
                                : ''}
                            </label>
                          </div>
                          <div
                            className="intr-last-row pointer"
                            onClick={(e) => {
                              setIsAccept('no');
                              setModalIsOpen(true);
                              setWhichCurrency(null);
                            }}
                            style={{ marginTop: '0' }}
                          >
                            <input
                              className="pointer"
                              type="radio"
                              id="no"
                              name="accepting"
                              checked={isAccept === 'no'}
                            />
                            <label
                              className="intr-last-row-label pointer"
                              htmlFor="no"
                            >
                              No{' '}
                            </label>
                          </div>
                        </div>
                      )}

                      <div className="">
                        <div
                          className="overlap-group-4"
                          style={{ height: '180px' }}
                        >
                          <textarea
                            name=""
                            id=""
                            className="mod-inside-input"
                            style={{ height: '100%' }}
                            placeholder="Description"
                            cols={30}
                            rows={10}
                            value={withdrawFormData.request_description}
                            onChange={(e) =>
                              setWithdrawFormData({
                                ...withdrawFormData,
                                request_description: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <img className="line-3" alt="Line" src={line7} />
                    <div className="right-2">
                      <div className="header">
                        <div className="success-icon">
                          <div className="overlap">
                            <div className="vuesax-bold-tick">
                              <div className="tick-circle-wrapper">
                                <div className="tick-circle">
                                  <div className="overlap-group-2">
                                    <div className="rectangle" />
                                    <img
                                      className="vector"
                                      alt="Vector"
                                      src={confirmedIcon}
                                    />
                                  </div>
                                  <img
                                    className="vector-2"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="header-text">
                          <div className="text-wrapper-10">
                            Your Requested Amount
                          </div>
                          <div className="text-wrapper-11">
                            ${numberWithCommas(withdrawFormData.amount, 0)}
                          </div>
                        </div>
                      </div>
                      <div className="frame-3">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group-3">
                            <div className="rectangle-3" />
                            <div className="payment-details-2">
                              <div className="div-2">
                                <div className="payment-detail">
                                  <div className="text-wrapper-2">
                                    Payment Method
                                  </div>
                                  <div className="text-wrapper-3">Withdraw</div>
                                </div>
                                <div className="payment-detail">
                                  <div className="text-wrapper-2">
                                    Requested Amount
                                  </div>
                                  <div className="text-wrapper-3">
                                    ${numberWithCommas(withdrawFormData.amount)}
                                  </div>
                                </div>
                              </div>
                              <img
                                className="line-4"
                                alt="Line"
                                src={dottedLine}
                              />
                              <div className="div-2">
                                <div className="payment-detail">
                                  <Flex
                                    width="fit-content"
                                    y="center"
                                    x="end"
                                    gap={0.5}
                                  >
                                    <div className="text-wrapper-2">
                                      BOS Fee
                                    </div>
                                    <div
                                      className="tooltip-info tooltip-info-normal"
                                      style={{ right: 0, top: '10px' }}
                                    >
                                      <div
                                        className="tooltip-info-icon"
                                        style={{
                                          height: 26
                                        }}
                                      >
                                        <img
                                          className="info"
                                          alt="Info"
                                          src={info}
                                        />
                                      </div>
                                      <div
                                        className="tooltip-info-text"
                                        style={{ top: '30px' }}
                                      >
                                        <span>
                                          This indicates the BOS fee, which is
                                          required to cover all aspects of the
                                          transaction, including management and
                                          exchange costs.
                                        </span>
                                      </div>
                                    </div>
                                  </Flex>
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      withdrawFormData.amount *
                                        Number(
                                          userInfo?.withdraw_fee_status == 1
                                            ? userInfo.withdraw_fee / 100
                                            : settings?.withdraw_bos_fee / 100
                                        )
                                    )}
                                  </div>
                                </div>
                                <div className="payment-detail">
                                  <div className="text-wrapper-2">
                                    Received Amount
                                  </div>
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(withdrawFormData.amount) -
                                        withdrawFormData.amount *
                                          Number(
                                            userInfo?.withdraw_fee_status == 1
                                              ? userInfo.withdraw_fee / 100
                                              : settings?.withdraw_bos_fee / 100
                                          )
                                    )}
                                  </div>
                                </div>
                                <div className="payment-detail">
                                  <p className="text-wrapper-2">
                                    Your BOS Balance will be
                                  </p>
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(balancesNew?.main_balance) +
                                        Number(balancesNew?.bos_interest) -
                                        Number(withdrawFormData.amount)
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {is2faShow === 1 && (
                          <Flex direction="col" h="unset">
                            <input
                              type="number"
                              placeholder="2FA Code"
                              style={{
                                marginBottom: '0px',
                                background: 'whitesmoke',
                                border: '1px solid #EEEEEE'
                              }}
                              className="mod-inside-body-input"
                              value={withdrawFormData?.token}
                              onChange={(e) =>
                                e.target.value.length <= 6 &&
                                setWithdrawFormData({
                                  ...withdrawFormData,
                                  token: e.target.value
                                })
                              }
                            />
                          </Flex>
                        )}
                        {is2faShow === 0 && (
                          <div
                            className="warning-message-deposit"
                            style={{
                              marginBottom: '-6px'
                            }}
                          >
                            <p>{dictionary['MY-WALLET-title77']}</p>
                            <p>
                              <b>{dictionary['MY-WALLET-title72']}</b> {'->'}{' '}
                              <Link to="/Profile">
                                <b className="link">
                                  {dictionary['MY-WALLET-title78']}
                                </b>
                              </Link>
                            </p>
                          </div>
                        )}
                        <div
                          className="overlap-wrapper pointer"
                          onClick={() => {
                            if (isNaN(selectedItem)) {
                              toast.error(dictionary['MY-WALLET-title83']);
                            } else {
                              if (
                                (isAccept === 'yes' && whichCurrency) ||
                                (isAccept === 'no' && isAcceptTerms == true) ||
                                selectedWalletType === 'Bank'
                              ) {
                                if (
                                  withdrawFormData.amount <=
                                  Number(balancesNew?.main_balance) +
                                    Number(balancesNew?.bos_interest)
                                ) {
                                  sendEmailCodeForWithdrawHandle();
                                } else {
                                  toast.error(dictionary['MY-WALLET-title82']);
                                }
                              } else {
                                toast.error(
                                  'Answering this question "Is this an Ultimo wallet?" is mandatory.'
                                );
                              }
                            }
                          }}
                        >
                          <div className="overlap-2">
                            <div className="text-wrapper-13">Submit</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-2"
                        alt="Line"
                        src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                className="hr"
                style={{
                  width:
                    screenWidth < 1037
                      ? '565px'
                      : screenWidth >= 1037
                      ? '1037px'
                      : undefined
                }}
              />
              <div
                className="header-2"
                style={{
                  width:
                    screenWidth < 1037
                      ? '516px'
                      : screenWidth >= 1037
                      ? '993px'
                      : undefined
                }}
              >
                <div className="text-wrapper-14">Withdraw</div>
                <div
                  className="close-btn"
                  onClick={() => setModalWithdraw(false)}
                >
                  <img
                    className="union"
                    alt="Union"
                    src={
                      (screenWidth >= 564 && screenWidth < 1037) ||
                      screenWidth < 564
                        ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                        : screenWidth >= 1037
                        ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
            {emailModalCode && (
              <Flex x="center">
                <div
                  className="mod"
                  style={{
                    zIndex: '201',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '0'
                  }}
                >
                  <div className="mod-border">
                    <div className="mod-box">
                      <p className="mod-box-p">Email Verification Code</p>
                      <button
                        className="mod-box-btn"
                        onClick={() => setEmailModalCode(false)}
                      >
                        <IoMdClose />
                      </button>
                    </div>

                    <div className="mod-inside">
                      <div className="mod-inside-body">
                        <img
                          style={{
                            width: '100%',
                            paddingBottom: '1.3rem',
                            paddingTop: '1rem'
                          }}
                          src="./images/email-verification-code.png"
                          alt=""
                          srcset=""
                        />
                        <p className="modal-description">
                          <p
                            style={{
                              textAlign: 'center',
                              padding: '0 7rem',
                              marginBottom: '3.6rem'
                            }}
                          >
                            Please check your email for the Withdrawal
                            validation code to confirm and approve your
                            withdrawal
                          </p>
                          <Flex direction="col" gap={2} h="unset">
                            <input
                              type="number"
                              placeholder="Email Code"
                              style={{
                                marginBottom: '0px',
                                background: 'whitesmoke',
                                border: '1px solid #EEEEEE'
                              }}
                              className="mod-inside-body-input"
                              value={withdrawFormData?.otp_code}
                              onChange={(e) =>
                                e.target.value.length <= 4 &&
                                setWithdrawFormData({
                                  ...withdrawFormData,
                                  otp_code: e.target.value
                                })
                              }
                            />
                          </Flex>
                        </p>
                        <div
                          className="overlap-2 "
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onClick={() => {
                            if (isNaN(selectedItem)) {
                              toast.error(dictionary['MY-WALLET-title83']);
                            } else {
                              if (
                                withdrawFormData.amount <=
                                Number(balancesNew?.main_balance) +
                                  Number(balancesNew?.bos_interest)
                              ) {
                                withdrawHandle();
                              } else {
                                toast.error(dictionary['MY-WALLET-title82']);
                              }
                            }
                          }}
                        >
                          <div
                            className="text-wrapper-13"
                            style={{
                              position: 'unset'
                            }}
                          >
                            Submit
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="blur-bg"
                  style={{
                    zIndex: '200'
                  }}
                  onClick={() => setEmailModalCode(false)}
                ></div>
              </Flex>
            )}
          </div>
        </>
      )}

      {modalWithdraw && withdrawSuccessData && (
        <SuccessModal
          requestedAmount={withdrawSuccessData?.requestedAmount}
          txID={withdrawSuccessData?.txID}
          method={ModalMethods.Withdraw}
          time={withdrawSuccessData?.time}
          fee={withdrawSuccessData?.fee}
          finalAmount={withdrawSuccessData?.finalAmount}
          closeState={setModalWithdraw}
        />
      )}
    </>
  );
};
