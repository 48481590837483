import React, { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import PrivateContext from '../../../context/private/privateContext';
import WizardContext from '../../../context/private/wizardContext';
import { Button } from './actions.enum';

const CompletionistEmail = () => {
  const { emailOTPHandle } = useContext(WizardContext);

  return (
    <span>
      <div
        style={{
          justifyItems: 'center',
          display: 'grid'
        }}
      >
        <p
          style={{
            fontWeight: 300,
            fontSize: 14,
            letterSpacing: '0.08em',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          I didn’t receive a code
        </p>
        <button
          style={{
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'unset',
            marginBottom: 27,
            fontWeight: 700,
            fontSize: 14,
            letterSpacing: '0.04em',
            color: '#535763'
          }}
          onClick={emailOTPHandle}
        >
          Resend
        </button>
      </div>
    </span>
  );
};

const rendererCountdownEmail = ({
  minutes,
  seconds,
  completed
}: {
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    return <CompletionistEmail />;
  } else {
    return (
      <>
        {String(minutes).padStart(2, '0')}:{' '}
        <span>{String(seconds).padStart(2, '0')}</span>
      </>
    );
  }
};

const CompletionistPhone = () => {
  const { phoneOTPHandle } = useContext(WizardContext);

  return (
    <span>
      <div
        style={{
          justifyItems: 'center',
          display: 'grid'
        }}
      >
        <p
          style={{
            fontWeight: 300,
            fontSize: 14,
            letterSpacing: '0.08em',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          I didn’t receive a code
        </p>
        <button
          style={{
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'unset',
            marginBottom: 27,
            fontWeight: 700,
            fontSize: 14,
            letterSpacing: '0.04em',
            color: '#535763'
          }}
          onClick={phoneOTPHandle}
        >
          Resend
        </button>
      </div>
    </span>
  );
};
const rendererCountdownPhone = ({
  minutes,
  seconds,
  completed
}: {
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    return <CompletionistPhone />;
  } else {
    return (
      <>
        {String(minutes).padStart(2, '0')}:{' '}
        <span>{String(seconds).padStart(2, '0')}</span>
      </>
    );
  }
};

const CompletionistUpline = () => {
  const { uplineOTPHandle } = useContext(WizardContext);

  return (
    <span>
      <div
        style={{
          justifyItems: 'center',
          display: 'grid'
        }}
      >
        <p
          style={{
            fontWeight: 300,
            fontSize: 14,
            letterSpacing: '0.08em',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          I didn’t receive a code
        </p>
        <button
          style={{
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'unset',
            marginBottom: 27,
            fontWeight: 700,
            fontSize: 14,
            letterSpacing: '0.04em',
            color: '#535763'
          }}
          onClick={uplineOTPHandle}
        >
          Resend
        </button>
      </div>
    </span>
  );
};

const rendererCountdownUpline = ({
  minutes,
  seconds,
  completed
}: {
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    return <CompletionistUpline />;
  } else {
    return (
      <>
        {String(minutes).padStart(2, '0')}:{' '}
        <span>{String(seconds).padStart(2, '0')}</span>
      </>
    );
  }
};

const Actions = ({
  token,
  setToken,
  setIsClicked
}: {
  token: string | any;
  setToken: (value: string[]) => void;
  setIsClicked?: (value: boolean) => void;
}): JSX.Element => {
  const [steps, setSteps] = useState<Button>(Button.Email);
  const { flowState } = useContext(PrivateContext);
  const {
    loadVerificationData,
    verificationData,
    emailOTPHandle,
    emailVerifyOTPHandle,
    isError,
    isVerificationCodeSentToEmail,
    expireTimeVCToEmail,
    phoneOTPHandle,
    phoneVerifyOTPHandle,
    isVerificationCodeSentToPhone,
    setIsVerificationCodeSentToPhone,
    uplineOTPHandle,
    uplineVerifyOTPHandle,
    isVerificationCodeSentToUpline,
    expireTimeVCToPhone,
    expireTimeVCToUpline
  } = useContext(WizardContext);
  const [value, setValue] = useState(0);

  useEffect(() => {
    switch (flowState.step) {
      case 0:
        setSteps(Button.Email);
        break;
      case 1:
        setSteps(Button.Phone);
        break;
      case 2:
        setSteps(Button.Upline);
        break;
      case 3:
        setSteps(Button.Complete);
        break;

      default:
        break;
    }
  }, [flowState]);

  useEffect(() => {
    loadVerificationData();
  }, []);

  useEffect(() => {
    if (isError) {
      if (setIsClicked) {
        setIsClicked(true);
      }
    }
  }, [isError]);

  return (
    <div style={{ marginTop: '1rem' }}>
      {steps === Button.Email && (
        <button
          className="button"
          onClick={() => {
            isVerificationCodeSentToEmail && setToken(['', '', '', '']);
            if (Number(verificationData?.email) !== -1) {
              if (Number(verificationData?.email) > +new Date()) {
                emailVerifyOTPHandle(token);
              } else {
                emailOTPHandle();
              }
            }
          }}
        >
          {Number(verificationData?.email) !== -1
            ? Number(verificationData?.email) > +new Date()
              ? 'Verify'
              : 'Send Code'
            : 'Loading...'}
        </button>
      )}
      {steps === Button.Phone && (
        <button
          className="button"
          onClick={() => {
            isVerificationCodeSentToPhone && setToken(['', '', '', '']);
            if (Number(verificationData?.phone) !== -1) {
              if (Number(verificationData?.phone) > +new Date()) {
                phoneVerifyOTPHandle(token);
              } else {
                phoneOTPHandle();
              }
            }
          }}
        >
          {Number(verificationData?.phone) !== -1
            ? Number(verificationData?.phone) > +new Date()
              ? 'Verify'
              : 'Send Code'
            : 'Loading...'}
        </button>
      )}
      {steps === Button.Upline && (
        <button
          className="button"
          onClick={() => {
            Number(verificationData?.upline) !== -1 &&
              !(Number(verificationData?.upline) > +new Date()) &&
              setToken(['', '', '', '', '', '']);

            isVerificationCodeSentToUpline && setToken(['', '', '', '']);
            if (Number(verificationData?.upline) !== -1) {
              if (Number(verificationData?.upline) > +new Date()) {
                uplineVerifyOTPHandle(token);
              } else {
                uplineOTPHandle();
              }
            }
          }}
        >
          {Number(verificationData?.upline) !== -1
            ? Number(verificationData?.upline) > +new Date()
              ? 'Verify'
              : 'Send Code'
            : 'Loading...'}
        </button>
      )}

      <div>
        {steps === Button.Email &&
          Number(verificationData?.email) !== -1 &&
          Number(verificationData?.email) > +new Date() && (
            <div
              style={{
                justifyItems: 'center',
                display: 'flex',
                gridTemplateColumns: '1fr 1fr',
                justifyContent: 'center',
                padding: '1rem 2rem',
                margin: '1rem auto',
                background: '#f5f5f54a',
                borderRadius: '1rem',
                border: '1px solid #e8f1f6'
              }}
            >
              <Countdown
                date={Number(verificationData?.email)}
                renderer={rendererCountdownEmail}
              />
            </div>
          )}
        {steps === Button.Phone &&
          Number(verificationData?.phone) !== -1 &&
          Number(verificationData?.phone) > +new Date() && (
            <div
              style={{
                justifyItems: 'center',
                display: 'flex',
                gridTemplateColumns: '1fr 1fr',
                justifyContent: 'center',
                padding: '1rem 2rem',
                margin: '1rem auto',
                background: '#f5f5f54a',
                borderRadius: '1rem',
                border: '1px solid #e8f1f6'
              }}
            >
              <Countdown
                date={Number(verificationData?.phone)}
                renderer={rendererCountdownPhone}
              />
            </div>
          )}
        {steps === Button.Upline &&
          Number(verificationData?.upline) !== -1 &&
          Number(verificationData?.upline) > +new Date() && (
            <div
              style={{
                justifyItems: 'center',
                display: 'flex',
                gridTemplateColumns: '1fr 1fr',
                justifyContent: 'center',
                padding: '1rem 2rem',
                margin: '1rem auto',
                background: '#f5f5f54a',
                borderRadius: '1rem',
                border: '1px solid #e8f1f6'
              }}
            >
              <Countdown
                date={Number(verificationData?.upline)}
                renderer={rendererCountdownUpline}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default Actions;
