import React, { useContext, useState } from 'react';
import AuthContext from '../context/public/loginContext';
import { Container, Flex } from '../styles/Styles';
import logo from "./../assets/images/bos-logo.png";
import video from "./../assets/videos/low.mp4";
import Copyright2 from './CopyrightPublic';



const ResetPassword = () => {
  const { isSendPasswordLink, isLockedBtn, errors, setErrors, strictMode, userInput, setUserInput, resetPasswordHandle, loginHandle, validate } = useContext<any>(AuthContext)

  const [input, setInput] = useState('')

  return (
    <div className="login-bg">
      <video className='videoTag' autoPlay={true} loop={true} muted>
        <source src={video} type='video/mp4' />
      </video>
      <Container x={'center'} y={'center'} className="container">
        <Flex direction={'col'} gap={1.7}>
          <Flex gap={1.7} x={'center'} className="login-flex">
            <div className="login-form" >
              <div className="logo" style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <img src={logo} alt="Blue of ocean" />
              </div>
              {/* <div className="login">Login</div> */}
              <div className='login-bottom-line' />

              {!isSendPasswordLink ? <>
                <label className="login-form-label email">Email Address: </label>
                <input
                  className="login-form-input"
                  type="text"
                  placeholder="Email"
                  value={input}

                  onChange={(e) => {
                    setInput(e.target.value)
                  }
                  }

                  autoComplete="off"
                />
                {/* {errors && <p style={{ 'color': '#da3838', 'margin': '0 0 1rem 0' }}>{errors?.email}</p>} */}

                <button
                  className="login-form-btn"
                  onClick={() => !isLockedBtn ? resetPasswordHandle(input) : null}
                >
                  <span>
                    Reset Password
                  </span>
                </button>
              </> : <>
                <div style={{
                  minWidth: 350,
                  textAlign: 'center',
                  fontSize: 16,
                }}>
                  <div >
                    Check your inbox
                  </div>
                  <div style={{
                    color: 'blue',
                  }}>
                    {input && input}
                  </div>
                </div>
              </>}
            </div>

          </Flex>
          <Flex x="center">
            <Copyright2 />
          </Flex>
        </Flex>
      </Container>
    </div>
  );
};

export default ResetPassword;
