import toast from "react-hot-toast";
import { ExtraData, TotalNumbers } from "../interfaces/map";
import { toPascalCase } from "../utils/tools";
import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";

class MapService {
    private _httpService = HttpService('MyMap');
    private _httpServiceNotification = HttpService('Notification');

    async getAll() {
        try {
            const responseTotalNumbers = await this._httpService.get<TotalNumbers>('/TotalNumbers');
            const responseExtraData = await this._httpService.get<ExtraData>('/ExtraData');

            if (responseTotalNumbers.data.status && responseExtraData.data.status) {
                return {
                    totalNumbers: responseTotalNumbers?.data?.message,
                    extraData: responseExtraData?.data?.message,
                };
            } else {
            }
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getTotalNumbers() {
        try {
            const response = await this._httpService.get<TotalNumbers>('/TotalNumbers');
            checkMaintenance(response)
            if (response.data.status) return response.data?.message;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getProfitChart(type: string) {
        try {
            const response = await this._httpService.get<any>('/ProfitChart?timeframe=' + type);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getBalanceChart(type: string) {
        try {
            const response = await this._httpService.get<any>('/BalanceChart?timeframe=' + type);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getExtraData() {
        try {
            const response = await this._httpService.get<ExtraData>('/ExtraData');
            checkMaintenance(response)
            if (response.data.status) return response.data;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getAgreements(offset?: any) {
        try {
            const response = await this._httpServiceNotification.get<any>(`/modals`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async submitAgreement(id: any, type: any) {
        try {
            const response = await this._httpServiceNotification.post<any>('/Edit/AcknowledgeOrClose/' + id, { "type": type });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getLogs(offset?: any) {
        try {
            const response = await this._httpService.get<any>(`/Log?limit=${10}&offset=${offset ? offset : 1}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }
}

export default new MapService()