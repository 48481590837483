import React from "react";
import "./style.css";
import { useWindowWidth } from "../breakpoint";
import { numberWithCommas, timestampToLocalDaily, timestampToLocalHour } from "../../../utils/tools";
import illustration from "../assets/illustration.png"
import info from "../assets/info.svg"
import info1 from "../assets/info-1.svg"
import info2 from "../assets/info-2.svg"
import line1 from "../assets/line-1.svg"
import line2 from "../assets/line-2.svg"
import line3 from "../assets/line-3.svg"
import line from "../assets/line.svg"
import union from "../assets/union.svg"
import union1 from "../assets/union-1.svg"
import vector5 from "../assets/vector-5@2x.png"
import vector from "../assets/vector.svg"
import vector2 from "../assets/vector-2.svg"
import vuesax from "../assets/vuesax-linear-import.svg"
import vuesax1 from "../assets/vuesax-linear-import-1.svg"
import vuesax2 from "../assets/vuesax-linear-import-2.svg"
import { Flex } from "../../../styles/Styles";

import dictionary from "../../../locals/modal.json"

export enum ModalMethods {
  ForceWithdraw = "Force Withdraw",
  TopUp = "Top up",
  Participate = "Participate",
  Deposit = "Deposit",
  Withdraw = "Withdraw",
  Transfer = "Transfer",
  Claim1 = "Claim",
  Claim2 = "Claim All",
}

interface Props {
  title?: string,
  requestedAmount: number,
  txID: string,
  method: string,
  time: string,
  fee?: number,
  finalAmount?: number,
  finalTreasuryAmount?: number,
  saving_balance?: number,
  treasuryFee?: number,
  totalClaim?: number,
  closeState: (value: boolean) => void
}

export const SuccessModal = ({ title, requestedAmount, txID, method, time, fee, finalAmount, finalTreasuryAmount, saving_balance, treasuryFee, totalClaim, closeState }: Props) => {
  const screenWidth = useWindowWidth();

  const findLable = () => {
    switch (method) {
      case "Force Withdraw":
        return dictionary["MODAL-title33"];

      case "Deposit":
        return dictionary["MODAL-title34"];

      case "Withdraw":
        return dictionary["MODAL-title35"];

      case "Transfer":
        return dictionary["MODAL-title36"];

      default:
        return dictionary["MODAL-title37"];
    }
  }

  const findTitle = () => {
    switch (method) {
      case "Force Withdraw":
        return dictionary["MODAL-title38"];

      case "Top up":
        return dictionary["MODAL-title39"];

      case "Participate":
        return dictionary["MODAL-title40"];

      case "Deposit":
        return dictionary["MODAL-title41"];

      case "Withdraw":
        return dictionary["MODAL-title42"];

      case "Transfer":
        return dictionary["MODAL-title43"];

      default:
        return "";
    }
  }


  return (
    <>
      <div className="blur-bg" onClick={() => closeState(false)}></div>

      <div className="success-modal">
        <div
          className="modal-success-sm"
          style={{
            overflow: screenWidth < 564 ? "hidden" : undefined,
            width:
              screenWidth < 564
                ? "420px"
                : screenWidth >= 564 && screenWidth < 1037
                  ? "564px"
                  : screenWidth >= 1037
                    ? "1037px"
                    : undefined,
          }}
        >
          <div
            className="content"
            style={{
              alignItems:
                (screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564
                  ? "flex-start"
                  : screenWidth >= 1037
                    ? "center"
                    : undefined,
              display:
                screenWidth >= 1037 || screenWidth < 564
                  ? "flex"
                  : screenWidth >= 564 && screenWidth < 1037
                    ? "inline-flex"
                    : undefined,
              flexDirection: (screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564 ? "column" : undefined,
              gap: (screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564 ? "19px" : undefined,
              justifyContent: screenWidth >= 1037 ? "space-between" : undefined,
              left:
                screenWidth < 564
                  ? "13px"
                  : screenWidth >= 564 && screenWidth < 1037
                    ? "17px"
                    : screenWidth >= 1037
                      ? "20px"
                      : undefined,
              width: screenWidth < 564 ? "463px" : screenWidth >= 1037 ? "985px" : undefined,
            }}
          >
            {((screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564) && (
              <div
                className="right"
                style={{
                  width: screenWidth < 564 ? "395px" : screenWidth >= 564 && screenWidth < 1037 ? "531px" : undefined,
                }}
              >
                <div className="header">
                  <div className="success-icon">
                    <div className="vuesax-bold-tick">
                      <div className="tick-circle-wrapper">
                        <div className="tick-circle">
                          <div className="overlap-group">
                            <div className="rectangle" />
                            <img
                              className="vector"
                              alt="Vector"
                              src={vector2}
                            />
                          </div>
                          <img
                            className="img"
                            style={{
                              left:
                                screenWidth < 564
                                  ? "-1529px"
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? "-997px"
                                    : undefined,
                            }}
                            alt="Vector"
                            src={vector5}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="header-text">
                    <div className="text-wrapper">{dictionary["MODAL-title44"]}</div>
                    <div className="div">${numberWithCommas(requestedAmount)}</div>
                  </div>
                </div>
                <img
                  className="line"
                  style={{
                    width:
                      screenWidth < 564 ? "325px" : screenWidth >= 564 && screenWidth < 1037 ? "403.62px" : undefined,
                  }}
                  alt="Line"
                  src={
                    screenWidth < 564
                      ? line2
                      : screenWidth >= 564 && screenWidth < 1037
                        ? line3
                        : undefined
                  }
                />
                <div
                  className="overlap-wrapper"
                  style={{
                    width: screenWidth < 564 ? "395px" : screenWidth >= 564 && screenWidth < 1037 ? "531px" : undefined,
                  }}
                >
                  <div className="overlap">
                    <div
                      className="tx-details"
                      style={{
                        width:
                          screenWidth < 564 ? "395px" : screenWidth >= 564 && screenWidth < 1037 ? "530px" : undefined,
                      }}
                    >
                      <div
                        className="tx-items-wrapper"
                        style={{
                          backgroundImage:
                            screenWidth < 564
                              ? "url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d749df4f3fd913c192ac17/img/bg-main-1.svg)"
                              : screenWidth >= 564 && screenWidth < 1037
                                ? "url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d749df4f3fd913c192ac17/img/bg-main-2.svg)"
                                : undefined,
                          left:
                            screenWidth < 564 ? "-13px" : screenWidth >= 564 && screenWidth < 1037 ? "-17px" : undefined,
                          width:
                            screenWidth < 564 ? "420px" : screenWidth >= 564 && screenWidth < 1037 ? "564px" : undefined,
                        }}
                      >
                        <div
                          className="tx-items"
                          style={{
                            left:
                              screenWidth < 564 ? "45px" : screenWidth >= 564 && screenWidth < 1037 ? "49px" : undefined,
                          }}
                        >
                          <div
                            className="tx-item"
                            style={{
                              marginRight: screenWidth >= 564 && screenWidth < 1037 ? "-114.46px" : undefined,
                              width:
                                screenWidth < 564
                                  ? "341px"
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? "467px"
                                    : undefined,
                            }}
                          >
                            <div className="text-wrapper-2">{dictionary["MODAL-title45"]}</div>
                            <div className="text-wrapper-3">{txID}</div>
                          </div>
                          <div
                            className="tx-item-2"
                            style={{
                              marginRight: screenWidth >= 564 && screenWidth < 1037 ? "-114.46px" : undefined,
                              width:
                                screenWidth < 564
                                  ? "341px"
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? "467px"
                                    : undefined,
                            }}
                          >
                            <div className="text-wrapper-2">{dictionary["MODAL-title7"]}</div>
                            <div className="text-wrapper-3">{method}</div>
                          </div>
                          <div
                            className="tx-item-3"
                            style={{
                              marginRight: screenWidth >= 564 && screenWidth < 1037 ? "-114.46px" : undefined,
                              width:
                                screenWidth < 564
                                  ? "341px"
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? "467px"
                                    : undefined,
                            }}
                          >
                            <div className="text-wrapper-2">{dictionary["MODAL-title46"]}</div>
                            <div className="text-wrapper-3">{`${timestampToLocalDaily(Number(time) / 1000)}, ${timestampToLocalHour(Number(time) / 1000)}`}</div>
                          </div>
                          {(fee || fee == 0) ? <div
                            className="tx-item-4"
                            style={{
                              marginRight: screenWidth >= 564 && screenWidth < 1037 ? "-114.46px" : undefined,
                              width:
                                screenWidth < 564
                                  ? "341px"
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? "467px"
                                    : undefined,
                            }}
                          >
                            <div className="frame">
                              <div className="text-wrapper-4">BOS Fee</div>
                              <div className="info-wrapper">
                                <Flex width="fit-content" y="center" x="end" gap={0.5} >
                                  <div className='tooltip-info tooltip-info-normal' style={{ right: 0, top: '10px' }}>
                                    <div className='tooltip-info-icon' style={{
                                      height: 26,
                                    }}>
                                      <img
                                        className="info"
                                        alt="Info"
                                        src={
                                          screenWidth < 564
                                            ? info1
                                            : screenWidth >= 564 && screenWidth < 1037
                                              ? info2
                                              : undefined
                                        }
                                      />
                                    </div>
                                    <div className="tooltip-info-text" style={{
                                      top: '30px',
                                      left: 0,
                                      width: 376,
                                      background: 'white',
                                    }}>
                                      <span>
                                        This indicates the BOS fee, which is required to cover all aspects of the transaction, including management and exchange costs.
                                      </span>
                                    </div>
                                  </div>
                                </Flex>

                              </div>
                            </div>
                            {saving_balance == 1 && <div className="text-wrapper-3">${numberWithCommas(fee)}, ${numberWithCommas(treasuryFee)}</div>}
                            {(saving_balance == 0 || saving_balance == 2) && <div className="text-wrapper-3">${saving_balance == 0 ? numberWithCommas(fee) : numberWithCommas(treasuryFee)}</div>}
                          </div> : null}
                          {(finalAmount || finalTreasuryAmount) ? (method === 'Force Withdraw' || method === 'Deposit' || method === 'Withdraw' || method === 'Transfer') && <div
                            className="tx-item-5"
                            style={{
                              marginRight: screenWidth >= 564 && screenWidth < 1037 ? "-114.46px" : undefined,
                              width:
                                screenWidth < 564
                                  ? "341px"
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? "467px"
                                    : undefined,
                            }}
                          >
                            <div className="text-wrapper-2">{findLable()}</div>
                            {saving_balance == 1 && <div className="text-wrapper-3">${numberWithCommas(finalAmount)}, ${numberWithCommas(finalTreasuryAmount)}</div>}
                            {(saving_balance == 0 || saving_balance == 2) && <div className="text-wrapper-3">${saving_balance == 0 ? numberWithCommas(finalAmount) : numberWithCommas(finalTreasuryAmount)}</div>}
                          </div> : null}
                          {totalClaim || totalClaim == 0 ? (method === 'Claim' || method === 'Claim All') && <div className="tx-item-7">
                            <div className="text-wrapper-2">{dictionary["MODAL-title52"]}</div>
                            <div className="text-wrapper-3">${numberWithCommas(totalClaim)}</div>
                          </div> : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="receipt"
                      style={{
                        backgroundImage:
                          screenWidth < 564
                            ? "url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/bg-subtracted-1.svg)"
                            : screenWidth >= 564 && screenWidth < 1037
                              ? "url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/bg-subtracted-2.svg)"
                              : undefined,
                        width:
                          screenWidth < 564 ? "395px" : screenWidth >= 564 && screenWidth < 1037 ? "531px" : undefined,
                      }}
                    >
                      <div
                        className="text not-allowed"
                        style={{
                          left:
                            screenWidth < 564 ? "102px" : screenWidth >= 564 && screenWidth < 1037 ? "170px" : undefined,
                          top: screenWidth < 564 ? "34px" : screenWidth >= 564 && screenWidth < 1037 ? "35px" : undefined,
                        }}
                      >
                        {/* <img
                          className="vuesax-linear-import"
                          alt="Vuesax linear import"
                          src={
                            screenWidth < 564
                              ? vuesax1
                              : screenWidth >= 564 && screenWidth < 1037
                                ? vuesax2
                                : undefined
                          }
                        />
                        <div className="text-wrapper-5">Get PDF Receipt</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {screenWidth >= 1037 && (
              <>
                <img
                  className="illustration"
                  alt="Illustration"
                  src={illustration}
                />
                <img
                  className="line-2"
                  alt="Line"
                  src={line}
                />
                <div className="right-2">
                  <div className="header">
                    <div className="success-icon">
                      <div className="vuesax-bold-tick">
                        <div className="tick-circle-wrapper">
                          <div className="tick-circle">
                            <div className="overlap-group">
                              <div className="rectangle" />
                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                            <img
                              className="vector-2"
                              alt="Vector"
                              src={vector5}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="header-text">
                      <div className="text-wrapper">{dictionary["MODAL-title44"]}</div>
                      <div className="div">${numberWithCommas(requestedAmount)}</div>
                    </div>
                  </div>
                  <img
                    className="line-3"
                    alt="Line"
                    src={line1}
                  />
                  <div className="overlap-group-wrapper">
                    <div className="overlap-2">
                      <div className="div-wrapper">
                        <div className="overlap-group-2">
                          <div className="tx-items-2">
                            <div className="tx-item-6">
                              <div className="text-wrapper-2">{dictionary["MODAL-title45"]}</div>
                              <div className="text-wrapper-3">{txID}</div>
                            </div>
                            <div className="tx-item-6">
                              <div className="text-wrapper-2">{dictionary["MODAL-title7"]}</div>
                              <div className="text-wrapper-3">{method}</div>
                            </div>
                            <div className="tx-item-6">
                              <div className="text-wrapper-2">{dictionary["MODAL-title46"]}</div>
                              <div className="text-wrapper-3">{`${timestampToLocalDaily(Number(time) / 1000)}, ${timestampToLocalHour(Number(time) / 1000)}`}</div>
                            </div>
                            {(fee || fee == 0) ? <div className="tx-item-6">
                              <div className="frame">
                                <div className="text-wrapper-4">BOS Fee</div>
                                <div className="info-wrapper">
                                  <Flex width="fit-content" y="center" x="end" gap={0.5} >
                                    <div className='tooltip-info tooltip-info-normal' style={{ right: 0, top: '10px' }}>
                                      <div className='tooltip-info-icon' style={{
                                        height: 26,
                                      }}>
                                        <img
                                          className="info"
                                          alt="Info"
                                          src={info}
                                        />
                                      </div>
                                      <div className="tooltip-info-text" style={{ top: '30px' }}>
                                        <span>
                                          This indicates the BOS fee, which is required to cover all aspects of the transaction, including management and exchange costs.
                                        </span>
                                      </div>
                                    </div>
                                  </Flex>

                                </div>
                              </div>
                              {saving_balance == 1 && <div className="text-wrapper-3">${numberWithCommas(fee)}, ${numberWithCommas(treasuryFee)}</div>}
                              {(saving_balance == 0 || saving_balance == 2) && <div className="text-wrapper-3">${saving_balance == 0 ? numberWithCommas(fee) : numberWithCommas(treasuryFee)}</div>}
                            </div> : null}
                            {(finalAmount || finalTreasuryAmount) ? (method === 'Force Withdraw' || method === 'Deposit' || method === 'Withdraw' || method === 'Transfer') && <div className="tx-item-7">
                              <div className="text-wrapper-2">{findLable()}</div>
                              {saving_balance == 1 && <div className="text-wrapper-3">${numberWithCommas(finalAmount)}, ${numberWithCommas(finalTreasuryAmount)}</div>}
                              {(saving_balance == 0 || saving_balance == 2) && <div className="text-wrapper-3">${saving_balance == 0 ? numberWithCommas(finalAmount) : numberWithCommas(finalTreasuryAmount)}</div>}
                            </div> : null}

                            {totalClaim || totalClaim == 0 ? (method === 'Claim' || method === 'Claim All') && <div className="tx-item-7">
                              <div className="text-wrapper-2">{dictionary["MODAL-title52"]}</div>
                              <div className="text-wrapper-3">${numberWithCommas(totalClaim)}</div>
                            </div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="receipt-2 not-allowed">
                        <div className="text-2">
                          {/* <img
                            className="vuesax-linear-import-2"
                            alt="Vuesax linear import"
                            src={vuesax}
                          />
                          <div className="text-wrapper-6">Get PDF Receipt</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="hr"
            style={{
              width:
                (screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564
                  ? "564px"
                  : screenWidth >= 1037
                    ? "1037px"
                    : undefined,
            }}
          />
          <div
            className="header-2"
            style={{
              width:
                screenWidth < 564
                  ? "372px"
                  : screenWidth >= 564 && screenWidth < 1037
                    ? "516px"
                    : screenWidth >= 1037
                      ? "993px"
                      : undefined,
            }}
          >
            <div
              className="text-wrapper-7"
              style={{
                marginRight: screenWidth < 564 ? "-22.18px" : undefined,
              }}
            >
              {title ? title : findTitle()}
            </div>
            <div
              className="close-btn"
              onClick={() => closeState(false)}
              style={{
                marginLeft: screenWidth < 564 ? "-60.48px" : undefined,
              }}
            >
              <img
                className="union"
                alt="Union"
                src={
                  (screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564
                    ? "https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg"
                    : screenWidth >= 1037
                      ? "https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg"
                      : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
