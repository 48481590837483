import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import WalletContext from '../../../context/private/walletContext';
import { numberRounder, numberWithCommas } from '../../../utils/tools';
import PrivateContext from '../../../context/private/privateContext';
import { ModalMethods, SuccessModal } from '../Success';
import { Flex } from '../../../styles/Styles';
import info from '../assets/info.svg';
import confirmedIcon from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';

import dictionary from '../../../locals/my-wallet.json';
import toast from 'react-hot-toast';
import islandService from '../../../services/islandService';
import walletService from '../../../services/walletService';
import settingService from '../../../services/settingService';

const transferBase = 0;

const Input = ({
  setSelectedItem,
  selectedWalletType,
  setSelectedWalletType,
  setActionType
}) => {
  const { topupTransferFormData, setTopupTransferFormData } =
    useContext(WalletContext);
  const [current, setCurrent] = useState(null);
  // useEffect(() => {
  //     loadAccounts();
  // }, []);
  // useEffect(() => {
  //     if (current === null || current === undefined) {
  //         setCurrent(allAccounts?.default);
  //         setWithdrawFormData({
  //             ...withdrawFormData,
  //             account_id: allAccounts?.default
  //         });
  //     }
  // }, [allAccounts]);

  const [islands, setIslands] = useState(null);
  async function loadIslands() {
    await islandService
      .getIslandsForTransfer()
      .then((island) => setIslands(island));
  }

  async function loadTransferType(id) {
    await walletService
      .getTransferType(id)
      .then((island) => setActionType(island));
  }

  useEffect(() => {
    loadIslands();
  }, []);

  return (
    <div
      className="appf-type-info"
      style={{
        width: '100%',
        marginTop: '1rem !important'
      }}
    >
      <label
        className="appf-type-label"
        style={{
          color: '#00000099',
          fontFamily: 'Acumin Pro-Regular, Helvetica',
          fontSize: '16.6px',
          fontWeight: 400,
          letterSpacing: 0
        }}
      >
        Islands:
      </label>
      {islands && islands?.length > 0 && (
        <select
          style={{
            background: '#F5F5F5',
            border: '0.5px solid #EEEEEE'
          }}
          className="appf-type-selector"
          onChange={(e) => {
            // setSelectedItem(Number(e.target.value));

            const result = islands?.find((i) => i.id === +e.target.value);
            loadTransferType(+e.target.value);
            result && setSelectedItem(result);

            setTopupTransferFormData({
              ...topupTransferFormData,
              island_id: +e.target.value
            });
            e.target.value === '' && setSelectedWalletType('-');
          }}
        >
          <option value={''} selected={true}>
            Select an island
          </option>
          {islands?.map((type, index) => {
            return (
              <option key={index} value={type.id}>
                {type.name}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export const TransferToIslandModal = ({ islandID = null }) => {
  const screenWidth = useWindowWidth();

  const [actionType, setActionType] = useState(null);

  const {
    balances,
    loadWalletInfoDepositModal,
    walletInfoDepositModal,
    walletListCrypto,
    loadCryptoWallet,
    walletListBank,
    loadBankWallet,
    allAccountsCrypto,
    allAccountsWire,
    loadAccounts,
    depositHandle,
    datePickerWireTransfer,
    datePickerWireExp,
    datePickerCryptoTransfer,
    withdrawFormData,
    setWithdrawFormData,
    withdrawHandle,
    modalDeposit,
    setModalDeposit,
    depositWireFormData,
    setDepositWireFormData,
    depositCryptoFormData,
    setDepositCryptoFormData,
    balancesNew,
    transferHandle,
    setDatePickerCryptoTransfer,
    depositSuccessData,
    setDepositSuccessData,
    modal,
    setModal,
    transferSuccessData,
    setTransferSuccessData,
    modalWithdraw,
    setModalWithdraw,
    modalTopupTransfer,
    setModalTopupTransfer,
    withdrawSuccessData,
    setWithdrawSuccessData,
    allAccounts,
    topupTransferFormData,
    setTopupTransferFormData,
    topupTransferSuccessData,
    setTopupTransferSuccessData,
    topupTransferToIslandHandle,
    participateTransferToIslandHandle
  } = useContext(WalletContext);

  const [
    ckeditorParticipateTransferModal,
    setCkeditorParticipateTransferModal
  ] = useState(null);
  const [ckeditorTopupTransferModal, setCkeditorTopupTransferModal] =
    useState(null);
  async function loadCkEditorByID() {
    await settingService
      .getCkEditorById(7)
      .then((data) => setCkeditorTopupTransferModal(data));
    await settingService
      .getCkEditorById(8)
      .then((data) => setCkeditorParticipateTransferModal(data));
  }

  const { is2faShow, check2FaStatus, setUpdateTimer, settings } =
    useContext(PrivateContext);
  const [selectedItem, setSelectedItem] = useState(NaN);
  const [selectedWalletType, setSelectedWalletType] = useState(null);
  useEffect(() => {
    check2FaStatus();
    loadCkEditorByID();
  }, []);

  useEffect(() => {
    !modalTopupTransfer && setSelectedItem(NaN);
    !modalTopupTransfer &&
      setTopupTransferFormData({
        ...topupTransferFormData,
        amount: '',
        request_description: '',
        token: ''
      });

    setUpdateTimer(+new Date());
    modalTopupTransfer === false && setTopupTransferSuccessData(null);
  }, [modalTopupTransfer]);

  return (
    <>
      {!topupTransferSuccessData && modalTopupTransfer && (
        <div
          className="blur-bg"
          onClick={() => setModalTopupTransfer(false)}
        ></div>
      )}

      {!topupTransferSuccessData && modalTopupTransfer && (
        <div className="transfer-to-island-modal">
          <div
            className="modal-mobile"
            style={{
              height:
                screenWidth < 1037
                  ? '1294px'
                  : screenWidth >= 1037
                  ? '704px'
                  : undefined,
              overflow: screenWidth < 1037 ? 'hidden' : undefined,
              width:
                screenWidth < 1037
                  ? '564px'
                  : screenWidth >= 1037
                  ? '1037px'
                  : undefined
            }}
          >
            <div
              className="content"
              style={{
                alignItems:
                  screenWidth < 1037
                    ? 'flex-start'
                    : screenWidth >= 1037
                    ? 'center'
                    : undefined,
                flexDirection: screenWidth < 1037 ? 'column' : undefined,
                gap: screenWidth < 1037 ? '20px' : undefined,
                justifyContent:
                  screenWidth >= 1037 ? 'space-between' : undefined,
                width:
                  screenWidth < 1037
                    ? '524px'
                    : screenWidth >= 1037
                    ? '989px'
                    : undefined
              }}
            >
              {screenWidth < 1037 && (
                <>
                  <div className="left">
                    <Input
                      setSelectedItem={setSelectedItem}
                      selectedWalletType={selectedWalletType}
                      setSelectedWalletType={setSelectedWalletType}
                      setActionType={setActionType}
                    />
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          BOS Gratitude Balance
                        </div>
                        {islandID ? (
                          <div className="text-wrapper-3">
                            {islandID?.available_profit ||
                            islandID?.available_profit === 0 ? (
                              `$${numberWithCommas(
                                islandID?.available_profit,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        ) : (
                          <div className="text-wrapper-3">
                            {balances?.gratitute_balance ||
                            balances?.gratitute_balance === 0 ? (
                              `$${numberWithCommas(
                                balances?.gratitute_balance,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Island Name</div>
                        <div className="text-wrapper-3">
                          {selectedItem ? selectedItem?.name : '-'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Action Type</div>
                        {actionType && actionType?.type === 1 ? (
                          <div className="text-wrapper-3">Topup</div>
                        ) : null}
                        {actionType && actionType?.type === 0 ? (
                          <div className="text-wrapper-3">Participate</div>
                        ) : null}
                      </div>
                      <div className="payment-detail">
                        <Flex width="fit-content" y="center" x="end" gap={0.5}>
                          <div className="text-wrapper-2">BOS Fee</div>
                          <div
                            className="tooltip-info tooltip-info-normal"
                            style={{ right: 0, top: '10px' }}
                          >
                            <div
                              className="tooltip-info-icon"
                              style={{
                                height: 26
                              }}
                            >
                              <img className="info" alt="Info" src={info} />
                            </div>
                            <div
                              className="tooltip-info-text"
                              style={{ top: '30px' }}
                            >
                              <span>
                                This indicates the BOS fee, which is required to
                                cover all aspects of the transaction, including
                                management and exchange costs.
                              </span>
                            </div>
                          </div>
                        </Flex>
                        <div className="text-wrapper-3">0%</div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Your Loan Allocation
                        </div>
                        <div className="text-wrapper-3">
                          {actionType?.loan_allocation ||
                          actionType?.loan_allocation === 0
                            ? `$${numberWithCommas(
                                actionType?.loan_allocation,
                                2
                              )}`
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={topupTransferFormData.amount}
                        onChange={(e) => {
                          const value = e.target.value;

                          const integerValue = value.replace(/\D/g, '');
                          if (islandID) {
                            if (
                              Number(islandID?.available_profit) >=
                              +integerValue
                            ) {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: integerValue
                              });
                            }
                          } else {
                            if (
                              Number(balances?.gratitute_balance) >=
                              +integerValue
                            ) {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: integerValue
                              });
                            }
                          }
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="options">
                        <p
                          className="min pointer"
                          onClick={(e) => {
                            if (actionType) {
                              if (actionType?.type === 1) {
                                setTopupTransferFormData({
                                  ...topupTransferFormData,
                                  amount: Math.floor(
                                    Number(selectedItem?.min_topup)
                                  )
                                });
                              } else if (actionType?.type === 0) {
                                setTopupTransferFormData({
                                  ...topupTransferFormData,
                                  amount: Math.floor(
                                    Number(selectedItem?.min_invest)
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          {!actionType ? (
                            <span className="text-wrapper-5">-</span>
                          ) : null}
                          {actionType && actionType?.type === 1 ? (
                            <span className="text-wrapper-5">
                              {selectedItem
                                ? `$${numberWithCommas(
                                    selectedItem?.min_topup
                                  )}`
                                : ''}
                            </span>
                          ) : null}
                          {actionType && actionType?.type === 0 ? (
                            <span className="text-wrapper-5">
                              {selectedItem
                                ? `$${numberWithCommas(
                                    selectedItem?.min_invest
                                  )}`
                                : ''}
                            </span>
                          ) : null}
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) => {
                            if (islandID) {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: numberRounder(
                                  Math.floor(Number(islandID?.available_profit))
                                )
                              });
                            } else {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: numberRounder(
                                  Math.floor(
                                    Number(balances?.gratitute_balance)
                                  )
                                )
                              });
                            }
                          }}
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          {islandID ? (
                            <span className="text-wrapper-5">
                              {islandID?.available_profit ||
                              islandID?.available_profit === 0 ? (
                                `$${numberWithCommas(
                                  Number(islandID?.available_profit),
                                  0
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          ) : (
                            <span className="text-wrapper-5">
                              {balances?.gratitute_balance ||
                              balances?.gratitute_balance === 0 ? (
                                `$${numberWithCommas(
                                  Number(balances?.gratitute_balance),
                                  0
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group-4">
                        {actionType && actionType?.type === 1 ? (
                          <div
                            className="mod-inside-input-ck-editor input-description"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorTopupTransferModal
                            }}
                          ></div>
                        ) : null}
                        {actionType && actionType?.type === 0 ? (
                          <div
                            className="mod-inside-input-ck-editor input-description"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorParticipateTransferModal
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                  />
                                </div>
                                <img
                                  className="img"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(topupTransferFormData.amount, 0)}
                        </div>
                      </div>
                    </div>
                    <div className="frame">
                      <div className="group">
                        <div className="overlap-group-3">
                          <div className="rectangle-2" />
                          <div className="payment-details">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">
                                  Transfer to Island
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    topupTransferFormData.amount
                                  )}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line"
                              alt="Line"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div className="text-wrapper-2">BOS Fee</div>
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <img
                                        className="info"
                                        alt="Info"
                                        src={info}
                                      />
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        This indicates the BOS fee, which is
                                        required to cover all aspects of the
                                        transaction, including management and
                                        exchange costs.
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                                <div className="text-wrapper-3">$0</div>
                              </div>
                              <div className="payment-detail">
                                {!actionType ? (
                                  <div className="text-wrapper-2">Amount</div>
                                ) : null}
                                {actionType && actionType?.type === 1 ? (
                                  <div className="text-wrapper-2">
                                    Topup Amount
                                  </div>
                                ) : null}
                                {actionType && actionType?.type === 0 ? (
                                  <div className="text-wrapper-2">
                                    Participate Amount
                                  </div>
                                ) : null}
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(topupTransferFormData.amount) -
                                      topupTransferFormData.amount *
                                        (transferBase / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Gratitude Balance will be
                                </p>
                                {islandID ? (
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(islandID?.available_profit) -
                                        Number(topupTransferFormData.amount)
                                    )}
                                  </div>
                                ) : (
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(balances?.gratitute_balance) -
                                        Number(topupTransferFormData.amount)
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your Loan Allocation will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {actionType?.loan_allocation ||
                                  actionType?.loan_allocation === 0
                                    ? numberWithCommas(
                                        Number(actionType?.loan_allocation) +
                                          Number(topupTransferFormData.amount)
                                      )
                                    : 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div
                          className="div-wrapper pointer"
                          onClick={() => {
                            if (islandID) {
                              if (selectedItem) {
                                if (actionType) {
                                  if (
                                    Number(actionType?.transfer_gratitude) === 1
                                  ) {
                                    if (topupTransferFormData.amount > 0) {
                                      if (
                                        topupTransferFormData.amount <=
                                        Number(islandID?.available_profit)
                                      ) {
                                        if (actionType?.type === 1) {
                                          topupTransferToIslandHandle(
                                            islandID?.island_id
                                          );
                                        } else if (actionType?.type === 0) {
                                          participateTransferToIslandHandle(
                                            islandID?.island_id
                                          );
                                        }
                                      } else {
                                        toast.error(
                                          dictionary['MY-WALLET-title82']
                                        );
                                      }
                                    } else {
                                      toast.error(
                                        'Amount is less than minimum Amount.'
                                      );
                                    }
                                  } else if (
                                    Number(actionType?.transfer_gratitude) === 0
                                  ) {
                                    toast.error(
                                      'Transfer gratitude is disabled on this island.'
                                    );
                                  }
                                }
                              } else {
                                toast.error('Please select an island.');
                              }
                            } else {
                              if (selectedItem) {
                                if (actionType) {
                                  if (
                                    Number(actionType?.transfer_gratitude) === 1
                                  ) {
                                    if (topupTransferFormData.amount > 0) {
                                      if (
                                        topupTransferFormData.amount <=
                                        Number(balances?.gratitute_balance)
                                      ) {
                                        if (actionType?.type === 1) {
                                          topupTransferToIslandHandle(
                                            islandID?.island_id
                                          );
                                        } else if (actionType?.type === 0) {
                                          participateTransferToIslandHandle(
                                            islandID?.island_id
                                          );
                                        }
                                      } else {
                                        toast.error(
                                          dictionary['MY-WALLET-title82']
                                        );
                                      }
                                    } else {
                                      toast.error(
                                        'Amount is less than minimum Amount.'
                                      );
                                    }
                                  } else if (
                                    Number(actionType?.transfer_gratitude) === 0
                                  ) {
                                    toast.error(
                                      'Transfer gratitude is disabled on this island.'
                                    );
                                  }
                                }
                              } else {
                                toast.error('Please select an island.');
                              }
                            }

                            // if (isNaN(selectedItem)) {
                            //     toast.error(dictionary['MY-WALLET-title83'])
                            // } else {
                            //     if (topupTransferFormData.amount <= (Number(balances?.gratitute_balance))) {
                            //         withdrawHandle()
                            //     } else {
                            //         toast.error(dictionary['MY-WALLET-title82'])
                            //     }
                            // }
                          }}
                        >
                          <div className="text-wrapper-12">Submit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}

              {screenWidth >= 1037 && (
                <>
                  <div className="left-2">
                    <Input
                      setSelectedItem={setSelectedItem}
                      selectedWalletType={selectedWalletType}
                      setSelectedWalletType={setSelectedWalletType}
                      setActionType={setActionType}
                    />
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          BOS Gratitude Balance
                        </div>
                        {islandID ? (
                          <div className="text-wrapper-3">
                            {islandID?.available_profit ||
                            islandID?.available_profit === 0 ? (
                              `$${numberWithCommas(
                                islandID?.available_profit,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        ) : (
                          <div className="text-wrapper-3">
                            {balances?.gratitute_balance ||
                            balances?.gratitute_balance === 0 ? (
                              `$${numberWithCommas(
                                balances?.gratitute_balance,
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Island Name</div>
                        <div className="text-wrapper-3">
                          {selectedItem ? selectedItem?.name : '-'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Action Type</div>
                        {actionType && actionType?.type === 1 ? (
                          <div className="text-wrapper-3">Topup</div>
                        ) : null}
                        {actionType && actionType?.type === 0 ? (
                          <div className="text-wrapper-3">Participate</div>
                        ) : null}
                      </div>
                      <div className="payment-detail">
                        <Flex width="fit-content" y="center" x="end" gap={0.5}>
                          <div className="text-wrapper-2">BOS Fee</div>
                          <div
                            className="tooltip-info tooltip-info-normal"
                            style={{ right: 0, top: '10px' }}
                          >
                            <div
                              className="tooltip-info-icon"
                              style={{
                                height: 26
                              }}
                            >
                              <img className="info" alt="Info" src={info} />
                            </div>
                            <div
                              className="tooltip-info-text"
                              style={{ top: '30px' }}
                            >
                              <span>
                                This indicates the BOS fee, which is required to
                                cover all aspects of the transaction, including
                                management and exchange costs.
                              </span>
                            </div>
                          </div>
                        </Flex>
                        <div className="text-wrapper-3">0%</div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Your Loan Allocation
                        </div>
                        <div className="text-wrapper-3">
                          {actionType?.loan_allocation ||
                          actionType?.loan_allocation === 0
                            ? `$${numberWithCommas(
                                actionType?.loan_allocation,
                                2
                              )}`
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={topupTransferFormData.amount}
                        onChange={(e) => {
                          const value = e.target.value;

                          const integerValue = value.replace(/\D/g, '');
                          if (islandID) {
                            if (
                              Number(islandID?.available_profit) >=
                              +integerValue
                            ) {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: integerValue
                              });
                            }
                          } else {
                            if (
                              Number(balances?.gratitute_balance) >=
                              +integerValue
                            ) {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: integerValue
                              });
                            }
                          }
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="options">
                        <p
                          className="min pointer"
                          onClick={(e) => {
                            if (actionType) {
                              if (actionType?.type === 1) {
                                setTopupTransferFormData({
                                  ...topupTransferFormData,
                                  amount: Math.floor(
                                    Number(selectedItem?.min_topup)
                                  )
                                });
                              } else if (actionType?.type === 0) {
                                setTopupTransferFormData({
                                  ...topupTransferFormData,
                                  amount: Math.floor(
                                    Number(selectedItem?.min_invest)
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          {!actionType ? (
                            <span className="text-wrapper-5">-</span>
                          ) : null}
                          {actionType && actionType?.type === 1 ? (
                            <span className="text-wrapper-5">
                              {selectedItem
                                ? `$${numberWithCommas(
                                    selectedItem?.min_topup
                                  )}`
                                : ''}
                            </span>
                          ) : null}
                          {actionType && actionType?.type === 0 ? (
                            <span className="text-wrapper-5">
                              {selectedItem
                                ? `$${numberWithCommas(
                                    selectedItem?.min_invest
                                  )}`
                                : ''}
                            </span>
                          ) : null}
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) => {
                            if (islandID) {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: numberRounder(
                                  Math.floor(Number(islandID?.available_profit))
                                )
                              });
                            } else {
                              setTopupTransferFormData({
                                ...topupTransferFormData,
                                amount: numberRounder(
                                  Math.floor(
                                    Number(balances?.gratitute_balance)
                                  )
                                )
                              });
                            }
                          }}
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          {islandID ? (
                            <span className="text-wrapper-5">
                              {islandID?.available_profit ||
                              islandID?.available_profit === 0 ? (
                                `$${numberWithCommas(
                                  Number(islandID?.available_profit),
                                  0
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          ) : (
                            <span className="text-wrapper-5">
                              {balances?.gratitute_balance ||
                              balances?.gratitute_balance === 0 ? (
                                `$${numberWithCommas(
                                  Number(balances?.gratitute_balance),
                                  0
                                )}`
                              ) : (
                                <div className="skeleton-element-item skeleton-box"></div>
                              )}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group">
                        {actionType && actionType?.type === 1 ? (
                          <div
                            className="mod-inside-input-ck-editor input-description"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorTopupTransferModal
                            }}
                          ></div>
                        ) : null}
                        {actionType && actionType?.type === 0 ? (
                          <div
                            className="mod-inside-input-ck-editor input-description"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorParticipateTransferModal
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src={line7} />
                  <div className="right-2">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src={confirmedIcon}
                                  />
                                </div>
                                <img
                                  className="vector-2"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(topupTransferFormData.amount, 0)}
                        </div>
                      </div>
                    </div>
                    <div className="frame-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-3" />
                          <div className="payment-details-2">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">
                                  Transfer to Island
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    topupTransferFormData.amount
                                  )}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line-4"
                              alt="Line"
                              src={dottedLine}
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div className="text-wrapper-2">BOS Fee</div>
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <img
                                        className="info"
                                        alt="Info"
                                        src={info}
                                      />
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        This indicates the BOS fee, which is
                                        required to cover all aspects of the
                                        transaction, including management and
                                        exchange costs.
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    topupTransferFormData.amount *
                                      (transferBase / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                {!actionType ? (
                                  <div className="text-wrapper-2">Amount</div>
                                ) : null}
                                {actionType && actionType?.type === 1 ? (
                                  <div className="text-wrapper-2">
                                    Topup Amount
                                  </div>
                                ) : null}
                                {actionType && actionType?.type === 0 ? (
                                  <div className="text-wrapper-2">
                                    Participate Amount
                                  </div>
                                ) : null}
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(topupTransferFormData.amount) -
                                      topupTransferFormData.amount *
                                        (transferBase / 100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Gratitude Balance will be
                                </p>
                                {islandID ? (
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(islandID?.available_profit) -
                                        Number(topupTransferFormData.amount)
                                    )}
                                  </div>
                                ) : (
                                  <div className="text-wrapper-3">
                                    $
                                    {numberWithCommas(
                                      Number(balances?.gratitute_balance) -
                                        Number(topupTransferFormData.amount)
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your Loan Allocation will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {actionType?.loan_allocation ||
                                  actionType?.loan_allocation === 0
                                    ? numberWithCommas(
                                        Number(actionType?.loan_allocation) +
                                          Number(topupTransferFormData.amount)
                                      )
                                    : 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="overlap-wrapper pointer"
                        onClick={() => {
                          if (islandID) {
                            if (selectedItem) {
                              if (actionType) {
                                if (
                                  Number(actionType?.transfer_gratitude) === 1
                                ) {
                                  if (topupTransferFormData.amount > 0) {
                                    if (
                                      topupTransferFormData.amount <=
                                      Number(islandID?.available_profit)
                                    ) {
                                      if (actionType?.type === 1) {
                                        topupTransferToIslandHandle(
                                          islandID?.island_id
                                        );
                                      } else if (actionType?.type === 0) {
                                        participateTransferToIslandHandle(
                                          islandID?.island_id
                                        );
                                      }
                                    } else {
                                      toast.error(
                                        dictionary['MY-WALLET-title82']
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      'Amount is less than minimum Amount.'
                                    );
                                  }
                                } else if (
                                  Number(actionType?.transfer_gratitude) === 0
                                ) {
                                  toast.error(
                                    'Transfer gratitude is disabled on this island.'
                                  );
                                }
                              }
                            } else {
                              toast.error('Please select an island.');
                            }
                          } else {
                            if (selectedItem) {
                              if (actionType) {
                                if (
                                  Number(actionType?.transfer_gratitude) === 1
                                ) {
                                  if (topupTransferFormData.amount > 0) {
                                    if (
                                      topupTransferFormData.amount <=
                                      Number(balances?.gratitute_balance)
                                    ) {
                                      if (actionType?.type === 1) {
                                        topupTransferToIslandHandle(
                                          islandID?.island_id
                                        );
                                      } else if (actionType?.type === 0) {
                                        participateTransferToIslandHandle(
                                          islandID?.island_id
                                        );
                                      }
                                    } else {
                                      toast.error(
                                        dictionary['MY-WALLET-title82']
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      'Amount is less than minimum Amount.'
                                    );
                                  }
                                } else if (
                                  Number(actionType?.transfer_gratitude) === 0
                                ) {
                                  toast.error(
                                    'Transfer gratitude is disabled on this island.'
                                  );
                                }
                              }
                            } else {
                              toast.error('Please select an island.');
                            }
                          }
                          // if (isNaN(selectedItem)) {
                          //     toast.error(dictionary['MY-WALLET-title83'])
                          // } else {
                          //     if (topupTransferFormData.amount <= (Number(balances?.gratitute_balance))) {
                          //         withdrawHandle()
                          //     } else {
                          //         toast.error(dictionary['MY-WALLET-title82'])
                          //     }
                          // }
                        }}
                      >
                        <div className="overlap-2">
                          <div className="text-wrapper-13">Submit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className="hr"
              style={{
                width:
                  screenWidth < 1037
                    ? '565px'
                    : screenWidth >= 1037
                    ? '1037px'
                    : undefined
              }}
            />
            <div
              className="header-2"
              style={{
                width:
                  screenWidth < 1037
                    ? '516px'
                    : screenWidth >= 1037
                    ? '993px'
                    : undefined
              }}
            >
              <div className="text-wrapper-14">Transfer</div>
              <div
                className="close-btn"
                onClick={() => setModalTopupTransfer(false)}
              >
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                    screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {modalTopupTransfer && topupTransferSuccessData && (
        <SuccessModal
          requestedAmount={topupTransferSuccessData?.requestedAmount}
          txID={topupTransferSuccessData?.txID}
          method={ModalMethods.Transfer}
          time={topupTransferSuccessData?.time}
          fee={topupTransferSuccessData?.fee}
          finalAmount={topupTransferSuccessData?.finalAmount}
          closeState={setModalTopupTransfer}
        />
      )}
    </>
  );
};
