import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import WizardContext from "../../../context/private/wizardContext";
import { Flex } from "../../../styles/Styles";

const DickPickerInputWireExp = () => {
    const {datePickerWireExp, setDatePickerWireExp} = useContext(WizardContext)
    
    return (
        <Flex y="center" gap="1" x="between" style={{marginBottom: '1rem'}}>
       <span className="date-picker-title">Expected clearing date</span>
       <DatePicker
            className="appf-form-input"
            selected={datePickerWireExp}
            onChange={(date) => setDatePickerWireExp(date)}
            placeholder="Expected clearing date"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
        />
       </Flex>
        
    );
};

export default DickPickerInputWireExp