import { Link } from 'react-router-dom'
import config from '../config'
import dictionary from '../locals/aside.json';

const CopyrightPrivate = () => {
    return (
        <>
            <div className="copyright">
                <span>{dictionary.COPYRIGHT}</span>
                <div className='version'>{config.version}</div>
                <div className="extra-menu">
                    <Link target='_blank' rel='noreferer' to="/faq-system"><div className='extra-menu-item'>FAQs</div></Link>
                    <Link target='_blank' rel='noreferer' to="/privacy-policy"><div className='extra-menu-item'>Privacy Policy</div></Link>
                </div>
            </div>
        </>
    )
}

export default CopyrightPrivate