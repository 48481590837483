import { useEffect } from 'react';

export default function useClearStorages(currentVersion) {
  useEffect(() => {
    try {
      const savedVersion = localStorage.getItem('version');

      if (savedVersion !== currentVersion) {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('version', currentVersion);
      }
    } catch (error) {
      console.error('useClearStorages:', error.message);
    }
  }, [currentVersion]);
}
