import { RegisterPropertyValidation } from './../types/authTypes';
import { LoginPropertyValidation, LoginValidation, RegisterValidation } from "../types/authTypes"
import { RegisterForm } from "../types/registerTypes"
const Joi = require("joi-browser");

class AuthValidation {

    _loginSchema = {
        email: Joi.string().email().min(3).required().label("email"),
        password: Joi.string().required().label("password"),
    }

    _registerSchema = {
        first_name: Joi.string().min(2).required().label("First Name"),
        last_name: Joi.string().required().label("Last Name"),
        email_address: Joi.string().email().min(2).required().label("Email"),
        phone_number: Joi.string().required().label("Phone"),
        username: Joi.string().min(3).max(30).required().label("Username"),
        referral: Joi.string().required().label("Referral"),
        country_id: Joi.string().required().label("Country"),
        password: Joi.string().min(8).required().label("Password"),
        confirm: Joi.any().valid(Joi.ref('password')).label("Confirm Password").required().options({ language: { any: { allowOnly: 'must match password' } } }),
        isCheckedTerms: Joi.boolean().valid(true).required().label("These agreements").options({ language: { any: { allowOnly: 'must be reviewed and accepted in order to proceed' } } }),

    }

    login(email: string, password: string) {

        const result = Joi.validate({ email, password }, this._loginSchema, { abortEarly: false })

        const errors: LoginValidation | any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    loginProperty(name: LoginPropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._loginSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }

    register(formData: RegisterForm) {
        const result = Joi.validate(formData, this._registerSchema, { abortEarly: false })

        const errors: RegisterValidation | any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    registerProperty(name: RegisterPropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._registerSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }

}

export default new AuthValidation()


