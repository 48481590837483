import { MyMap } from '.'
import Layout from './layout/Index'

const MyMapView = () => {
  return (
    <Layout className="parent">
      <MyMap />
    </Layout>
  )
}

export default MyMapView