import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";
import toast from "react-hot-toast";
import { toPascalCase } from "../utils/tools";

class IslandService {
    private _httpService = HttpService('Island');
    private _httpServiceMyTreasure = HttpService('MyTreasure');

    async getCheckClaimWindow(id: number) {
        try {
            const response = await this._httpService.get<any>('/CheckClaimWindows/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getIslands(searchQuery?: string, filterStatus?: string, filterType?: string, offset?: any, limit?: number) {
        try {
            const response = await this._httpService.get<any>(`?limit=${limit ? limit : 1}&offset=${offset ? offset : 1}&islandStatus=${filterStatus === 'all' ? '' : filterStatus}&participationStatus=${filterType === 'all' ? '' : filterType}`,
                {
                    params: {
                        searchKey: searchQuery
                    }
                }
            );
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getIslandsForTransfer() {
        try {
            const response = await this._httpService.get<any>('/AvailableIslandToTransfer');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getOverview(id: number) {
        try {
            const response = await this._httpService.get<any>('/Overview/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getUserChart(id: number, type: string) {
        try {
            const response = await this._httpService.get<any>(`/UserChart/${id}?timeframe=${type}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getTransactionChart(id: number, type: string) {
        try {
            const response = await this._httpService.get<any>(`/TransactionChart/${id}?timeframe=${type}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getHasParticipate(id: number) {
        try {
            const response = await this._httpService.get<any>('/IsUserParticipate/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getUserInIsland(id: number) {
        try {
            const response = await this._httpService.get<any>('/UserInIsland/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getTotalCommission(id: number) {
        try {
            const response = await this._httpService.get<any>('/TotalCommission/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getExplorer(id: number) {
        try {
            const response = await this._httpService.get<any>('/IslandExplorer/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getParticipateDetail(id: number) {
        try {
            const response = await this._httpService.get<any>('/ParticipateDetail/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async createNewParticipate(formData: any) {
        try {
            const response = await this._httpService.post<any>('/New', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async createNewParticipateFromGratitude(formData: any) {
        try {
            const response = await this._httpService.post<any>('/ParticipateGratitude', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
  
    async createNewParticipateFromGratitudePerIsland(formData: any, id: number) {
        try {
            const response = await this._httpService.post<any>('/ParticipateGratitude/' + id, formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async createNewTopupFromGratitude(formData: any) {
        try {
            const response = await this._httpService.post<any>('/TopupGratitude', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async createNewTopupFromGratitudePerIsland(formData: any, id: number) {
        try {
            const response = await this._httpService.post<any>('/TopupGratitude/' + id, formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async transferIslandBalance(formData: any) {
        try {
            const response = await this._httpServiceMyTreasure.post<any>('/TransferIslandBalance', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getUpdate(id: number, offset: any) {
        try {
            const response = await this._httpService.get<any>('/IslandUpdate/' + id + `?limit=${10}&offset=${offset ? offset : 1}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getCondition(id: number) {
        try {
            const response = await this._httpService.get<any>('/IslandCondition/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getFaqs(id: number) {
        try {
            const response = await this._httpService.get<any>('/IslandFAQ/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            if (ex.response && ex.response && ex.response.status === 400) {
                throw new Error(ex.response.data.error)
            }
        }
    }

}

export default new IslandService()