import { useContext } from 'react';
import TreasuryContext from '../../../context/private/treasuryContext';
import MyCrew from './my-crew';
import Overview from './overview';
import Transactions from './transactions';

const Contents = (): JSX.Element => {
  const { currentTab } = useContext(TreasuryContext)

  switch (currentTab) {
    case 0:
      return <Overview />
    case 1:
      return <MyCrew />
    case 2:
      return <Transactions />
    // case 3:
    //   return <MyCrew />

    default:
      return <></>
  }
}

export default Contents