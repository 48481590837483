import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import WalletContext from "../../../context/private/walletContext";
import { Flex } from "../../../styles/Styles";
import { timeToYYYYMMDD } from "../../../utils/tools";
import dictionary from "../../../locals/my-wallet.json"

const DickPickerInputWireTransfer = () => {
    const { datePickerWireTransfer, setDatePickerWireTransfer, depositWireFormData, setDepositWireFormData } = useContext(WalletContext)

    return (
        <Flex y="center" gap="1" marginB={0.8}>
            <DatePicker
                className="appf-form-input"
                selected={datePickerWireTransfer}
                onChange={(date) => {
                    setDatePickerWireTransfer(date)
                    setDepositWireFormData({ ...depositWireFormData, transfer_date: +new Date(date) })
                }}
                placeholder={dictionary['MY-WALLET-title120']}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
            />
        </Flex>
    );
};

export default DickPickerInputWireTransfer