import CropViewer from 'rc-cropping';
import 'rc-cropping/assets/index.css';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import { useContext } from 'react';
import ProfileContext from '../context/private/profileContext';


const ModalCropper = () => {
    const { setDefaultAvatar, setIsActiceEditor, updateAvatarHandle } = useContext(ProfileContext)

    function previewBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setDefaultAvatar(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <CropViewer
            size={[64, 64]}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog />}
            onChange={(file) => {
                if (file) {
                    previewBase64(file)

                    const formData = new FormData()
                    formData.append('avatar', file)
                    updateAvatarHandle(formData)

                    setIsActiceEditor(false)
                }
            }}
            circle={true}
        />
    )
}

export default ModalCropper