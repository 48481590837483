import HttpService, { HttpServiceImages, catchReporter, checkMaintenance, reporter } from "./httpService";

class PublicService {
    private _httpServiceFAQ = HttpService('FAQ');
    private _httpServicePrivacyPolicy = HttpService('Privacy');
    private _httpServiceImages = HttpServiceImages('/');


    async getFaq() {
        try {
            const response = await this._httpServiceFAQ.get<any>('/');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getFaqSection() {
        try {
            const response = await this._httpServiceFAQ.get<any>('/Sections');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getFaqTopic(id: any) {
        try {
            const response = await this._httpServiceFAQ.get<any>(`/Topics?section_id=`+id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getFaqQuestion(id: any) {
        try {
            const response = await this._httpServiceFAQ.get<any>(`/Questions?topic_id=`+id+'&searchBy=title');
            checkMaintenance(response)
            return response?.data?.message
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }
    async searchFaqQuestion(searchQuery: any) {
        try {
            const response = await this._httpServiceFAQ.get<any>('/Search?searchBy=question',
             {
                params: {
                    searchKey: searchQuery
                }
             }
            );
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }
   
    async getFaqQuestionById(id: any, topicId:any) {
        try {
            const response = await this._httpServiceFAQ.get<any>(`/SingleQuestions/`+topicId+`/`+id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }
   
    async submitVote(isLike: number, questionId: number) {
        try {
            const response = await this._httpServiceFAQ.post<any>(`/Questions/Vote/`+questionId, {
                question_id: questionId,
                type: isLike
            });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getPopularQuestions() {
        try {
            const response = await this._httpServiceFAQ.get<any>(`/PopularQuestions`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getFaqQuestionsByIslandId(id: number) {
        try {
            const response = await this._httpServiceFAQ.get<any>(`/Questions/island`, {
                params: {
                    island_id: id
                }
            });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getPrivacyPolicy() {
        try {
            const response = await this._httpServicePrivacyPolicy.get<any>('/');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getImage(url: string) {
        
        try {
            const response = await this._httpServiceImages.get<any>(url);
            return response;
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

}

export default new PublicService()