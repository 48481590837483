import { createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import PrivateContext from './privateContext';
import useAutoLogout from '../../hooks/useAutoLogout';
const SidebarContext = createContext<any>({});

export const SidebarContextProvider = ({ children }: JSX.Element | any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setFlowState, updateTimer, flowState } = useContext(PrivateContext)

    async function logoutHandle() {
        const response = await authService.logout()
        setFlowState({ step: -1, menu: 0, status: 0, email: '' })
        if (response && location.pathname.toLowerCase() !== "/login") {
            navigate('/login')
        }
    }

    useAutoLogout(updateTimer, flowState)

    const context = {
        logoutHandle
    };

    return (
        <SidebarContext.Provider value={context}>
            {children}
        </SidebarContext.Provider>
    );
};

export default SidebarContext;