import React, { useContext, useEffect, useState } from "react";
import PrivateContext from "../../context/private/privateContext";
import TreasuryContext from "../../context/private/treasuryContext";
import useGetDataFromUrl from "../../hooks/useGetDataFromUrl";
import { Container, Flex } from "../../styles/Styles";
import Header from "../Header";
import Layout from "../layout/Index";
import Loader from "../Loader";
import Contents from "./contents";
import Tabs from "./tabs/tabs";
import { useSearchParams } from "react-router-dom";


const MyTreasure = (): JSX.Element => {
  const { islandData, setCurrentIslandId } = useContext(TreasuryContext)
  const { setCurrentSelectedIslandId } = useContext(PrivateContext)

  const [searchParams, setSearchParams] = useSearchParams()
  if (window.location.href.includes('?id=')) {
    const id = searchParams.get('id')
    setCurrentIslandId(id)
    setCurrentSelectedIslandId(id)
  }

  const [keyData, setKeyData] = useState('')
  useGetDataFromUrl('id', setKeyData)
  useEffect(() => {
    if (keyData) {
      // setCurrentIslandId(keyData)
      setCurrentSelectedIslandId(keyData)
    }
  }, [keyData])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (

    <Layout className="over">
      <Container x={'center'} y={'center'} className="container over-container">
        <Flex gap={1.7} x={'center'} className="over-flex">
          <div className="over-all">
            <div className="over-header">
              <Header title={islandData?.[0]?.title} />
              <Tabs />
            </div>
            <Contents />
          </div>
        </Flex>
      </Container>
    </Layout>

  );
};

export default MyTreasure;
