import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import wizardService from '../../services/wizardService';
import { RegisterValidation } from '../../types/authTypes';
import { ReferralAddress, Register } from '../../types/registerTypes';
import authValidation from '../../validations/authValidation';

const RegisterContext = createContext<any>({});

export const RegisterContextProvider = ({ children }: JSX.Element | any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentCountry, setCurrentCountry] = useState<string | null>('0')
  const [currentPrefix, setCurrentPrefix] = useState<string | null>('+0')

  // const [userInputReferral, setUserInputReferral] = useState<ReferralAddress>({
  //   referral: '',
  // })
  const [userInput, setUserInput] = useState<Register>({
    first_name: '',
    last_name: '',
    email_address: '',
    phone_number: '',
    password: '',
    confirm: '',
    country_id: '',
    username: '',
    referral: '',
    isCheckedTerms: false
  });
  const [userUplineInfo, setUserUplineInfo] = useState({ firstName: '', lastName: '' })
  const [errors, setErrors] = useState<RegisterValidation>({})
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const [strictMode, setStrictMode] = useState<boolean>(false)

  const [isLockedBtn, setIsLockedBtn] = useState(false)
  const [isValidRefToSignup, setIsValidRefToSignup] = useState(false)

  const [countriesList, setCountriesList] = useState<[{ id: number; name: string; logo: string; suffixes: string; root: string; }] | null | undefined>(null)

  async function registerHandle() {
    const responseMigratedUser = await authService.checkUser(userInput.email_address)


    let ref: any;
    if (window.location.href.includes('?connector=')) {
      ref = window.location.href.split('?connector=')[1];
      // setUserInputReferral({ referral: ref })

    }
    const errors = authValidation.register(userInput);
    if (Object.keys(errors).length === 0) {
      // const referral = await authService.isRefValid({ referral_code: ref })
      setIsLockedBtn(true)
      if (ref) {
        setStrictMode(false)
        toast.promise(
          authService.register(userInput).then(),
          {
            loading: 'Registering...',
            success: (data) => {
              if (data && location.pathname.toLowerCase() === '/register') {
                // navigate('/login');
                setIsRegistered(true)
                setIsMigratedUser(responseMigratedUser)
              }
              setIsLockedBtn(false)

              return `Registered.`
            },
            error: (err) => {
              setIsLockedBtn(false)
              return `${err.message.toString()}`
            },
          },
          {
            success: {
              duration: 6000,
              icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
            },
          }
        );
      }
    } else {
      setStrictMode(true)
      setErrors(errors)
      setIsLockedBtn(false)
    };
  }

  async function loadCountries() {
    await wizardService.getCountries().then(list => setCountriesList(list))
  }

  const [isMigratedUser, setIsMigratedUser] = useState(false)
  async function checkUserByMail(email: string) {
    await authService.checkUser(email).then(response => setIsMigratedUser(response))
  }

  async function checkParentToRegister() {
    let ref: string = '';
    if (window.location.href.includes('?connector=')) {
      ref = window.location.href.split('?connector=')[1];
      setUserInput({ ...userInput, referral: ref })
    }
    try {
      const result = await authService.checkParentToRegister(ref)
      setUserUplineInfo({ firstName: result.first_name, lastName: result.last_name })
      setIsValidRefToSignup(true)
    } catch (error) {
      navigate('/login');
    }
  }

  function validate() {
    const errors = authValidation.register(userInput)
    return Object.keys(errors).length === 0
  }

  const context = {
    errors,
    setErrors,
    strictMode,
    setStrictMode,
    userInput,
    setUserInput,
    // setUserInputReferral,
    registerHandle,
    validate,
    buttonLock: isLockedBtn,
    loadCountries,
    countriesList,
    checkParentToRegister,
    isValidRefToSignup,
    userUplineInfo, setUserUplineInfo,
    isRegistered, setIsRegistered,

    currentCountry, setCurrentCountry,
    currentPrefix, setCurrentPrefix,

    isMigratedUser, checkUserByMail, setIsMigratedUser
  };

  return (
    <RegisterContext.Provider value={context}>
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterContext;