import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";

class BosService {
    private _httpService = HttpService('MyBOS');

    async getSocialPage() {
        try {
            const response = await this._httpService.get<any>('/BosSocial');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getAcademyPage() {
        try {
            const response = await this._httpService.get<any>('/BosAcademy');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getServiceOffshorePage() {
        try {
            const response = await this._httpService.get<any>('/BOSServiceOffshore');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getServiceUltimoPage() {
        try {
            const response = await this._httpService.get<any>('/BOSServiceUltimo');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getLifestylePage() {
        try {
            const response = await this._httpService.get<any>('/BosLifestyle');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getEventsPage() {
        try {
            const response = await this._httpService.get<any>('/BosEvents');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getShopPage() {
        try {
            const response = await this._httpService.get<any>('/BosShop');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getCharityPage() {
        try {
            const response = await this._httpService.get<any>('/BosCharity');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)

        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getHelpdeskPage() {
        try {
            const response = await this._httpService.get<any>('/BosHelpdesk');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getTutorialsPage() {
        try {
            const response = await this._httpService.get<any>('/BosTutorials');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getUpdatesPage(offset?: any) {
        try {
            const response = await this._httpService.get<any>(`/BOSUpdate?limit=${10}&offset=${offset ? offset : 1}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getAboutPage() {
        try {
            const response = await this._httpService.get<any>('/BOSAboutUs');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getDashboardFaqPage() {
        try {
            const response = await this._httpService.get<any>('/DashboardFAQ');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosFaqPage() {
        try {
            const response = await this._httpService.get<any>('/BOSFAQs');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosVideoPage() {
        try {
            const response = await this._httpService.get<any>('/BOSVideoArchive');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getDashboardTutorialsPage() {
        try {
            const response = await this._httpService.get<any>('/DashboardTutorial');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getCryptoTutorialsPage() {
        try {
            const response = await this._httpService.get<any>('/CryptoTutorial');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosServicePage() {
        try {
            const response = await this._httpService.get<any>('/BOSService');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosMeetingPage() {
        try {
            const response = await this._httpService.get<any>('/BOSMeetings');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosQAPage() {
        try {
            const response = await this._httpService.get<any>('/Q&A');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosWebinarsPage() {
        try {
            const response = await this._httpService.get<any>('/BOSWebinars');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosImblPage() {
        try {
            const response = await this._httpService.get<any>('/IMBLBank');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosOffshoreBankingPage() {
        try {
            const response = await this._httpService.get<any>('/OffshoreBanking');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosCayeePage() {
        try {
            const response = await this._httpService.get<any>('/CayeBanking');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosChasePage() {
        try {
            const response = await this._httpService.get<any>('/ChaseBanking');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosRecommendationsPage() {
        try {
            const response = await this._httpService.get<any>('/Recommendations');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosStrategySessionPage() {
        try {
            const response = await this._httpService.get<any>('/StrategySession');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosNevisPage() {
        try {
            const response = await this._httpService.get<any>('/Nevis2024');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosNevisRegistrationPage() {
        try {
            const response = await this._httpService.get<any>('/Nevis2024Registration');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosStoriesPage() {
        try {
            const response = await this._httpService.get<any>('/BosStories');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getBosWithdrawPage() {
        try {
            const response = await this._httpService.get<any>('/BosWithdraw');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
}

export default new BosService()