import React, { useContext } from "react";
import WizardContext from "../../../../context/private/wizardContext";
import { Flex } from "../../../../styles/Styles";
import Header from "../../../Header";

const Approved = (): JSX.Element => {
  const { setupNow, skipSetupNow } = useContext(WizardContext)
  return (
    <Flex gap={1.7} x={"center"} className="approved-flex flex-approved">        
      <div className="approved">
        <img src="/images/shinyMan.svg" alt="shinyMan" />
        <div className="approved-h">Approved</div>
        <div className="approved-t">
          Congratulations, your account has been successfully verified.
        </div>
        <div className="approved-parent">
          <button className="approved-btn" onClick={() => setupNow()}>Continue</button>
        </div>
      </div>
    </Flex>
  );
};

export default Approved;
