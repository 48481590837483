import { createContext, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import mapService from '../../services/mapService';
import walletService from '../../services/walletService';
import { timeToYYYYMMDD } from '../../utils/tools';
import walletValidation from '../../validations/walletValidation';
import settingService from '../../services/settingService';
import islandService from '../../services/islandService';
import CrewContext from './crewContext';
const WalletContext = createContext<any>({});

export const WalletContextProvider = ({ children }: JSX.Element | any) => {
    const [emailModalCode, setEmailModalCode] = useState(false);
    const [paymentList, setPaymentList] = useState(null)
    const { loadGratitude } = useContext(CrewContext)

    async function loadPayments(page: any) {
        await walletService.getAllPayment(page).then(list => setPaymentList(list))
    }

    const [isModalOpenRenew, setIsModalOpenRenew] = useState(false);
    const [isModalOpenCompound, setIsModalOpenCompound] = useState(false);
    const [isModalOpenUnlock, setIsModalOpenUnlock] = useState(false);
    const [isModalOpenClaim, setIsModalOpenClaim] = useState(false);
    const [isModalOpenStake, setIsModalOpenStake] = useState(false);



    const [walletListCrypto, setWalletListCrypto] = useState(null)

    async function loadCryptoWallet() {
        await walletService.getWalletsInfo('crypto').then(list => setWalletListCrypto(list))
    }

    const [walletListBank, setWalletListBank] = useState(null)

    async function loadBankWallet() {
        await walletService.getWalletsInfo('bank').then(list => setWalletListBank(list))
    }

    const [walletInfoMembershipModal, setWalletInfoMembershipModal] = useState(null)
    async function loadWalletInfoMembershipModal() {
        await walletService.getWalletsInfoForModal('membership').then(list => setWalletInfoMembershipModal(list))
    }



    const [ckeditorTransferModal, setCkeditorTransferModal] = useState(null)
    async function loadCkEditorByID(id: number) {
        await settingService.getCkEditorById(id).then(data => setCkeditorTransferModal(data))
    }

    const [walletInfoDepositModal, setWalletInfoDepositModal] = useState(null)
    async function loadWalletInfoDepositModal() {
        await walletService.getWalletsInfoForModal('deposit').then(list => setWalletInfoDepositModal(list))
    }


    const [depositList, setDepositList] = useState(null)

    async function loadDeposits(page: any) {
        await walletService.getDepositAll(page).then(list => setDepositList(list))
    }


    const [withdrawList, setWithdrawList] = useState(null)

    async function loadWithdraws(page: any) {
        await walletService.getWithdrawAll(page).then(list => setWithdrawList(list))
    }


    const [feesList, setFeesList] = useState(null)

    async function loadFees(page: any) {
        await walletService.getFeesAll(page).then(list => setFeesList(list))
    }


    const [GPList, setGPList] = useState(null)

    async function loadGP(page: any) {
        await walletService.getGPAll(page).then(list => setGPList(list))
    }


    const [balances, setBalances] = useState(null)
    const [balancesNew, setBalancesNew] = useState<any>(null)

    async function loadBalances() {
        await walletService.getBalances().then(data => setBalances(data))
        await mapService.getTotalNumbers().then(data => setBalancesNew(data))
    }

    const [diamondGlobalData, setDiamondGlobalData] = useState<any>(null)

    async function loadDiamondBalance() {
        await walletService.getStakeGloabal().then(data => setDiamondGlobalData(data))
    }

    const [diamondHistory, setDiamondHistory] = useState<any>(null)

    async function loadDiamondHistory(page?: any) {
        await walletService.getStakeHistory(page).then(data => setDiamondHistory(data))
    }

    const [diamondActionHistory, setDiamondActionHistory] = useState<any>(null)

    async function loadDiamondActionHistory(page?: any) {
        await walletService.getActionHistory(page).then(data => setDiamondActionHistory(data))
    }


    const [transactions, setTransactions] = useState(null)

    async function loadTransactions() {
        await walletService.getTransactions().then(txs => setTransactions(txs))
    }


    const [allPaymentList, setAllPaymentList] = useState(null)

    async function loadAllPayments(page?: any) {
        await walletService.getAllPayment(page).then(payment => setAllPaymentList(payment))
    }
    async function loadAllPaymentsSorted(page?: any, sortType?: string) {
        // Next Verision
        // await walletService.getAllPaymentSorted(page, sortType).then(payment => setAllPaymentList(payment))
        // await walletService.getDepositSorted(page, sortType).then(payment => setAllPaymentList(payment))
        // await walletService.getWithdrawSorted(page, sortType).then(payment => setAllPaymentList(payment))
        // await walletService.getAllPaymentSorted(page, sortType).then(payment => setAllPaymentList(payment))
        // await walletService.getAllPaymentSorted(page, sortType).then(payment => setAllPaymentList(payment))
    }

    async function loadTransaction(id: number) {
        const response = await walletService.getDetail(id)
        return response?.[0];
    }

    const [allAccounts, setAllAccounts] = useState(null)
    const [allAccountsCrypto, setAllAccountsCrypto] = useState(null)
    const [allAccountsWire, setAllAccountsWire] = useState(null)

    async function loadAccounts() {
        await walletService.getAccounts(0).then(account => setAllAccountsWire(account))
        await walletService.getAccounts(1).then(account => setAllAccountsCrypto(account))
        await walletService.getAccounts().then(account => setAllAccounts(account))

    }

    const [datePickerWireTransfer, setDatePickerWireTransfer] = useState(new Date());
    const [datePickerWireExp, setDatePickerWireExp] = useState(new Date());
    const [datePickerCryptoTransfer, setDatePickerCryptoTransfer] = useState(new Date());

    const [modal, setModal] = useState(false)
    const [modalWithdraw, setModalWithdraw] = useState(false)

    const [locker, setLocker] = useState(false)
    const [withdrawFormData, setWithdrawFormData] = useState({
        account_id: 1,
        amount: '',
        request_description: '',
        otp_code: ''
    })






    const [withdrawSuccessData, setWithdrawSuccessData] = useState(null)
    async function sendEmailCodeForWithdrawHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.sendEmailCodeForWithdraw(withdrawFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        setEmailModalCode(true);
                        // setWithdrawFormData({
                        //     account_id: 1,
                        //     amount: '',
                        //     request_description: ''
                        // })

                        loadDeposits(1)
                        loadBalances()
                        loadAllPayments()
                        loadTransactions()
                        loadWithdraws(1)

                        // setModalWithdraw(false)

                        // setWithdrawSuccessData(data)

                        // setWithdrawFormData({
                        //     account_id: 1,
                        //     amount: '',
                        //     request_description: ''
                        // })

                        return `Success.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }

    }
    async function withdrawHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.createWithdraw(withdrawFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        setEmailModalCode(false);
                        setWithdrawFormData({
                            account_id: 1,
                            amount: '',
                            request_description: '',
                            otp_code: ''
                        })

                        loadDeposits(1)
                        loadBalances()
                        loadAllPayments()
                        loadTransactions()
                        loadWithdraws(1)

                        // setModalWithdraw(false)
                        setWithdrawSuccessData(data)

                        setWithdrawFormData({
                            account_id: 1,
                            amount: '',
                            request_description: '',
                            otp_code: ''
                        })

                        return `You have successfully withdrawn.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }

    }
    async function cancelWithdrawHandle(id: any) {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.cancelWithdraw(id),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        loadDeposits(1)
                        loadBalances()
                        loadAllPayments()
                        loadTransactions()
                        loadWithdraws(1)

                        return `Success.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }

    }
    const [transferSuccessData, setTransferSuccessData] = useState(null)
    async function transferHandle(islandID) {
        if (islandID) {
            if (!locker) {
                setLocker(true)
                toast.promise(
                    walletService.createTransferPerIsland(withdrawFormData, islandID),
                    {
                        loading: 'Preparing...',
                        success: (data: any) => {
                            setLocker(false)
                            setWithdrawFormData({
                                account_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            loadDeposits(1)
                            loadBalances()
                            loadAllPayments()
                            loadTransactions()
                            loadWithdraws(1)
                            loadGratitude()

                            // setModal(false)
                            setTransferSuccessData(data)

                            setWithdrawFormData({
                                account_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            return `Success.`
                        },
                        error: (err) => {
                            setLocker(false)
                            return `${err.message.toString()}`
                        },
                    },
                    {
                        success: {
                            duration: 6000,
                            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                        },
                    }
                );
            }
        } else {
            if (!locker) {
                setLocker(true)
                toast.promise(
                    walletService.createTransfer(withdrawFormData),
                    {
                        loading: 'Preparing...',
                        success: (data: any) => {
                            setLocker(false)
                            setWithdrawFormData({
                                account_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            loadDeposits(1)
                            loadBalances()
                            loadAllPayments()
                            loadTransactions()
                            loadWithdraws(1)

                            // setModal(false)
                            setTransferSuccessData(data)

                            setWithdrawFormData({
                                account_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            return `Success.`
                        },
                        error: (err) => {
                            setLocker(false)
                            return `${err.message.toString()}`
                        },
                    },
                    {
                        success: {
                            duration: 6000,
                            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                        },
                    }
                );
            }
        }
    }


    const [newStakeFormData, setNewStakeFormData] = useState({
        amount: '',
    })

    async function newStakeHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.createNewStake(newStakeFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        setNewStakeFormData({
                            amount: '',
                        })

                        setIsModalOpenStake(false)
                        loadDiamondHistory()

                        return `You have successfully deposited to the BOS Treasury.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }
    }

    const [renewStakeFormData, setRenewStakeFormData] = useState({
        amount: '',
    })

    async function renewStakeHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.renewStake(renewStakeFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        setRenewStakeFormData({
                            amount: '',
                        })

                        setIsModalOpenRenew(false)
                        loadDiamondHistory()

                        return `You have successfully Renewed.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }
    }


    const [claimAvailableStakeFormData, setClaimAvailableStakeFormData] = useState({
        amount: '',
    })

    async function claimAvailableStakeHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.claimAvailableStake
                    (claimAvailableStakeFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {

                        setLocker(false)
                        setClaimAvailableStakeFormData({
                            amount: '',
                        })
                        setIsModalOpenClaim(false)
                        loadDiamondHistory()
                        loadDiamondActionHistory()

                        return `You have successfully Claimed.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }
    }


    const [compoundAvailableStakeFormData, setCompoundAvailableStakeFormData] = useState({
        amount: '',
    })

    async function compoundAvailableStakeHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.compoundAvailableStake
                    (compoundAvailableStakeFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        setCompoundAvailableStakeFormData({
                            amount: '',
                        })

                        setIsModalOpenCompound(false)
                        loadDiamondHistory()
                        loadDiamondActionHistory()

                        return `You have successfully Reallocated an amount.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }
    }


    const [unlockStakeFormData, setUnlockStakeFormData] = useState({
        amount: '',
    })

    async function unlockStakeHandle() {
        if (!locker) {
            setLocker(true)
            toast.promise(
                walletService.unlockStake
                    (unlockStakeFormData),
                {
                    loading: 'Preparing...',
                    success: (data: any) => {
                        setLocker(false)
                        setUnlockStakeFormData({
                            amount: '',
                        })

                        setIsModalOpenRenew(false)
                        loadDiamondHistory()

                        return `You have successfully Unlocked.`
                    },
                    error: (err) => {
                        setLocker(false)
                        return `${err.message.toString()}`
                    },
                },
                {
                    success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                }
            );
        }
    }



    const [modalTopupTransfer, setModalTopupTransfer] = useState(false)
    const [topupTransferFormData, setTopupTransferFormData] = useState({
        island_id: 1,
        amount: '',
        request_description: '',
        otp_code: ''
    })

    const [topupTransferSuccessData, setTopupTransferSuccessData] = useState(null)
    async function topupTransferToIslandHandle(islandID) {
        if (islandID) {
            if (!locker) {
                setLocker(true)
                toast.promise(
                    islandService.createNewTopupFromGratitudePerIsland(topupTransferFormData, islandID),
                    {
                        loading: 'Preparing...',
                        success: (data: any) => {
                            setLocker(false)
                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            loadDeposits(1)
                            loadBalances()
                            loadAllPayments()
                            loadTransactions()
                            loadWithdraws(1)
                            loadGratitude()

                            // setModal(false)
                            setTopupTransferSuccessData(data)

                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            return `Success.`
                        },
                        error: (err) => {
                            setLocker(false)
                            return `${err.message.toString()}`
                        },
                    },
                    {
                        success: {
                            duration: 6000,
                            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                        },
                    }
                );
            }
        } else {
            if (!locker) {
                setLocker(true)
                toast.promise(
                    islandService.createNewTopupFromGratitude(topupTransferFormData),
                    {
                        loading: 'Preparing...',
                        success: (data: any) => {
                            setLocker(false)
                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            loadDeposits(1)
                            loadBalances()
                            loadAllPayments()
                            loadTransactions()
                            loadWithdraws(1)

                            // setModal(false)
                            setTopupTransferSuccessData(data)

                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            return `Success.`
                        },
                        error: (err) => {
                            setLocker(false)
                            return `${err.message.toString()}`
                        },
                    },
                    {
                        success: {
                            duration: 6000,
                            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                        },
                    }
                );
            }
        }

    }
    async function participateTransferToIslandHandle(islandID) {
        if (islandID) {
            if (!locker) {
                setLocker(true)
                toast.promise(
                    islandService.createNewParticipateFromGratitudePerIsland(topupTransferFormData, islandID),
                    {
                        loading: 'Preparing...',
                        success: (data: any) => {
                            setLocker(false)
                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            loadDeposits(1)
                            loadBalances()
                            loadAllPayments()
                            loadTransactions()
                            loadWithdraws(1)
                            loadGratitude()

                            // setModal(false)
                            setTopupTransferSuccessData(data)

                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            return `Success.`
                        },
                        error: (err) => {
                            setLocker(false)
                            return `${err.message.toString()}`
                        },
                    },
                    {
                        success: {
                            duration: 6000,
                            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                        },
                    }
                );
            }
        } else {
            if (!locker) {
                setLocker(true)
                toast.promise(
                    islandService.createNewParticipateFromGratitude(topupTransferFormData),
                    {
                        loading: 'Preparing...',
                        success: (data: any) => {
                            setLocker(false)
                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            loadDeposits(1)
                            loadBalances()
                            loadAllPayments()
                            loadTransactions()
                            loadWithdraws(1)

                            // setModal(false)
                            setTopupTransferSuccessData(data)

                            setTopupTransferFormData({
                                island_id: 1,
                                amount: '',
                                request_description: '',
                                otp_code: ''
                            })

                            return `Success.`
                        },
                        error: (err) => {
                            setLocker(false)
                            return `${err.message.toString()}`
                        },
                    },
                    {
                        success: {
                            duration: 6000,
                            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                        },
                    }
                );
            }
        }

    }

    const [modalDeposit, setModalDeposit] = useState(false)
    const [depositWireFormData, setDepositWireFormData] = useState({
        account_id: '',
        amount: '',
        transfer_date: timeToYYYYMMDD(+new Date()),
        expected_date: timeToYYYYMMDD(+new Date()),

        request_description: '',
        transaction_document: null,
        transaction_document_2: null,
        transaction_document_3: null,
        otp_code: '',
        saving_balance: '',
        bank_type: 4
    })

    const [depositCryptoFormData, setDepositCryptoFormData] = useState({
        account_id: '',
        amount: '',

        expected_date: timeToYYYYMMDD(+new Date()),
        transfer_date: timeToYYYYMMDD(+new Date()),
        transaction_hash: '',

        request_description: '',
        otp_code: '',
        saving_balance: '',
    })

    const [depositSuccessData, setDepositSuccessData] = useState(null)
    async function depositHandle(formData: any, data: any, type: string) {

        if (type === 'crypto') {
            // const errorsCrypto = walletValidation.membershipFeeCrypto(formData);

            if (!locker) {
                setLocker(true)
                // if (Object.keys(errorsCrypto).length === 0) {
                if (datePickerCryptoTransfer) {
                    toast.promise(
                        walletService.createDeposit(formData),
                        {
                            loading: 'Waiting...',
                            success: (data: any) => {
                                setDepositSuccessData(data)
                                setLocker(false)
                                // setModalDeposit(false)

                                loadDeposits(1)
                                loadBalances()
                                loadAllPayments()
                                loadTransactions()
                                loadWithdraws(1)

                                setDepositCryptoFormData({
                                    account_id: '',
                                    amount: '',

                                    expected_date: timeToYYYYMMDD(+new Date()),
                                    transfer_date: timeToYYYYMMDD(+new Date()),
                                    transaction_hash: '',

                                    request_description: '',
                                    otp_code: '',
                                    saving_balance: '',
                                })

                                return `You have successfully Deposited.`
                            },
                            error: (err: any) => {
                                setLocker(false)
                                return `${err.message.toString()}`
                            },
                        },
                        {
                            success: {
                                duration: 6000,
                                icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                            },
                        })
                } else {
                    toast.error('"Transfer date" is required.')
                    setLocker(false)
                }
                // } else {
                //     setErrorsCryptoFee(errorsCrypto)
                //     setLocker(false)
                // };
            }
        }

        if (type === 'wire') {
            // const errorsWire = wazardValidation.membershipFeeWire(data);
            if (!locker) {
                setLocker(true)
                // if (Object.keys(errorsWire).length === 0) {
                if ((Object.keys(data).length !== 0) && (data?.transaction_document?.size > 0)) {
                    if (datePickerWireExp && datePickerWireTransfer) {
                        toast.promise(
                            walletService.createDeposit(formData),
                            {
                                loading: 'Preparing...',
                                success: (data: any) => {
                                    setLocker(false)
                                    setDepositSuccessData(data)
                                    // setModalDeposit(false)

                                    loadDeposits(1)
                                    loadBalances()
                                    loadAllPayments()
                                    loadTransactions()
                                    loadWithdraws(1)

                                    setDepositWireFormData({
                                        account_id: '',
                                        amount: '',
                                        transfer_date: timeToYYYYMMDD(+new Date()),
                                        expected_date: timeToYYYYMMDD(+new Date()),

                                        request_description: '',
                                        transaction_document: null,
                                        transaction_document_2: null,
                                        transaction_document_3: null,
                                        otp_code: '',
                                        bank_type: 4,
                                        saving_balance: '',

                                    })

                                    return `Done.`
                                },
                                error: (err: any) => {
                                    setLocker(false)
                                    return `${err.message.toString()}`
                                },
                            },
                            {
                                success: {
                                    duration: 6000,
                                    icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                                },
                            })
                    } else {
                        toast.error('Both date are required.')
                        setLocker(false)
                    }
                } else {
                    toast.error("Bank transaction receipt is required.")
                    setLocker(false)
                }
            }
            // } else {
            //     setErrorsWireFee(errorsWire)
            //     setLocker(false)
            // };
        }

    }



    const [modalIslandTransferToIsland, setModalIslandTransferToIsland] = useState(false)



    const context = {
        cancelWithdrawHandle,
        modalIslandTransferToIsland, setModalIslandTransferToIsland,
        paymentList,
        loadPayments,

        depositList,
        loadDeposits,

        withdrawList,
        loadWithdraws,

        feesList,
        loadFees,

        GPList,
        loadGP,

        balances, balancesNew,
        loadBalances,

        transactions,
        loadTransactions,

        allPaymentList,
        loadAllPayments,

        loadTransaction,

        allAccounts,
        loadAccounts,
        setAllAccounts,
        loadAllPaymentsSorted,
        walletListCrypto,
        loadCryptoWallet,

        walletListBank,
        loadBankWallet,

        withdrawFormData, setWithdrawFormData, withdrawHandle, transferHandle, sendEmailCodeForWithdrawHandle,
        modal, setModal,
        modalTransfer: modal, setModalTransfer: setModal,

        depositWireFormData, setDepositWireFormData, depositHandle,
        modalDeposit, setModalDeposit,

        datePickerWireTransfer, setDatePickerWireTransfer,
        datePickerWireExp, setDatePickerWireExp,
        datePickerCryptoTransfer, setDatePickerCryptoTransfer,

        depositCryptoFormData, setDepositCryptoFormData,

        allAccountsCrypto, setAllAccountsCrypto,
        allAccountsWire, setAllAccountsWire,

        walletInfoDepositModal, loadWalletInfoMembershipModal,
        walletInfoMembershipModal, loadWalletInfoDepositModal,
        depositSuccessData, setDepositSuccessData,
        transferSuccessData, setTransferSuccessData,
        modalWithdraw, setModalWithdraw,
        withdrawSuccessData, setWithdrawSuccessData,
        ckeditorTransferModal, loadCkEditorByID, emailModalCode, setEmailModalCode,
        topupTransferFormData, setTopupTransferFormData, topupTransferSuccessData, setTopupTransferSuccessData, topupTransferToIslandHandle, participateTransferToIslandHandle, modalTopupTransfer, setModalTopupTransfer,

        diamondGlobalData, loadDiamondBalance,
        diamondHistory, loadDiamondHistory,

        unlockStakeHandle, setUnlockStakeFormData, unlockStakeFormData,
        compoundAvailableStakeHandle, setCompoundAvailableStakeFormData, compoundAvailableStakeFormData,
        claimAvailableStakeHandle, setClaimAvailableStakeFormData, claimAvailableStakeFormData,
        renewStakeHandle, setRenewStakeFormData, renewStakeFormData,
        newStakeHandle, setNewStakeFormData, newStakeFormData,

        loadDiamondActionHistory, setDiamondActionHistory, diamondActionHistory,

        isModalOpenRenew, setIsModalOpenRenew,
        isModalOpenCompound, setIsModalOpenCompound,
        isModalOpenUnlock, setIsModalOpenUnlock,
        isModalOpenClaim, setIsModalOpenClaim,
        isModalOpenStake, setIsModalOpenStake,
    };

    return (
        <WalletContext.Provider value={context}>
            {children}
        </WalletContext.Provider>
    );
};

export default WalletContext;