import { useContext } from "react"
import PrivateContext from "../../../context/private/privateContext"
import Membership from "./membership"
import Pending from "./pending"
import Premium from "./premium"


const Box = () => {
    const { flowState } = useContext(PrivateContext)

    switch (flowState.step) {
        case 14:
            return <Membership />
        case 15:
            return <Pending />
        case 16:
            return <Premium />

        default:
            return <Membership />
    }
}

export default Box