import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import TreasuryContext from '../../../context/private/treasuryContext';
import { numberRounder, numberWithCommas } from '../../../utils/tools';
import AccountContext from '../../../context/private/accountContext';
import PrivateContext from '../../../context/private/privateContext';
import dictionary from '../../../locals/modal.json';
import { ModalMethods, SuccessModal } from '../Success';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import confirmedIcon from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';

export const IslandTopupModal = ({ topUpAmount, setTopUpAmount }) => {
  const screenWidth = useWindowWidth();

  const { setUpdateTimer } = useContext(PrivateContext);

  const [isTermsCheck, setTermsCheck] = useState('');
  const {
    islandsDetailData,
    modalTopUp,
    setModalTopUp,
    topUpHandle,
    islandData,
    currentIslandId,
    loadCkEditorByID,
    topupSuccessData,
    setTopupSuccessData,
    ckeditorTopupModal
  } = useContext(TreasuryContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    setUpdateTimer(+new Date());
    modalTopUp === false && setTopupSuccessData(null);
  }, [modalTopUp]);

  useEffect(() => {
    loadCkEditorByID(1);
  }, []);

  return (
    <>
      {!topupSuccessData && modalTopUp && (
        <div className="blur-bg" onClick={() => setModalTopUp(false)}></div>
      )}

      {!topupSuccessData && modalTopUp && (
        <div className="island-topup-modal">
          <div
            className="modal-mobile"
            style={{
              height:
                screenWidth < 1037
                  ? '1311px'
                  : screenWidth >= 1037
                    ? '665px'
                    : undefined,
              overflow: screenWidth < 1037 ? 'hidden' : undefined,
              width:
                screenWidth < 1037
                  ? '564px'
                  : screenWidth >= 1037
                    ? '1037px'
                    : undefined
            }}
          >
            <div
              className="content"
              style={{
                alignItems:
                  screenWidth < 1037
                    ? 'flex-start'
                    : screenWidth >= 1037
                      ? 'center'
                      : undefined,
                flexDirection: screenWidth < 1037 ? 'column' : undefined,
                gap: screenWidth < 1037 ? '20px' : undefined,
                justifyContent:
                  screenWidth >= 1037 ? 'space-between' : undefined,
                width:
                  screenWidth < 1037
                    ? '524px'
                    : screenWidth >= 1037
                      ? '989px'
                      : undefined
              }}
            >
              {screenWidth < 1037 && (
                <>
                  <div className="left">
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          {dictionary['MODAL-title1']}:
                        </div>
                        <div className="text-wrapper-3">
                          {islandData?.[0]?.title}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          {dictionary['MODAL-title2']}:
                        </div>
                        <div className="text-wrapper-3">
                          {userInfo?.total_balance ||
                            userInfo?.total_balance === 0
                            ? `$${numberWithCommas(
                              Number(userInfo?.total_balance)
                            )}`
                            : 'Loading...'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          {dictionary['MODAL-title3']}:
                        </div>
                        <div className="text-wrapper-3">
                          {islandsDetailData ? (
                            islandsDetailData?.balance ? (
                              `$${numberWithCommas(
                                islandsDetailData?.balance,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={topUpAmount}
                        pattern="\d*"
                        onChange={(e) => {
                          const value = e.target.value;

                          const integerValue = value.replace(/\D/g, '');
                          if (userInfo?.total_balance >= +integerValue) {
                            setTopUpAmount(integerValue.length > 0 ? Number(integerValue) : '');
                          }

                          // const value = e.target.value;

                          // if (/^\d*$/.test(value) && userInfo?.total_balance >= +value) {
                          //   setTopUpAmount(value.length > 0 ? Math.floor(Number(value)) : '');
                          // }
                        }}
                        onKeyPress={(e) => {

                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="options">
                        <p
                          className="min pointer"
                          onClick={(e) =>
                            setTopUpAmount(Math.floor(Number(islandsDetailData?.minimum_topup)))
                          }
                        >
                          <span className="span">
                            {dictionary['MODAL-title4']}:
                          </span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandsDetailData
                              ? `$${numberWithCommas(
                                islandsDetailData?.minimum_topup
                              )}`
                              : ''}
                          </span>
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) => {
                            setTopUpAmount(
                              numberRounder(Math.floor(Number(userInfo?.total_balance)))
                            );
                          }}
                        >
                          <span className="span">
                            {dictionary['MODAL-title5']}:
                          </span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {userInfo?.total_balance ||
                              userInfo?.total_balance === 0 ? (
                              `$${numberWithCommas(
                                Number(userInfo?.total_balance),
                                0
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group">
                        <p className="please-ensure-to">
                          <span
                            className="text-wrapper-6"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorTopupModal
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                  />
                                </div>
                                <img
                                  className="img"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          {dictionary['MODAL-title6']}
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(topUpAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="frame">
                      <div className="group">
                        <div className="overlap-group-3">
                          <div className="rectangle-2" />
                          <div className="payment-details">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  {dictionary['MODAL-title7']}
                                </div>
                                <div className="text-wrapper-3">Top up</div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  {dictionary['MODAL-title8']}
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(topUpAmount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line"
                              alt="Line"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  {dictionary['MODAL-title9']}
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(userInfo?.total_balance) -
                                    Number(topUpAmount)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  {dictionary['MODAL-title10']}
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(islandsDetailData?.balance) +
                                    Number(topUpAmount)
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-2">
                        <input
                          className="checkbox-size"
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setTermsCheck(e.target.checked)}
                        />
                        <label htmlFor="horns">
                          <p className="p">
                            {dictionary['MODAL-title11']}{' '}
                            <Link
                              to={`/island-page?id=${currentIslandId}&tab=conditions`}
                              target="_blank"
                            >
                              {dictionary['MODAL-title12']}
                            </Link>
                          </p>
                        </label>
                      </div>
                      <div className="item">
                        <div
                          className="div-wrapper pointer"
                          style={
                            isTermsCheck
                              ? {}
                              : { opacity: 0.3, cursor: 'not-allowed' }
                          }
                          onClick={() => {
                            if (isTermsCheck) {
                              if (islandsDetailData) {
                                if (
                                  islandsDetailData?.minimum_topup <=
                                  topUpAmount
                                ) {
                                  topUpHandle(topUpAmount, currentIslandId);
                                  setTopUpAmount('');
                                } else {
                                  toast.error(
                                    `Minimum top-up is $${islandsDetailData?.minimum_topup}`
                                  );
                                }
                              }
                            }
                          }}
                        >
                          <div className="text-wrapper-12">
                            {dictionary['MODAL-title13']}
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}

              {screenWidth >= 1037 && (
                <>
                  <div className="left-2">
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          {dictionary['MODAL-title1']}:
                        </div>
                        <div className="text-wrapper-3">
                          {islandData?.[0]?.title}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          {dictionary['MODAL-title2']}:
                        </div>
                        <div className="text-wrapper-3">
                          {userInfo?.total_balance ||
                            userInfo?.total_balance === 0
                            ? `$${numberWithCommas(
                              Number(userInfo?.total_balance)
                            )}`
                            : 'Loading...'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          {dictionary['MODAL-title3']}:
                        </div>
                        <div className="text-wrapper-3">
                          {islandsDetailData ? (
                            islandsDetailData?.balance ? (
                              `$${numberWithCommas(
                                islandsDetailData?.balance,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="number"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={topUpAmount}
                        pattern="\d*"
                        onChange={(e) => {
                          const value = e.target.value;

                          const integerValue = value.replace(/\D/g, '');
                          if (userInfo?.total_balance >= +integerValue) {
                            setTopUpAmount(integerValue.length > 0 ? Number(integerValue) : '');
                          }

                          // const value = e.target.value;

                          // if (/^\d*$/.test(value) && userInfo?.total_balance >= +value) {
                          //   setTopUpAmount(value.length > 0 ? Math.floor(Number(value)) : '');
                          // }
                        }
                        }
                        onKeyPress={(e) => {

                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="options">
                        <p
                          className="min pointer"
                          onClick={(e) =>
                            setTopUpAmount(Math.floor(islandsDetailData?.minimum_topup))
                          }
                        >
                          <span className="span">
                            {dictionary['MODAL-title4']}:
                          </span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandsDetailData
                              ? `$${numberWithCommas(
                                islandsDetailData?.minimum_topup
                              )}`
                              : ''}
                          </span>
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) => {
                            setTopUpAmount(
                              numberRounder(Math.floor(Number(userInfo?.total_balance)))
                            );
                          }}
                        >
                          <span className="span">
                            {dictionary['MODAL-title5']}:
                          </span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {userInfo?.total_balance ||
                              userInfo?.total_balance === 0 ? (
                              `$${numberWithCommas(
                                Number(userInfo?.total_balance),
                                0
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group-4">
                        <p className="please-ensure-to">
                          <span
                            className="text-wrapper-6"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorTopupModal
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src={line7} />
                  <div className="right-2">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src={confirmedIcon}
                                  />
                                </div>
                                <img
                                  className="vector-2"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          {dictionary['MODAL-title6']}
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(topUpAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="frame-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-3" />
                          <div className="payment-details-2">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  {dictionary['MODAL-title7']}
                                </div>
                                <div className="text-wrapper-3">Top up</div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  {dictionary['MODAL-title8']}
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(topUpAmount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line-4"
                              alt="Line"
                              src={dottedLine}
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  {dictionary['MODAL-title9']}
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(userInfo?.total_balance) -
                                    Number(topUpAmount)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  {dictionary['MODAL-title10']}
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(islandsDetailData?.balance) +
                                    Number(topUpAmount)
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-2">
                        <input
                          className="checkbox-size"
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setTermsCheck(e.target.checked)}
                        />
                        <label htmlFor="horns">
                          <p className="p">
                            {dictionary['MODAL-title11']}{' '}
                            <Link
                              to={`/island-page?id=${currentIslandId}&tab=conditions`}
                              target="_blank"
                            >
                              {dictionary['MODAL-title12']}
                            </Link>
                          </p>
                        </label>
                      </div>
                      <div className="overlap-wrapper">
                        <div
                          className="overlap-2 pointer"
                          style={
                            isTermsCheck
                              ? {}
                              : { opacity: 0.3, cursor: 'not-allowed' }
                          }
                          onClick={() => {
                            if (isTermsCheck) {
                              if (islandsDetailData) {
                                if (
                                  islandsDetailData?.minimum_topup <=
                                  topUpAmount
                                ) {
                                  topUpHandle(topUpAmount, currentIslandId);
                                  setTopUpAmount('');
                                } else {
                                  toast.error(
                                    `Minimum top-up is $${islandsDetailData?.minimum_topup}`
                                  );
                                }
                              }
                            }
                          }}
                        >
                          <div className="text-wrapper-13">
                            {dictionary['MODAL-title13']}
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className="hr"
              style={{
                width:
                  screenWidth < 1037
                    ? '565px'
                    : screenWidth >= 1037
                      ? '1037px'
                      : undefined
              }}
            />
            <div
              className="header-2"
              style={{
                width:
                  screenWidth < 1037
                    ? '516px'
                    : screenWidth >= 1037
                      ? '993px'
                      : undefined
              }}
            >
              <div className="text-wrapper-14">Island Top up</div>
              <div className="close-btn" onClick={() => setModalTopUp(false)}>
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                      screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                        ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                        : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {modalTopUp && topupSuccessData && (
        <SuccessModal
          requestedAmount={topupSuccessData?.requestedAmount}
          txID={topupSuccessData?.txID}
          method={ModalMethods.TopUp}
          time={topupSuccessData?.time}
          fee={topupSuccessData?.fee}
          finalAmount={topupSuccessData?.finalAmount}
          closeState={setModalTopUp}
        />
      )}
    </>
  );
};
