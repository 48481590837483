import React, { useContext } from 'react'
import IslandsContext from '../../../context/private/islandsContext'
import Tab from './tab'
import { IslandsItemContext } from '../Index'
import dictionary from "./../../../locals/island.json"

const Tabs = () => {

  const tabs = [dictionary['ISLAND-PAGE-TAB-title1'], dictionary['ISLAND-PAGE-TAB-title2'], dictionary['ISLAND-PAGE-TAB-title3'], dictionary['ISLAND-PAGE-TAB-title4'], dictionary['ISLAND-PAGE-TAB-title5'], dictionary['ISLAND-PAGE-TAB-title6']]

  return (
    <div className="over-nav">
      {tabs.map((tab, i) => <Tab key={i} title={tab} index={i} />)}
    </div>
  )
}
export default Tabs