import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import WizardContext from "../../../context/private/wizardContext";
import { Flex } from "../../../styles/Styles";

const DickPickerInputCryptoTransfer = () => {
    const { datePickerCryptoTransfer, setDatePickerCryptoTransfer, setMembershipFormDataCryptoRenew, membershipFormDataCryptoRenew } = useContext(WizardContext)
    
    return (
        <Flex y="center" gap="1" style={{marginBottom: '1rem'}}>
            <span className="date-picker-title">Transfer date</span>
            
            <DatePicker
                className="appf-form-input"
                selected={datePickerCryptoTransfer}
                onChange={(date) => {
                    setDatePickerCryptoTransfer(date)
                    
                    setMembershipFormDataCryptoRenew({
                        ...membershipFormDataCryptoRenew,
                        transfer_date: +new Date(date)
                      });
                }}
                placeholder="Transfer date"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </Flex>
    );
};

export default DickPickerInputCryptoTransfer