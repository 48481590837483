
import Expiration from './Expiration'
import Layout from './layout/Index'

const ExpirationView = () => {
  return (
    <Layout className="parent">
      <Expiration />
    </Layout>
  )
}

export default ExpirationView