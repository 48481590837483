import React, { useContext, useEffect } from 'react';
import PrivateContext from '../context/private/privateContext';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Layout from './layout/Index';
import Loader from './Loader';

const PrivacyPolicy = () => {
  const { loadPrivacyPolicy, privacyPolicy } = useContext(PublicContext);
  const { settings } = useContext(PrivateContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://app.termly.io/embed-policy.min.js';

    const termlyDiv = document.querySelector('[name="termly-embed"]');
    termlyDiv?.appendChild(script);

    return () => {
      termlyDiv?.removeChild(script);
    };
  }, [settings]);

  return (
    <Layout className="parent">
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            {/* header*/}

            <Header title="Privacy Policy" />

            {settings ? (
              <div className="explorer-body">
                <div className="explorer-content">
                  <h4>Privacy Policy & Terms</h4>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: settings?.privacy_global
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
