import { useContext, useEffect, useState } from 'react';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Loader from './Loader';
import Layout from './layout/Index';
import { TbMessage2Question } from 'react-icons/tb';
import { VscFeedback } from 'react-icons/vsc';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NotFoundImage from './../assets/images/not-found.png';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import { BiArrowBack } from 'react-icons/bi';

export const NotFoundTopic = ({ isSearch = false }) => {
  return (
    <Flex direction={'col'} x="center">
      <img className="not-found-faq" src={NotFoundImage} />
    </Flex>
  );
};

export const SearchBox = () => {
  const {
    loadFaq,
    faqsData,
    faqsSectionData,
    loadFaqSection,
    faqsTopicData,
    loadFaqTopic,
    faqsQuestionData,
    popularQuestionsData,
    loadFaqQuestion,
    searchFaqHandle,
    faqsQuestionSearchedData,
    setFaqsQuestionSearchedData,
    searchQuery,
    setSearchQuery
  } = useContext(PublicContext);

  useEffect(() => {
    searchFaqHandle(searchQuery);
  }, [searchQuery]);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="faq-searchbox">
      <BiArrowBack className='pointer' color='#1e6a92' onClick={handleGoBack} size={'2rem'} />
      <Link to="/faq-system">
        <VscFeedback size={'2rem'} />
      </Link>
      <div className="faq-searchbox-input">
        <img
          width="20"
          height="20"
          src="/images/searchLogo.svg"
          alt="search Logo"
        />
        <input
          className="faq-searchbox-input-text"
          type="text"
          placeholder="Search through the Frequently Asked Questions..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
    </div>
  );
};

export const SearchWrapper = ({ children }) => {
  const {
    loadFaq,
    faqsData,
    faqsSectionData,
    loadFaqSection,
    faqsTopicData,
    loadFaqTopic,
    faqsQuestionData,
    popularQuestionsData,
    loadFaqQuestion,
    searchFaqHandle,
    faqsQuestionSearchedData,
    setFaqsQuestionSearchedData,
    searchQuery,
    setSearchQuery
  } = useContext(PublicContext);

  useEffect(() => {
    searchFaqHandle(searchQuery);
  }, [searchQuery]);

  const [WhichData, setWhichData] = useState(0);
  const [topicID, setTopicID] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const targetParam = urlParams.get('topic');
    setTopicID(targetParam);
    console.log(targetParam);

    if (targetParam) {
      setWhichData(1);
      loadFaqTopic(targetParam);
    } else {
      setWhichData(0);
      loadFaqSection();
    }
  }, [location.search]);

  return (
    <>
      {Boolean(searchQuery && searchQuery.length > 0) ? (
        <>
          {faqsQuestionSearchedData ? (
            <>
              {!faqsQuestionSearchedData ||
                faqsQuestionSearchedData?.length === 0 ? (
                <NotFoundTopic isSearch={true} />
              ) : (
                <div className="faq-list-content">
                  {[''].map((item) => (
                    <div className="faq-question-list">
                      <h2 className="faq-question-list-title">
                        <Link
                          // to={`/faq-system?topic=${faqsQuestionData?.Topics?.[0]?.id}`}
                          to={`/faq-system`}
                        >
                          Search
                        </Link>{' '}
                      </h2>
                      <div className="faq-question-list-content">
                        {faqsQuestionSearchedData?.map((item) => (
                          <div
                            className="faq-question-item pointer"
                            onClick={() => {
                              setSearchQuery('');
                              setFaqsQuestionSearchedData([]);
                            }}
                          >
                            <MdOutlineQuestionAnswer
                              className="faq-question-item-icon"
                              color="#153458"
                              size={'1.2em'}
                            />
                            <Link
                              to={`/faq-system/questions/question?id=${item?.child_id}&topic-id=${item?.parent_id}`}
                            >
                              {item?.question}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        children
      )}
    </>
  );
};

export const AsideItems = () => {
  const { loadPopularQuestions, popularQuestionsData, setSearchQuery } =
    useContext(PublicContext);

  useEffect(() => {
    loadPopularQuestions();
  }, []);

  return (
    <div className="faq-aside">
      <div className="faq-popular">
        <h2 className="faq-popular-title">Most Popular</h2>
        <div className="faq-popular-content">
          {popularQuestionsData?.map((item) => (
            <div className="faq-popular-item">
              <TbMessage2Question
                className="faq-question-item-icon"
                color="#153458"
                size={'1.2em'}
              />
              <Link
                className="text-limit"
                onClick={() => setSearchQuery('')}
                to={`/faq-system/questions/question?id=${item?.child_id}&topic-id=${item?.parent_id}`}
              >
                {item?.question}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="faq-support">
        <h2 className="faq-popular-title">Need Support?</h2>
        <div className="faq-popular-content">
          We strive to provide you with a seamless and enjoyable experience on
          our website. However, we understand that there might be times when you
          have questions or concerns that aren't addressed in our FAQ section.{' '}
          <br /> <br /> Don't worry – our dedicated support team is here to
          help! If you haven't found the information you need or if you're
          facing any issues, please feel free to reach out to us via email at
          hello@blueoceansociety.club. <br /> <br />
          Our support team is always ready to assist you with any inquiries you
          may have.
        </div>
      </div>
    </div>
  );
};

const FaqsList = () => {
  const {
    loadFaq,
    faqsData,
    faqsSectionData,
    loadFaqSection,
    faqsTopicData,
    loadFaqTopic,
    faqsQuestionData,
    popularQuestionsData,
    loadFaqQuestion,
    searchFaqHandle,
    faqsQuestionSearchedData,
    setFaqsQuestionSearchedData,
    searchQuery,
    setSearchQuery
  } = useContext(PublicContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({
    title: 'FAQs',
    rows: []
  });

  const [WhichData, setWhichData] = useState(0);
  const [topicID, setTopicID] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const targetParam = urlParams.get('topic');
    setTopicID(targetParam);
    console.log(targetParam);

    if (targetParam) {
      setWhichData(1);
      loadFaqTopic(targetParam);
    } else {
      setWhichData(0);
      loadFaqSection();
    }
  }, [location.search]);

  return (
    <Layout className="parent">
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title="Frequently Asked Questions" />
            <SearchBox />
            <div className="faq-system-wrapper">
              <SearchWrapper>
                <div
                  className={
                    WhichData === 1 && faqsTopicData?.length === 0
                      ? 'faq-content faq-not-found'
                      : 'faq-content'
                  }
                >
                  {WhichData === 0 && (
                    <>
                      {faqsSectionData?.map((item) => (
                        <div className="faq-topic">
                          <h2 className="faq-topic-title text-limit pointer">
                            <Link
                              to={`/faq-system?topic=${item?.id}`}
                              onClick={() => loadFaqTopic(item?.id)}
                            >
                              {item?.title}
                            </Link>
                          </h2>

                          <div className="faq-topic-content">
                            {item?.description}
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  {WhichData === 1 && (
                    <>
                      {faqsTopicData?.length === 0 ? (
                        <NotFoundTopic />
                      ) : (
                        faqsTopicData &&
                        faqsTopicData?.map((item) => (
                          <div className="faq-topic">
                            <h2 className="faq-topic-title text-limit pointer">
                              <Link
                                to={`/faq-system/questions?id=${item?.id}&topic-id=${topicID}`}
                                onClick={() => loadFaqQuestion(item?.id)}
                              >
                                {item?.title}
                              </Link>
                            </h2>
                            <div className="faq-topic-content">
                              {item?.description}
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </SearchWrapper>

              <AsideItems />
            </div>
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

export default FaqsList;
