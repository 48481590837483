import React, { useEffect, useState } from 'react'
import { Flex } from '../../../styles/Styles'
import settingService from '../../../services/settingService';

const ModalAgreement = ({ isOpen, onClick, onClose, ckEditorKey }: any) => {
    const [isAccept, setIsAccept] = useState(false)

    const [ckeditorModal, setCkeditorModal] = useState(null);
    async function loadCkEditorByID() {
        await settingService
            .getCkEditorById(ckEditorKey)
            .then((data) => setCkeditorModal(data));
    }

    useEffect(() => {
        ckEditorKey && loadCkEditorByID()
    }, [ckEditorKey])


    return (
        <>
            {isOpen && (
                <div className='absolute w-full flex justify-center items-center left-0 right-0'>
                    <Flex x="center">
                        <div className="mod">
                            <div className="mod-border w-full">
                                <div className="mod-box">
                                    <p className="mod-box-p">
                                        Agreement
                                    </p>
                                </div>


                                <div className="boscrew-t-row">
                                    <div
                                        className="boscrew-t-cap"
                                        dangerouslySetInnerHTML={{
                                            __html: ckeditorModal
                                        }}
                                    ></div>

                                    {/* <div className='leading-[0.8] w-full'><p className='font-normal'>If you have questions about this notification please contact</p> <br /><span className='text-md'><a href="mailto:hello@blueoceansociety.club">hello@blueoceansociety.club</a></span></div> */}


                                    <div className="boscrew-t-end">
                                        <div className="boscrew-t-line">
                                            <div className="boscrew-t-combo">
                                                <input
                                                    type="checkbox"
                                                    id="horns"
                                                    name="horns"
                                                    checked={isAccept}
                                                    onChange={(e) => setIsAccept(e.target.checked)}
                                                />
                                                <label
                                                    className="boscrew-t-label"
                                                    htmlFor="horns"
                                                >
                                                    I hereby confirm that I have read the complete Agreement, and I acknowledge that I understand this is a binding confirmation.  
                                                </label>
                                            </div>
                                            <div className="boscrew-t-for-btn">
                                                <button
                                                    className={
                                                        isAccept
                                                            ? 'intr-last-row-right-btn'
                                                            : 'intr-last-row-right-btn disable'
                                                    }
                                                    disabled={!isAccept}
                                                    onClick={(e) => {
                                                        onClick(e)
                                                        setIsAccept(false)
                                                    }}
                                                >
                                                    Accept
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="blur-bg" onClick={(e) => {
                            onClose(e)
                            setIsAccept(false)
                        }}></div>
                    </Flex>
                </div>
            )}
        </>
    )
}

export default ModalAgreement