import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';
import authService from '../services/authService';
import { toast } from 'react-hot-toast';
import Countdown from 'react-countdown';

const rendererCountdown = ({ seconds }) => {
    return (seconds > 0 ? `Auto logout in ${String(seconds).padStart(2, "0")}s of inactivity.` : 'Processing...');
};

export default function useAutoLogout(updateTimer, flowState) {
    const navigate = useNavigate();
    const location = useLocation();

    async function logoutHandle() {
        const response = await authService.logout()
        localStorage.setItem('flowState', JSON.stringify({ step: -1, menu: 0, status: 0, email: '' }));
        if (response && location.pathname.toLowerCase() !== "/login") {
            navigate('/login')
        }
    }

    useEffect(() => {
        const exceptionSteps = [4, 5, 6, 7, 8];
        const currentStep = flowState ? flowState.step : 0;
        const isLong = exceptionSteps.includes(currentStep);

        // Get current time 
        const currentChangesTime = +new Date()
        const remainderTime = isLong ? (currentChangesTime + config.autoLogoutTimeLong) : (currentChangesTime + config.autoLogoutTime);
        const remainderTimeToast = (remainderTime - (+new Date())) - (1 * 60 * 1000)
        const remainderTimeLogout = (remainderTime - (+new Date()))
        let toastId = null;
        // Save it in local storage
        localStorage.setItem('lastUserActivity', JSON.stringify(currentChangesTime));

        // Show timer to the user
        const showToastId = setTimeout(() => {
            // Counter
            toastId = toast((t) => (
                <Countdown date={remainderTime} renderer={rendererCountdown} />
            ), {
                duration: config.autoLogoutTime,
            });
        }, remainderTimeToast);

        const timeoutId = setTimeout(() => {
            // Logout function
            remainderTimeLogout && remainderTimeLogout > 0 && logoutHandle()
        }, remainderTimeLogout);

        // Reset the timeout when the component unmounts or when the dependency array changes
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(showToastId);
            toastId && toast.dismiss(toastId);
        };
    }, [navigate, updateTimer, flowState]);


    return 'time'
}
