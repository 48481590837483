import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import dictionary from '../locals/my-map.json';
import { nFormatter } from '../utils/tools';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    bezierCurve: true,

    plugins: {
        legend: {
            display: true,
        },
        filler: {
            propagate: false
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += '$' + context.parsed.y.toLocaleString();
                    return label;
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                callback: function (value) {
                    return nFormatter(value);
                }
            }
        }
    }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const up = (ctx, value) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;
const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

let width, height, gradient;
export const getGradient = (ctx, chartArea, color1, color2, color3) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, color1);
        gradient.addColorStop(0.5, color2);
        gradient.addColorStop(0, color3);
    }

    return gradient;
}

export function ProfitChartMonthly({ data, mode }) {
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const convertedTime = []
    data?.timstamp.map(t => {
        const tempDate = new Date(Number(t))
        convertedTime.push(monthNames[tempDate.getMonth()])
    })

    const chartData1 = {
        labels: convertedTime,
        datasets: [
            {
                label: dictionary['CHART-PROFIT-lable-2'],
                data: data?.referral,
                // data: [238, 23, 155, 3, 21, 35, 400, 473, 764, 54, 5, 7, 7, 86, 3, 2],
                borderColor: '#2388CE',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-3'],
                data: data?.island,
                // data: [345, 2, 4, 3, 24, 5, 3245, 4, 43, 2, 345, 7, 99, 5, 5674, 8, 76, 678],
                borderColor: '#5c23ce',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-4'],
                data: data?.global,
                // data: [234, 134, 13, 324, 23, 1, 123, 3, 4, 657, 86, 5, 34, 45, 48, 84, 94],
                borderColor: '#ce2359',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
        ],
    };

    const chartData2 = {
        labels: convertedTime,
        datasets: [
            {
                label: dictionary['CHART-PROFIT-lable-1'],
                // data: [23, 23, 1234, 3345, 241, 354, 4, 43, 4, 54, 5, 7, 7, 86, 3, 2],
                data: data?.interest,
                borderColor: '#140e1a',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 10,
                borderSkipped: true,
            },
            {
                label: dictionary['CHART-PROFIT-lable-2'],
                data: data?.referral,
                // data: [345, 2, 4, 3, 24, 5, 3245, 4, 43, 2, 345, 7, 99, 5, 5674, 8, 76, 678],
                borderColor: '#2388CE',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-3'],
                data: data?.island,
                // data: [123, 2345, 2, 45, 2, 45, 546, 453, 2, 4, 43, 34, 654, 6, 90],
                borderColor: '#5c23ce',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-4'],
                data: data?.global,
                // data: [234, 134, 13, 324, 23, 1, 123, 3, 4, 657, 86, 5, 34, 45, 48, 84, 94],
                borderColor: '#ce2359',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-5'],
                data: data?.treasury,
                // data: [234, 134, 13, 324, 23, 1, 123, 3, 4, 657, 86, 5, 34, 45, 48, 84, 94],
                borderColor: '#ce2359',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
        ],
    };

    if (mode === "Profit Accumulated")
        return <Line options={options} data={chartData1} />
    else
        return <Line options={options} data={chartData2} />
}