const {
  REACT_APP_BASE_URL,
  REACT_APP_BASE_SLUG,
  REACT_APP_AUTO_LOGOUT_TIME,
  REACT_APP_AUTO_LOGOUT_TIME_LONG
} = process.env;
import version from './version.json';

const config = {
  version: version.version,
  server: `${REACT_APP_BASE_URL}`,
  service: {
    baseUrl: `${REACT_APP_BASE_URL}${REACT_APP_BASE_SLUG}`,
    tokenKey: 'token'
  },
  autoLogoutTime: Number(REACT_APP_AUTO_LOGOUT_TIME) * 60 * 1000,
  autoLogoutTimeLong: Number(REACT_APP_AUTO_LOGOUT_TIME_LONG) * 60 * 1000
};
export default config;
