import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import dictionary from '../locals/my-map.json';

import { nFormatter } from '../utils/tools';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    bezierCurve: true,
    xAxes: [{
        display: true,
        type: 'time',
        time: {
            parser: 'MM/DD/YYYY HH:mm',
            tooltipFormat: 'll HH:mm',
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
                'day': 'MM/DD/YYYY'
            }
        }
    }],

    plugins: {
        legend: {
            display: true,
        },
        filler: {
            propagate: false
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += '$' + context.parsed.y.toLocaleString();
                    return label;
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                callback: function (value) {
                    return nFormatter(value);
                }
            }
        }
    }
};


export function ProfitChartDaily({ data, mode }) {
    const convertedTime = []
    data?.timstamp.map(t => {
        const tempDate = new Date(Number(t))
        convertedTime.push(tempDate.getDate())
    })

    const chartData1 = {
        labels: convertedTime,
        datasets: [
            {
                label: dictionary['CHART-PROFIT-lable-2'],
                data: data?.referral,
                borderColor: '#2388CE',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-3'],
                data: data?.island,
                borderColor: '#5c23ce',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-4'],
                data: data?.global,
                borderColor: '#ce2359',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
        ],
    };
    const chartData2 = {
        labels: convertedTime,
        datasets: [
            {
                label: dictionary['CHART-PROFIT-lable-1'],
                data: data?.interest,
                borderColor: '#140e1a',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 10,
                borderSkipped: true,
            },
            {
                label: dictionary['CHART-PROFIT-lable-2'],
                data: data?.referral,
                borderColor: '#2388CE',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-3'],
                data: data?.island,
                borderColor: '#5c23ce',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: dictionary['CHART-PROFIT-lable-4'],
                data: data?.global,
                borderColor: '#ce2359',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,

            },
            {
                label: dictionary['CHART-PROFIT-lable-5'],
                data: data?.treasury,
                borderColor: '#ce2359',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,

            },
        ],
    };

    if (mode === "Profit Accumulated")
        return <Line options={options} data={chartData1} />
    else
        return <Line options={options} data={chartData2} />
}
