import React from "react";
import { ThemeProvider } from "styled-components";

const colors = {
    white: "#ffffff",
    black: "#171921",
    blue: "#3572e9",
    text: "#ffffff",
    background: {
        primary: '#ffffff"',
        secondary: "#FAFAFB"
    }
}

const breakpoints = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    customS: '576px',
    tabletS: '600px',
    tabletM: '768px',
    tabletL: '801px',
    customM: '960px',
    desktopS: '1024px',
    desktopM: '1170px',
    customL: '1200px',
    customXL: '1400px',
    desktopL: '1440px',
};

const transitions = {
    btn: "0.3s",
};

const fonts = ["sans-serif", "Roboto"];

const fontSizes = {
    small: "1em",
    medium: "2em",
    large: "3em"
};

const theme = {
    colors,
    transitions,
    breakpoints,
    fonts,
    fontSizes
}

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
