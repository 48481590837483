import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    barPercentage: .45,
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {

        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'top'
        },
        filler: {
            propagate: false
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += '$' + context.parsed.y.toLocaleString();
                    return label;
                }
            }
        }
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [1, 10, 21, 14, 12],
            backgroundColor: '#292D30',
            borderRadius: 8,
            borderSkipped: false,
        },
        {
            label: 'Dataset 2',
            data: [17, 15, 27, 14, 15],
            backgroundColor: '#34B3F1',
            borderRadius: 8,
            borderSkipped: false,
        },
    ]
};

export function TxChartMonthly({ data }) {

    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const convertedTime = []
    data?.timstamp.map(t => {
        const tempDate = new Date(Number(t))
        convertedTime.push(monthNames[tempDate.getMonth()])
    })

    const chartData = {
        labels: convertedTime,
        datasets: [
            {
                label: 'Deposits',
                data: data?.income,
                backgroundColor: '#34B3F1',
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: 'Withdrawals',
                data: data?.outcome,
                backgroundColor: '#292D30',
                borderRadius: 8,
                borderSkipped: false,
            },
            {
                label: 'Profit',
                data: data?.profit,
                backgroundColor: '#8a2be2',
                borderRadius: 8,
                borderSkipped: false,
            },
        ],
    };

    return <Bar options={options} data={chartData} />;
}
