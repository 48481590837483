import { useState } from "react"
import useFetchConfigJson from "../../../hooks/useFetchConfigJson"
import Input from "./input"

const Questions = (): JSX.Element => {
    const [questions, setQuestions] = useState<[] | null>(null)
    useFetchConfigJson('Questions', setQuestions)


    return (
        <>
            <div className="note-in-watch-video">
                <p>
                    <b>Note: </b> Please watch all of the videos so that you can then answer the questions below.
                </p>
                <p className="mt-10 text-sm leading-[30px]">
                    Please complete the following questions to assist us in evaluating your potential membership with Blue Ocean Society.
                </p>
                <p>
                    The "I agree" button will only activate once all questions have been answered correctly.
                </p>
            </div>
            <div>
                {(questions && questions.length > 0) && questions.map((question: { question: string, id: number, items: string[], answer: number }, index: number) => (
                    <Input key={index} index={index} id={index + 1} question={question.question} items={question.items} answer={question.answer} />
                ))}
            </div>
        </>
    )
}

export default Questions