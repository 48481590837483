import { useContext } from "react"
import PrivateContext from "../../../context/private/privateContext"
import AccountInformationForm from "./account-information"
import AccountType from "./account-type"
import Approved from "./message/approved"
import Pending from "./message/pending"
import Submitted from "./message/submitted"

const Form = () => {
    const { flowState } = useContext(PrivateContext)

    switch (flowState.step) {
        case 9:
            return <AccountType />
        case 10:
            return <AccountInformationForm />
        case 11:
            return <Submitted />
        case 12:
            return <Pending />
        case 13:
            return <Approved />

        default:
            return <AccountType />
    }
}

export default Form