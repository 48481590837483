import { useContext } from "react"
import WizardContext from "../../../context/private/wizardContext"
import { StepProps } from "./step.types"

const Step = ({ step, number, title }: StepProps): JSX.Element => {
    const { backToFirstStepApplicationForm } = useContext(WizardContext)
    return (
        <div className={`step ${(step || number === 1) && "active"} ${(number === 1) && "pointer"}`} onClick={() => number === 1 && backToFirstStepApplicationForm()}>
            <div className="number">{number}</div>
            <div className="title">{title}</div>
        </div>
    )
}

export default Step