import { useEffect } from 'react';

export default function useFetchConfigJson(key, setState) {
  useEffect(() => {
    const fetchConfigJson = async () => {
      try {
        const response = await fetch('config.json');
        const data = await response.json();
        setState(data[key]);
      } catch (error) {
        console.error('useFetchConfigJson:', error.message);
      }
    };

    fetchConfigJson();
  }, [key, setState]);
}
