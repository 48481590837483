import { createContext, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import authValidation from '../../validations/authValidation';
import PrivateContext from '../private/privateContext';
import PublicContext from './publicContext';

const LoginContext = createContext<any>({});

export const LoginContextProvider = ({ children }: JSX.Element | any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLockedBtn, setIsLockedBtn] = useState(false)

  const [userInput, setUserInput] = useState({
    email_address: '',
    password: '',
    token: '',
  });


  const [checkPasswordLink, setCheckPasswordLink] = useState<any>(null)
  async function loadCheckPasswordLink(hash: string) {
    await authService.passwordLinkChecker(hash).then(data => setCheckPasswordLink(data))
  }


  const [errors, setErrors] = useState<{ email?: string, password?: string }>({})
  const [strictMode, setStrictMode] = useState<boolean>(false)
  const [isUserHasAnActive2FA, setIsUserHasAnActive2FA] = useState<boolean | null>(null)


  function setDidAcknowledgeCookie() {
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
    document.cookie = `aknowledge=watching; expires=${expires.toUTCString()}; path=/; SameSite=Strict`;
  }


  async function loginHandle(tokenCode?: string) {

    if (tokenCode && tokenCode?.length > 0) {

      toast.promise(
        authService.login(
          '',
          '',
          tokenCode
        ),
        {
          loading: 'Login...',
          success: (data) => {
            if (data && location.pathname.toLowerCase() === '/login') {
              navigate('/');
            }
            setDidAcknowledgeCookie();
            setIsLockedBtn(false)
            return `Welcome`
          },
          error: (err) => {
            setIsLockedBtn(false)
            return `${err.message.toString()}`
          },
        },
        {
          success: {
            duration: 6000,
            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
          },
        }
      );
    } else {
      const errors = authValidation.login(userInput.email_address, userInput.password);

      setIsLockedBtn(true)
      if (Object.keys(errors).length === 0) {
        setStrictMode(false)
        toast.promise(
          authService.login2FAChecker(
            userInput.email_address,
            userInput.password
          ),
          {
            loading: 'Checking...',
            success: (data) => {
              window.localStorage.removeItem('flowState')
              window.localStorage.removeItem('applicationFormData')

              if (data) {
                if (isUserHasAnActive2FA === true) {
                  toast.promise(
                    authService.login2FA(
                      userInput.email_address,
                      userInput.password,
                      userInput.token,
                    ),
                    {
                      loading: 'Login...',
                      success: (data) => {
                        setIsUserHasAnActive2FA(null)
                        window.localStorage.removeItem('flowState')
                        window.localStorage.removeItem('applicationFormData')
                        if (data && location.pathname.toLowerCase() === '/login') {
                          navigate('/');
                        }
                        setDidAcknowledgeCookie();
                        setIsLockedBtn(false)
                        setUserInput({
                          email_address: '',
                          password: '',
                          token: '',
                        })
                        return `Welcome`
                      },
                      error: (err) => {
                        setIsLockedBtn(false)
                        return `${err.message.toString()}`
                      },
                    },
                    {
                      success: {
                        duration: 6000,
                        icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                      },
                    }
                  );
                } else {
                  setIsUserHasAnActive2FA(data)
                }
              } else {
                toast.promise(
                  authService.login(
                    userInput.email_address,
                    userInput.password
                  ),
                  {
                    loading: 'Login...',
                    success: (data) => {
                      window.localStorage.removeItem('flowState')
                      window.localStorage.removeItem('applicationFormData')
                      if (data && location.pathname.toLowerCase() === '/login') {
                        navigate('/');
                      }
                      setDidAcknowledgeCookie();
                      setIsLockedBtn(false)
                      return `Welcome`
                    },
                    error: (err) => {
                      setIsLockedBtn(false)
                      return `${err.message.toString()}`
                    },
                  },
                  {
                    success: {
                      duration: 6000,
                      icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
                    },
                  }
                );
              }

              setIsLockedBtn(false)
              return `Done`
            },
            error: (err) => {
              setIsLockedBtn(false)
              return `${err.message.toString()}`
            },
          },
          {
            success: {
              duration: 1,
            },
          }
        );
      } else {
        setStrictMode(true)
        setErrors(errors)
        setIsLockedBtn(false)
      };
    }
  }

  const [isSendPasswordLink, setIsSendPasswordLink] = useState(false)
  async function resetPasswordHandle(email: string) {
    setIsLockedBtn(true)
    toast.promise(
      authService.resetPassword(email),
      {
        loading: 'Waiting...',
        success: (data) => {

          setIsLockedBtn(false)
          setIsSendPasswordLink(true)
          return `Reset Password Sent`
        },
        error: (err) => {
          setIsLockedBtn(false)
          return `${err.message.toString()}`
        },
      },
      {
        success: {
          duration: 6000,
          icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
        },
      }
    );
  }

  const [isChanged, setIsChanged] = useState<boolean>(false)
  async function confirmNewPasswordHandle(password: string, token: string, key: string) {
    setIsLockedBtn(true)
    toast.promise(
      authService.newPassword(password, token, key),
      {
        loading: 'Waiting...',
        success: (data) => {

          setIsLockedBtn(false)
          setIsSendPasswordLink(true)
          setIsChanged(true)
          return `Password Changed`
        },
        error: (err) => {
          setIsLockedBtn(false)
          return `${err.message.toString()}`
        },
      },
      {
        success: {
          duration: 6000,
          icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
        },
      }
    );
  }

  function validate() {
    const errors = authValidation.login(userInput.email_address, userInput.password)
    return Object.keys(errors).length === 0
  }

  const context = {
    errors,
    setErrors,
    strictMode,
    setStrictMode,
    userInput,
    setUserInput,
    loginHandle,
    resetPasswordHandle,
    confirmNewPasswordHandle,
    isSendPasswordLink,
    validate,
    isLockedBtn,
    loadCheckPasswordLink,
    checkPasswordLink, isChanged, isUserHasAnActive2FA
  };

  return (
    <LoginContext.Provider value={context}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;