import { useContext, useEffect } from "react"
import { IslandsItemContext } from "../Index"
import IslandsContext from "../../../context/private/islandsContext"
import toast from "react-hot-toast"
import dictionary from "../../../locals/island.json"
import AccountContext from "../../../context/private/accountContext"

const Tab = ({ title, index }: { title: string, index: number }): JSX.Element => {
  const { currentTab, setCurrentTab, islandOverview } = useContext(IslandsItemContext)
  const { setModalParticipate, setModalClosedParticipateAndTopup } = useContext(IslandsContext)
  const { userInfo } = useContext(AccountContext);

  return (

    <>
      {(userInfo?.is_action_limit === 1 && index === 2) ? <></> :

        <button
          className={currentTab === index ? "over-nav-btn-deac pointer" : "over-nav-btn pointer"}
          onClick={() => {
            if (index === 2) {
              islandOverview ?
                ((Number(islandOverview?.deadline) === -1 || Number(islandOverview?.deadline) > +new Date())) ? Number(islandOverview?.is_input_open) ? setModalParticipate(true) : toast.error('Participate is closed.') : setModalClosedParticipateAndTopup(true) : null;
            } else {
              setCurrentTab(index)
            }
          }}> {title}</button >}
    </>
  )
}

export default Tab