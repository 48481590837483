import React, { useEffect, useRef } from 'react';
import Vimeo from '@vimeo/player';

const VimeoPlayer = ({ videoId }) => {
    const videoPlayer = useRef(null);
    let player;

    useEffect(() => {
        initializePlayer();
        
        return () => {
            player.destroy();
        };
    }, [videoId]);

    const initializePlayer = () => {
        player = new Vimeo(videoPlayer.current, {
            id: videoId,
        });
    };

    return <div ref={videoPlayer} />;
};

export default VimeoPlayer;
