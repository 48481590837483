import { AiOutlineInfoCircle } from 'react-icons/ai';

const TooltipInfo = ({
  text,
  isInInput,
  type = false
}: {
  text: any;
  isInInput?: boolean;
  type?: boolean;
}): JSX.Element => {
  if (type) {
    return (
      <div className='tooltip-info tooltip-info-normal'>
        <AiOutlineInfoCircle className="tooltip-info-icon" />
        <div className="tooltip-info-text">
          <span>{text}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={isInInput ? 'tooltip-info tooltip-info-input' : 'tooltip-info'}>
        <AiOutlineInfoCircle className="tooltip-info-icon" />
        <div className="tooltip-info-text">
          <span>{text}</span>
        </div>
      </div>
    );
  }
};

export default TooltipInfo;
