import { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Link, useSearchParams } from 'react-router-dom';
import IslandsContext from '../../../context/private/islandsContext';
import PrivateContext from '../../../context/private/privateContext';
import TreasuryContext from '../../../context/private/treasuryContext';
import { Flex } from '../../../styles/Styles';
import {
  numberWithCommas,
  timeDifference,
  timestampToLocalDaily,
  timestampToLocalHour,
  timeToYYYYMMDD
} from '../../../utils/tools';
import { ProfitChartDaily } from '../../ProfitChartDaily';
import { ProfitChartMonthly } from '../../ProfitChartMonthly';
import { TxChartDaily } from '../charts/transactions-daily-chart';
import { TxChartMonthly } from '../charts/transactions-monthly-chart';
import styles from './CountDownTimer.module.scss';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import dictionary from '../../../locals/my-treasures.json';
import { IslandWithdrawalModal } from '../../Modal/IslandWithdrawal';
import { IslandTopupModal } from '../../Modal/IslandTopup';
import { ModalMethods, SuccessModal } from '../../Modal/Success';
import { IslandClaimWindowModal } from '../../Modal/IslandClaim';
import { IlsandTransferToIslandModal } from '../../Modal/IslandTransferToIsland';
import AccountContext from '../../../context/private/accountContext';

const CircleBar = ({ color, percentage }: any) => {
  const [displayPercentage, setDisplayPercentage] = useState(0);
  useEffect(() => {
    const percentageComplete = percentage / 100;
    const strokeDashOffsetValue = 100 - percentageComplete * 100;
    setDisplayPercentage(strokeDashOffsetValue);
  }, [percentage]);
  return (
    <div className="statisticItem">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className="progress-bar__background"
        />

        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className="progress-bar__progress 
                 js-progress-bar"
          strokeDashoffset={displayPercentage}
        />
      </svg>
    </div>
  );
};

const Overview = () => {
  // const { islandClaimWindow, loadIslandClaimWithdraw } = useContext(IslandsContext)
  const {
    islandClaimWindow,
    loadProfitChart,
    loadIslandClaimWithdraw,
    modalForceWithdrawAreYouSure,
    setModalForceWithdrawAreYouSure,
    modalTopUpAreYouSure,
    setModalTopUpAreYouSure,
    profitChartDailyData,
    profitChartMonthlyData,
    approximatlyAvailable,
    loadTransactionChart,
    transactionChartDailyData,
    transactionChartMonthlyData,
    islandsDetailData,
    crewData,
    modalTopUp,
    setModalTopUp,
    modalClaim,
    setModalClaim,
    modalForceWithdraw,
    setModalForceWithdraw,
    topUpHandle,
    islandData,
    claimHandle,
    claimHandleForType2,
    forceWithdrawHandle,
    currentIslandId,
    claimSuccessData,
    setClaimSuccessData,
    setModalIslandTransferToIsland
  } = useContext(TreasuryContext);

  const { userInfo } = useContext(AccountContext);

  const [userProfitChart, setUserProfitChart] = useState('yearly');
  const [txChart, setTxChart] = useState('yearly');

  const { flowState, setUpdateTimer } = useContext(PrivateContext);

  useEffect(() => {
    if (flowState.step === 17 && currentIslandId !== -1 && currentIslandId && userInfo
      && userInfo?.membershipStatus !== 2) {
      userProfitChart === 'monthly' &&
        loadProfitChart(currentIslandId, 'monthly');
      userProfitChart === 'yearly' &&
        loadProfitChart(currentIslandId, 'yearly');
    }
  }, [userProfitChart, currentIslandId, flowState]);

  useEffect(() => {
    if (flowState.step === 17 && currentIslandId !== -1 && currentIslandId && userInfo
      && userInfo?.membershipStatus !== 2) {
      txChart === 'monthly' && loadTransactionChart(currentIslandId, 'monthly');
      txChart === 'yearly' && loadTransactionChart(currentIslandId, 'yearly');
    }
  }, [txChart, currentIslandId, flowState]);

  const [topUpAmount, setTopUpAmount] = useState<any>(null);
  const [claimAmount, setClaimAmount] = useState<any>(null);
  const [forceWithdrawAmount, setForceWithdrawAmount] = useState<any>(null);

  const [timerChecker, setTimerChecker] = useState<any>(false);
  useEffect(() => {
    timerChecker && loadIslandClaimWithdraw();
  }, [timerChecker]);

  const rendererCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: any) => {
    if (
      completed ||
      (+days === 0 && +hours === 0 && +minutes === 0 && +seconds === 0)
    ) {
      setTimerChecker(true);
      return (
        <div className={styles.container}>
          <div className={`light-theme-time timerWait`}>
            {dictionary['TREASURE-title10']}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <div className={`light-theme-time ${styles.timer}`}>
            <span className={styles.days}>
              {String(days).padStart(2, '0')}d
            </span>
            <span className={styles.hours}>
              {hours && String(hours).padStart(2, '0')}h
            </span>
            <span className={styles.minutes}>
              {minutes && String(minutes).padStart(2, '0')}m
            </span>
            <span className={styles.second}>
              {seconds && String(seconds).padStart(2, '0')}s
            </span>
          </div>
        </div>
      );
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setUpdateTimer(+new Date());
    setClaimSuccessData(null);
    modalClaim === false &&
      currentIslandId &&
      loadIslandClaimWithdraw(currentIslandId);
  }, [modalTopUp, modalClaim, modalForceWithdraw]);

  const calculateApproximatlyTimeTitle = (type: any) => {
    switch (type) {
      case 0:
        // monthly
        return `Monthly Predicted Rate`
      case 1:
        // daily
        return `Daily Predicted Rate`
      case 2:
        // yearly
        return `Yearly Predicted Rate`

      default:
        // default
        return `Interest Rate`
    }
  }

  const calculateApproximatlyTime = (type: any) => {
    switch (type) {
      case 0:
        // monthly
        return `${numberWithCommas(
          Number(islandsDetailData?.approximatilyRate)
        )}`
      case 1:
        // daily
        return `${numberWithCommas(
          Number(
            islandsDetailData?.approximatilyRate
          ) / 30
        )}`
      case 2:
        // yearly
        return `${numberWithCommas(
          Number(
            islandsDetailData?.approximatilyRate
          ) * 12
        )}`
    }
  }

  return (
    <>
      <>
        <IslandClaimWindowModal
          forceWithdrawAmount={claimAmount}
          setForceWithdrawAmount={setClaimAmount}
        />
        {/* {
          !claimSuccessData && modalClaim && <Flex x="center">
            <div className="mod">

              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">{dictionary["TREASURE-title20"]}</p>
                  <button className="mod-box-btn" onClick={() => setModalClaim(false)}>X</button>
                </div>

                <div className="mod-inside">
                  <div className='mod-inside-body'>
                    <p className="modal-description">{dictionary["TREASURE-title21"]} <Link target={'_blank'} to={`/island-page?id=${currentIslandId}&tab=conditions`}>{dictionary["TREASURE-title22"]}</Link> {dictionary["TREASURE-title23"]}</p>

                    {islandClaimWindow?.island_type === 3 && <input
                      type="number"
                      placeholder="Enter an amount"
                      className="mod-inside-body-input"
                      value={claimAmount}
                      onChange={(e) => setClaimAmount(e.target.value.length > 0 ? Number(e.target.value) : '')}
                    />}

                  </div>
                  <Flex x="center" gap="1">
                    <button className="mod-inside-body-btn pointer"
                      onClick={() => {
                        setClaimAmount('')
                      }}>{dictionary["TREASURE-title18"]}</button>

                    <button className="mod-inside-body-btn pointer"
                      onClick={() => {
                        islandClaimWindow?.island_type === 3 ? claimHandle(claimAmount, currentIslandId) : claimHandleForType2(claimAmount, currentIslandId);
                        setClaimAmount('')
                      }}>{dictionary["TREASURE-title19"]}</button>
                  </Flex>

                </div>
              </div>
            </div>
            <div className="blur-bg" onClick={() => setModalClaim(false)}>
            </div>
          </Flex>
        } */}

        {/* {
          modalClaim && claimSuccessData && <SuccessModal
            requestedAmount={claimSuccessData?.requestedAmount}
            txID={claimSuccessData?.txID}
            method={islandClaimWindow?.island_type === 3 ? ModalMethods.Claim1 : ModalMethods.Claim2}
            time={claimSuccessData?.time}
            fee={claimSuccessData?.fee}
            totalClaim={claimSuccessData?.finalAmount}
            closeState={setModalClaim}
          />
        } */}

        <IlsandTransferToIslandModal />

        <IslandTopupModal
          topUpAmount={topUpAmount}
          setTopUpAmount={setTopUpAmount}
        />
        <IslandWithdrawalModal
          forceWithdrawAmount={forceWithdrawAmount}
          setForceWithdrawAmount={setForceWithdrawAmount}
        />
      </>
      <div className="over-body">
        <>
          {islandsDetailData?.hasPendingCalculation == 1 && (
            <button className="myt-body-r-box-r-btn deactive dark head-alert">
              {dictionary['TREASURE-title109']}
            </button>
          )}
          {islandsDetailData?.is_active === 2 && (
            <button className="myt-body-r-box-r-btn deactive head-alert">
              {dictionary['TREASURE-title33']}
            </button>
          )}
          {islandsDetailData?.is_active !== 0 &&
            islandsDetailData?.balance < islandsDetailData?.minimum_invest &&
            islandsDetailData?.exception === 0 && (
              <button className="myt-body-r-box-r-btn deactive head-alert">
                {dictionary['TREASURE-title34']}
              </button>
            )}

          <div className="inside-my-treasure-items">
            {/*totals circle */}
            <div
              style={{
                display: 'flex',
                gap: 23,
                width: '100%',
                justifyContent: 'center',
                flexDirection: 'row'
              }}
            >
              <div className="over-main">
                <Flex direction="col" gap="1.4">
                  <div className="mymap-s">
                    <div className="mymap-s-box">
                      <div className="mymap-s-row">
                        <div className="myt-body-r-box-flex">
                          <Flex y="center" gap={0.5}>
                            <div className="mymap-s-h">
                              {dictionary['TREASURE-title35']}
                            </div>
                            <div
                              className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                              style={{
                                left: 0,
                                width: 20,
                                margin: '-14px 0px 0px 4px'
                              }}
                            >
                              <AiOutlineInfoCircle className="tooltip-info-icon" />
                              <div className="tooltip-info-text">
                                <span>{dictionary['TREASURE-title36']}</span>
                              </div>
                            </div>
                          </Flex>
                        </div>
                      </div>

                      <select
                        className="mymap-s-selector"
                        onChange={(e) => setUserProfitChart(e.target.value)}
                      >
                        <option value="monthly">
                          {dictionary['TREASURE-title37']}
                        </option>
                        <option selected value="yearly">
                          {dictionary['TREASURE-title38']}
                        </option>
                      </select>
                    </div>
                    {userProfitChart === 'monthly' && (
                      <>
                        {profitChartDailyData ? (
                          <ProfitChartDaily
                            data={profitChartDailyData}
                            mode="Profit Accumulated"
                          />
                        ) : (
                          <div className="chart-skeleton-container">
                            <div className="skeleton-element-item chart skeleton-box  skeleton-chart-fix"></div>
                          </div>
                        )}
                      </>
                    )}
                    {userProfitChart === 'yearly' && (
                      <>
                        {profitChartMonthlyData ? (
                          <ProfitChartMonthly
                            data={profitChartMonthlyData}
                            mode="Profit Accumulated"
                          />
                        ) : (
                          <div className="chart-skeleton-container">
                            <div className="skeleton-element-item chart skeleton-box  skeleton-chart-fix"></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="over-connections">
                    <div className="over-connections-schema">
                      <img
                        src="/images/mt-plugin-connect.svg"
                        alt="pluginLogo"
                        className="over-connections-schema-symbol"
                      />
                      <span>
                        {crewData?.my_total_crew
                          ? `${numberWithCommas(crewData?.my_total_crew)}`
                          : '0'}
                      </span>
                    </div>
                    <div className="over-connections-total">
                      <div className="over-connections-total-symbol">
                        <img src="/images/money.svg" alt="money" />
                      </div>
                      <div className="over-connections-total-text">
                        <span>{dictionary['TREASURE-title39']}</span>
                        <b>
                          {crewData ? (
                            crewData?.total_commission ? (
                              `$${numberWithCommas(crewData?.total_commission)}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                </Flex>
                <div className="over-participate">
                  <div
                    className="wall-body-post"
                    style={{ padding: '0', minHeight: 'unset' }}
                  >
                    {/* start code */}
                    <div className="participate-info">
                      <Flex gap={1} x="between">
                        <div className="participate-info-item">
                          <div className="title">
                            {dictionary['TREASURE-title40']}
                          </div>
                          {islandData?.[0]?.is_active == 0 && (
                            <span className="is-active-badge">
                              {dictionary['TREASURE-title41']}
                            </span>
                          )}
                          <div className="data-container">
                            <div className="data-container-item">
                              <Flex y="center" gap={0.5}>
                                <div className="data-title">
                                  {dictionary['TREASURE-title42']}
                                </div>
                                <div
                                  className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                  style={{ right: 0, top: 0 }}
                                >
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-title43']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>

                              <div className="data-value">
                                {islandsDetailData ? (
                                  islandsDetailData?.activeAmount ? (
                                    `$${numberWithCommas(
                                      islandsDetailData?.activeAmount,
                                      2
                                    )}`
                                  ) : (
                                    '$0'
                                  )
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>
                            <div className="data-container-item">
                              <Flex y="center" gap={0.5}>
                                <div className="data-title">
                                  {dictionary['TREASURE-title44']}
                                </div>
                                <div
                                  className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                  style={{ right: 0, top: 0 }}
                                >
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-title45']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>

                              <div className="data-value">
                                {islandsDetailData ? (
                                  islandsDetailData?.balance ? (
                                    `$${numberWithCommas(
                                      islandsDetailData?.balance,
                                      2
                                    )}`
                                  ) : (
                                    '$0'
                                  )
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>

                            <div className="data-container-item">
                              <Flex y="center" gap={0.5}>
                                <div className="data-title">
                                  {dictionary['TREASURE-title107']}
                                </div>
                                <div
                                  className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                  style={{ right: 0, top: 0 }}
                                >
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-title108']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>

                              <div className="data-value">
                                {islandData?.[0] ? (
                                  islandData?.[0]?.sumPendingAllocation ? (
                                    `$${numberWithCommas(
                                      islandData?.[0]?.sumPendingAllocation,
                                      2
                                    )}`
                                  ) : (
                                    '$0'
                                  )
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="participate-info-item">
                          <div className="title">
                            {dictionary['TREASURE-title46']}
                          </div>
                          <div className="data-container">
                            {islandsDetailData?.show_accured_interest == 1 && (
                              <div className="data-container-item">
                                <Flex y="center" gap={0.5}>
                                  <div className="data-title">
                                    {dictionary['TREASURE-title47']}
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{ right: 0, top: 0 }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div className="tooltip-info-text">
                                      <span>
                                        {dictionary['TREASURE-title48']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>

                                <div className="data-value">
                                  {islandsDetailData?.hasPendingCalculation ==
                                    1 ? (
                                    'Waiting...'
                                  ) : approximatlyAvailable ? (
                                    approximatlyAvailable?.available ? (
                                      `$${numberWithCommas(
                                        approximatlyAvailable?.available,
                                        2
                                      )}`
                                    ) : (
                                      '$0'
                                    )
                                  ) : (
                                    <div className="skeleton-element-item skeleton-box"></div>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="data-container-item">
                              <Flex y="center" gap={0.5}>
                                <div className="data-title">
                                  {dictionary['TREASURE-title49']}
                                </div>
                                <div
                                  className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                  style={{ right: 0, top: 0 }}
                                >
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-title50']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>

                              <div className="data-value">
                                {islandsDetailData ? (
                                  islandsDetailData?.available_balance ? (
                                    `$${numberWithCommas(
                                      islandsDetailData?.available_balance,
                                      2
                                    )}`
                                  ) : (
                                    '$0'
                                  )
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>
                            <div className="data-container-item">
                              <Flex y="center" gap={0.5}>
                                <div className="data-title">
                                  {dictionary['TREASURE-title51']}
                                </div>
                                <div
                                  className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                  style={{ right: 0, top: 0 }}
                                >
                                  <AiOutlineInfoCircle className="tooltip-info-icon" />
                                  <div className="tooltip-info-text">
                                    <span>
                                      {dictionary['TREASURE-title52']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>

                              <div className="data-value">
                                {islandsDetailData ? (
                                  islandsDetailData?.hasClaimedAll == 1 ? (
                                    islandsDetailData?.totalEarned ? (
                                      `$${numberWithCommas(
                                        Number(islandsDetailData?.totalEarned) -
                                        (Number(
                                          islandsDetailData?.totalTopup
                                        ) +
                                          Number(
                                            islandsDetailData?.TotalParticipate
                                          )),
                                        2
                                      )}`
                                    ) : (
                                      '$0'
                                    )
                                  ) : islandsDetailData?.totalEarned ? (
                                    `$${numberWithCommas(
                                      islandsDetailData?.totalEarned,
                                      2
                                    )}`
                                  ) : (
                                    '$0'
                                  )
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>
                  </div>

                  <div className="over-participate-w">
                    <div className="over-participate-w-f">
                      <h5>{dictionary['TREASURE-title53']}</h5>
                      {islandsDetailData ? (
                        <span>
                          {islandsDetailData?.minimum_withdraw
                            ? `$${numberWithCommas(
                              islandsDetailData?.minimum_withdraw,
                              2
                            )}`
                            : '$0'}
                        </span>
                      ) : (
                        <div className="skeleton-element-item skeleton-box"></div>
                      )}
                    </div>
                    <div className="over-participate-w-s">
                      <h5>{dictionary['TREASURE-title54']}</h5>
                      {islandsDetailData ? (
                        <span>
                          {islandsDetailData?.maximum_withdraw
                            ? islandsDetailData?.maximum_withdraw == -1
                              ? 'Unlimited'
                              : `$${numberWithCommas(
                                islandsDetailData?.maximum_withdraw,
                                2
                              )}`
                            : '$0'}
                        </span>
                      ) : (
                        <div className="skeleton-element-item skeleton-box"></div>
                      )}
                    </div>
                  </div>
                  <hr />

                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>
                        {islandsDetailData
                          ? calculateApproximatlyTimeTitle(islandsDetailData?.approximatily_timeframe)
                          : dictionary['TREASURE-title104']}
                      </span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title56']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandsDetailData ? (
                      <b>
                        {islandsDetailData?.approximatilyRate > 0
                          ? `${calculateApproximatlyTime(islandsDetailData?.approximatily_timeframe)}%`
                          : '0%'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title57']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title58']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandsDetailData ? (
                      <b>
                        {islandsDetailData?.TotalParticipate
                          ? `$${numberWithCommas(
                            islandsDetailData?.TotalParticipate,
                            2
                          )}`
                          : '$0'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title59']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title60']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandsDetailData ? (
                      <b>
                        {islandsDetailData?.totalTopup
                          ? `$${numberWithCommas(
                            islandsDetailData?.totalTopup,
                            2
                          )}`
                          : '$0'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title61']} </span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title62']}</span>
                        </div>
                      </div>
                    </Flex>
                    {islandsDetailData ? (
                      <b>
                        {islandsDetailData?.totalWithdtraw
                          ? `$${numberWithCommas(
                            islandsDetailData?.totalWithdtraw,
                            2
                          )}`
                          : '$0'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span> {dictionary['TREASURE-title63']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title64']}</span>
                        </div>
                      </div>
                    </Flex>
                    {islandsDetailData ? (
                      <b>
                        {islandsDetailData?.totalClaim
                          ? `$${numberWithCommas(
                            islandsDetailData?.totalClaim,
                            2
                          )}`
                          : '$0'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title65']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title66']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandsDetailData ? (
                      <b>
                        {islandsDetailData?.minimum_topup
                          ? `$${numberWithCommas(
                            islandsDetailData?.minimum_topup,
                            2
                          )}`
                          : '$0'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title105']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title106']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandsDetailData ? (
                      <b>
                        {islandData?.[0]?.participate_delay
                          ? `${islandData?.[0]?.participate_delay} day${islandData?.[0]?.participate_delay > 1 ? 's' : ''
                          }`
                          : '0 day'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title67']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title68']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandData ? (
                      <b>
                        {islandData?.[0]?.start_participate_date
                          ? `${timestampToLocalDaily(
                            Number(islandData?.[0]?.start_participate_date) /
                            1000
                          )}, ${timestampToLocalHour(
                            Number(islandData?.[0]?.start_participate_date) /
                            1000
                          )}`
                          : dictionary['TREASURE-title69']}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title70']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title71']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandData ? (
                      <b>
                        {islandData?.[0]?.start_participate_date &&
                          timeDifference(
                            Number(islandData?.[0]?.start_participate_date) /
                            1000
                          )}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                  <div className="over-participate-d">
                    <Flex y="center" gap={0.5}>
                      <span>{dictionary['TREASURE-title72']}</span>
                      <div
                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                        style={{ right: 0, top: 0 }}
                      >
                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                        <div className="tooltip-info-text">
                          <span>{dictionary['TREASURE-title73']}</span>
                        </div>
                      </div>
                    </Flex>

                    {islandData && islandsDetailData ? (
                      <b>
                        {islandsDetailData?.island_finish_date == -1
                          ? 'Unlimited'
                          : timeToYYYYMMDD(
                            islandsDetailData?.island_finish_date
                          )}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>

                  {islandClaimWindow &&
                    Number(islandClaimWindow?.island_id) ===
                    Number(searchParams.get('id')) ? (
                    <>
                      {+new Date() > Number(islandClaimWindow?.start_date) &&
                        islandClaimWindow?.status === true &&
                        islandClaimWindow?.island_type === 3 && (
                          <div className="over-participate-d">
                            <span>{dictionary['TREASURE-title74']}</span>
                            <b>
                              {
                                <Countdown
                                  date={Number(islandClaimWindow?.expire_date)}
                                  renderer={rendererCountdown}
                                />
                              }
                            </b>
                          </div>
                        )}
                      {!(+new Date() > Number(islandClaimWindow?.start_date)) &&
                        islandClaimWindow?.status === true &&
                        islandClaimWindow?.island_type === 3 && (
                          <div className="over-participate-d">
                            <span>{dictionary['TREASURE-title75']}</span>
                            <b>
                              {
                                <Countdown
                                  date={Number(islandClaimWindow?.start_date)}
                                  renderer={rendererCountdown}
                                />
                              }
                            </b>
                          </div>
                        )}
                    </>
                  ) : null}

                  {/* 
                  {(islandClaimWindow?.status === true && islandClaimWindow?.island_type === 3 && Number(islandClaimWindow?.totalCapacity) !== -1) && Number(islandClaimWindow?.claim_window_view) === 1 ?
                    <>
                      <hr />
                      <div className="claim-window-stats">


                        <div className="title">{dictionary["TREASURE-title117"]}</div>
                        <div className="information">
                          <div>
                            {islandClaimWindow && (Number(islandClaimWindow?.island_id) === Number(searchParams.get('id'))) ?
                              <>
                                <div className="over-participate-d">

                                  <span>{dictionary["TREASURE-title110"]}</span>
                                  <b>{islandClaimWindow
                                    ?
                                    islandClaimWindow?.totalCapacity == -1 ?
                                      dictionary["TREASURE-title116"]
                                      : islandClaimWindow?.totalCapacity ? `$${numberWithCommas(islandClaimWindow?.totalCapacity)}` : '$0'
                                    : <div className='skeleton-element-item skeleton-box'></div>}</b>
                                </div>
                              </>
                              : null}


                            {islandClaimWindow && (Number(islandClaimWindow?.island_id) === Number(searchParams.get('id'))) ?
                              <>
                                <div className="over-participate-d">
                                  <span>{dictionary["TREASURE-title111"]}</span>
                                  <b>{islandClaimWindow ? islandClaimWindow?.usedCapacity ? `$${numberWithCommas(islandClaimWindow?.usedCapacity)}` : '$0' : <div className='skeleton-element-item skeleton-box'></div>}</b>
                                </div>
                              </>
                              : null}


                            {islandClaimWindow && (Number(islandClaimWindow?.island_id) === Number(searchParams.get('id'))) ?
                              <>
                                <div className="over-participate-d">
                                  <span>{dictionary["TREASURE-title112"]}</span>
                                  <b>{islandClaimWindow ? islandClaimWindow?.availableCapacity ? `$${numberWithCommas(islandClaimWindow?.availableCapacity)}` : '$0' : <div className='skeleton-element-item skeleton-box'></div>}</b>
                                </div>
                              </>
                              : null}
                          </div>

                          <div>


                            {islandClaimWindow && (Number(islandClaimWindow?.island_id) === Number(searchParams.get('id'))) ?
                              <>
                                <div className="over-participate-d">
                                  <span>{dictionary["TREASURE-title114"]}</span>
                                  <b>{islandClaimWindow ? islandClaimWindow?.userUsedCapacity ? `$${numberWithCommas(islandClaimWindow?.userUsedCapacity)}` : '$0' : <div className='skeleton-element-item skeleton-box'></div>}</b>
                                </div>
                              </>
                              : null}


                            {islandClaimWindow && (Number(islandClaimWindow?.island_id) === Number(searchParams.get('id'))) ?
                              <>
                                <div className="over-participate-d">
                                  <span>{dictionary["TREASURE-title115"]}</span>
                                  <b>{islandClaimWindow ? islandClaimWindow?.userAvailableCapacity ? `$${numberWithCommas(islandClaimWindow?.userAvailableCapacity)}` : '$0' : <div className='skeleton-element-item skeleton-box'></div>}</b>
                                </div>
                              </>
                              : null}
                          </div>
                        </div>

                      </div>
                    </>
                    : null} */}
                  {/* <>{console.log("userInfo", userInfo?.is_action_limit)}</> */}
                  <div className="over-participate-b my-treasure-box-btn">
                    {userInfo?.is_action_limit === 0 && islandData &&
                      islandData?.length > 0 &&
                      Number(islandData?.[0]?.is_input_open) &&
                      (Number(islandData?.[0]?.participate_deadline) === -1 ||
                        Number(islandData?.[0]?.participate_deadline) >
                        +new Date()) ? (
                      <button
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setModalTopUp(true);
                        }}
                      >
                        {dictionary['TREASURE-title76']}
                      </button>
                    ) : (
                      <>
                        {Number(islandData?.[0]?.participate_deadline) >
                          +new Date() ? (
                          <button>
                            <div className="skeleton-element-item skeleton-box"></div>
                          </button>
                        ) : null}
                      </>
                    )}

                    {userInfo?.is_action_limit === 0 && islandClaimWindow?.status === true &&
                      islandsDetailData?.available_balance > 0 &&
                      Number(islandClaimWindow?.start_date) <= +new Date() &&
                      Number(islandClaimWindow?.expire_date) >= +new Date() && (
                        <button
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setModalClaim(true);
                          }}
                        >
                          {islandClaimWindow?.island_type === 2 &&
                            dictionary['TREASURE-title77']}
                          {islandClaimWindow?.island_type === 3 &&
                            dictionary['TREASURE-title78']}
                        </button>
                      )}

                    {/* Transfer */}
                    {userInfo?.is_action_limit === 0 && islandsDetailData?.non_refundable == 0 && (
                      <>
                        {(islandsDetailData?.transfer_out == 1 && islandsDetailData?.transfer_only_available == 0) && (
                          <button
                            onClick={() => setModalIslandTransferToIsland(true)}
                          >
                            {dictionary['TREASURE-title118']}
                          </button>
                        )}
                        {(islandsDetailData?.transfer_out == 1 &&
                          islandsDetailData?.transfer_only_available == 1 &&
                          islandClaimWindow?.status === true &&
                          islandsDetailData?.available_balance > 0 &&
                          Number(islandClaimWindow?.start_date) <= +new Date() &&
                          Number(islandClaimWindow?.expire_date) >= +new Date()) && (
                            <button
                              onClick={() => setModalIslandTransferToIsland(true)}
                            >
                              {dictionary['TREASURE-title118']}
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/*chart*/}
            <div className="over-chart">
              <Flex x="between">
                <div className="over-chart-title">
                  {dictionary['TREASURE-title79']}
                </div>
                <select
                  className="mymap-s-selector"
                  onChange={(e) => setTxChart(e.target.value)}
                >
                  <option value="monthly">
                    {dictionary['TREASURE-title80']}
                  </option>
                  <option selected value="yearly">
                    {dictionary['TREASURE-title81']}
                  </option>
                </select>
              </Flex>
              {/* <div className="over-chart-content">
                <div className="over-chart-content-s">
                  <div className="over-chart-content-s-blue"></div>
                  <span>Deposits</span>
                </div>
                <div className="over-chart-content-s">
                  <div className="over-chart-content-s-green"></div>
                  <span>Withdrawals</span>
                </div>
                <div className="over-chart-content-s">
                  <div className="over-chart-content-s-darkblue"></div>
                  <span>Profit</span>
                </div>
              </div> */}
              <div className="over-chart-line">
                {txChart === 'monthly' &&
                  (transactionChartDailyData ? (
                    <TxChartDaily data={transactionChartDailyData} />
                  ) : (
                    <div
                      className="chart-skeleton-container"
                      style={{ height: '56rem' }}
                    >
                      <div className="skeleton-element-item chart skeleton-box"></div>
                    </div>
                  ))}
                {txChart === 'yearly' &&
                  (transactionChartMonthlyData ? (
                    <TxChartMonthly data={transactionChartMonthlyData} />
                  ) : (
                    <div
                      className="chart-skeleton-container"
                      style={{ height: '56rem' }}
                    >
                      <div className="skeleton-element-item chart skeleton-box"></div>
                    </div>
                  ))}
              </div>
            </div>

            {islandsDetailData?.non_refundable == 0 && (
              <>
                {islandsDetailData && userInfo?.is_action_limit === 0 &&
                  Number(islandsDetailData?.force_withdraw_status) === 1 && (
                    <div className="over-chart">
                      <Flex direction="row" x="between" y="center" gap="8">
                        <div className="description-withdraw">
                          <h2 className="title-description">
                            {dictionary['TREASURE-title82']}
                          </h2>
                          <p>{dictionary['TREASURE-title83']}</p>
                        </div>
                        <button
                          className="button-withdraw"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setModalForceWithdraw(true);
                          }}
                        >
                          {dictionary['TREASURE-title84']}
                        </button>
                      </Flex>
                    </div>
                  )}
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Overview;
