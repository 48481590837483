import React from 'react';
import { Container, Flex } from '../../styles/Styles';
import Layout from '../layout/Index';
import Box from './boxes';
import Modal from './modal';
import Header from '../Header';


const AccountSetup = (): JSX.Element => {
  return (
    <Layout className='account-setup'>
      <Container x={'center'} y={'center'} className="container set-container">
         <Flex  gap={1.7} y="start" x={'center'} marginT={1}>
          <Flex gap={1.7} y="center" x={'center'} className="set-flex">
          <Header title='Blue Ocean Society' />
          <Modal />
          <Box />
        </Flex>
        </Flex>
        
      </Container>
    </Layout>
  );
};

export default AccountSetup;
