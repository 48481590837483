import { Container, Flex } from '../../styles/Styles';
import Header from '../Header';
import Layout from '../layout/Index';
import Form from './boxes';
import Steps from './steps/steps';

const ApplicationForm = (): JSX.Element => {
  return (
    <Layout className="parent">
      <Container x={'center'} y={'center'} className="container container-appf">
        <Flex gap={1.7} h="unset" x={'center'} marginT={1} className="flex-appf">
          <Header title='Blue Ocean Society' />
          <Steps />
          <Form />
        </Flex>
      </Container>
    </Layout>
  );
};

export default ApplicationForm;
