import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosClose } from 'react-icons/io';

type TModal = {
  onChange?: (e: any) => void;
  onClick?: () => void;
  min?: number;
  max?: number;
  image?: string;
  title?: string;
  isOpen?: boolean;
  setIsOpen?: (e: boolean) => void;
  value?: any;
  setValue?: any;
  balance?: any;
};

const ModalWithSwitch: React.FC<any> = ({
  onChange,
  onClick,
  min,
  max,
  image,
  title,
  isOpen,
  setIsOpen,
  value,
  setValue,
  balance,

  onChange2,
  onClick2,
  min2,
  max2,
  image2,
  title2,
  isOpen2,
  setIsOpen2,
  value2,
  setValue2,
  balance2
}: any) => {

  const [whichModa, setWhichModa] = useState(1)

  return (
    <>
      <div
        onClick={() => {
          setIsOpen(false)
          setIsOpen2(false)
        }}
        className={`bg-black bg-opacity-[0.65] inset-0 z-[150] fixed w-full h-full transition duration-300 backdrop-blur-[3px] ${isOpen ? 'visible opacity-100' : 'hidden invisible opacity-0'
          }`}
      ></div>
      <div
        className={`bg-white fixed left-1/2 top-1/2 w-full p-4 lg:p-8 rounded-2xl max-w-2xl -translate-y-1/2 transition duration-300 -translate-x-1/2 z-[155] ${isOpen ? 'visible opacity-100' : 'invisible opacity-0'
          }`}
      >
        <div className="flex justify-between items-center mb-5 border-b border-gray-400 pb-5">
          <span className="font-bold text-lg lg:text-[22px] ">{title}</span>
          <div
            onClick={() => {
              setIsOpen(false)
              setIsOpen2(false)
            }}
            className="p-1 cursor-pointer rounded-full bg-[#e9f1f599] hover:rotate-360 transition"
          >
            <IoIosClose className="text-5xl opacity-50" />
          </div>
        </div>

        <ul className="flex flex-wrap justify-center items-center text-[18px] font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="me-2">
            <button className={whichModa == 1
              ? "w-full inline-block px-6 py-3 text-white bg-[#153458] rounded-2xl active" : "inline-block px-6 py-3 rounded-2xl hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"}
              onClick={() => setWhichModa(1)}
            >Renew</button>
          </li>
          <li className="me-2">
            <button className={
              whichModa == 2
                ? "w-full inline-block px-6 py-3 text-white bg-[#153458] rounded-2xl active" : "inline-block px-6 py-3 rounded-2xl hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
            }
              onClick={() => setWhichModa(2)}
            >Transfer to Main Balance</button>
          </li>
        </ul>

        {whichModa == 1 ? <div className="flex flex-col gap-y-2">
          <img src={image} className="w-full h-60 object-contain" />
          {balance ? balance : null}
          <input
            type="number"
            className="bg-[#f5f5f5] border border-[#eee] h-[52px] px-2 lg:px-4 rounded-xl placeholder:text-gray-500"
            min={0}
            max={10}
            onChange={onChange}
            value={value?.amount}
            placeholder="Enter an amount"
          />
          <div className='flex justify-between'>

            {min == 0 || min ? <div className="flex justify-left">
              <div className="flex gap-x-2 items-center cursor-pointer" onClick={() => setValue({ ...value, amount: min })}>
                <span className="text-2xl">Min:</span>
                <span>${min}</span>
              </div>

            </div> : null}

            {max == 0 || max ? <div className="flex gap-x-2 items-center cursor-pointer" onClick={() => setValue({ ...value, amount: max })}>
              <span className="text-2xl">Max:</span>
              <span>${max}</span>
            </div> : null}
          </div>
          <button
            onClick={(e) => {
              if (min === undefined || min === null || value?.amount >= min) {
                onClick(e)
              } else {
                toast.error('Minimum reallocate amount is ' + min);
              }

            }}
            className="bg-[#153458] w-full h-[54px] text-2xl font-extrabold text-white md:text-[24px] rounded-2xl mt-10"
          >
            Submit
          </button>
        </div> : null}

        {whichModa == 2 ? <div className="flex flex-col gap-y-2">
          <img src={image} className="w-full h-60 object-contain" />
          {balance2 ? balance2 : null}
          <input
            type="number"
            className="bg-[#f5f5f5] border border-[#eee] h-[52px] px-2 lg:px-4 rounded-xl placeholder:text-gray-500"
            min={0}
            max={10}
            onChange={onChange2}
            value={value2?.amount}
            placeholder="Enter an amount"
          />
          <div className='flex justify-between'>

            {min == 0 || min ? <div className="flex justify-left">
              <div className="flex gap-x-2 items-center cursor-pointer" onClick={() => setValue2({ ...value, amount: Math.floor(min * 100) / 100 })}>
                <span className="text-2xl">Min:</span>
                <span>${min}</span>
              </div>

            </div> : null}

            {max == 0 || max ? <div className="flex gap-x-2 items-center cursor-pointer" onClick={() => setValue2({ ...value, amount: Math.floor(max * 100) / 100 })}>
              <span className="text-2xl">Max:</span>
              <span>${max}</span>
            </div> : null}
          </div>
          <button
            onClick={(e) => {
              if (min2 === undefined || min2 === null || value2?.amount >= min2) {
                onClick2(e)
              } else {
                toast.error('Minimum amount is ' + min2);
              }

            }}
            className="bg-[#153458] w-full h-[54px] text-2xl font-extrabold text-white md:text-[24px] rounded-2xl mt-10"
          >
            Submit
          </button>
        </div> : null}
      </div>
    </>
  );
};

export default ModalWithSwitch;
