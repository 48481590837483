import axios from 'axios';
import React, { useState } from 'react';
import { Container, Flex } from '../../styles/Styles';
import Layout from '../layout/Index';
import Actions from './actions';
import Steps from './steps/steps';
import Video from './videos';
import Header from '../Header';


const IntroductionVideo = (): JSX.Element => {
  const [values, setValues] = useState({
    watch_video: 0,
  });

  const submit = async () => {
    const data = {
      watch_video: values.watch_video
    };
    const response = await axios
      .post(
        'http://192.168.1.144:3000/Api/User/Authentication/VerifiyWatchVideo',
        data
      )
      .catch((e) => console.log('Error (Login)', e));
  };
  return (
    <Layout className="intr">
      <Container x={'center'} y={'center'} className="container intr-container">
        <Flex direction="col" className="intr-flex" gap={1.7} x={'left'} marginT={1} >
          <Header title='Blue Ocean Society' />
          <div className="intr-post verify-video">
            <Steps />
            <Video />
            <Actions />
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

export default IntroductionVideo;
