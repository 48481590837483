import { VerifyCode } from "."
import Layout from "./layout/Index"

const VerifyEmailView = () => {
  return (
    <Layout className="parent">
      <VerifyCode />
    </Layout>
  )
}

export default VerifyEmailView