import { useContext, useEffect, useState } from "react";
import NotFoundImage from "../../../assets/images/not-found.png";
import IslandsContext from "../../../context/private/islandsContext";
import PrivateContext from "../../../context/private/privateContext";
import { Flex } from "../../../styles/Styles";
import { IslandsItemContext } from "../Index";
import Loader from "../../Loader";
import AccountContext from "../../../context/private/accountContext";

const Explorer = () => {
  const { flowState } = useContext(PrivateContext)
  const { loadExplorer } = useContext(IslandsContext)
  const { currentId } = useContext(IslandsItemContext)
  const { userInfo } = useContext(AccountContext)

  const [islandExplorer, setIslandExplorer] = useState<any>(null)

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      loadExplorer(currentId, setIslandExplorer)
    }
  }, [flowState, userInfo])

  if (islandExplorer === null) return <Loader />
  return (
    <>
      {islandExplorer ? <>
        <div className="explorer-body" >
          <div className="explorer-content" >
            <h4>{islandExplorer?.title}</h4>
            <div className="content" dangerouslySetInnerHTML={{ __html: islandExplorer?.content }}></div>
          </div>
        </div>
      </> : <Flex y="center" x="center">
        <img src={NotFoundImage} />
      </Flex>}
    </>
  )
}

export default Explorer