import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import islandService from '../../services/islandService';
import PrivateContext from './privateContext';
const IslandsContext = createContext<any>({});

export const IslandsContextProvider = ({ children }: JSX.Element | any) => {
  const [modalTx, setModalTx] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const { currentSelectedIslandId, setCurrentSelectedIslandId } = useContext(PrivateContext)

  const [locker, setLocker] = useState(false)
  const [modalTopUp, setModalTopUp] = useState(false)
  const [modalParticipate, setModalParticipate] = useState(false)
  const [modalClosedParticipateAndTopup, setModalClosedParticipateAndTopup] = useState(false)

  const [islands, setIslands] = useState<any>(null)
  async function loadIslands(searchQuery?: string, filterStatus?: string, filterType?: string, offset?: any, limit?: number) {
    await islandService.getIslands(searchQuery, filterStatus, filterType, offset, limit).then(island => setIslands(island))
  }

  const [islandClaimWindow, setIslandClaimWindow] = useState<any>(null)
  async function loadOverview(id: any, setStateFunctionOverview: any, setStateFunctionIslandUser: any, setStateFunctionCrew: any, setStateFunctionClaimWindow: any) {
    if (Number(id) !== -1) {
      await islandService.getOverview(id).then(data => setStateFunctionOverview(data))
      await islandService.getUserInIsland(id).then(data => setStateFunctionIslandUser(data))
      await islandService.getTotalCommission(id).then(data => setStateFunctionCrew(data))
      await islandService.getCheckClaimWindow(id).then(data => setStateFunctionClaimWindow(data))
    }
  }
  async function loadIslandClaimWithdraw() {
    if (Number(currentSelectedIslandId) !== -1) {
      await islandService.getCheckClaimWindow(currentSelectedIslandId).then(data => setIslandClaimWindow(data))
    }
  }

  const [userHasParticipate, setUserHasParticipate] = useState<any>(null)
  async function loadUserHasParticipate(id: number, setStateFunction: any) {
    if (Number(id) !== -1)
      await islandService.getHasParticipate(id).then(data => setStateFunction(data))
  }

  const [islandExplorer, setIslandExplorer] = useState<any>(null)
  async function loadExplorer(id: number, setStateFunction: any) {
    if (Number(id) !== -1)
      await islandService.getExplorer(id).then(data => setStateFunction(data))
  }

  const [islandParticipateDetail, setIslandParticipateDetail] = useState<any>(null)
  async function loadParticipateDetail(id: number, setStateFunction: any) {
    if (Number(id) !== -1)
      await islandService.getParticipateDetail(id).then(data => setStateFunction(data))
  }

  const [islandUpdate, setIslandUpdate] = useState<any>(null)
  async function loadUpdate(id: number, setStateFunction: any, currentPage: any) {
    if (Number(id) !== -1)
      await islandService.getUpdate(id, currentPage).then(data => setStateFunction(data))
  }

  const [islandCondition, setIslandCondition] = useState<any>(null)
  async function loadCondition(id: number, setStateFunction: any) {
    if (Number(id) !== -1)
      await islandService.getCondition(id).then(data => setStateFunction(data))
  }

  async function loadFaqs(id: number, setStateFunction: any) {
    if (Number(id) !== -1)
      await islandService.getFaqs(id).then(data => setStateFunction(data))
  }

  const [profitChartDailyData, setProfitChartDailyData] = useState<any>(null)
  const [profitChartMonthlyData, setProfitChartMonthlyData] = useState<any>(null)
  async function loadProfitChart(id: number, type: string, setStateFunctionYear: any, setStateFunctionMonth: any) {
    if (Number(currentSelectedIslandId) !== -1) {
      type === 'monthly' && await islandService.getUserChart(id, type).then(data => setStateFunctionMonth(data))
      type === 'yearly' && await islandService.getUserChart(id, type).then(data => setStateFunctionYear(data))
    }
  }

  const [txChartDailyData, setTxChartDailyData] = useState<any>(null)
  const [txChartMonthlyData, setTxChartMonthlyData] = useState<any>(null)
  async function loadTxChart(id: number, type: string, setStateFunctionYear: any, setStateFunctionMonth: any) {
    if (Number(currentSelectedIslandId) !== -1) {
      type === 'monthly' && await islandService.getTransactionChart(id, type).then(data => setStateFunctionMonth(data))
      type === 'yearly' && await islandService.getTransactionChart(id, type).then(data => setStateFunctionYear(data))
    }
  }

  const [participateSuccessData, setParticipateSuccessData] = useState(null)
  async function participateHandle(amount: any) {
    if (!locker) {
      setLocker(true)
      toast.promise(
        islandService.createNewParticipate({ island_id: currentSelectedIslandId, amount: amount }),
        {
          loading: 'Participating...',
          success: (data: any) => {
            setLocker(false)
            // setModalTopUp(false)
            setParticipateSuccessData(data)

            // loadAccounts()
            setModalTx(true)

            return `Success.`
          },
          error: (err: any) => {
            setLocker(false)
            return `${err.message.toString()}`
          },
        },
        {
          success: {
            duration: 6000,
            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
          },
        })
    }
  }

  const context = {
    currentTab, setCurrentTab,
    islands, loadIslands,

    loadOverview, loadIslandClaimWithdraw,

    loadExplorer,
    islandExplorer,

    loadUpdate,
    islandUpdate,

    loadUserHasParticipate,
    userHasParticipate,

    loadCondition,
    islandCondition,

    loadParticipateDetail,
    islandParticipateDetail,

    participateHandle, modalTopUp, setModalTopUp,
    currentSelectedIslandId,

    loadProfitChart,
    profitChartDailyData, profitChartMonthlyData,

    loadTxChart,
    txChartDailyData, txChartMonthlyData,

    modalTx, setModalTx,
    setIslandClaimWindow, loadFaqs,
    modalParticipate, setModalParticipate,
    participateSuccessData, setParticipateSuccessData,
    modalClosedParticipateAndTopup, setModalClosedParticipateAndTopup
  };

  return (
    <IslandsContext.Provider value={context}>
      {children}
    </IslandsContext.Provider>
  );
};

export default IslandsContext;