import snsWebSdk from '@sumsub/websdk';
import { useContext, useEffect, useState } from 'react';
import PrivateContext from '../context/private/privateContext';
import sumsubService from '../services/sumsubService';
import wizardService from '../services/wizardService';
import { Container, Flex } from '../styles/Styles';
import Layout from './layout/Index';
import Loader from './Loader';
import Header from './Header';

function Sumsub() {
    const [sumsubInfo, setSumsubInfo] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const { flowState, setFlowState, setUserInfo } = useContext(PrivateContext)

    function launchWebSdk(accessToken, applicantEmail, applicantPhone, customI18nMessages) {
        let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            // token update callback, must return Promise
            // Access token expired
            // get a new one and pass it to the callback to re-initiate the WebSDK
            () => getNewAccessToken()
        )
            .withConf({
                lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
                email: applicantEmail,
                phone: applicantPhone,
                // i18n: customI18nMessages, //JSON of custom SDK Translations
                uiConf: {
                    customCss: "https://url.com/styles.css"
                    // URL to css file in case you need change it dynamically from the code
                    // the similar setting at Customizations tab will rewrite customCss
                    // you may also use to pass string with plain styles `customCssStr:`
                },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            // see below what kind of messages WebSDK generates
            .on('idCheck.stepCompleted', (payload) => {
                // console.log('stepCompleted', payload)
                sumsubService.updateStatus(payload.step, 'step')
            })
            .on('idCheck.applicantStatus', (payload) => {
                // console.log('applicantStatus', payload)
                sumsubService.updateStatus(payload.reviewStatus, 'status')
                payload.reviewStatus === "completed" && wizardService.updateStep(12).then(() => setFlowState({ ...flowState, step: 12 }))
            })
            .on('idCheck.onError', (error) => {
                console.log('onError', error)
            })
            .build();
        setLoading(true)

        // you are ready to go:
        // just launch the WebSDK by providing the container element for it
        snsWebSdkInstance.launch('#sumsub-websdk-container')
    }

    async function getNewAccessToken() {
        const newToken = await sumsubService.getSumsubReGenerate()
        if (newToken)
            return newToken?.accessToken?.token;
        else
            return sumsubInfo?.accessToken?.token;
    }

    async function loadSumsubInfo() {
        await sumsubService.getSumsubInfo().then((info) => {
            if (!(info.accessToken && info.accessToken?.token)) {
                loadSumsubInfo()
            }
            setSumsubInfo(info)
        });
    }

    async function loadSumsubInfoBasic() {
        await sumsubService.getSumsubInfoBasic().then((info) => {
            if (info.accessToken?.token === null) {
                loadSumsubInfoBasic()
            }
            info?.accessToken?.status === "completed" && wizardService.updateStep(12).then(() => setFlowState({ ...flowState, step: 12 }))
        }
        );
    }

    useEffect(() => {
        if ((flowState.step > 10 && flowState.step < 13) || (flowState.step == 20)) {
            loadSumsubInfo()
            loadSumsubInfoBasic()
        }
    }, [flowState])

    useEffect(() => {
        sumsubInfo && sumsubInfo?.accessToken?.token && launchWebSdk(sumsubInfo?.accessToken?.token, sumsubInfo?.email, sumsubInfo?.phone);
    }, [sumsubInfo])

    return (
        <Layout className="intr">
            <Container x={'center'} y={'center'} className="container intr-container">
                <Flex direction="col" className="intr-flex" gap={1.7} x={'left'} marginT={2}>
                    <Header title='Blue Ocean Society' />
                    <div className='sumsub-style'> <div id="sumsub-websdk-container"></div>{!isLoading && <Loader />} </div>
                </Flex>
            </Container>
        </Layout>
    );
}

export default Sumsub;
