import { useEffect } from 'react';

export default function useGetDataFromUrl(key, setState) {
  useEffect(() => {
    const getDataFromUrl = () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const data = urlParams.get(key);
        
        if (data) {
          setState(data);
        }
      } catch (error) {
        console.error('useGetDataFromUrl:', error.message);
      }
    };

    getDataFromUrl();
  }, [key, setState]);
}
