import { useContext, useEffect } from "react"
import PrivateContext from "../../../context/private/privateContext"
import TreasuryContext from "../../../context/private/treasuryContext"
import { numberWithCommas } from "../../../utils/tools"
import CopyLink2 from "../../CopyLink2"
import dictionary from "../../../locals/my-treasures.json"
import AccountContext from "../../../context/private/accountContext"

const MyCrew = () => {
  const { crewData, loadCrew, currentIslandId } = useContext(TreasuryContext)

  const { flowState } = useContext(PrivateContext)
  const { userInfo } = useContext(AccountContext)

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      if (currentIslandId && currentIslandId !== null && currentIslandId !== -1 && userInfo
        && userInfo?.membershipStatus !== 2) {
        loadCrew(currentIslandId)
      }
    }
  }, [flowState, currentIslandId])

  return (
    <div className="explorer-body">
      <div className="mycrew-inside">
        <div className="mycrew-inside-border">
          <div className="mycrew-inside-post">
            <div className="mycrew-inside-post-title">
              {dictionary["TREASURE-title1"]}
              <div className="mycrew-inside-post-combo">
                <div className="mycrew-inside-post-label">
                  {dictionary["TREASURE-title2"]}
                </div>
                <div className="mycrew-inside-post-value">{crewData?.my_total_crew ? `${numberWithCommas(crewData?.my_total_crew)}` : '0'}</div>
              </div>
              <div className="mycrew-inside-post-parent">
                <div className="mycrew-inside-post-label">
                  {dictionary["TREASURE-title3"]}
                </div>
                <div className="mycrew-inside-post-value">{crewData?.total_commission ? `$${numberWithCommas(crewData?.total_commission)}` : '$0'}</div>
              </div>
              {/* <div className="mycrew-inside-post-parent">
                <div className="mycrew-inside-post-label">
                  Total Gratitude
                </div>
                <div className="mycrew-inside-post-value">{crewData?.total_commission ? `$${numberWithCommas(crewData?.total_commission)}` : '$0'}</div>
              </div>
              <div className="mycrew-inside-post-parent">
                <div className="mycrew-inside-post-label">
                  Total Gratitude
                </div>
                <div className="mycrew-inside-post-value">{crewData?.total_commission ? `$${numberWithCommas(crewData?.total_commission)}` : '$0'}</div>
              </div> */}
            </div>
            {userInfo?.is_action_limit === 0 ? <div className="mycrew-post">
              {crewData?.referral_code ? <CopyLink2 crewDetail={crewData} /> : dictionary["TREASURE-title4"]}
            </div> : <></>}
          </div>
        </div>
      </div>

      <div className={crewData?.subset?.length > 5 ? "boscrew-s-row start" : "boscrew-s-row"}>
        <div className="boscrew-s-r">
          <div className="boscrew-s-r-t">
            <img src="/images/userIcon.svg" alt="userIcon" />
            {dictionary["TREASURE-title5"]}
          </div>
          <div className="boscrew-s-r-combo">
            <div className="boscrew-s-r-combo-parent">
              <div className="boscrew-s-r-combo-t">{dictionary["TREASURE-title6"]}</div>
              <div className="boscrew-s-r-combo-num">{crewData?.my_total_crew ? numberWithCommas(crewData?.my_total_crew) : null}</div>
            </div>
          </div>
          <div className="boscrew-s-r-combo-cap">
            <div className="boscrew-s-r-combo-info">{dictionary["TREASURE-title7"]}:</div>
            <div className="boscrew-s-r-combo-name">{crewData?.upper_hand}</div>
          </div>
          {crewData?.my_total_crew > 0 && <div className='boscrew-s-r-icon'>
            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4182" width="28"><path d="M990.9248 519.4752l-307.2-307.2c-9.984-9.984-26.2144-9.984-36.1984 0s-9.984 26.2144 0 36.1984l263.4752 263.4752-834.2016 0c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6l834.2016 0-263.4752 263.4752c-9.984 9.984-9.984 26.2144 0 36.1984 5.0176 5.0176 11.5712 7.4752 18.1248 7.4752s13.1072-2.5088 18.1248-7.4752l307.2-307.2c9.984-9.984 9.984-26.2144 0-36.1984z" fill="#000000" p-id="4183"></path></svg>
          </div>}
        </div>

        <div className="boscrew-r">

          {crewData?.my_total_crew > 0 && crewData?.subset && crewData?.subset?.length > 0 && crewData?.subset?.map((item: any, index: number) => <div key={index} className="boscrew-r-parent">
            <div className="boscrew-r-children">
              <img src="/images/userfillLogo.svg" alt="userfillLogo" />
              <b>{item?.username}</b>
            </div>
            <div className="boscrew-r-combo">
              <div className="boscrew-r-child">
                <div className="boscrew-r-child-cap">
                  {dictionary["TREASURE-title8"]}
                </div>
              </div>
              <div className="boscrew-r-child">
                <img src="/images/crewSmall.svg" alt="crewSmall.svg" />
                <div className="boscrew-r-child-label">{dictionary["TREASURE-title9"]}:</div>
                <div className="boscrew-r-child-amount">{item?.crew_count ? numberWithCommas(item?.crew_count) : '0'}</div>
              </div>
            </div>
          </div>)
          }
        </div>
      </div>
      {/* <div className="mycrew-bottom">
        <div className="mycrew-bottom-border">
          <div className="mycrew-bottom-border-box">
            <img src="/images/userIcon.svg" alt="userIcon" />
            <span>YOU</span>
          </div>

          <div className="mycrew-bottom-combo">
            <div className="mycrew-bottom-row">
              <div className="mycrew-bottom-row-title">Direct</div>
              <div className="mycrew-bottom-row-value">5</div>
            </div>
            <div className="mycrew-bottom-row">
              <div className="mycrew-bottom-row-title">Direct</div>
              <div className="mycrew-bottom-row-value">5</div>
            </div>
          </div>

          <div className="mycrew-bottom-row-bottom">
            <div className="mycrew-bottom-row-bottom-title">
              Your upline:
            </div>
            <div className="mycrew-bottom-row-bottom-value">Nina</div>
          </div>
          <div className='mycrew-bottom-row-icon'>
            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4182" width="28"><path d="M990.9248 519.4752l-307.2-307.2c-9.984-9.984-26.2144-9.984-36.1984 0s-9.984 26.2144 0 36.1984l263.4752 263.4752-834.2016 0c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6l834.2016 0-263.4752 263.4752c-9.984 9.984-9.984 26.2144 0 36.1984 5.0176 5.0176 11.5712 7.4752 18.1248 7.4752s13.1072-2.5088 18.1248-7.4752l307.2-307.2c9.984-9.984 9.984-26.2144 0-36.1984z" fill="#000000" p-id="4183"></path></svg>
          </div>
        </div>

        <div className="mycrew-right">
          <div className="mycrew-right-post">
            <div className="mycrew-right-post-name">
              <img src="/images/userfillLogo.svg" alt="userfillLogo" />
              <b>Dan</b>
            </div>
            <div className="mycrew-right-post-gap">
              <div className="mycrew-right-post-label">
                <img
                  src="/images/totalDepositSmall.svg"
                  alt="totalDepositSmall.svg"
                />
                <div className="mycrew-right-post-title">
                  Total Deposit:
                </div>
                <div className="mycrew-right-post-value">$10,000</div>
              </div>
              <div className="mycrew-right-post-label">
                <img
                  src="/images/commissionSmall.svg"
                  alt="CommissionSmall.svg"
                />
                <div className="mycrew-right-post-second">
                  Commission:
                </div>
                <div className="mycrew-right-post-value">$700</div>
              </div>
              <div className="mycrew-right-post-label">
                <img src="/images/crewSmall.svg" alt="crewSmall.svg" />
                <div className="mycrew-right-post-second">Crew:</div>
                <div className="mycrew-right-post-value">0</div>
              </div>
            </div>
          </div>
          <div className="mycrew-right-post">
            <div className="mycrew-right-post-name">
              <img src="/images/userfillLogo.svg" alt="userfillLogo" />
              <b>Dan</b>
            </div>
            <div className="mycrew-right-post-gap">
              <div className="mycrew-right-post-label">
                <img
                  src="/images/totalDepositSmall.svg"
                  alt="totalDepositSmall.svg"
                />
                <div className="mycrew-right-post-title">
                  Total Deposit:
                </div>
                <div className="mycrew-right-post-value">$10,000</div>
              </div>
              <div className="mycrew-right-post-label">
                <img
                  src="/images/commissionSmall.svg"
                  alt="CommissionSmall.svg"
                />
                <div className="mycrew-right-post-second">
                  Commission:
                </div>
                <div className="mycrew-right-post-value">$700</div>
              </div>
              <div className="mycrew-right-post-label">
                <img src="/images/crewSmall.svg" alt="crewSmall.svg" />
                <div className="mycrew-right-post-second">Crew:</div>
                <div className="mycrew-right-post-value">0</div>
              </div>
            </div>
          </div>
          <div className="mycrew-right-post">
            <div className="mycrew-right-post-name">
              <img src="/images/userfillLogo.svg" alt="userfillLogo" />
              <b>Dan</b>
            </div>
            <div className="mycrew-right-post-gap">
              <div className="mycrew-right-post-label">
                <img
                  src="/images/totalDepositSmall.svg"
                  alt="totalDepositSmall.svg"
                />
                <div className="mycrew-right-post-title">
                  Total Deposit:
                </div>
                <div className="mycrew-right-post-value">$10,000</div>
              </div>
              <div className="mycrew-right-post-label">
                <img
                  src="/images/commissionSmall.svg"
                  alt="CommissionSmall.svg"
                />
                <div className="mycrew-right-post-second">
                  Commission:
                </div>
                <div className="mycrew-right-post-value">$700</div>
              </div>
              <div className="mycrew-right-post-label">
                <img src="/images/crewSmall.svg" alt="crewSmall.svg" />
                <div className="mycrew-right-post-second">Crew:</div>
                <div className="mycrew-right-post-value">0</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default MyCrew