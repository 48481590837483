import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
  Tooltip
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import dictionary from '../locals/my-map.json';
import { nFormatter } from '../utils/tools';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  bezierCurve: true,
  plugins: {
    legend: {
      display: true,
    },
    filler: {
      propagate: false
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          label += '$' + context.parsed.y.toLocaleString();
          return label;
        }
      }
    }
  },
  scales: {
    y: {
        ticks: {
            callback: function(value) {
                return nFormatter(value);
            }
        }
    }
}
};

const labels = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
];

let width, height, gradient;
export const getGradient = (ctx, chartArea) => {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, '#153458');
    gradient.addColorStop(0.5, '#2388CE');
    gradient.addColorStop(0, '#E7F5FF');
  }

  return gradient;
};

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [1, 2, 3, 14, 21, 2, 9, 3, 5, 6, 1],
      borderColor: '#2388CE',
      borderWidth: 7,
      fill: true,
      tension: 0.45,
      pointRadius: 1,
      borderRadius: 8,
      borderSkipped: false
    },
    {
      label: 'Dataset 2',
      data: [4, 5, 6, 9, 5, 5, 3],
      borderColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        ctx.shadowColor = '#a0d8ff';
        ctx.shadowBlur = 16;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 10;
        if (!chartArea) {
          return;
        }
        return getGradient(ctx, chartArea);
      },
      borderWidth: 7,
      fill: true,
      tension: 0.45,
      pointRadius: 1,
      borderRadius: 8,
      borderSkipped: false
    }
  ]
};

export function BalanceChartDaily({ data }) {
  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const convertedTime = [];
  data?.timstamp.map((t) => {
    const tempDate = new Date(Number(t));
    convertedTime.push(monthNames[tempDate.getMonth()]);
  });

  const chartData = {
    labels: convertedTime,
    datasets: [
      {
        label: dictionary['CHART-BALANCE-lable-1'],
        data: data?.bos,
        borderColor: '#2388CE',
        borderWidth: 3,
        fill: true,
        tension: 0.45,
        pointRadius: 1,
        borderRadius: 8,
        borderSkipped: false
      },
      {
        label: dictionary['CHART-BALANCE-lable-2'],
        data: data?.gratitude,
        borderColor: '#5c23ce',
        borderWidth: 3,
        fill: true,
        tension: 0.45,
        pointRadius: 1,
        borderRadius: 8,
        borderSkipped: false
      },
      {
        label: dictionary['CHART-BALANCE-lable-3'],
        data: data?.treasury,
        borderColor: '#16111f',
        borderWidth: 3,
        fill: true,
        tension: 0.45,
        pointRadius: 1,
        borderRadius: 8,
        borderSkipped: false
      }
    ]
  };

  return <Line options={options} data={chartData} />
}
