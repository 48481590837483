import { useContext, useEffect, useState } from 'react';
import Faq from 'react-faq-component';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Loader from './Loader';
import Layout from './layout/Index';

const Faqs = () => {
  const { loadFaq, faqsData } = useContext(PublicContext)

  useEffect(() => {
    loadFaq()
    window.scrollTo(0, 0)
  }, [])

  const [data, setData] = useState({
    title: "FAQs",
    rows: [],
  })

  useEffect(() => {
    if (faqsData) {
      data.rows = faqsData
      setData({
        ...data,
        rows: faqsData
      })
    }
  }, [faqsData])

  return (
    <Layout className="parent">
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title='Frequently Asked Questions' />

            <div className="faq-style-wrapper">

              {data && data?.rows?.length > 0 ? <Faq data={data}
                styles={{
                  bgColor: "transparent",
                  titleTextSize: "28px",
                  titleTextColor: "#0E608B",
                  rowTitleColor: "#0E608B",
                  rowTitleTextSize: 'large',
                  rowContentColor: "#48484a",
                  rowContentTextSize: '16px',
                  rowContentPaddingTop: '10px',
                  rowContentPaddingBottom: '10px',
                  rowContentPaddingLeft: '50px',
                  rowContentPaddingRight: '150px',
                  arrowColor: "black",
                }}

              /> : <Loader />}

            </div>
          </div>
        </Flex>
      </Container>
    </Layout>
  )
}

export default Faqs