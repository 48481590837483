import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";

class SumsubService {
    private _httpService = HttpService('Authentication');

    async getSumsubInfo() {
        try {
            const response = await this._httpService.get<any>('/KYC');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async getSumsubInfoBasic() {
        try {
            const response = await this._httpService.get<any>('/GetKYC');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async getSumsubReGenerate() {
        try {
            const response = await this._httpService.get<any>('/UpdateKYCToken');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async updateStatus(data: any, step: any) {
        try {
            const response = await this._httpService.post<any>('/UpdateKYCStatus', { data: data, type: step });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

}

export default new SumsubService()