

import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import PrivateRoutes from "./private/PrivateRoutes";
import PublicRoutes from "./public/PublicRoutes";
import { PublicContextProvider } from "../context/public/publicContext";
import useAutoLogout from "../hooks/useAutoLogout";

const AllRoutes = ({ setMaintenanceStatus }: any) => {
    return (
        <PublicContextProvider setMaintenanceStatus={setMaintenanceStatus}>
            <PublicRoutes />
            <PrivateRoutes />
        </PublicContextProvider>
    );
};
const IndexRouter = ({ setMaintenanceStatus }: any) => {

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <BrowserRouter basename={''}>
                <AllRoutes setMaintenanceStatus={setMaintenanceStatus} />
            </BrowserRouter>
        </Suspense>
    );
};

export default IndexRouter;