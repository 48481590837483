import { createContext, useState } from 'react';
import toast from 'react-hot-toast';
import crewService from '../../services/crewService';
import settingService from '../../services/settingService';
const CrewContext = createContext<any>({});

export const CrewContextProvider = ({ children }: JSX.Element | any) => {
  const [locker, setLocker] = useState(false)

  const [crews, setCrews] = useState<any>(null)
  async function loadCrew(checkbox: boolean) {
    await crewService.getCrewTree(checkbox).then(crew => setCrews(crew))
  }

  const [gratitudeList, setGratitudeList] = useState<any>(null)
  async function loadGratitude() {
    await crewService.getGratitudeList().then(list => setGratitudeList(list))
  }

  const [crewDetail, setCrewDetail] = useState<any>(null)
  async function loadCrewDetail() {
    await crewService.getCrewDetail().then(detail => setCrewDetail(detail))
  }

  const [ckeditorGratitudeProgram, setCkeditorGratitudeProgram] = useState(null)
  async function loadCkEditorByID(id: number) {
    id === 6 && await settingService.getCkEditorById(id).then(data => setCkeditorGratitudeProgram(data))
  }

  const [crewAgreement, setCrewAgreement] = useState<any>(null)
  async function loadCrewAgreement() {
    await crewService.getAgreeTerms().then(agreement => setCrewAgreement(agreement))
  }

  async function acceptHandle() {
    if (!locker) {
      setLocker(true)
      toast.promise(
        crewService.agreeTerms()
        ,
        {
          loading: 'Checking terms...',
          success: (data) => {
            setLocker(false)
            setCrewAgreement(1)
            return `Success.`
          },
          error: (err) => {
            setLocker(false)
            return `${err.message.toString()}`
          },
        },
        {
          success: {
            duration: 6000,
            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
          },
        }
      );
    }
  }

  const context = {

    gratitudeList, loadGratitude, 

    crews, setCrews, loadCrew,

    crewDetail, setCrewDetail, loadCrewDetail,

    crewAgreement, setCrewAgreement, loadCrewAgreement,

    acceptHandle, ckeditorGratitudeProgram, loadCkEditorByID
  };

  return (
    <CrewContext.Provider value={context}>
      {children}
    </CrewContext.Provider>
  );
};

export default CrewContext;