import { ProfileUpdateForm, ProfileUpdatePropertyValidation } from '../types/profileTypes';
const Joi = require("joi-browser");

class ProfileValidation {

    // update
    _telegramSchema = {
        telegram_username: Joi.string().min(3).max(30).optional().allow('')
    }

    profileUpdate(formData: ProfileUpdateForm) {
        const result = Joi.validate(formData, this._telegramSchema, { abortEarly: false })

        const errors: any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    profileUpdateProperty(name: ProfileUpdatePropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._telegramSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }
}

export default new ProfileValidation()


