import { useContext, useEffect, useState } from "react"
import PrivateContext from "../../../context/private/privateContext"
import WizardContext from "../../../context/private/wizardContext"
import Questions from "../questions"
import authService from "../../../services/authService"
import VimeoPlayer from "./player"

const Video = () => {
    const { flowState } = useContext(PrivateContext)
    const { loadQuestionsInWatchVideo } = useContext(WizardContext)
    const [steps, setSteps] = useState([0, 0, 0, 0])
    const [watchVideoIds, setWatchVideoIds] = useState<any>(null)

    async function loadWatchVideoIds() {
        await authService.getWatchVideos().then((ids): any => setWatchVideoIds(ids));
    }

    useEffect(() => {
        loadWatchVideoIds()
        loadQuestionsInWatchVideo()
    }, [])

    useEffect(() => {
        switch (flowState.step) {
            case 4:
                setSteps([0, 0, 0, 0])
                break;
            case 5:
                setSteps([1, 0, 0, 0])
                break;
            case 6:
                setSteps([1, 1, 0, 0])
                break;
            case 7:
                setSteps([1, 1, 1, 0])
                break;
            case 8:
                setSteps([1, 1, 1, 1])
                break;

            default:
                setSteps([0, 0, 0, 0])
                break;
        }
    }, [flowState])

    const youtubePlayerOptions = {
        height: '460',
        width: '650',
        playerVars: { autoplay: 0 },
    };

    const youtubePlayerOptions2 = {
        height: '105',
        width: '250',
        playerVars: { autoplay: 0 },
    };

    switch (flowState.step) {
        case 4:
            return (
                <>
                    <div className="intr-video">
                        {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.welcome)} /> : ''}
                    </div>
                    <div
                        style={{ margin: '0 0 1rem 0' }}
                        dangerouslySetInnerHTML={{
                            __html: watchVideoIds?.welcome_ck
                        }}
                    ></div>
                </>
            )
        case 5:
            return (
                <>
                    <div className="intr-video">
                        {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.What_is_it)} /> : ''}
                    </div>
                    <div
                        style={{ margin: '0 0 1rem 0' }}
                        dangerouslySetInnerHTML={{
                            __html: watchVideoIds?.What_is_it_ck
                        }}
                    ></div>
                </>
            )
        case 6:
            return (
                <>
                    <div className="intr-video">
                        {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.Why_offshore)} /> : ''}
                    </div>
                    <div
                        style={{ margin: '0 0 1rem 0' }}
                        dangerouslySetInnerHTML={{
                            __html: watchVideoIds?.Why_offshore_ck
                        }}
                    ></div>
                </>
            )
        case 7:
            return (
                <>
                    <div className="intr-video">
                        {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.How_to_get_started)} /> : ''}
                    </div>
                    <div
                        style={{ margin: '0 0 1rem 0' }}
                        dangerouslySetInnerHTML={{
                            __html: watchVideoIds?.How_to_get_started_ck

                        }}
                    ></div>
                </>
            )
        case 8:
            return (
                <>
                    <div className="intr-videos">
                        {/* <div className="intr-videos-logo responsive-video">
                            {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.welcome)} /> : ''}
                        </div>
                        <div className="intr-videos-logo responsive-video">
                            {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.What_is_it)} /> : ''}
                        </div>
                        <div className="intr-videos-logo responsive-video">
                            {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.Why_offshore)} /> : ''}
                        </div>
                        <div className="intr-videos-logo responsive-video">
                            {watchVideoIds ? <VimeoPlayer videoId={Number(watchVideoIds?.How_to_get_started)} /> : ''}
                        </div> */}
                    </div>
                    <Questions />
                </>
            )

        default:
            return (
                <div className="intr-video">
                    <img src="/images/Play.svg" alt="play" />
                </div>
            )
    }
}

export default Video