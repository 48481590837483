import HttpService, {
  catchReporter,
  checkMaintenance,
  reporter
} from './httpService';
import toast from 'react-hot-toast';
import { toPascalCase } from '../utils/tools';

class WalletService {
  private _httpServiceStake = HttpService('Stake');
  private _httpServiceGlobal = HttpService('MyWallet');
  private _httpServiceDeposit = HttpService('PaymentDeposit');
  private _httpServiceWithdraw = HttpService('PaymentWithdraw');
  private _httpServiceUserAccount = HttpService('UserAccount');

  async getStakeGloabal() {
    try {
      const response = await this._httpServiceStake.get<any>('/StakeData/');
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }
  async getStakeHistory(page: any) {
    try {
      const response = await this._httpServiceStake.get<any>('/StakeHistory/', {
        params: {
          offset: page
        }
      });
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getActionHistory(page: any) {
    try {
      const response = await this._httpServiceStake.get<any>('/ActionData/', {
        params: {
          offset: page
        }
      });
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async createNewStake(formData: any) {
    try {
      const response = await this._httpServiceStake.post<any>(
        '/StakeMainBalance',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async renewStake(formData: any) {
    try {
      const response = await this._httpServiceStake.post<any>(
        '/RenewStake',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async claimAvailableStake(formData: any) {
    try {
      const response = await this._httpServiceStake.post<any>(
        '/ClaimAvailableStake',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async compoundAvailableStake(formData: any) {
    try {
      const response = await this._httpServiceStake.post<any>(
        '/CompoundAvailableStake',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async unlockStake(formData: any) {
    try {
      const response = await this._httpServiceStake.post<any>(
        '/UnlockStake',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async getBalances() {
    try {
      const response = await this._httpServiceGlobal.get<any>('/Balances');
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getTransactions() {
    try {
      const response = await this._httpServiceGlobal.get<any>('/Transactions');
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getAllPayment(page: any, filter?: string) {
    try {
      if (filter) {
        const response = await this._httpServiceWithdraw.get<any>('/Mixed', {
          params: {
            offset: page,
            filterBy: filter
          }
        });
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      } else {
        const response = await this._httpServiceWithdraw.get<any>('/Mixed', {
          params: {
            offset: page
          }
        });
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      }
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }
  async getAllPaymentSorted(page: any, sortType?: string) {
    try {
      const response = await this._httpServiceWithdraw.get<any>(
        `/Mixed?sortBy=tx_date&sortType=${sortType}`,
        {
          params: {
            offset: page
          }
        }
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getDetail(id: number) {
    try {
      const response = await this._httpServiceWithdraw.get<any>(
        '/Detail/' + id
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getTransferType(id: number) {
    try {
      const response = await this._httpServiceWithdraw.get<any>(
        '/WithdrawToIslandType?island_id=' + id
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getWithdrawAll(page: any, filter?: string) {
    try {
      const response = await this._httpServiceWithdraw.get<any>('/', {
        params: {
          offset: page,
          filterBy: filter
        }
      });
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getFeesAll(page: any, filter?: string) {
    try {
      if (filter) {
        const response = await this._httpServiceDeposit.get<any>(
          '/IslandTransactions',
          {
            params: {
              offset: page,
              filterBy: filter
            }
          }
        );
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      } else {
        const response = await this._httpServiceDeposit.get<any>(
          '/IslandTransactions',
          {
            params: {
              offset: page
            }
          }
        );
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      }
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getGPAll(page: any, filter?: string) {
    try {
      if (filter) {
        const response = await this._httpServiceDeposit.get<any>(
          '/GPTransactions',
          {
            params: {
              offset: page,
              filterBy: filter
            }
          }
        );
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      } else {
        const response = await this._httpServiceDeposit.get<any>(
          '/GPTransactions',
          {
            params: {
              offset: page
            }
          }
        );
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      }
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async createWithdraw(formData: any) {
    try {
      const response = await this._httpServiceWithdraw.post<any>(
        '/New',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }
  async cancelWithdraw(id: any) {
    try {
      const response = await this._httpServiceWithdraw.post<any>(
        `/CancelWithdraw/${id}`
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async sendEmailCodeForWithdraw(formData: any) {
    try {
      const response = await this._httpServiceWithdraw.post<any>(
        '/SendVerifyCode',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async createTransfer(formData: any) {
    try {
      const response = await this._httpServiceWithdraw.post<any>(
        '/Transfer',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async createTransferPerIsland(formData: any, id: number) {
    try {
      const response = await this._httpServiceWithdraw.post<any>(
        '/Transfer/' + id,
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async getDepositAll(page: any, filter?: string) {
    try {
      const response = await this._httpServiceDeposit.get<any>('/', {
        params: {
          offset: page,
          filterBy: filter
        }
      });
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getWalletsInfo(key: 'bank' | 'crypto') {
    try {
      const response = await this._httpServiceDeposit.get<any>(
        `/GetWallets?account=${key}`
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getWalletsInfoForModal(key: 'membership' | 'deposit') {
    try {
      const response = await this._httpServiceDeposit.get<any>(
        `/GetAllWallets?account=${key}`
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else reporter(response);
    } catch (ex: any) {
      if (ex.response && ex.response && ex.response.status === 400) {
        catchReporter(ex);
        throw new Error(ex.response.data.error);
      }
    }
  }

  async getAccounts(key?: any) {
    if (Number(key) === 0 || Number(key) === 1) {
      try {
        const response = await this._httpServiceUserAccount.get<any>('/List', {
          params: {
            filterKey: key,
            filterBy: 'type'
          }
        });
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      } catch (ex: any) {
        if (ex.response && ex.response && ex.response.status === 400) {
          catchReporter(ex);
          throw new Error(ex.response.data.error);
        }
      }
    } else {
      try {
        const response = await this._httpServiceUserAccount.get<any>('/List');
        checkMaintenance(response);
        if (response.data.status) return response?.data?.message;
        else reporter(response);
      } catch (ex: any) {
        if (ex.response && ex.response && ex.response.status === 400) {
          catchReporter(ex);
          throw new Error(ex.response.data.error);
        }
      }
    }
  }

  async createDeposit(formData: any) {
    try {
      const response = await this._httpServiceDeposit.post<any>(
        '/New',
        formData
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        if (typeof response.data.error === 'string') {
          throw new Error(response?.data?.error?.toString());
        } else if (response.data.errors) {
          response.data.errors.map((e: any) =>
            toast.error(`${toPascalCase(e.param)}: ${e.msg}`)
          );
          throw new Error();
        }
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }

  async agreeTerms() {
    try {
      const response = await this._httpServiceGlobal.post<any>(
        '/MywalletAgreement'
      );
      checkMaintenance(response);
      if (response.data.status) return response?.data?.message;
      else {
        reporter(response);
        throw new Error(response?.data?.error?.toString());
      }
    } catch (ex: any) {
      catchReporter(ex);
      throw new Error(ex);
    }
  }
}

export default new WalletService();
