import { StepProps } from "./step.types"

const Step = ({ step, number, title }: StepProps): JSX.Element => {
    return (
        <div className={`step ${(step || number === 1) && "active"}`}>
            <div className="number">{number}</div>
            <div className="title">{title}</div>
        </div>
    )
}

export default Step