import { Container, Flex } from "../../styles/Styles";
import Header from "../Header";
import Box from "./boxes/box";
import Steps from "./steps/steps";

const VerifyCode = (): JSX.Element => {

  return (
    <Container x={"center"} y={"center"} className="container verify-code">
      <Flex gap={1.7} x={"center"} marginT={1} className="verify-code-body" style={{ height: 'unset' }}>
        <Header title='Blue Ocean Society' />
        <Steps />
        <Box state={'email'} />

      </Flex>
    </Container>
  );
};

export default VerifyCode;
