import { useContext, useEffect, useState } from 'react';
import NotFoundImage from '../../../assets/images/not-found.png';
import { Flex } from '../../../styles/Styles';
import { IslandsItemContext } from '../Index';
import Loader from '../../Loader';
import Faq from 'react-faq-component';
import IslandsContext from '../../../context/private/islandsContext';
import PrivateContext from '../../../context/private/privateContext';
import PublicContext from '../../../context/public/publicContext';
import { Link } from 'react-router-dom';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import AccountContext from '../../../context/private/accountContext';

const FAQItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className="faq-question-item pointer">
        <MdOutlineQuestionAnswer
          className="faq-question-item-icon"
          color="#153458"
          size={'1.2em'}
        />
        <Link
          onClick={() => setIsOpen(!isOpen)}
          style={isOpen ? {
            fontWeight: 600
          } : {}}
        >
          {item?.question}
        </Link>

      </div>
      {isOpen && <div
        style={{
          color: '#153458',
          paddingLeft: '4rem'
        }}
        dangerouslySetInnerHTML={{ __html: item?.answer }}
      ></div>}
    </>
  )
}

const Faqs = () => {
  const { userInfo } = useContext(AccountContext);
  const { flowState } = useContext(PrivateContext);
  const { loadFaqs } = useContext(IslandsContext);
  const { currentId } = useContext(IslandsItemContext);
  const { islandQuestionsData, setIslandQuestionsData, loadIslandQuestions } =
    useContext(PublicContext);

  const [islandFaqs, setIslandFaqs] = useState(null);

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      loadFaqs(currentId, setIslandFaqs);
      loadIslandQuestions(currentId);
    }
  }, [flowState, userInfo]);

  const [data, setData] = useState({
    title: 'FAQs',
    rows: []
  });

  useEffect(() => {
    if (islandFaqs) {
      data.rows = islandFaqs;
      setData({
        ...data,
        rows: islandFaqs
      });
    }
  }, [islandFaqs]);

  if (islandFaqs === null) return <Loader />;
  return (
    <>
      {islandQuestionsData && islandQuestionsData.length > 0 ? (
        <div className="conditions-body">
          <div className="conditions-content">
            <>
              {islandQuestionsData ? (
                <>
                  {!islandQuestionsData || islandQuestionsData?.length === 0 ? (
                    <NotFoundTopic />
                  ) : (
                    <div className="faq-list-content">
                      {[''].map((item) => (
                        <div className="faq-question-list">
                          <h2 className="faq-question-list-title">FAQ</h2>
                          <div className="faq-question-list-content">
                            {islandQuestionsData?.map((item) => (
                              <FAQItem item={item} />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </>
          </div>
        </div>
      ) : (
        <Flex y="center" x="center">
          <img src={NotFoundImage} />
        </Flex>
      )}
    </>
  );
};

export default Faqs;
