import { useContext } from 'react'
import WizardContext from '../../../../context/private/wizardContext'
import { Flex } from '../../../../styles/Styles'

const Submitted = () => {
    const { SubmittedIGotIt } = useContext(WizardContext)
    return (
        <Flex gap={1.7} className="flex-kvc" x={'center'}>
            <div className="kvc">
                <div className="kvc-check">&#x2714;</div>
                <div className="kvc-parent">
                    <div className="kvc-h">🔖 Application Submitted</div>
                    <div className="kvc-t">
                        We are reviewing your application.{' '}
                    </div>
                </div>
                <div className="kvc-cap">
                Congratulations! You have now completed the application process.  The team will get back to you with a status update once they have finished reviewing the information you have provided.
                </div>
                <button className="kvc-btn pointer" onClick={() => SubmittedIGotIt()}>Got it</button>
            </div>
        </Flex>
    )
}

export default Submitted