import { createContext, useState, useEffect, useContext } from 'react';
import profileService from '../../services/profileService';
import wizardService from '../../services/wizardService';
import authService from '../../services/authService';
import config from '../../config';
import PublicContext from '../public/publicContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import mapService from '../../services/mapService';
import MapContext from './mapContext';
const PrivateContext = createContext<any>({});

export const PrivateContextProvider = ({ children, defaultLoaded, defaultFlow }: JSX.Element | any) => {
  const getToken = (): string | null => localStorage.getItem(config.service.tokenKey);

  const [updateTimer, setUpdateTimer] = useState(+new Date)
  const [isLong, setIsLong] = useState(false)
  const [currentSelectedIslandId, setCurrentSelectedIslandId] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)


  const [forceWithdrawModal, setForceWithdrawModal] = useState(false)
  const [topUpModal, setTopUpModal] = useState(false)
  const [participateModal, setParticipateModal] = useState(false)
  const [depositModal, setDepositModal] = useState(false)
  const [withdrawModal, setWithdrawModal] = useState(false)
  const [transferModal, setTransferModal] = useState(false)



  const [countriesList, setCountriesList] = useState<[{ id: number; name: string; logo: string; suffixes: string; root: string; }] | null | undefined>(null)
  const [userInput, setUserInput] = useState<any>({
    phone_number: '',
    country_id: '',

  });
  async function loadCountries() {
    await wizardService.getCountries().then(list => setCountriesList(list))
  }

  const [isMigratedUpline, setIsMigratedUpline] = useState(false)
  async function checkUplineByMail(email: string) {
    await authService.checkUpline(email).then(response => setIsMigratedUpline(response))
  }

  const [isFlowOpen, setFlowOpen] = useState(defaultLoaded)
  const [flowState, setFlowState] = useState<any>({ step: -1, menu: 0, status: 0, email: '' })
  const [sophisticateStatus, setSophisticateStatus] = useState<any>(null)
  const [sophisticateData, setSophisticateData] = useState<any>(null)
  useEffect(() => {
    const data = window.localStorage.getItem('flowState');
    if (data !== null) setFlowState(JSON.parse(data));
  }, []);

  const [userStatus, setUserStatus] = useState<number | unknown>(null)
  const [userInfo, setUserInfo] = useState<{ email: string, username: string, phone: number } | unknown>(null)

  const loadUserStatus = async () => {
    const response = await wizardService.getStatus()
    setUserStatus(response)
  }
  const loadSophisticateStatus = async () => {
    const response2 = await wizardService.getSophisticateStatus()
    // console.log("sophisticateStatus run", response2)
    response2 && setSophisticateStatus(response2?.[0])
    response2 && setSophisticateData(response2?.[0])
  }
  const loadUserSteps = async () => {
    const response = await wizardService.getStep()
    response && setFlowState(response)
    setFlowOpen(true)


  }

  const [is2faShow, setIs2faShow] = useState<0 | 1 | null>(null)

  async function check2FaStatus() {
    await profileService.checkTwoFA().then(tfas => setIs2faShow(tfas))
  }

  const [settings, setSettings] = useState<any>(null)

  async function loadSettings() {
    await profileService.getSettings().then((data: any) => setSettings(data?.[0]))
  }

  const [notification, setNotification] = useState<any>(null)
  const [notificationLocal, setNotificationLocal] = useState<any>([])
  const [notificationPage, setNotificationPage] = useState<any>(1)
  const [currentPage1, setCurrentPage1] = useState(0);
  const [currentPageNotification, setCurrentPageNotification] = useState(0);
  async function loadNotification(page: any) {
    const currentToken = getToken();
    currentToken && await profileService.getNotification(page).then((notify: any) => setNotification(notify))
  }
  async function notificationHandle(id: any, type: any) {
    await profileService.changeReadStatusHandle(id, type).then()
    loadNotification(currentPage1)
  }
  async function notificationHandle2(id: any, type: any) {
    await profileService.changeReadStatusHandle(id, type).then()
    loadNotification(currentPage1)
  }


  const [agreementModalData, setAgreementModalData] = useState<any>(null)
  async function loadAgreementModal() {
    await mapService.getAgreements().then(data => setAgreementModalData(data))
  }

  async function submitAgreementForm(id: any, type: any, refetch?: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        mapService.submitAgreement(id, type),
        {
          loading: 'Changing...',
          success: (data: any) => {
            setLocker(false);
            refetch && loadAgreementModal();

            return type == 1 ? `Updated Successfully.` : <p>{`Thank you for your acknowledgment. We have recorded this in your Member Log, located in your BOS Profile. You can view the log by clicking the link below:`} <br /> <a className='cursor-pointer !text-blue underline-offset-1' href='/logs'>{'View Link'}</a></p>;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }


  async function submitAgreementForm2(id: any, type: any, refetch?: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        mapService.submitAgreement(id, type),
        {
          loading: 'Changing...',
          success: (data: any) => {
            setLocker(false);
            refetch && loadAgreementModal();

            return type == 1 ? `Updated Successfully.` : <p>{`Thank you for your acknowledgment. We have recorded this in your Member Log, located in your BOS Profile. You can view the log by clicking the link below`} <br /> <a href='/logs'>{'View Link'}</a></p>;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }


  const [notificationModal, setNotificationModal] = useState<any>(null)
  async function getNotificationSingle(id: any) {
    await profileService.getSingleNotification(id).then((item: any) => setNotificationModal(item))
  }





  const [notificationAll, setNotificationAll] = useState<any>(null)
  async function loadAllNotification(page: any) {
    const currentToken = getToken();
    currentToken && await profileService.getNotificationAll(page).then((notify: any) => setNotificationAll(notify))
  }

  const [historyLog, setHistoryLog] = useState<any>(null)
  async function loadHistoryLog(page: any) {
    const currentToken = getToken();
    currentToken && await profileService.getHistoryLog(page).then((history: any) => setHistoryLog(history))
  }

  async function allNotificationHandle(id: any, type: any) {
    await profileService.changeReadStatusHandle(id, type).then()
    loadAllNotification(currentPageNotification)
  }

  const { setMaintenanceStatus } = useContext(PublicContext)

  async function handshake() {
    const currentToken = getToken();
    currentToken && await profileService.handShake().then((response) => {
      if (response && response?.maintenance_status === 1) {
        setMaintenanceStatus(response?.maintenance_status)
        localStorage.clear()
      }
    })
  }

  async function notificationAllHandle() {
    await profileService.changeReadAllStatusHandle().then()
    loadNotification(currentPage1)
  }
  async function removeNotificationHandle(id: any, type: any) {
    await profileService.removeNotificationHandle(id, type).then()
    loadNotification(currentPage1)
  }
  async function removeAllNotificationHandle() {
    await profileService.removeAllNotificationHandle().then()
    loadNotification(currentPage1)
  }

  useEffect(() => {
    if (notification) {
      notification?.msg?.data?.map((item: any) => notificationLocal.push(item))
    }
  }, [notification])

  useEffect(() => {
    loadSophisticateStatus()
    loadUserSteps()
    loadSettings()
  }, [])

  const [locker, setLocker] = useState(false)
  const navigate = useNavigate();
  async function sophisticateHandle(formData: any) {
    if (!locker) {
      setLocker(true)
      toast.promise(
        wizardService.submitSophisticateFrom(formData),
        {
          loading: 'Submiting...',
          success: (data: any) => {
            setLocker(false)
            loadUserSteps()
            loadSophisticateStatus()
            // window.location.reload();
            navigate("/")
            return `${data}`
          },
          error: (err: any) => {
            setLocker(false)
            return `${err.message.toString()}`
          },
        },
        {
          success: {
            duration: 12000,
            icon: <img className='toast-logo' src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`} />,
          },
        })
    }
  }



  const context = {
    userStatus, flowState, setFlowState, userInfo, setUserInfo, isFlowOpen, setFlowOpen, loadUserSteps,
    loadCountries, countriesList, userInput, setUserInput,

    currentSelectedIslandId, setCurrentSelectedIslandId,

    is2faShow, setIs2faShow, check2FaStatus,
    isOpen, setIsOpen,
    settings, loadSettings,
    notification, removeNotificationHandle, removeAllNotificationHandle, notificationHandle2,
    handshake,
    isMigratedUpline, setIsMigratedUpline, checkUplineByMail,
    loadNotification, notificationPage, setNotificationPage, notificationLocal, notificationHandle, currentPage1, setCurrentPage1, notificationAllHandle,
    loadAllNotification, notificationAll, allNotificationHandle, currentPageNotification,
    setCurrentPageNotification, updateTimer, setUpdateTimer, isLong, setIsLong,
    forceWithdrawModal, setForceWithdrawModal,
    topUpModal, setTopUpModal,
    participateModal, setParticipateModal,
    depositModal, setDepositModal,
    withdrawModal, setWithdrawModal,
    transferModal, setTransferModal, sophisticateStatus, sophisticateHandle, locker, setLocker,
    sophisticateData, setSophisticateData,
    loadHistoryLog, historyLog, getNotificationSingle,
    notificationModal, submitAgreementForm, loadAgreementModal, agreementModalData, submitAgreementForm2
  };

  return (
    <PrivateContext.Provider value={context}>
      {children}
    </PrivateContext.Provider>
  );
};

export default PrivateContext;