import React, { useContext, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import Pagination from 'react-responsive-pagination';
import config from '../config';
import AccountContext from '../context/private/accountContext';
import PrivateContext from '../context/private/privateContext';
import ProfileContext from '../context/private/profileContext';
import defaultImage from '../assets/images/defaultProfile.svg';
import { Flex } from '../styles/Styles';
import { nFormatter, numberWithCommas } from '../utils/tools';
import { timestampToLocalDaily, timestampToLocalHour } from '../utils/tools';
import { Link } from 'react-router-dom';
import { BsFillBellFill, BsBell } from 'react-icons/bs';
import dictionary from '../locals/notification.json';
import MapContext from '../context/private/mapContext';

const Notification = () => {
  const {
    removeNotificationHandle,
    removeAllNotificationHandle,
    notificationAllHandle,
    currentPage1,
    setCurrentPage1,
    loadNotification,
    notificationHandle,
    notificationHandle2,
    getNotificationSingle,
    notificationModal,
    notification,
    notificationPage,
    setNotificationPage,
    notificationLocal,
    handshake,
    setUpdateTimer
  } = useContext(PrivateContext);

  const [isMore, setIsMore] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modalData, setModalData] = useState({
    title: '',
    description: ''
  });
  const [isHaveNotification, setIsHaveNotification] = useState(true);

  const handleNotification = () => {
    window.scrollTo(0, 0);
    setIsMore((prevState) => !prevState);
  };

  const unReadEdited = notification?.msg?.total_unread
    ? notification?.msg?.total_unread.toString().split('').slice(0, 2)
    : 0;

  useEffect(() => {
    setUpdateTimer(+new Date());

    loadNotification(currentPage1);
    const intervalNotify = setInterval(() => {
      loadNotification(currentPage1);
    }, 30000);

    return () => {
      clearInterval(intervalNotify);
    };
  }, [currentPage1]);

  useEffect(() => {
    handshake();
    const intervalHandshake = setInterval(() => {
      handshake();
    }, 30000);

    return () => {
      clearInterval(intervalHandshake);
    };
  }, []);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [modal, modalData]);

  const modalHandle = (item) => {
    setModalData({
      ...modalData,
      title: item.title,
      description: item.description
    });
    notificationHandle(item.id, item.type);

    if (item?.isModal) {
      getNotificationSingle(item.id);
      setModal2(true);
    } else {
      setModal(true);
    }
  };

  const { submitAgreementForm } = useContext(MapContext);

  const [isAccept, setIsAccept] = useState(false);
  if (notification) {
    return (
      <>
        {modal && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">{modalData.title}</p>
                  <button
                    className="mod-box-btn"
                    onClick={() => setModal(false)}
                  >
                    <IoMdClose />
                  </button>
                </div>

                <div className="mod-inside">
                  <div className="mod-inside-body">
                    <p
                      className="modal-description"
                      dangerouslySetInnerHTML={{
                        __html: modalData.description
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="blur-bg" onClick={() => setModal(false)}></div>
          </Flex>
        )}
        {modal2 && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border w-full">
                <div className="mod-box">
                  <p className="mod-box-p">{notificationModal?.[0]?.title}</p>
                  {(notificationModal?.[0].has_close === true ||
                    notificationModal?.[0]?.acknowledge) && (
                    <button
                      className="mod-box-btn"
                      onClick={() => {
                        !notificationModal?.[0]?.acknowledge &&
                          submitAgreementForm(notificationModal?.[0]?.id, 1);
                        setModal2(false);
                        setIsAccept(false);
                      }}
                    >
                      <IoMdClose />
                    </button>
                  )}
                </div>

                <div className="boscrew-t-row">
                  <div
                    className="boscrew-t-cap"
                    dangerouslySetInnerHTML={{
                      __html: notificationModal?.[0]?.content
                    }}
                  ></div>

                  <div className="leading-[0.8] w-full">
                    <p className="font-normal">
                      If you have questions about this notification please
                      contact
                    </p>{' '}
                    <br />
                    <span className="text-md">
                      <a href="mailto:hello@blueoceansociety.club">
                        hello@blueoceansociety.club
                      </a>
                    </span>
                  </div>
                  {!notificationModal?.[0]?.acknowledge && (
                    <div className="boscrew-t-end">
                      <div className="boscrew-t-line">
                        <div className="boscrew-t-combo">
                          <input
                            type="checkbox"
                            id="horns"
                            name="horns"
                            checked={isAccept}
                            onChange={(e) => setIsAccept(e.target.checked)}
                          />
                          <label className="boscrew-t-label" htmlFor="horns">
                            I acknowledge that I have read and understand this
                            notification
                          </label>
                        </div>
                        <div className="boscrew-t-for-btn">
                          <button
                            className={
                              isAccept
                                ? 'intr-last-row-right-btn'
                                : 'intr-last-row-right-btn disable'
                            }
                            disabled={!isAccept}
                            onClick={() => {
                              isAccept
                                ? submitAgreementForm(
                                    notificationModal?.[0]?.id,
                                    0
                                  )
                                : null;
                              isAccept ? setModal2(false) : null;
                              isAccept ? setIsAccept(false) : null;
                            }}
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="blur-bg"
              onClick={() =>
                notificationModal?.[0]?.acknowledge && setModal2(false)
              }
            ></div>
          </Flex>
        )}

        <div className="notification-wrapper">
          <div
            className={
              notification?.msg?.total_unread > 0 ? 'bell wave' : 'bell'
            }
          >
            {notification?.msg?.total_unread > 0 ? (
              <BsFillBellFill size="24px" />
            ) : (
              <BsBell size="24px" />
            )}
            <div className="unread-count">
              {nFormatter(notification?.msg?.total_unread, 0)}
            </div>
          </div>
          <div className="notification-container">
            <div className="notification">
              {notification?.msg?.data.length ? (
                <>
                  <div className="notification-header">
                    <span>{dictionary['NOTIFICATION-title1']}</span>
                    {notification?.msg?.data.length ? (
                      <div>
                        <Link
                          to="/notifications"
                          className="sidebar-link sub"
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <span>{dictionary['NOTIFICATION-title2']}</span>
                        </Link>
                        <span
                          onClick={() => {
                            removeAllNotificationHandle();
                          }}
                        >
                          {dictionary['NOTIFICATION-title3']}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="notification-list">
                    {notification?.msg?.data?.map((item, index) => (
                      <div
                        key={index}
                        className={`notification-item ${
                          !item?.is_read && item?.is_admin ? 'admin' : ''
                        } ${item?.is_read ? '' : 'active'}`}
                      >
                        <IoMdClose
                          onClick={() => {
                            removeNotificationHandle(item.id, item.type);
                          }}
                        />
                        <div
                          onClick={() => {
                            modalHandle(item);
                          }}
                        >
                          <Flex y="center" gap="1">
                            <span
                              onClick={() => {
                                modalHandle(item);
                              }}
                            >
                              {item.title}
                            </span>{' '}
                            {item?.is_admin ? (
                              <span className="notification-item-from-admin">
                                {dictionary['NOTIFICATION-title4']}
                              </span>
                            ) : (
                              ''
                            )}
                          </Flex>
                        </div>
                        <span
                          onClick={() => {
                            modalHandle(item);
                          }}
                          className="line-clamp-2"
                          dangerouslySetInnerHTML={{
                            __html: item.description
                          }}
                        ></span>
                        <span
                          onClick={() => {
                            modalHandle(item);
                          }}
                          className={`time ${
                            !item?.is_read && item?.is_admin ? 'admin' : ''
                          }`}
                        >
                          {item?.timestamp
                            ? `${timestampToLocalDaily(
                                Number(+new Date(item?.timestamp)) / 1000
                              )}, ${timestampToLocalHour(
                                Number(+new Date(item?.timestamp)) / 1000
                              )}`
                            : '◾️◾️◾️'}
                        </span>
                      </div>
                    ))}
                  </div>

                  {notification?.total_page && (
                    <Pagination
                      className="pagination"
                      total={notification?.total_page}
                      current={notification?.current_page}
                      maxWidth={80}
                      renderNav={false}
                      ariaPreviousLabel=" "
                      ariaNextLabel=" "
                      a11yActiveLabel=""
                      onPageChange={(page) => setCurrentPage1(page)}
                    />
                  )}
                </>
              ) : (
                <p className="notification-empty">
                  {dictionary['NOTIFICATION-title5']}
                </p>
              )}

              {/* {notification?.current_page === notification?.total_page ? null : <span onClick={() => setNotificationPage(notificationPage + 1)}>More</span>} */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div className="skeleton-element-item bell skeleton-box"></div>;
  }
};

const UserInfoWrapper = () => {
  const { userInfo, loadUserInfo } = useContext(AccountContext);
  const { profileData, defaultAvatar } = useContext(ProfileContext);

  return (
    <Flex gap={2} y="center" x="right">
      {userInfo ? (
        <div className="user-info">
          <div className="user-info-detail">
            <Link to={'/profile'}>
              <img
                placeholder={defaultImage}
                src={
                  userInfo?.avatar
                    ? `${config.server}${
                        profileData ? profileData?.avatar : userInfo?.avatar
                      }`
                    : defaultImage
                }
                alt=""
              />
            </Link>
            <Flex direction="col">
              <span>{userInfo?.username}</span>
              <div className="line" />

              <div className="flex gap-x-6">
                <div className="flex flex-col gap-y-0.5 items-start">
                  <span className="text-[12px] font-normal">
                    {dictionary['NOTIFICATION-title6']}{' '}
                  </span>
                  <span className="">
                    {userInfo?.total_balance || userInfo?.total_balance === 0
                      ? `$${numberWithCommas(
                          Number(userInfo?.total_balance),
                          2
                        )}`
                      : 'Loading...'}
                  </span>
                </div>
                <div className="flex flex-col gap-y-0.5 items-start">
                  <span className="text-[12px] font-normal">
                    {dictionary['NOTIFICATION-title7']}{' '}
                  </span>
                  <span className="">
                    {' '}
                    {userInfo?.treasury_balance ||
                    userInfo?.treasury_balance === 0
                      ? `$${numberWithCommas(
                          Number(userInfo?.treasury_balance),
                          2
                        )}`
                      : userInfo
                      ? '$0'
                      : 'Loading...'}
                  </span>
                </div>
              </div>
            </Flex>
          </div>
        </div>
      ) : (
        <div className="skeleton-element-item skeleton-box"></div>
      )}
      <Notification />
    </Flex>
  );
};

export default UserInfoWrapper;
