import { useState, useEffect, useContext } from "react"
import PrivateContext from "../../../context/private/privateContext"
import BetweenBar from "./bar"
import Step from "./step"
import { BarResponse, StepResponse } from "./steps.enum"

const Steps = (): JSX.Element => {
  const { flowState } = useContext(PrivateContext)

  const [steps, setSteps] = useState([0, 0, 0])
  const [bar1, setBar1] = useState<BarResponse>(BarResponse.On)
  const [bar2, setBar2] = useState<BarResponse>(BarResponse.Off)

  const [step1] = useState<StepResponse>(StepResponse.On)
  const [step2, setStep2] = useState<StepResponse>(StepResponse.Off)
  const [step3, setStep3] = useState<StepResponse>(StepResponse.Off)

  useEffect(() => {
    switch (flowState.step) {
      case 8:
        setSteps([0, 0, 0])
        break;
      case 9:
        setSteps([0, 0, 0])
        break;
      case 10:
        setSteps([1, 0, 0])
        break;
      case 11:
        setSteps([1, 1, 0])
        break;

      default:
        break;
    }
  }, [flowState])



  const update = () => {
    if (steps[0] === 1 && steps[1] === 1 && steps[2] === 1) {
      setBar2(BarResponse.Complete)
    } else if (steps[0] === 1 && steps[1] === 1 && steps[2] === 0) {
      setBar2(BarResponse.Complete)
    } else if (steps[0] === 1 && steps[1] === 0 && steps[2] === 0) {
      setBar2(BarResponse.On)
    } else if (steps[0] === 0 && steps[1] === 0 && steps[2] === 0) {
      setBar2(BarResponse.Off)
    }

    if (steps[1] === 1 || steps[0] === 1) {
      setBar1(BarResponse.Complete)
    } else if (steps[0] === 0) {
      setBar1(BarResponse.On)
    }

    if (steps[0] === 1) {
      setStep2(StepResponse.On)
    }
    if (steps[1] === 1) {
      setStep3(StepResponse.On)
    }
  }

  useEffect(() => {
    update()
  })

  if (flowState.step < 12) {
    return (
      <div className="steps">
        <Step step={step1} number={1} title={'Type'} />
        <BetweenBar isDone={bar1} />
        <Step step={step2} number={2} title={'Information'} />
        <BetweenBar isDone={bar2} />
        <Step step={step3} number={3} title={'Registered'} />
      </div>
    )
  } else {return(<></>)}
}

export default Steps