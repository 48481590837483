import { useContext, useEffect, useState } from "react";
import Pagination from 'react-responsive-pagination';
import TreasuryContext from "../../../context/private/treasuryContext";
import { numberWithCommas, timestampToLocalDaily, timestampToLocalHour, timeToYYYYMMDD } from "../../../utils/tools";
import PrivateContext from "../../../context/private/privateContext";

const TxItem = ({ tx }: any) => {
    const methodsDic = ['Participate', 'Top up', 'Claim Profit', 'Withdraw', 'Refund', 'Admin Withdrawal', 'Withdraw All', 'Transfer']

    return (
        <div className="trans-post" key={tx?.id}>
            <div className="trans-post-combo">
                <div className="trans-post-combo-grid">
                    <div className="trans-post-combo-logo">
                        {methodsDic[Number(tx?.type)] === 'Participate' || methodsDic[Number(tx?.type)] === 'Top up' ? <img src="/images/income.svg" alt="income.svg" /> : <img src="/images/outcome.svg" alt="outcome.svg" />}
                    </div>
                    <div className="trans-post-flex">
                        <div className="trans-post-col">
                            <div className="trans-post-value">Amount</div>
                            <div className="trans-post-value-l">{tx?.amount ? `$${numberWithCommas(Number(tx?.amount).toFixed(2), 2)}` : '$0'}</div>
                        </div>

                        <div className="trans-post-col">
                            <div className="trans-post-value">Method</div>
                            <div className="trans-post-value-label">{methodsDic[Number(tx?.type)]}{(tx?.type === 5 && tx?.is_close === 1) ? ' (Closed)' : null}</div>
                        </div>

                        <div className="trans-post-col">
                            <div className="trans-post-value">Date</div>
                            <div className="trans-post-value-l">{tx?.timestamp ? `${timestampToLocalDaily(+tx?.timestamp / 1000)}, ${timestampToLocalHour(+tx?.timestamp / 1000)}` : '◾️◾️◾️'}</div>
                            {/* <div className="trans-post-value-l">{timeToYYYYMMDD(+new Date(tx?.created_at))}</div> */}
                        </div>
                        <div className="trans-post-col">
                            <div className="trans-post-value">Execute Date</div>
                            <div className="trans-post-value-l">{tx?.execute_date ? `${timestampToLocalDaily(+tx?.execute_date / 1000)}, ${timestampToLocalHour(+tx?.execute_date / 1000)}` : '◾️◾️◾️'}</div>
                        </div>
                        {tx?.description && <div className="trans-post-col">
                            <div className="trans-post-value">Description: </div>
                            <div className="trans-post-value-l">{tx?.description ? tx?.description : ''} </div>
                        </div>}
                    </div>
                </div>
                <div className="trans-middle">
                    <div className="trans-middle-val" style={
                        tx?.status === 0 ? { color: 'gray' } : tx?.status === 1 ? { color: 'green' } : tx?.status === 2 ? { color: 'red' } : {}
                    }>
                        {tx?.status === 0 && 'Pending'}
                        {tx?.status === 1 && 'Accepted'}
                        {tx?.status === 2 && 'Rejected'}
                        {tx?.status === 3 && 'Confirmed - Waiting'}


                        {tx?.status === 5 && 'Validation error - Rejected'}
                        {tx?.status === 6 && 'In queue - Accepted'}
                        {tx?.status === 7 && 'In queue - Rejected'}
                    </div>
                </div>
            </div>
        </div>
    )
}

const Transactions = () => {
    const { currentIslandId, currentPage1, setCurrentPage1, currentPage2, setCurrentPage2, currentPage3, setCurrentPage3, transactionListData, loadTransaction, loadTransactionParticipate, loadTransactionClaim, transactionListParticipateData, transactionListTopUpData, transactionListClaimData, transactionListWithdrawForceData, transactionListRefundData, setTransactionListSortedBy } = useContext(TreasuryContext)
    const [input, setInput] = useState<number | string>(0)

    const [currentTab, setCurrentTab] = useState<any>(0)

    const tabs = ['All', 'Funds', 'Withdrawals']

    const { setUpdateTimer } = useContext(PrivateContext)

    useEffect(() => {
        if (currentIslandId && currentIslandId !== null) {

            loadTransaction(currentIslandId)
        }
    }, [currentIslandId])

    useEffect(() => {
        setUpdateTimer(+new Date)
        if (currentIslandId && currentIslandId !== null && currentIslandId !== -1) {
            currentTab === 0 && currentPage1 > 0 && loadTransaction(currentIslandId, currentPage1)
            currentTab === 1 && currentPage2 > 0 && loadTransactionParticipate(currentIslandId, currentPage2)
            currentTab === 2 && currentPage3 > 0 && loadTransactionClaim(currentIslandId, currentPage3)
        }

    }, [currentPage1, currentPage2, currentPage3, currentTab, currentIslandId])

    return (
        <div className="part-body">
            <div className="trans-inside">
                <div className="trans-inside-border">
                    <div className="trans-inside-post">
                        <div className="trans-inside-post-inside">
                            {tabs.map((tab, i) => <button key={i} className={currentTab === i ? "trans-inside-post-btn-deac pointer" : "trans-inside-post-btns pointer"} onClick={() => setCurrentTab(i)} >{tab}</button>)}
                        </div>

                        <div className="trans-inside-flex">
                            <div className="trans-inside-flex-filter">
                                <img
                                    width="20"
                                    height="20"
                                    src="/images/filterLogo.svg"
                                    alt="filter Logo"
                                />
                                <select className="trans-inside-flex-filter-options" onChange={(e: any) => setTransactionListSortedBy(e.target.value)}>
                                    <option value="desc" selected={true}>Desc</option>
                                    <option value="asc">Asc</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {currentTab === 0 && transactionListData && transactionListData?.msg?.map((tx: any, index: number) => <TxItem key={index} tx={tx} />)}
                    {currentTab === 1 && transactionListParticipateData && transactionListParticipateData?.msg?.map((tx: any, index: number) => <TxItem key={index} tx={tx} />)}
                    {/* {currentTab === 2 && transactionListTopUpData && transactionListTopUpData?.msg?.map((tx: any) => <TxItem tx={tx} />)} */}
                    {currentTab === 2 && transactionListClaimData && transactionListClaimData?.msg?.map((tx: any, index: number) => <TxItem key={index} tx={tx} />)}
                    {/* {currentTab === 3 && transactionListClaimData && transactionListClaimData?.msg?.map((tx: any, index: number) => <TxItem key={index} tx={tx} />)} */}
                    {/* {currentTab === 3 && transactionListWithdrawForceData && transactionListWithdrawForceData?.msg?.map((tx: any) => <TxItem tx={tx} />)}
                    {currentTab === 4 && transactionListRefundData && transactionListRefundData?.msg?.map((tx: any) => <TxItem tx={tx} />)} */}


                    {currentTab === 0 && <Pagination
                        className='pagination'
                        total={transactionListData?.totalPage}
                        current={currentPage1}
                        maxWidth={300}
                        a11yActiveLabel=''
                        previousLabel=" "
                        nextLabel=" "
                        ariaPreviousLabel="Prev"
                        ariaNextLabel="Next"
                        onPageChange={(page: any) => setCurrentPage1(page)}
                    />}

                    {currentTab === 1 && <Pagination
                        className='pagination'
                        total={transactionListParticipateData?.totalPage}
                        current={currentPage2}
                        maxWidth={300}
                        a11yActiveLabel=''
                        previousLabel=" "
                        nextLabel=" "
                        ariaPreviousLabel="Prev"
                        ariaNextLabel="Next"
                        onPageChange={(page: any) => setCurrentPage2(page)}
                    />}

                    {currentTab === 2 && <Pagination
                        className='pagination'
                        total={transactionListClaimData?.totalPage}
                        current={currentPage3}
                        maxWidth={300}
                        a11yActiveLabel=''
                        previousLabel=" "
                        nextLabel=" "
                        ariaPreviousLabel="Prev"
                        ariaNextLabel="Next"
                        onPageChange={(page: any) => setCurrentPage3(page)}
                    />}



                </div>
            </div>
        </div>
    )
}

export default Transactions