import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config';
import IslandsContext from '../context/private/islandsContext';
import PrivateContext from '../context/private/privateContext';
import { Container, Flex } from '../styles/Styles';
import { numberWithCommas, timeToYYYYMMDD } from '../utils/tools';
import NotFoundImage from './../assets/images/not-found.png';
import Header from './Header';
import Layout from './layout/Index';
import dictionary from "../locals/island.json"
import publicService from '../services/publicService';
import imageNoThumbnail from '../assets/images/no-thumbnail.jpg'
import Pagination from 'react-responsive-pagination';
import AccountContext from '../context/private/accountContext';


const IslandsList = (): JSX.Element => {
  const { flowState } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);
  const navigate = useNavigate();

  const { islands, loadIslands } = useContext(IslandsContext)

  const [typeFilter, setTypeFilter] = useState('all')
  const [statusFilter, setStatusFilter] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [currentLimitPage, setCurrentLimitPage] = useState<any>(10)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      typeFilter && statusFilter && loadIslands(searchQuery, statusFilter, typeFilter, currentPage, currentLimitPage)
    }
  }, [searchQuery, typeFilter, statusFilter, currentPage, flowState, currentLimitPage])


  const [filteredIslands, setFilteredIslands] = useState([])

  const search = (filterTerm: any, terms: any) => {
    filterTerm = filterTerm.toLowerCase().trim();
    return terms.filter((v: any) => v.name.toLowerCase().includes(filterTerm))
  };

  // const getImage = async (url: string) => {
  //   const result = await publicService.getImage(url)
  //   const imageData = result?.data;
  //   const imageUrl = URL.createObjectURL(new Blob([imageData]));
  //   return imageUrl
  // };


  // useEffect(() => {
  //   if (searchQuery.length > 0) {
  //     const result = search(searchQuery, islands)
  //     setFilteredIslands(result)
  //   }
  // }, [searchQuery])


  // const [fetchedImages, setFetchedImages] = useState([])
  // const [fetchedImages2, setFetchedImages2] = useState<any>([])
  // useEffect(() => {
  //   if (islands && islands.length > 0) {
  //     const images = islands.map((i: any) => i.image);
  //     setFetchedImages(images);
  //     console.log("images islands", images);
  //   }
  // }, [islands]);

  // useEffect(() => {
  //   const fetchImages = async () => {
  //     if (fetchedImages && fetchedImages.length > 0) {
  //       const imagePromises = fetchedImages.map((i: any) => getImage(i));
  //       const images = await Promise.all(imagePromises);
  //       console.log("images", images);
  //       setFetchedImages2(images);
  //     }
  //   };

  //   console.log("call");
  //   fetchImages();
  // }, [fetchedImages]);



  if (flowState?.step === 17 && userInfo
    && userInfo?.membershipStatus !== 2)
    return (
      <Layout className="fir-tr">
        <Container
          x={'center'}
          marginT={1}
          className="container fir-tr-container"
        >
          <Flex gap={1.7} x={'center'} y={'start'} className="fir-tr-flex">
            <div className="fir-tr-bod">
              <div className="fir-tr-body">
                <Header title='Islands' />

                <div className="fir-tr-combo-header">


                  <div className="fir-tr-combo-header-input pagination-dynamic-input">
                    <span className='span-first'>Display</span>
                    {/* <input
                      type="number"
                      min={1}
                      className="fir-tr-combo-header-input-search input-dynamic"
                      placeholder="Items"
                      value={currentLimitPage}
                      onChange={(e) => ((Number(e.target.value) > 0) || (e.target.value.trim() == "")) && setCurrentLimitPage(e.target.value)}
                    /> */}

                    <select
                      className="drop-down-select-style pagination-dynamic"
                      value={currentLimitPage}
                      onChange={(e) => ((Number(e.target.value) > 0) || (e.target.value.trim() == "")) && setCurrentLimitPage(e.target.value)}
                    >
                      <option value="2">2</option>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option defaultChecked value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                  </div>

                  <div className='drop-down-style'>
                    <select
                      className="mymap-s-selector drop-down-select-style type"
                      value={typeFilter}
                      onChange={(e) => setTypeFilter(e.target.value)}
                    >
                      <option value="all">All Type</option>
                      <option value="notParticipated">
                        Unparticipated Islands
                      </option>
                      <option value="participated">
                        Participated Islands
                      </option>
                    </select>
                  </div>

                  <div className='drop-down-style'>
                    <select
                      className="mymap-s-selector  drop-down-select-style status"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      <option value="all">All Status</option>
                      <option value="open">
                        Open Islands
                      </option>
                      <option value="closed">
                        Closed Islands
                      </option>
                    </select>
                  </div>
                  <div className="fir-tr-combo-header-input">
                    <img
                      width="20"
                      height="20"
                      src="/images/searchLogo.svg"
                      alt="search Logo"
                    />


                    <input
                      className="fir-tr-combo-header-input-search"
                      type="text"
                      placeholder="Search for island"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="firstMyTreasure-post fir-tr-posts" style={(islands?.msg?.length === 0) ? { gridTemplateColumns: "1fr" } : {}}>

                {islands?.msg?.length === 0 ? <img src={NotFoundImage} /> : islands ? <>
                  {islands?.msg?.map((island: any, index: number) => <div key={index} className="fir-tr-post-l">
                    <div className="fir-tr-post-l-inside">
                      <div className="box-island-header">
                        <div className="fir-tr-post-l-inside-image">
                          <img src={island?.image ? config.server.slice(0, config.server.length - 1) + island?.image : imageNoThumbnail} alt="nature Picture" />
                        </div>
                        <div>
                          <div className="fir-tr-post-l-header">
                            <Link to={`/island-page?id=${island?.id}`}>
                              <h3 className="fir-tr-post-l-header-title pointer">
                                {island?.name}
                              </h3>
                            </Link>

                          </div>
                          <span className="fir-tr-post-l-header-name">
                            {island?.description}
                          </span>
                        </div>
                      </div>

                      <div className='box-island-content'>
                        {island?.total_invest_visible === 1 ? <div className="fir-tr-post-l-box">
                          <span className="fir-tr-post-l-title">{dictionary['ISLAND-LIST-ITEM-title1']}</span>
                          <b className="fir-tr-post-l-value">${numberWithCommas(island?.total_deposit, 0)}</b>
                        </div> : <hr style={{ margin: '1rem 0' }} />}

                        <div className="fir-tr-post-l-margin">
                          {island && island?.is_visible == 1 && <>
                            <div className="fir-tr-post-l-row">
                              <span className="fir-tr-post-l-row-value">{dictionary['ISLAND-LIST-ITEM-title2']}</span>
                              <b className="fir-tr-post-l-row-value">{island?.total_users_deposit}</b>
                            </div>
                            <div className="fir-tr-post-l-row">
                              <span className="fir-tr-post-l-row-value">{dictionary['ISLAND-LIST-ITEM-title3']}</span>
                              <b className="fir-tr-post-l-row-value">{island?.maximum_participate ? island?.maximum_participate == -1 ? dictionary['ISLAND-LIST-ITEM-title8'] : `$${numberWithCommas(island?.maximum_participate)}` : "Loading..."}</b>
                            </div>
                          </>}

                          <div className="fir-tr-post-l-row">
                            <span className="fir-tr-post-l-row-value">{dictionary['ISLAND-LIST-ITEM-title4']}</span>
                            <b className="fir-tr-post-l-row-value">{island?.duration ? island?.duration == -1 ? dictionary['ISLAND-LIST-ITEM-title8'] : timeToYYYYMMDD(Number(island?.duration)) : "Loading..."}</b>
                          </div>

                          <div className="fir-tr-post-l-row">
                            <span className="fir-tr-post-l-row-value">{dictionary['ISLAND-LIST-ITEM-title5']}</span>
                            <b className="fir-tr-post-l-row-value">{island?.total_capacity ? island?.total_capacity == -1 ? dictionary['ISLAND-LIST-ITEM-title8'] : `$${numberWithCommas(island?.total_capacity, 2)}` : '$0'}</b>
                          </div>

                        </div>
                        {island?.total_invest_visible === 1 && <div className='fir-tr-post-l-btn-row-bottom-content'>
                          <div className="fir-tr-post-l-btn-row-bottom-content-top">
                            <b className="fir-tr-post-l-btn-row-bottom-content-value">{dictionary['ISLAND-LIST-ITEM-title6']}</b>
                            {island?.total_capacity == -1 ? '' : <div className="fir-tr-post-l-btn-row-bottom-content-s-p">
                              <div className="fir-tr-post-l-btn-row-bottom-content-s-p-g">
                                <div className="fir-tr-post-l-btn-row-bottom-content-s-blue"></div>
                                <span>{dictionary['ISLAND-LIST-ITEM-title7']}</span>
                              </div>
                            </div>}
                          </div>
                          <div className="fir-tr-post-l-btn-row-bottom-content-bottom">
                            {(island?.total_capacity == -1) ? 'Unlimited' : <>{(island?.total_capacity && island?.total_deposit) ? `${numberWithCommas(island?.total_deposit / island?.total_capacity * 100, 2)}%` : '0%'}</>}
                            <div className="fir-tr-post-l-btn-row-bottom">
                              <div className="fir-tr-post-l-btn-row-bottom-percent" style={{
                                minWidth: `${island?.total_capacity == -1 ? '100%' : '0%'}`,
                                width: `${island?.total_capacity == -1 ? (island?.total_deposit / island?.total_deposit * 100) : (island?.total_deposit / island?.total_capacity * 100)}%`,
                              }}>
                                {/* {island?.total_capacity ? `$${numberWithCommas(island?.total_deposit)}` : island?.total_capacity} */}
                              </div>
                            </div>
                          </div>
                        </div>}
                        <Link to={`/island-page?id=${island?.id}`}>
                          <div className="fir-tr-post-l-btn-row">
                            <button className="fir-tr-post-l-btn-btn">{dictionary['ISLAND-LIST-ITEM-title9']}</button>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>)}
                </> : ['', ''].map((t, index) => <div key={index} className="fir-tr-post-l">
                  <div className="fir-tr-post-l-inside">
                    <div className="fir-tr-post-l-inside-image skeleton-box">
                      {/* <img src="/images/islands-01.jpg" alt="nature Picture"/> */}
                    </div>
                    <div>
                      <div className="fir-tr-post-l-header">
                        <h3 className="fir-tr-post-l-header-title skeleton-box" style={{ width: "40%" }}>
                        </h3>
                      </div>
                      <span className="fir-tr-post-l-header-name skeleton-box" style={{ width: "45px" }}></span>
                    </div>

                    <div className="fir-tr-post-l-box">
                      <span className="fir-tr-post-l-title skeleton-box" style={{ width: "65px" }}></span>
                      <b className="fir-tr-post-l-value skeleton-box" style={{ width: "45px" }}></b>
                    </div>

                    <div className="fir-tr-post-l-margin">
                      <div className="fir-tr-post-l-row">
                        <span className="fir-tr-post-l-row-value skeleton-box" style={{ width: "65px" }}></span>
                        <b className="fir-tr-post-l-row-value skeleton-box" style={{ width: "45px" }}></b>
                      </div>

                      <div className="fir-tr-post-l-row">
                        <span className="fir-tr-post-l-row-value skeleton-box" style={{ width: "65px" }}></span>
                        <b className="fir-tr-post-l-row-value skeleton-box" style={{ width: "45px" }}></b>
                      </div>
                    </div>
                    <div className='fir-tr-post-l-btn-row-bottom-content'>
                      <div className="fir-tr-post-l-btn-row-bottom-content-top">
                        <b className="fir-tr-post-l-btn-row-bottom-content-value skeleton-box" style={{ width: "65px" }}></b>
                        <div className="fir-tr-post-l-btn-row-bottom-content-s-p">
                          <div className="fir-tr-post-l-btn-row-bottom-content-s-p-g skeleton-box" style={{ width: "45px" }}>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fir-tr-post-l-btn-row">
                      <button className="fir-tr-post-l-btn-btn skeleton-box" style={{ backgroundColor: "#DDDBDD" }}></button>
                    </div>
                  </div>
                </div>)}

              </div>


              {islands?.totalPage ? <div className='island-list-pagination'>
                <Pagination
                  className="pagination"
                  total={islands?.totalPage}
                  current={islands?.current_page}
                  maxWidth={300}
                  a11yActiveLabel=""
                  previousLabel=" "
                  nextLabel=" "
                  renderNav={false}
                  ariaPreviousLabel={"Prev"}
                  ariaNextLabel={"Next"}
                  onPageChange={(page: any) => setCurrentPage(page)}
                /></div> : null}

            </div>
          </Flex>
        </Container>
      </Layout>
    );
  else
    return (<>{navigate('/')}</>)
};

export default IslandsList;
