import React, { useContext, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import Pagination from 'react-responsive-pagination';
import config from '../config';
import AccountContext from '../context/private/accountContext';
import PrivateContext from '../context/private/privateContext';
import ProfileContext from '../context/private/profileContext';
import defaultImage from '../assets/images/defaultProfile.svg';
import { Flex } from '../styles/Styles';
import { nFormatter, numberWithCommas } from '../utils/tools';
import { timestampToLocalDaily, timestampToLocalHour } from '../utils/tools';
import { Link } from 'react-router-dom';
import { BsFillBellFill, BsBell } from 'react-icons/bs';
import dictionary from "../locals/notification.json";
import MapContext from '../context/private/mapContext';
import { getDidAcknowledgeValue, removeDidAcknowledgeCookie, updateDidAcknowledgeValue } from '../services/authService';

const Notification = () => {
  const {
    removeNotificationHandle,
    removeAllNotificationHandle,
    notificationAllHandle,
    currentPage1,
    setCurrentPage1,
    loadNotification,
    notificationHandle,
    notification,
    notificationPage,
    setNotificationPage,
    notificationLocal,
    handshake,
    setUpdateTimer
  } = useContext(PrivateContext);

  const [isMore, setIsMore] = useState(false);
  const [modal, setModal] = useState(true);
  const [modalData, setModalData] = useState(false);
  const [isHaveNotification, setIsHaveNotification] = useState(true);

  const handleNotification = () => {
    window.scrollTo(0, 0);
    setIsMore((prevState) => !prevState);
  };

  const unReadEdited = notification?.msg?.total_unread ? notification?.msg?.total_unread
    .toString()
    .split('')
    .slice(0, 2) : 0;

  useEffect(() => {
    setUpdateTimer(+new Date)

    loadNotification(currentPage1)
    const intervalNotify = setInterval(() => {
      loadNotification(currentPage1);
    }, 30000);


    return () => {
      clearInterval(intervalNotify);
    };
  }, [currentPage1]);

  useEffect(() => {
    handshake();
    const intervalHandshake = setInterval(() => {
      handshake();
    }, 30000);

    return () => {
      clearInterval(intervalHandshake);
    };
  }, [])

  useEffect(() => {
    setUpdateTimer(+new Date)
  }, [modal, modalData])


  const [isAccept, setIsAccept] = useState(false);
  const { userInfo, loadUserInfo } = useContext(AccountContext);

  const {
    agreementModalData, submitAgreementForm: submitAgreementForm2, loadAgreementModal
  } = useContext(PrivateContext);



  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);

  useEffect(() => {
    userInfo?.forceModal === true && setIsOpenModal(userInfo?.forceModal)
  }, [userInfo])

  if (notification) {
    return (
      <>
        {((userInfo?.forceModal === true && agreementModalData && agreementModalData?.length > 0) && isOpenModal) && (agreementModalData?.length > (currentId)) && getDidAcknowledgeValue() !== "watched" && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border w-full">
                <div className="mod-box">
                  <p className="mod-box-p">{agreementModalData?.[currentId]?.title}</p>
                  {agreementModalData?.[currentId]?.has_close === true && <button className="mod-box-btn" onClick={() => {
                    submitAgreementForm2(agreementModalData?.[currentId]?.id, 1, agreementModalData?.length === currentId);

                    agreementModalData?.length === currentId + 1 && updateDidAcknowledgeValue('watched')

                    agreementModalData && (agreementModalData?.length === currentId + 1) && setIsOpenModal(false);
                    agreementModalData && (agreementModalData?.length === currentId + 1) && setIsAccept(false);
                    (agreementModalData?.length > (currentId + 1)) ? setCurrentId(currentId + 1) : setIsOpenModal(false);
                    // agreementModalData?.length === currentId + 1 && setTimeout(() => {
                    //   loadAgreementModal()
                    // }, 1500);
                    // agreementModalData?.length === currentId + 1 && setTimeout(() => {
                    //   loadUserInfo()
                    // }, 1500);


                  }}>
                    <IoMdClose />
                  </button>}
                </div>


                <div className="boscrew-t-row">
                  <div
                    className="boscrew-t-cap"
                    dangerouslySetInnerHTML={{
                      __html: agreementModalData?.[currentId]?.content
                    }}
                  ></div>

                  <div className='leading-[0.8] w-full'><p className='font-normal'>If you have questions about this notification please contact</p> <br /><span className='text-md'><a href="mailto:hello@blueoceansociety.club">hello@blueoceansociety.club</a></span></div>
                  <div className="boscrew-t-end">
                    <div className="boscrew-t-line">
                      <div className="boscrew-t-combo">
                        <input
                          type="checkbox"
                          id="horns"
                          name="horns"
                          checked={isAccept}
                          onChange={(e) => setIsAccept(e.target.checked)}
                        />
                        <label
                          className="boscrew-t-label"
                          htmlFor="horns"
                        >
                          I acknowledge that I have read and understand this notification
                        </label>
                      </div>
                      <div className="boscrew-t-for-btn">
                        <button
                          className={
                            isAccept
                              ? 'intr-last-row-right-btn'
                              : 'intr-last-row-right-btn disable'
                          }
                          disabled={!isAccept}
                          onClick={() => {
                            isAccept ? submitAgreementForm2(agreementModalData?.[currentId]?.id, 0, agreementModalData?.length === currentId) : null

                            agreementModalData?.length === currentId + 1 && updateDidAcknowledgeValue('watched')

                            isAccept ? (agreementModalData.length === currentId + 1) ? setIsOpenModal(false) : null : null
                            isAccept ? setIsAccept(false) : null
                            setCurrentId(currentId + 1)
                            // agreementModalData?.length === currentId + 1 && setTimeout(() => {
                            //   loadAgreementModal()
                            // }, 1500);
                            // agreementModalData?.length === currentId + 1 && setTimeout(() => {
                            //   loadUserInfo()
                            // }, 1500);
                          }
                          }
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="blur-bg"></div>
          </Flex>
        )}
      </>
    );
  } else {
    return (
      <div className='skeleton-element-item bell skeleton-box'></div>
    )
  }
};

const UserForceAgreementModal = () => {

  const { userInfo, loadUserInfo } = useContext(AccountContext);
  const { profileData, defaultAvatar } = useContext(ProfileContext);



  return (
    <Notification />
  );
};

export default UserForceAgreementModal;
