import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PrivateContext from "../../../context/private/privateContext"
import WizardContext from "../../../context/private/wizardContext"

const Button = ({ action, title = 'Next' }: any) => (
  <div className="intr-bottom">
    <button className="intr-bottom-btn pointer" onClick={action}>{title}</button>
  </div>
)

const Actions = () => {
  const { flowState } = useContext(PrivateContext)
  const { firstWelcomeVideoBack, secondWelcomeVideoBack, thirdWelcomeVideoBack, fourthWelcomeVideoBack, firstWelcomeVideo, secondWelcomeVideo, thirdWelcomeVideo, fourthWelcomeVideo, watchVideoVerifyHandle, isAllAnswersCurrect } = useContext(WizardContext)

  const [isAccept, setIsAccept] = useState(false)

  switch (flowState.step) {
    case 4:
      return <Button action={firstWelcomeVideo} />


    case 5:
      return (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button title="Previous" action={firstWelcomeVideoBack} />
        <Button action={secondWelcomeVideo} />
      </div>)

    case 6:
      return (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button title="Previous" action={secondWelcomeVideoBack} />
        <Button action={thirdWelcomeVideo} />
      </div>)

    case 7:
      return (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button title="Previous" action={thirdWelcomeVideoBack} />
        <Button action={fourthWelcomeVideo} />
      </div>)

    case 8:
      return (
        <div className="intr-last" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="intr-last-row">
            <input
              type="checkbox"
              id="horns"
              name="horns"
              onChange={(e) => setIsAccept(e.target.checked)}
            />
            <label className="intr-last-row-label" htmlFor="horns">
              I’ve watched the videos and agree to the <Link target={'_blank'} to="/privacy-policy">BOS Terms & Conditions | Disclaimer | Cookie and Privacy Policies</Link> {' '}
            </label>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button title="Previous" action={fourthWelcomeVideoBack} />
            <button
              className={isAccept && isAllAnswersCurrect ? "intr-last-row-right-btn" : "intr-last-row-right-btn disable"}
              onClick={() => isAccept && isAllAnswersCurrect ? watchVideoVerifyHandle() : null}
              disabled={!isAccept && !isAllAnswersCurrect}
            >
              I Agree
            </button>
          </div>
        </div>
      )

    default:
      return <Button action={null} />
  }
}

export default Actions