import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import WalletContext from '../../../context/private/walletContext';
import { numberRounder, numberWithCommas } from '../../../utils/tools';
import PrivateContext from '../../../context/private/privateContext';
import { ModalMethods, SuccessModal } from '../Success';
import { Flex } from '../../../styles/Styles';
import info from '../assets/info.svg';
import confirmedIcon from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';

import dictionary from '../../../locals/my-wallet.json';
import toast from 'react-hot-toast';
import islandService from '../../../services/islandService';
import walletService from '../../../services/walletService';
import settingService from '../../../services/settingService';
import TreasuryContext from '../../../context/private/treasuryContext';

const transferBase = 0;

const Input = ({
  setSelectedItem,
  selectedWalletType,
  setSelectedWalletType,
  setActionType
}) => {
  const {
    listOfTransferableIslandData,
    islandsDetailData,
    islandTransferToIslandData,
    setIslandTransferToIslandData,
    currentIslandId
  } = useContext(TreasuryContext);
  const [current, setCurrent] = useState(null);
  // useEffect(() => {
  //     loadAccounts();
  // }, []);
  // useEffect(() => {
  //     if (current === null || current === undefined) {
  //         setCurrent(allAccounts?.default);
  //         setWithdrawFormData({
  //             ...withdrawFormData,
  //             account_id: allAccounts?.default
  //         });
  //     }
  // }, [allAccounts]);

  const [islands, setIslands] = useState(null);
  async function loadIslands() {
    await islandService
      .getIslandsForTransfer()
      .then((island) => setIslands(island));
  }

  async function loadTransferType(id) {
    await walletService
      .getTransferType(id)
      .then((island) => setActionType(island));
  }

  useEffect(() => {
    loadIslands();
  }, []);

  return (
    <div
      className="appf-type-info"
      style={{
        width: '100%',
        display: 'grid',
        alignItems: 'start',
        gridTemplateColumns: '1fr',
        justifyContent: 'space-between',
        gap: '1rem'
      }}
    >
      <div>
        <label
          className="appf-type-label"
          style={{
            color: '#00000099',
            fontFamily: 'Acumin Pro-Regular, Helvetica',
            fontSize: '16.6px',
            fontWeight: 400,
            letterSpacing: 0
          }}
        >
          Transfer to:
        </label>
        {listOfTransferableIslandData &&
          listOfTransferableIslandData?.length > 0 && (
            <select
              style={{
                background: '#F5F5F5',
                border: '0.5px solid #EEEEEE'
              }}
              className="appf-type-selector"
              onChange={(e) => {
                // setSelectedItem(Number(e.target.value));

                const result = listOfTransferableIslandData?.find(
                  (i) => i.id === +e.target.value
                );
                loadTransferType(+e.target.value);
                result && setSelectedItem(result);

                setIslandTransferToIslandData({
                  ...islandTransferToIslandData,
                  to_island_id: +e.target.value,
                  amount: ''
                });

                e.target.value === '' && setSelectedWalletType('-');
              }}
            >
              <option value={''} selected={true}>
                Select an island
              </option>
              {listOfTransferableIslandData
                ?.filter((i) => i.id != currentIslandId)
                ?.map((type, index) => {
                  return (
                    <option key={index} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
            </select>
          )}
      </div>
    </div>
  );
};

export const IlsandTransferToIslandModal = () => {
  const screenWidth = useWindowWidth();

  const [actionType, setActionType] = useState(null);

  const { balances, participateTransferToIslandHandle } =
    useContext(WalletContext);

  const {
    islandsDetailData,
    modalIslandTransferToIsland,
    setModalIslandTransferToIsland,
    setIslandTransferToIslandData,
    islandTransferToIslandData,
    islandTransferToIslandSuccessData,
    setIslandTransferToIslandSuccessData,
    islandTransferToIslandHandle,
    currentIslandId
  } = useContext(TreasuryContext);

  const [ckeditorTransferModal, setCkeditorTransferModal] = useState(null);
  const [ckeditorTopupTransferModal, setCkeditorTopupTransferModal] =
    useState(null);
  async function loadCkEditorByID() {
    await settingService
      .getCkEditorById(11)
      .then((data) => setCkeditorTransferModal(data));
  }

  const { is2faShow, check2FaStatus, setUpdateTimer, settings } =
    useContext(PrivateContext);
  const [selectedItem, setSelectedItem] = useState(NaN);
  const [selectedWalletType, setSelectedWalletType] = useState(null);
  useEffect(() => {
    check2FaStatus();
    loadCkEditorByID();
  }, []);

  useEffect(() => {
    setIslandTransferToIslandData({
      ...islandTransferToIslandData,
      island_id: Number(currentIslandId)
    });
  }, [islandsDetailData]);

  useEffect(() => {
    !modalIslandTransferToIsland && setSelectedItem(NaN);
    !modalIslandTransferToIsland &&
      setIslandTransferToIslandData({
        ...islandTransferToIslandData,
        amount: '',
        island_id: Number(currentIslandId),
        to_island_id: ''
      });

    setUpdateTimer(+new Date());
    if (modalIslandTransferToIsland === false) {
      setIslandTransferToIslandSuccessData(null);
      setActionType(null);
    }
  }, [modalIslandTransferToIsland]);

  function roundToTwoDecimalPlaces(number) {
    return Number(number.toFixed(2));
  }

  // Min,Max Amount
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [isBalanceEnough, setIsBalanceEnough] = useState(false);
  useEffect(() => {
    // Min Amount
    if (islandsDetailData) {
      // Participate === 0, Topup === 1
      let targetIslandMinAmount = 0;
      if (actionType?.type === 0)
        targetIslandMinAmount = actionType?.minimum_invest;
      if (actionType?.type === 1)
        targetIslandMinAmount = actionType?.minimum_topup;

      if (islandsDetailData?.transfer_fee_type === 1) {
        setMinAmount(
          roundToTwoDecimalPlaces(
            Number(targetIslandMinAmount) +
              Number(islandsDetailData?.transfer_fee)
          )
        );
      } else if (islandsDetailData?.transfer_fee_type === 0) {
        setMinAmount(
          roundToTwoDecimalPlaces(
            (targetIslandMinAmount * 100) /
              (100 - Number(islandsDetailData?.transfer_fee))
          )
        );
      }
    }
  }, [islandsDetailData, actionType, islandTransferToIslandData]);

  useEffect(() => {
    islandsDetailData &&
      setIsBalanceEnough(islandsDetailData?.activeAmount >= minAmount);
  }, [islandsDetailData, actionType, minAmount]);

  return (
    <>
      {modalIslandTransferToIsland && !islandTransferToIslandSuccessData && (
        <div
          className="blur-bg"
          onClick={() => {
            setModalIslandTransferToIsland(false);
          }}
        ></div>
      )}

      {modalIslandTransferToIsland && !islandTransferToIslandSuccessData && (
        <div className="transfer-to-island-modal">
          <div
            className="modal-mobile"
            style={{
              height:
                screenWidth < 1037
                  ? '1294px'
                  : screenWidth >= 1037
                  ? '704px'
                  : undefined,
              overflow: screenWidth < 1037 ? 'hidden' : undefined,
              width:
                screenWidth < 1037
                  ? '564px'
                  : screenWidth >= 1037
                  ? '1037px'
                  : undefined
            }}
          >
            <div
              className="content"
              style={{
                alignItems:
                  screenWidth < 1037
                    ? 'flex-start'
                    : screenWidth >= 1037
                    ? 'center'
                    : undefined,
                flexDirection: screenWidth < 1037 ? 'column' : undefined,
                gap: screenWidth < 1037 ? '20px' : undefined,
                justifyContent:
                  screenWidth >= 1037 ? 'space-between' : undefined,
                width:
                  screenWidth < 1037
                    ? '524px'
                    : screenWidth >= 1037
                    ? '989px'
                    : undefined
              }}
            >
              {screenWidth < 1037 && (
                <>
                  <div className="left">
                    <Input
                      setSelectedItem={setSelectedItem}
                      selectedWalletType={selectedWalletType}
                      setSelectedWalletType={setSelectedWalletType}
                      setActionType={setActionType}
                    />
                    <div className="details">
                      {/* <div className="payment-detail">
                        <div className="text-wrapper-2">
                          BOS Gratitude Balance
                        </div>
                        <div className="text-wrapper-3">
                          {balances?.gratitute_balance ||
                          balances?.gratitute_balance === 0 ? (
                            `$${numberWithCommas(
                              balances?.gratitute_balance,
                              2
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div> */}
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Island Name</div>
                        <div className="text-wrapper-3">
                          {selectedItem ? selectedItem?.name : '-'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Action Type</div>
                        {actionType && actionType?.type === 1 ? (
                          <div className="text-wrapper-3">Topup</div>
                        ) : null}
                        {actionType && actionType?.type === 0 ? (
                          <div className="text-wrapper-3">Participate</div>
                        ) : null}
                      </div>
                      <div className="payment-detail">
                        <Flex width="fit-content" y="center" x="end" gap={0.5}>
                          <div className="text-wrapper-2">BOS Fee</div>
                          <div
                            className="tooltip-info tooltip-info-normal"
                            style={{ right: 0, top: '10px' }}
                          >
                            <div
                              className="tooltip-info-icon"
                              style={{
                                height: 26
                              }}
                            >
                              <img className="info" alt="Info" src={info} />
                            </div>
                            <div
                              className="tooltip-info-text"
                              style={{ top: '30px' }}
                            >
                              <span>
                                This indicates the BOS fee, which is required to
                                cover all aspects of the transaction, including
                                management and exchange costs.
                              </span>
                            </div>
                          </div>
                        </Flex>
                        <div className="text-wrapper-3">0%</div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Your Loan Allocation
                        </div>
                        <div className="text-wrapper-3">
                          {actionType?.loan_allocation ||
                          actionType?.loan_allocation === 0
                            ? `$${numberWithCommas(
                                actionType?.loan_allocation,
                                2
                              )}`
                            : '-'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Your Balance:</div>
                        <div className="text-wrapper-3">
                          {islandsDetailData?.activeAmount ||
                          islandsDetailData?.activeAmount === 0 ? (
                            `$${numberWithCommas(
                              Number(islandsDetailData?.transferable_balance),
                              0
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={islandTransferToIslandData.amount}
                        onChange={(e) =>
                          Number(islandsDetailData?.transferable_balance) >=
                            +e.target.value &&
                          setIslandTransferToIslandData({
                            ...islandTransferToIslandData,
                            amount: e.target.value
                          })
                        }
                        disabled={!isBalanceEnough}
                        style={
                          isBalanceEnough
                            ? { opacity: 1 }
                            : { opacity: 0.5, cursor: 'not-allowed' }
                        }
                      />
                      <div className="options">
                        <p
                          className="min pointer"
                          onClick={(e) => {
                            if (isBalanceEnough) {
                              if (islandsDetailData) {
                                setIslandTransferToIslandData({
                                  ...islandTransferToIslandData,
                                  amount: minAmount
                                });
                              }
                            } else {
                              toast.error(
                                'Your balance is below the minimum amount.'
                              );
                            }
                          }}
                        >
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          {islandsDetailData && actionType ? (
                            <span className="text-wrapper-5">{`$${numberWithCommas(
                              Number(minAmount).toFixed(2),
                              0
                            )}`}</span>
                          ) : (
                            <span className="text-wrapper-5">-</span>
                          )}
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) => {
                            if (isBalanceEnough) {
                              setIslandTransferToIslandData({
                                ...islandTransferToIslandData,
                                amount: numberRounder(
                                  islandsDetailData?.transferable_balance
                                )
                              });
                            } else {
                              toast.error(
                                'Your balance is below the minimum amount.'
                              );
                            }
                          }}
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandsDetailData?.activeAmount ||
                            islandsDetailData?.activeAmount === 0 ? (
                              `$${numberWithCommas(
                                Number(islandsDetailData?.transferable_balance),
                                0
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group-4">
                        <div
                          className="mod-inside-input-ck-editor input-description"
                          dangerouslySetInnerHTML={{
                            __html: ckeditorTransferModal
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                  />
                                </div>
                                <img
                                  className="img"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          $
                          {numberWithCommas(
                            islandTransferToIslandData.amount,
                            0
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="frame">
                      <div className="group">
                        <div className="overlap-group-3">
                          <div className="rectangle-2" />
                          <div className="payment-details">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">
                                  Transfer to Island
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    islandTransferToIslandData.amount
                                  )}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line"
                              alt="Line"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                {!actionType ? (
                                  <div className="text-wrapper-2">Amount</div>
                                ) : null}
                                {actionType && actionType?.type === 1 ? (
                                  <div className="text-wrapper-2">
                                    Topup Amount
                                  </div>
                                ) : null}
                                {actionType && actionType?.type === 0 ? (
                                  <div className="text-wrapper-2">
                                    Participate Amount
                                  </div>
                                ) : null}
                                <div className="text-wrapper-3">
                                  $
                                  {islandsDetailData?.transfer_fee_type == 1
                                    ? numberWithCommas(
                                        islandsDetailData?.transfer_fee
                                      )
                                    : numberWithCommas(
                                        islandTransferToIslandData.amount *
                                          ((islandsDetailData
                                            ? islandsDetailData?.transfer_fee
                                            : 0) /
                                            100)
                                      )}
                                </div>
                              </div>
                              {/* <div className="payment-detail">
                                                            <p className="text-wrapper-2">Your BOS Gratitude Balance will be</p>
                                                            <div className="text-wrapper-3">${numberWithCommas(Number(balances?.gratitute_balance) - Number(islandTransferToIslandData.amount))}</div>
                                                        </div> */}
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your Loan Allocation will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {actionType && islandsDetailData
                                    ? (actionType?.loan_allocation ||
                                        actionType?.loan_allocation === 0) &&
                                      islandsDetailData?.transfer_fee_type == 1
                                      ? numberWithCommas(
                                          Number(actionType?.loan_allocation) +
                                            (islandTransferToIslandData.amount -
                                              (islandsDetailData
                                                ? islandsDetailData?.transfer_fee
                                                : 0) >=
                                            0
                                              ? islandTransferToIslandData.amount -
                                                (islandsDetailData
                                                  ? islandsDetailData?.transfer_fee
                                                  : 0)
                                              : 0)
                                        )
                                      : numberWithCommas(
                                          Number(actionType?.loan_allocation) +
                                            (islandTransferToIslandData.amount *
                                              ((islandsDetailData
                                                ? islandsDetailData?.transfer_fee
                                                : 0) /
                                                100) >=
                                            0
                                              ? islandTransferToIslandData.amount -
                                                islandTransferToIslandData.amount *
                                                  ((islandsDetailData
                                                    ? islandsDetailData?.transfer_fee
                                                    : 0) /
                                                    100)
                                              : 0)
                                        )
                                    : 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div
                          className="div-wrapper pointer"
                          onClick={() => {
                            if (selectedItem) {
                              if (actionType) {
                                const finalMin =
                                  actionType?.type === 1
                                    ? actionType?.minimum_topup
                                    : actionType?.minimum_invest;
                                if (
                                  islandTransferToIslandData.amount > 0 &&
                                  islandTransferToIslandData.amount >= finalMin
                                ) {
                                  console.log('test');
                                  islandTransferToIslandHandle();
                                } else {
                                  toast.error(
                                    'Amount is less than minimum Amount.'
                                  );
                                }
                              }
                            } else {
                              toast.error('Please select an island.');
                            }
                          }}
                        >
                          <div className="text-wrapper-12">Submit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}

              {screenWidth >= 1037 && (
                <>
                  <div className="left-2">
                    <Input
                      setSelectedItem={setSelectedItem}
                      selectedWalletType={selectedWalletType}
                      setSelectedWalletType={setSelectedWalletType}
                      setActionType={setActionType}
                    />
                    <div className="details">
                      {/* <div className="payment-detail">
                        <div className="text-wrapper-2">
                          BOS Gratitude Balance
                        </div>
                        <div className="text-wrapper-3">
                          {balances?.gratitute_balance ||
                          balances?.gratitute_balance === 0 ? (
                            `$${numberWithCommas(
                              balances?.gratitute_balance,
                              2
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div> */}
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Island Name</div>
                        <div className="text-wrapper-3">
                          {selectedItem ? selectedItem?.name : '-'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Action Type</div>
                        {actionType && actionType?.type === 1 ? (
                          <div className="text-wrapper-3">Topup</div>
                        ) : null}
                        {actionType && actionType?.type === 0 ? (
                          <div className="text-wrapper-3">Participate</div>
                        ) : null}
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Your Loan Allocation
                        </div>
                        <div className="text-wrapper-3">
                          {actionType?.loan_allocation ||
                          actionType?.loan_allocation === 0
                            ? `$${numberWithCommas(
                                actionType?.loan_allocation,
                                2
                              )}`
                            : '-'}
                        </div>
                      </div>

                      <div className="payment-detail">
                        <div className="text-wrapper-2">Your Balance:</div>
                        <div className="text-wrapper-3">
                          {islandsDetailData?.activeAmount ||
                          islandsDetailData?.activeAmount === 0 ? (
                            `$${numberWithCommas(
                              Number(islandsDetailData?.transferable_balance),
                              0
                            )}`
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="text"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={islandTransferToIslandData.amount}
                        onChange={(e) => {
                          Number(islandsDetailData?.transferable_balance) >=
                            +e.target.value &&
                            setIslandTransferToIslandData({
                              ...islandTransferToIslandData,
                              amount: e.target.value
                            });
                        }}
                        disabled={!isBalanceEnough}
                        style={
                          isBalanceEnough
                            ? { opacity: 1 }
                            : { opacity: 0.5, cursor: 'not-allowed' }
                        }
                      />
                      <div className="options">
                        <p
                          className="min pointer"
                          onClick={(e) => {
                            if (isBalanceEnough) {
                              if (islandsDetailData) {
                                setIslandTransferToIslandData({
                                  ...islandTransferToIslandData,
                                  amount: minAmount
                                });
                              }
                            } else {
                              toast.error(
                                'Your balance is below the minimum amount.'
                              );
                            }
                          }}
                        >
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          {islandsDetailData && actionType ? (
                            <span className="text-wrapper-5">{`$${numberWithCommas(
                              Number(minAmount).toFixed(2),
                              0
                            )}`}</span>
                          ) : (
                            <span className="text-wrapper-5">-</span>
                          )}
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) => {
                            if (isBalanceEnough) {
                              setIslandTransferToIslandData({
                                ...islandTransferToIslandData,
                                amount: numberRounder(
                                  islandsDetailData?.transferable_balance
                                )
                              });
                            } else {
                              toast.error(
                                'Your balance is below the minimum amount.'
                              );
                            }
                          }}
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandsDetailData?.activeAmount ||
                            islandsDetailData?.activeAmount === 0 ? (
                              `$${numberWithCommas(
                                Number(islandsDetailData?.transferable_balance),
                                0
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      <div className="overlap-group">
                        <div
                          className="mod-inside-input-ck-editor input-description"
                          dangerouslySetInnerHTML={{
                            __html: ckeditorTransferModal
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src={line7} />
                  <div className="right-2">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src={confirmedIcon}
                                  />
                                </div>
                                <img
                                  className="vector-2"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          $
                          {numberWithCommas(
                            islandTransferToIslandData.amount,
                            0
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="frame-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-3" />
                          <div className="payment-details-2">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">
                                  Transfer to Island
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    islandTransferToIslandData.amount
                                  )}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line-4"
                              alt="Line"
                              src={dottedLine}
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div className="text-wrapper-2">BOS Fee</div>
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <img
                                        className="info"
                                        alt="Info"
                                        src={info}
                                      />
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        This indicates the BOS fee, which is
                                        required to cover all aspects of the
                                        transaction, including management and
                                        exchange costs.
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                                <div className="text-wrapper-3">
                                  $
                                  {islandsDetailData?.transfer_fee_type == 1
                                    ? numberWithCommas(
                                        islandsDetailData?.transfer_fee
                                      )
                                    : numberWithCommas(
                                        islandTransferToIslandData.amount *
                                          ((islandsDetailData
                                            ? islandsDetailData?.transfer_fee
                                            : 0) /
                                            100)
                                      )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                {!actionType ? (
                                  <div className="text-wrapper-2">Amount</div>
                                ) : null}
                                {actionType && actionType?.type === 1 ? (
                                  <div className="text-wrapper-2">
                                    Topup Amount
                                  </div>
                                ) : null}
                                {actionType && actionType?.type === 0 ? (
                                  <div className="text-wrapper-2">
                                    Participate Amount
                                  </div>
                                ) : null}
                                <div className="text-wrapper-3">
                                  $
                                  {islandsDetailData?.transfer_fee_type == 1
                                    ? numberWithCommas(
                                        islandTransferToIslandData.amount -
                                          (islandsDetailData
                                            ? islandsDetailData?.transfer_fee
                                            : 0) >=
                                          0
                                          ? islandTransferToIslandData.amount -
                                              (islandsDetailData
                                                ? islandsDetailData?.transfer_fee
                                                : 0)
                                          : 0
                                      )
                                    : numberWithCommas(
                                        islandTransferToIslandData.amount -
                                          islandTransferToIslandData.amount *
                                            ((islandsDetailData
                                              ? islandsDetailData?.transfer_fee
                                              : 0) /
                                              100) >=
                                          0
                                          ? islandTransferToIslandData.amount -
                                              islandTransferToIslandData.amount *
                                                ((islandsDetailData
                                                  ? islandsDetailData?.transfer_fee
                                                  : 0) /
                                                  100)
                                          : 0
                                      )}
                                </div>
                              </div>
                              {/* <div className="payment-detail">
                                                            <p className="text-wrapper-2">Your BOS Gratitude Balance will be</p>
                                                            <div className="text-wrapper-3">${numberWithCommas(Number(balances?.gratitute_balance) - Number(islandTransferToIslandData.amount))}</div>
                                                        </div> */}
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your Loan Allocation will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {actionType && islandsDetailData
                                    ? (actionType?.loan_allocation ||
                                        actionType?.loan_allocation === 0) &&
                                      islandsDetailData?.transfer_fee_type == 1
                                      ? numberWithCommas(
                                          Number(actionType?.loan_allocation) +
                                            (islandTransferToIslandData.amount -
                                              (islandsDetailData
                                                ? islandsDetailData?.transfer_fee
                                                : 0) >=
                                            0
                                              ? islandTransferToIslandData.amount -
                                                (islandsDetailData
                                                  ? islandsDetailData?.transfer_fee
                                                  : 0)
                                              : 0)
                                        )
                                      : numberWithCommas(
                                          Number(actionType?.loan_allocation) +
                                            (islandTransferToIslandData.amount *
                                              ((islandsDetailData
                                                ? islandsDetailData?.transfer_fee
                                                : 0) /
                                                100) >=
                                            0
                                              ? islandTransferToIslandData.amount -
                                                islandTransferToIslandData.amount *
                                                  ((islandsDetailData
                                                    ? islandsDetailData?.transfer_fee
                                                    : 0) /
                                                    100)
                                              : 0)
                                        )
                                    : 0}
                                  {/* {actionType?.loan_allocation ||
                                  actionType?.loan_allocation === 0
                                    ? numberWithCommas(
                                        Number(actionType?.loan_allocation) +
                                          Number(
                                            islandTransferToIslandData.amount
                                          )
                                      )
                                    : 0} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="overlap-wrapper pointer"
                        onClick={() => {
                          if (selectedItem) {
                            if (actionType) {
                              const finalMin =
                                actionType?.type === 1
                                  ? actionType?.minimum_topup
                                  : actionType?.minimum_invest;
                              if (
                                islandTransferToIslandData.amount > 0 &&
                                islandTransferToIslandData.amount >= finalMin
                              ) {
                                console.log('test');
                                islandTransferToIslandHandle();
                              } else {
                                toast.error(
                                  'Amount is less than minimum Amount.'
                                );
                              }
                            }
                          } else {
                            toast.error('Please select an island.');
                          }
                        }}
                      >
                        <div className="overlap-2">
                          <div className="text-wrapper-13">Submit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className="hr"
              style={{
                width:
                  screenWidth < 1037
                    ? '565px'
                    : screenWidth >= 1037
                    ? '1037px'
                    : undefined
              }}
            />
            <div
              className="header-2"
              style={{
                width:
                  screenWidth < 1037
                    ? '516px'
                    : screenWidth >= 1037
                    ? '993px'
                    : undefined
              }}
            >
              <div className="text-wrapper-14">Transfer</div>
              <div
                className="close-btn"
                onClick={() => setModalIslandTransferToIsland(false)}
              >
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                    screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {modalIslandTransferToIsland && islandTransferToIslandSuccessData && (
        <SuccessModal
          requestedAmount={islandTransferToIslandSuccessData?.requestedAmount}
          txID={islandTransferToIslandSuccessData?.txID}
          method={ModalMethods.Transfer}
          time={islandTransferToIslandSuccessData?.time}
          fee={islandTransferToIslandSuccessData?.fee}
          finalAmount={islandTransferToIslandSuccessData?.finalAmount}
          closeState={setModalIslandTransferToIsland}
        />
      )}
    </>
  );
};
