import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";
import toast from "react-hot-toast";
import { toPascalCase } from "../utils/tools";

class ProfileService {
    private _httpService = HttpService('Profile');
    private _httpServiceAuth = HttpService('Authentication');
    private _httpServiceNotification = HttpService('Notification');
    private _httpServiceSetting = HttpService('Setting');

    async getSettings() {
        try {
            const response = await this._httpServiceSetting.get<any>('/');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async getNotification(offset?: any) {
        try {
            const response = await this._httpServiceNotification.get<any>(`?limit=5&offset=${offset ? offset : 1}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async getNotificationAll(offset?: any) {
        try {
            const response = await this._httpServiceNotification.get<any>(`All?limit=10&offset=${offset ? offset : 1}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async getHistoryLog(offset?: any) {
        try {
            const response = await this._httpService.get<any>(`ProfileHistory?limit=10&offset=${offset ? offset : 1}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async handShake() {
        try {
            const response = await this._httpService.post<any>('/HandShake');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async change2faStatus(status: any, token: any) {
        try {
            const response = await this._httpService.post<any>('/Change2faStatus', { status, token });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }


    async changeReadStatusHandle(id: string, type: string) {
        try {
            const response = await this._httpServiceNotification.post<any>('/Edit/ChangeSingleRead', { id, type });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async changeReadAllStatusHandle() {
        try {
            const response = await this._httpServiceNotification.post<any>('/Edit/ChangeRead');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async removeNotificationHandle(id: string, type: string) {
        try {
            const response = await this._httpServiceNotification.post<any>('/Edit/DeleteSingleNotification', { id, type });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async removeAllNotificationHandle() {
        try {
            const response = await this._httpServiceNotification.post<any>('/Edit/DeleteNotification');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getAll() {
        try {
            const response = await this._httpService.get<any>('/UserDashboard');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }


    async getSingleNotification(id: any) {
        try {
            const response = await this._httpServiceNotification.get<any>('/Single/' + id);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async update(formData: any) {
        try {
            const response = await this._httpService.put<any>('/Edit', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateAvatar(newFile: any) {
        try {
            const response = await this._httpService.put<any>('/Edit/SetAvatar', newFile);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async get2FA() {
        try {
            const response = await this._httpServiceAuth.get<any>('/Setup2FA');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async checkTwoFA() {
        try {
            const response = await this._httpServiceAuth.get<any>('/Is2faActive');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex.response.data.error)
        }
    }

    async verify2FAHandle(code: string) {
        try {
            const response = await this._httpServiceAuth.post<any>('/Verify2FA', { token: code });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async sendEmailVerification(email: string) {
        try {
            const response = await this._httpServiceAuth.post<any>('/ChangeEmailLink', { email: email });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async checkEmailVerificationCode(code: string, code2FA: string) {
        try {
            const response = await this._httpServiceAuth.post<any>('/VerifyChangedEmail', { otp_code: code, token: code2FA });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateBeneficiaryEmail(form: any) {
        try {
            const response = await this._httpService.put<any>('/Edit/EditBeneficiary', form);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateFacebook(form: any) {
        try {
            const response = await this._httpService.put<any>('/Edit/Facebook', form);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateX(form: any) {
        try {
            const response = await this._httpService.put<any>('/Edit/x', form);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateLinkedIn(form: any) {
        try {
            const response = await this._httpService.put<any>('/Edit/linkdin', form);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async sendPhoneVerification(countryId: string, phoneNumber: string) {
        try {
            const response = await this._httpServiceAuth.post<any>('/ChangePhoneNumberProfile', { country_id: countryId, phone_number: phoneNumber });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async checkPhoneVerificationCode(code: string, code2FA: string) {
        try {
            const response = await this._httpServiceAuth.post<any>('/VerifyChangesPhoneProfile', { otp_code: code, token: code2FA });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async changePassword(password: string, code2FA: string) {
        try {
            const response = await this._httpService.post<any>('/ResetPassword', { password: password, token: code2FA });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
}

export default new ProfileService()