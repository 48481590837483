import { Container, Flex } from "../../styles/Styles";
import Header from "../Header";
import Box from "./boxes/box";
import Steps from "./steps/steps";

const SophisticateForm = (): JSX.Element => {

  return (
    <Container x={"center"} y={"start"} className="container verify-code sophisticate-page">
      {/* <Flex gap={1.7} y={"start"} x={"center"} marginT={1} className="verify-code-body" style={{ height: 'unset' }}> */}
      <div className="sophisticate-container">
        <Header title='Assessment' />
        <Box />
      </div>

      {/* </Flex> */}
    </Container>
  );
};

export default SophisticateForm;
