import { useContext, useEffect, useState } from "react";
import WizardContext from "../../../context/private/wizardContext";

const Input = ({ question, id, index, items, answer }: { question: string, id: number, index: number, items: string[], answer: number }): JSX.Element => {
    const { answers, finalAnswers, setIsAllAnswersCurrect, questionsAnswers, setQuestionsAnswers } = useContext(WizardContext)
    const special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];

    const [selectedItem, setSelectedItem] = useState(-1)

    useEffect(() => {
        finalAnswers[index] = answer
    }, [])

    useEffect(() => {
        answers[index] = selectedItem
    }, [selectedItem])


    useEffect(() => {
        if (questionsAnswers.answer1.trim().length > 0 && questionsAnswers.answer2.trim().length > 0 && questionsAnswers.answer3.trim().length > 0 && questionsAnswers.answer4.trim().length > 0) {
            setIsAllAnswersCurrect(true)
        } else {
            setIsAllAnswersCurrect(false)
        }
    }, [questionsAnswers])


    return (
        <>
            <label className="intr-q">{question}</label>

            <textarea className="intr-q-input min-h-[100px]" name="" id="" onChange={(e) => {
                switch (index) {
                    case 0:
                        setQuestionsAnswers({ ...questionsAnswers, answer1: e.target.value })
                        break;
                    case 1:
                        setQuestionsAnswers({ ...questionsAnswers, answer2: e.target.value })
                        break;
                    case 2:
                        setQuestionsAnswers({ ...questionsAnswers, answer3: e.target.value })
                        break;
                    case 3:
                        setQuestionsAnswers({ ...questionsAnswers, answer4: e.target.value })
                        break;

                    default:
                        break;
                }
            }}>

            </textarea>
        </>
    )
}

export default Input
