import { useContext, useEffect, useState } from 'react';
import WizardContext from '../../../context/private/wizardContext';

const Inputs = ({
  step,
  token,
  setToken,
  isClicked
}: {
  step: number | any;
  token: string | any;
  setToken: (value: string) => void;
  isClicked?: boolean
}): JSX.Element => {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input6, setInput6] = useState('');

  useEffect(() => {
    setInput1('');
    setInput2('');
    setInput3('');
    setInput4('');
    setInput5('');
    setInput6('');
  }, [step]);

  const clearAllInputs = () => {
    setInput1('')
    setInput2('')
    setInput3('')
    setInput4('')
    setInput5('')
    setInput6('')
  }

  useEffect(() => {
    if (isClicked) {
      clearAllInputs()
    }
  }, [isClicked])

  const { emailVerifyOTPHandle, phoneVerifyOTPHandle, uplineVerifyOTPHandle } =
    useContext(WizardContext);

  useEffect(() => {
    switch (step) {
      case 0:
        if (
          input1.length === 4
        ) {
          emailVerifyOTPHandle(token);
          // clearAllInputs()
        }
        break;
      case 1:
        if (
          input1.length === 6
        ) {
          phoneVerifyOTPHandle(token);
        }
        break;
      case 2:
        if (
          input1.length === 4
        ) {
          uplineVerifyOTPHandle(token);
        }
        break;
      case 3:
        break;

      default:
        break;
    }
  }, [input1, input2, input3, input4, input5, input6, token, step]);

  // useEffect(() => {
  //   // DOM utility functions:

  //   const els = (sel: any, par?: any) =>
  //     (par || document).querySelectorAll(sel);

  //   // Task: multiple inputs "field"
  //   els('.pin').forEach((elGroup: any) => {
  //     const elsInput = [...elGroup.children];
  //     const len = elsInput.length;

  //     const handlePaste = (ev: any) => {
  //       const clip = ev.clipboardData.getData('text'); // Get clipboard data
  //       const pin = clip.replace(/\s/g, ''); // Sanitize string
  //       const ch = [...pin]; // Create array of chars
  //       console.log('ch', ch)
  //       elsInput.forEach((el, i) => {
  //         console.log(ch[i], el.children[0], el);
  //         el.children[0].value = ch[i] ?? '';
  //       }); // Populate inputs
  //       elsInput[pin.length - 1]?.children[0].focus(); // Focus input
  //     };

  //     const handleInput = (ev: any) => {
  //       const elInp = ev.currentTarget;
  //       const i = elsInput.indexOf(elInp);
  //       if (elsInput[0]?.children[0].value && (i + 1) % len)
  //         elsInput[i + 1]?.children[0].focus(); // focus next
  //     };

  //     const handleKeyDn = (ev: any) => {
  //       const elInp = ev.currentTarget;
  //       const i = elsInput.indexOf(elInp);
  //       if (!elsInput[i]?.children[0].value && ev.key === 'Backspace' && i) {
  //         elsInput[i - 1]?.children[0].focus().select();
  //       } // Focus previous
  //     };

  //     // Add the same events to every input in group:
  //     elsInput.forEach((elInp) => {
  //       elInp.addEventListener('paste', handlePaste); // Handle pasting
  //       elInp.addEventListener('input', handleInput); // Handle typing
  //       elInp.addEventListener('keydown', handleKeyDn); // Handle deleting
  //     });
  //   });
  // }, []);

  return (
    <>
      <div className={step === 1 ? 'inputs phone pin' : 'inputs pin'}>
        <div className="input">
          <input
            id="verify-input-0"
            type="number"
            // step={1}
            max={6}
            className="input"
            value={input1}
            name="pass[]"
            maxLength={6}
            autoComplete="off"
            required
            pattern="\d{6}"
            autoFocus
            onChange={(e) => {

              if (
                e.target.value.length >= 0 && (!(e.target.value.includes('e'))) && e.target.value !== 'e' &&
                Number(e.target.value) >= 0
              ) {
                setInput1(e.target.value);
                let newToken = token;
                newToken = e.target.value;
                // newToken[1] = '';
                setToken(newToken);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Inputs;
