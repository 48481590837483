import React, { useContext, useEffect, useState } from 'react';
import PublicContext from '../context/public/publicContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Layout from './layout/Index';
import Loader from './Loader';
import PrivateContext from '../context/private/privateContext';

import { IoMdClose } from 'react-icons/io';
import Pagination from 'react-responsive-pagination';
import { timeToYYYYMMDD } from '../utils/tools';
import { useNavigate } from 'react-router-dom';
import dictionary from "../locals/my-account.json"
import AccountContext from '../context/private/accountContext';

const Notifications = () => {
  const { flowState } = useContext(PrivateContext);
  const navigate = useNavigate();

  const { loadPrivacyPolicy, privacyPolicy } = useContext(PublicContext);
  const { settings } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);

  const {
    removeNotificationHandle,
    removeAllNotificationHandle,
    notificationAllHandle,
    currentPageNotification,
    setCurrentPageNotification,
    loadNotification,
    notificationHandle,
    notification,
    loadAllNotification, notificationAll, allNotificationHandle,
    handshake, setUpdateTimer
  } = useContext(PrivateContext);

  const [isMore, setIsMore] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: '',
    description: ''
  });
  const [isHaveNotification, setIsHaveNotification] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0);
    loadAllNotification()
  }, []);
  useEffect(() => {
    setUpdateTimer(+new Date)
    loadAllNotification(currentPageNotification)
  }, [currentPageNotification]);

  if (flowState?.step === 17 && userInfo
    && userInfo?.membershipStatus !== 2)
    return (
      <>
        {modal && (
          <Flex x="center">
            <div className="mod">
              <div className="mod-border">
                <div className="mod-box">
                  <p className="mod-box-p">{modalData.title}</p>
                  <button className="mod-box-btn" onClick={() => setModal(false)}>
                    <IoMdClose />
                  </button>
                </div>

                <div className="mod-inside">
                  <div className="mod-inside-body">
                    <p className="modal-description"
                      dangerouslySetInnerHTML={{
                        __html: modalData.description
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="blur-bg" onClick={() => setModal(false)}></div>
          </Flex>
        )}

        <Layout className="parent">
          <Container
            x={'center'}
            marginT={1}
            className="container container-boscrew"
          >



            <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
              <div className="boscrew" style={{ width: '100%' }}>
                {/* header*/}

                <Header title={dictionary['PAGE-title3']} />

                {settings ? (
                  <div className="explorer-body">
                    <div className="explorer-content" style={{ gap: 0 }}>
                      {notificationAll ? <h4>{dictionary['MY-ACCOUNT-title105']}</h4> : <h4><div className='skeleton-element-item  ck-editor-title skeleton-box'></div></h4>}
                      <div
                        className="content notification-holder"
                      >
                        <div className="notification-container">
                          <div className="notification">

                            {notificationAll ? notificationAll?.msg?.data.length ? (
                              <>
                                <div className="notification-header">
                                  <span></span>
                                  {notificationAll?.msg?.data.length ? (
                                    <div>
                                      <span onClick={() => {
                                        notificationAllHandle()
                                        loadAllNotification(currentPageNotification)
                                      }}>
                                        {dictionary['MY-ACCOUNT-title106']}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="notification notification-list">
                                  {notificationAll?.msg?.data?.map((item: any, index: number) => (
                                    <div
                                      key={index}
                                      className={`notification-item ${!item?.is_read && item?.is_admin ? 'admin' : ''
                                        } ${item?.is_read ? '' : 'active'}`}
                                    >

                                      <Flex y="center" gap="1">
                                        <span
                                          onClick={() => {
                                            setModalData({
                                              ...modalData,
                                              title: item.title,
                                              description: item.description
                                            });
                                            allNotificationHandle(item.id, item.type);
                                            setModal(true);
                                          }}
                                        >
                                          {item.title}
                                        </span>{' '}
                                        {item?.is_admin ? (
                                          <span className="notification-item-from-admin">
                                            {dictionary['MY-ACCOUNT-title107']}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </Flex>
                                      <span
                                        onClick={() => {
                                          setModalData({
                                            ...modalData,
                                            title: item.title,
                                            description: item.description
                                          });
                                          allNotificationHandle(item.id, item.type);
                                          setModal(true);
                                        }}
                                        className="line-clamp-2"
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                      >
                                      </span>
                                      <span
                                        className={`time ${!item?.is_read && item?.is_admin ? 'admin' : ''
                                          }`}
                                      >
                                        {timeToYYYYMMDD(+new Date(item?.timestamp))}
                                      </span>
                                    </div>
                                  ))}
                                </div>

                                {notificationAll?.total_page && <Pagination
                                  className="pagination"
                                  total={notificationAll?.total_page}
                                  current={notificationAll?.current_page}
                                  maxWidth={80}
                                  renderNav={false}
                                  ariaPreviousLabel=" "
                                  ariaNextLabel=" "
                                  a11yActiveLabel=""
                                  onPageChange={(page) => setCurrentPageNotification(page)}
                                />}
                              </>
                            ) :
                              <p className="notification-empty">{dictionary['MY-ACCOUNT-title108']}s</p>
                              : <>
                                <div className='skeleton-element-item ck-editor-content skeleton-box'></div>
                              </>}
                          </div></div>

                      </div>
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </Flex>
          </Container>
        </Layout>
      </>
    );
  else
    return (<>{navigate('/')}</>)

};

export default Notifications;
