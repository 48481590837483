import styled, { css } from "styled-components";

const usefulSpacing = css`
  margin-top: ${(props) => {
        if (props && props.marginY) {
            return props.marginY === "auto"
                ? `${props.marginY}`
                : `${props.marginY}rem`;
        } else if (props && props.marginT) {
            return props.marginT === "auto"
                ? `${props.marginT}`
                : `${props.marginT}rem`;
        } else {
            return "unset";
        }
    }};
  margin-bottom: ${(props) => {
        if (props && props.marginY) {
            return props.marginY === "auto"
                ? `${props.marginY}`
                : `${props.marginY}rem`;
        } else if (props && props.marginB) {
            return props.marginB === "auto"
                ? `${props.marginB}`
                : `${props.marginB}rem`;
        } else {
            return "unset";
        }
    }};
  margin-right: ${(props) => {
        if (props && props.marginX) {
            return props.marginX === "auto"
                ? `${props.marginX}`
                : `${props.marginX}rem`;
        } else if (props && props.marginR) {
            return props.marginR === "auto"
                ? `${props.marginR}`
                : `${props.marginR}rem`;
        } else {
            return "unset";
        }
    }};
  margin-left: ${(props) => {
        if (props && props.marginX) {
            return props.marginX === "auto"
                ? `${props.marginX}`
                : `${props.marginX}rem`;
        } else if (props && props.marginL) {
            return props.marginL === "auto"
                ? `${props.marginL}`
                : `${props.marginL}rem`;
        } else {
            return "unset";
        }
    }};
  padding-top: ${(props) => {
        if (props && props.paddingY) {
            return `${props.paddingY}rem`;
        } else if (props && props.paddingT) {
            return `${props.paddingT}rem`;
        } else {
            return "unset";
        }
    }};
  padding-bottom: ${(props) => {
        if (props && props.paddingY) {
            return `${props.paddingY}rem`;
        } else if (props && props.paddingB) {
            return `${props.paddingB}rem`;
        } else {
            return "unset";
        }
    }};
  padding-right: ${(props) => {
        if (props && props.paddingX) {
            return `${props.paddingX}rem`;
        } else if (props && props.paddingR) {
            return `${props.paddingR}rem`;
        } else {
            return "unset";
        }
    }};
  padding-left: ${(props) => {
        if (props && props.paddingX) {
            return `${props.paddingX}rem`;
        } else if (props && props.paddingL) {
            return `${props.paddingL}rem`;
        } else {
            return "unset";
        }
    }};
`;
const usefulDirections = css`
  align-items: ${(props) => {
        if (props && props.direction && props.direction === "col") {
            if (props.x === "center") return "center";
            if (props.x === "left") return "left";
            if (props.x === "right") return "right";
            if (props.x === "stretch") return "stretch";
        } else {
            if (props.y === "center") return "center";
            if (props.y === "left") return "left";
            if (props.y === "right") return "right";
            if (props.y === "stretch") return "stretch";
        }
    }};
  justify-content: ${(props) => {
        if (props && props.direction && props.direction === "col") {
            if (props.y === "center") return "center";
            if (props.y === "left") return "left";
            if (props.y === "right") return "right";
            if (props.y === "between") return "space-between";
            if (props.y === "start") return "flex-start";
            if (props.y === "end") return "flex-end";
        } else {
            if (props.x === "center") return "center";
            if (props.x === "left") return "left";
            if (props.x === "right") return "right";
            if (props.x === "between") return "space-between";
            if (props.x === "start") return "flex-start";
            if (props.x === "end") return "flex-end";
        }
    }}; ;
`;
const useGrideBreakpoints = css`
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.desktopL}) {
    grid-template-columns: ${(props) =>
        props && props.desktopL ? "1fr ".repeat(props.desktopL) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customXL}) {
    grid-template-columns: ${(props) =>
        props && props.customXL ? "1fr ".repeat(props.customXL) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customL}) {
    grid-template-columns: ${(props) =>
        props && props.customL ? "1fr ".repeat(props.customL) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.desktopM}) {
    grid-template-columns: ${(props) =>
        props && props.desktopM ? "1fr ".repeat(props.desktopM) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.desktopS}) {
    grid-template-columns: ${(props) =>
        props && props.desktopS ? "1fr ".repeat(props.desktopS) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customM}) {
    grid-template-columns: ${(props) =>
        props && props.customM ? "1fr ".repeat(props.customM) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tabletL}) {
    grid-template-columns: ${(props) =>
        props && props.tabletL ? "1fr ".repeat(props.tabletL) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tabletM}) {
    grid-template-columns: ${(props) =>
        props && props.tabletM ? "1fr ".repeat(props.tabletM) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tabletS}) {
    grid-template-columns: ${(props) =>
        props && props.tabletS ? "1fr ".repeat(props.tabletS) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customS}) {
    grid-template-columns: ${(props) =>
        props && props.customS ? "1fr ".repeat(props.customS) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.mobileL}) {
    grid-template-columns: ${(props) =>
        props && props.mobileL ? "1fr ".repeat(props.mobileL) : ""};
    gap: ${(props) => props && props.gapMin && `${props.gapMin}rem`};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.mobileM}) {
    grid-template-columns: ${(props) =>
        props && props.mobileM ? "1fr ".repeat(props.mobileM) : ""};
  }
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.mobileS}) {
    grid-template-columns: ${(props) =>
        props && props.mobileS ? "1fr ".repeat(props.mobileS) : ""};
  }
`;
const useFlexBreakpoints = css`
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tabletM}) {
    flex-direction: ${(props) => {
        if (props && typeof props.tabletM !== "undefined") {
            return props.tabletM === "col" ? `column` : "row";
        } else {
            return ""
        }
    }};

align-items: ${(props) => {
        if (props && props.tabletM && props.tabletM === "col") {
            if (props.xTabletM === "center") return "center";
            if (props.xTabletM === "left") return "left";
            if (props.xTabletM === "right") return "right";
            if (props.xTabletM === "stretch") return "stretch";
        } else {
            if (props.yTabletM === "center") return "center";
            if (props.yTabletM === "left") return "left";
            if (props.yTabletM === "right") return "right";
            if (props.yTabletM === "stretch") return "stretch";
        }
    }};
  justify-content: ${(props) => {
        if (props && props.tabletM && props.tabletM === "col") {
            if (props.yTabletM === "center") return "center";
            if (props.yTabletM === "left") return "left";
            if (props.yTabletM === "right") return "right";
            if (props.yTabletM === "between") return "space-between";
            if (props.yTabletM === "start") return "flex-start";
            if (props.yTabletM === "end") return "flex-end";
        } else {
            if (props.xTabletM === "center") return "center";
            if (props.xTabletM === "left") return "left";
            if (props.xTabletM === "right") return "right";
            if (props.xTabletM === "between") return "space-between";
            if (props.xTabletM === "start") return "flex-start";
            if (props.xTabletM === "end") return "flex-end";
        }
    }}; ;
   
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${(props) => {
        if (props && props.marginY) {
            return `${props.marginY}rem`;
        } else if (props && props.marginT) {
            return `${props.marginT}rem`;
        } else {
            return "unset";
        }
    }};
  margin-bottom: ${(props) => {
        if (props && props.marginY) {
            return `${props.marginY}rem`;
        } else if (props && props.marginB) {
            return `${props.marginB}rem`;
        } else {
            return "unset";
        }
    }};
  padding-top: ${(props) => {
        if (props && props.paddingY) {
            return `${props.paddingY}rem`;
        } else if (props && props.paddingT) {
            return `${props.paddingT}rem`;
        } else {
            return "unset";
        }
    }};
  padding-bottom: ${(props) => {
        if (props && props.paddingY) {
            return `${props.paddingY}rem`;
        } else if (props && props.paddingB) {
            return `${props.paddingB}rem`;
        } else {
            return "unset";
        }
    }};
  padding-right: ${(props) => {
        if (props && props.paddingX) {
            return `${props.paddingX}rem`;
        } else if (props && props.paddingR) {
            return `${props.paddingR}rem`;
        } else {
            return "unset";
        }
    }};
  padding-left: ${(props) => {
        if (props && props.paddingX) {
            return `${props.paddingX}rem`;
        } else if (props && props.paddingL) {
            return `${props.paddingL}rem`;
        } else {
            return "unset";
        }
    }};
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.mobileM}) {
    padding: 0 1rem !important;
  }
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customS}) {
    max-width: 540px;
  }
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tabletS}) {
    padding: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tabletM}) {
    max-width: 720px;
  }
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customM}) {
    max-width: 960px;
  }
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customL}) {
    max-width: 1140px;
  }
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.customXL}) {
    max-width: 1320px;
  }
`;

export const Flex = styled.div`
  ${usefulSpacing}
  display: flex;
  flex-wrap: ${(props) => {
        if (props.wrap === "wrap") return "wrap";
        if (props.wrap === "nowrap") return "nowrap";
        if (props.wrap === "wrap-reverse") return "wrap-reverse";
        if (props.wrap === "revert") return "revert";
    }};
  gap: ${(props) => (props && props.gap ? `${props.gap}rem` : "unset")};
  ${usefulDirections}
  flex-direction: ${(props) =>
        props && props.direction === "col" ? `column` : "row"};
  cursor: ${(props) => (props && props.pointer ? `pointer` : "unset")};
  width: ${(props) => (props && props.width ? props.width : "100%")};
  height: ${(props) => {
        if (props.h === "unset") return "unset";
        if (props.h === "100vh") return "100vh";
        else return "100%"
    }};
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
        breakpoints.mobileL}) {
    gap: ${(props) => props && props.gapMin && `${props.gapMin}rem`};
  }

  ${useFlexBreakpoints}
`;

export const Grid = styled.div`
  ${usefulSpacing}
  display: grid;
  justify-items: center;
  align-items: ${(props) => {
        if (props && props.x === "start") {
            return `${props.x}rem`;
        } else if (props && props.x === "end") {
            return `${props.x}rem`;
        } else {
            return "center";
        }
    }};
  ${usefulDirections}
  grid-template-columns: ${(props) => {
        if (props.item) return "1fr ".repeat(props.item);

        if (props.itemShape) {
            const fractions = props.itemShape.split(" ");
            let shape = "";
            fractions.forEach((fraction) => {
                shape += `${fraction}fr `;
            });
            return shape;
        }
    }};
  ${usefulDirections}
  gap: ${(props) => (props && props.gap ? `${props.gap}rem` : "")};
  ${useGrideBreakpoints}
  cursor: ${(props) => (props && props.pointer ? `pointer` : "")};
`;
