import React from 'react'
import "../../assets/css/react-datepicker.css"
import { SidebarContextProvider } from '../../context/private/sidebarContext'
import Aside from '../Sidebar'
import { LayoutProps } from '../types'

const Layout: React.FunctionComponent<LayoutProps> = ({ children, className }): JSX.Element => {
    return (
        <div className={className}>
            <SidebarContextProvider>
                <Aside />
            </SidebarContextProvider>
            {children}
        </div>
    )
}

export default Layout
