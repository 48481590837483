import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";

class CrewService {
    private _httpService = HttpService('Crew');

    async getGratitudeList() {
        try {
            const response = await this._httpService.get<any>('/list');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getCrewTree(checkbox: boolean) {
        try {
            const response = await this._httpService.get<any>('/CrewTree?show_user=' + checkbox);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async agreeTerms() {
        try {
            const response = await this._httpService.post<any>('/Agreement', { status: "accpeted" });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getAgreeTerms() {
        try {
            const response = await this._httpService.get<any>('/CheckAgreement');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message?.[0]?.crew_agreement
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getCrewDetail() {
        try {
            const response = await this._httpService.get<any>('/CrewDetail');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }
}

export default new CrewService()