import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import IslandsContext from '../../../context/private/islandsContext';
import TreasuryContext from '../../../context/private/treasuryContext';
import { Flex } from '../../../styles/Styles';
import {
  numberWithCommas,
  timestampToLocalDaily,
  timestampToLocalHour,
  timeToYYYYMMDD
} from '../../../utils/tools';
import { IslandsItemContext } from '../Index';
import PrivateContext from '../../../context/private/privateContext';
import Loader from '../../Loader';
import dictionary from './../../../locals/island.json';
import AccountContext from '../../../context/private/accountContext';

const Participate = () => {
  const { flowState, setUpdateTimer } = useContext(PrivateContext);
  const {
    modalTx,
    setModalTx,
    loadUserHasParticipate,
    loadParticipateDetail,
    participateHandle,
    modalTopUp,
    setModalTopUp
  } = useContext(IslandsContext);

  const [userHasParticipate, setUserHasParticipate] = useState<any>(null);
  const [islandParticipateDetail, setIslandParticipateDetail] =
    useState<any>(null);
  const { currentId, islandOverview, islandUser } =
    useContext(IslandsItemContext);
  const { userInfo } =
    useContext(AccountContext);

  const [input, setInput] = useState<number | string>('');

  useEffect(() => {
    if (flowState.step === 17 && userInfo
      && userInfo?.membershipStatus !== 2) {
      loadUserHasParticipate(currentId, setUserHasParticipate);
      loadParticipateDetail(currentId, setIslandParticipateDetail);
    }
  }, []);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [modalTx, modalTopUp]);

  if (islandParticipateDetail === null || userHasParticipate === null)
    return <Loader />;
  return (
    <>
      {modalTx && (
        <Flex h="unset" x="center">
          <div className="mod">
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">
                  {dictionary['ISLAND-PAGE-PARTICPATE-title1']}
                </p>
                <button
                  className="mod-box-btn"
                  onClick={() => setModalTx(false)}
                >
                  X
                </button>
              </div>

              <div className="mod-inside">
                <div className="mod-inside-body">
                  <p>
                    {dictionary['ISLAND-PAGE-PARTICPATE-title2']}:<br />
                    <br />
                    <span>
                      <Link to={`/my-treasure/island?id=${currentId}#tx`}>
                        {dictionary['ISLAND-PAGE-PARTICPATE-title3']}
                      </Link>
                    </span>
                  </p>
                </div>
                <Flex x="center" gap="1">
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      setModalTx(false);
                    }}
                  >
                    {dictionary['ISLAND-PAGE-PARTICPATE-title4']}
                  </button>
                </Flex>
              </div>
            </div>
          </div>
          <div className="blur-bg" onClick={() => setModalTx(false)}></div>
        </Flex>
      )}
      {modalTopUp && (
        <Flex h="unset" x="center">
          <div className="mod">
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">
                  {dictionary['ISLAND-PAGE-PARTICPATE-title1']}
                </p>
                <button
                  className="mod-box-btn"
                  onClick={() => setModalTopUp(false)}
                >
                  X
                </button>
              </div>

              <div className="mod-inside">
                <div className="mod-inside-body">
                  <p>
                    {dictionary['ISLAND-PAGE-PARTICPATE-title5']}{' '}
                    <b>{input ? `$${numberWithCommas(input, 2)}` : '$0'}</b>{' '}
                    {dictionary['ISLAND-PAGE-PARTICPATE-title6']}
                  </p>
                </div>
                <Flex x="center" gap="1">
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      setModalTopUp(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      participateHandle(input);
                    }}
                  >
                    Yes
                  </button>
                </Flex>
              </div>
            </div>
          </div>
          <div className="blur-bg" onClick={() => setModalTopUp(false)}></div>
        </Flex>
      )}
      <div className="part-body">
        <div className="part-post-border">
          {!userHasParticipate ? (
            <div className="part-post">
              <div className="part-post-l">
                <div className="part-post-l-title">
                  {dictionary['ISLAND-PAGE-PARTICPATE-title1']}
                </div>

                <div className="part-post-l-row">
                  <div className="part-post-l-row-info">
                    <div className="part-post-l-row-info-title">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title7']}
                    </div>
                    <div className="part-post-l-row-info-value">
                      {islandParticipateDetail?.wallet_balance
                        ? `$${numberWithCommas(
                          islandParticipateDetail?.wallet_balance,
                          1
                        )}`
                        : '$0'}
                    </div>
                  </div>
                  <div className="part-post-l-row-info-parent">
                    <div className="part-post-l-row-info-date">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title8']}
                    </div>
                    <div className="part-post-l-row-info-date-value">
                      {islandParticipateDetail?.participate_deadline
                        ? islandParticipateDetail?.participate_deadline === -1
                          ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                          : timeToYYYYMMDD(
                            islandParticipateDetail?.participate_deadline
                          )
                        : ''}
                    </div>
                  </div>
                </div>

                <input
                  type="number"
                  className="part-post-l-row-middle"
                  value={input}
                  onChange={(e) =>
                    e.target.value !== ''
                      ? setInput(Number(e.target.value))
                      : setInput(e.target.value)
                  }
                />

                <div className="part-post-l-row-penult">
                  <div className="part-post-l-row-penult-parent">
                    <div className="part-post-l-row-penult-title">Min:</div>
                    <div className="part-post-l-row-penult-value">
                      {islandParticipateDetail?.min
                        ? `$${numberWithCommas(islandParticipateDetail?.min)}`
                        : 'Loading...'}
                    </div>
                    <div className="part-post-l-row-penult-currency">USD</div>
                  </div>
                  {islandUser && Number(islandUser?.is_visible) === 1 && (
                    <div className="part-post-l-row-penult-parent">
                      <div className="part-post-l-row-penult-title">Max:</div>
                      <div className="part-post-l-row-penult-value">
                        {islandUser?.max_particiapte
                          ? islandUser?.max_particiapte === -1
                            ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                            : `$${numberWithCommas(islandUser?.max_particiapte)}`
                          : '0'}
                      </div>
                    </div>
                  )}
                </div>

                <div className="part-post-l-row-last">
                  <div className="part-post-l-row-last-parent">
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        setInput(Number(islandParticipateDetail?.min))
                      }
                    >
                      MIN
                    </button>
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        typeof input === 'number'
                          ? setInput(input + 100)
                          : setInput(100)
                      }
                    >
                      +100
                    </button>
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        typeof input === 'number'
                          ? setInput(input + 500)
                          : setInput(500)
                      }
                    >
                      +500
                    </button>
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        typeof input === 'number'
                          ? setInput(input + 1000)
                          : setInput(1000)
                      }
                    >
                      +1000
                    </button>
                  </div>

                  <div className="part-post-l-row-last-parent">
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        typeof input === 'number'
                          ? setInput(input + 2500)
                          : setInput(2500)
                      }
                    >
                      +2500
                    </button>
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        typeof input === 'number'
                          ? setInput(input + 5000)
                          : setInput(5000)
                      }
                    >
                      +5000
                    </button>
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        typeof input === 'number'
                          ? setInput(input + 10000)
                          : setInput(10000)
                      }
                    >
                      +10000
                    </button>
                    <button
                      className="part-post-l-row-last-btn"
                      onClick={() =>
                        setInput(
                          Number(islandParticipateDetail?.wallet_balance)
                        )
                      }
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>

              <div className="part-post-r">
                <div className="part-post-r-title">
                  {dictionary['ISLAND-PAGE-PARTICPATE-title9']}
                </div>
                <div className="part-post-r-rows">
                  <div className="part-post-r-row">
                    <div className="part-post-r-row-title">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title10']}
                    </div>
                    <div className="part-post-r-row-value">
                      {islandParticipateDetail?.total_capacity == -1
                        ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                        : islandParticipateDetail?.total_capacity
                          ? `$${numberWithCommas(
                            islandParticipateDetail?.total_capacity
                          )}`
                          : '$0'}
                    </div>
                  </div>
                  {islandOverview?.total_invest_visible === 1 && (
                    <div className="part-post-r-row">
                      <div className="part-post-r-row-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title11']}
                      </div>
                      <div className="part-post-r-row-value">
                        {islandParticipateDetail?.total_available_capacity == -1
                          ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                          : islandParticipateDetail?.total_available_capacity
                            ? `$${numberWithCommas(
                              islandParticipateDetail?.total_available_capacity
                            )}`
                            : '$0'}
                      </div>
                    </div>
                  )}
                  <div className="part-post-r-row">
                    <div className="part-post-r-row-title">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title12']}
                    </div>
                    <div className="part-post-r-row-value">
                      {islandParticipateDetail?.end_date == -1
                        ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                        : islandParticipateDetail?.end_date
                          ? `${timestampToLocalDaily(
                            Number(islandParticipateDetail?.end_date) / 1000
                          )}, ${timestampToLocalHour(
                            Number(islandParticipateDetail?.end_date) / 1000
                          )}`
                          : '◾️◾️◾️'}
                    </div>
                  </div>
                  {islandParticipateDetail?.non_refundable == 0 && (
                    <div className="part-post-r-row">
                      <div className="part-post-r-row-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title13']}
                      </div>
                      <div className="part-post-r-row-value">
                        {islandParticipateDetail?.lock_period == -1
                          ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                          : `${islandParticipateDetail?.lock_period} Day${Number(islandParticipateDetail?.lock_period) > 1
                            ? 's'
                            : ''
                          }`}
                      </div>
                    </div>
                  )}
                  <div className="part-post-r-row">
                    <div className="part-post-r-row-title">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title14']}
                    </div>
                    <div className="part-post-r-row-value">
                      {islandParticipateDetail?.withdraw_frequency_type == 0 &&
                        dictionary['ISLAND-PAGE-PARTICPATE-title15']}
                      {islandParticipateDetail?.withdraw_frequency_type == 1 &&
                        dictionary['ISLAND-PAGE-PARTICPATE-title15']}
                      {islandParticipateDetail?.withdraw_frequency_type == 2 &&
                        `After, ${timestampToLocalDaily(
                          Number(islandParticipateDetail?.end_date) / 1000
                        )}, ${timestampToLocalHour(
                          Number(islandParticipateDetail?.end_date) / 1000
                        )}`}
                      {islandParticipateDetail?.withdraw_frequency_type == 3 &&
                        dictionary['ISLAND-PAGE-PARTICPATE-title16']}
                    </div>
                  </div>
                </div>
                <div className="part-post-r-br"></div>
                <div className="part-post-r-penult">
                  <div className="part-post-r-row">
                    <div className="part-post-r-row-title">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title17']}
                    </div>
                    <div className="part-post-r-row-value">
                      {islandParticipateDetail?.wallet_balance
                        ? `$${input.toString().trim()
                          ? numberWithCommas(input)
                          : '0'
                        }`
                        : '$0'}
                    </div>
                  </div>
                </div>

                {islandOverview &&
                  (Number(islandOverview?.deadline) === -1 ||
                    Number(islandOverview?.deadline) > +new Date()) ? (
                  <button
                    className="part-post-r-btn"
                    onClick={() => {
                      if (input < islandParticipateDetail?.min) {
                        return toast.error(
                          `You cannot participate with less than the minimum amount of $${numberWithCommas(
                            islandParticipateDetail?.min
                          )} on this island.`
                        );
                      } else if (
                        input > islandParticipateDetail?.wallet_balance
                      ) {
                        return toast.error(
                          dictionary['ISLAND-PAGE-PARTICPATE-title28']
                        );
                      } else if (
                        input >= islandParticipateDetail?.min &&
                        input <= islandParticipateDetail?.wallet_balance
                      ) {
                        setModalTopUp(true);
                      }
                    }}
                  >
                    {dictionary['ISLAND-PAGE-PARTICPATE-title18']}
                  </button>
                ) : (
                  <button
                    style={{
                      cursor: 'not-allowed',
                      opacity: '0.7'
                    }}
                    className="part-post-r-btn"
                    onClick={() =>
                      toast.error(dictionary['ISLAND-PAGE-PARTICPATE-title27'])
                    }
                  >
                    {dictionary['ISLAND-PAGE-PARTICPATE-title19']}
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>
              <div
                className="part-post"
                style={{
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <div
                  className="participate-overlay"
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: 'rgb(255 255 255 / 34%)',
                    backdropFilter: 'blur(3px)'
                  }}
                ></div>
                <div className="part-post-l">
                  <div className="part-post-l-title">
                    {dictionary['ISLAND-PAGE-PARTICPATE-title1']}
                  </div>

                  <div className="part-post-l-row">
                    <div className="part-post-l-row-info">
                      <div className="part-post-l-row-info-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title7']}
                      </div>
                      <div className="part-post-l-row-info-value">
                        {islandParticipateDetail?.wallet_balance
                          ? `$${numberWithCommas(
                            islandParticipateDetail?.wallet_balance,
                            1
                          )}`
                          : '$0'}
                      </div>
                    </div>
                    <div className="part-post-l-row-info-parent">
                      <div className="part-post-l-row-info-date">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title8']}
                      </div>
                      <div className="part-post-l-row-info-date-value">
                        {islandParticipateDetail?.participate_deadline
                          ? islandParticipateDetail?.participate_deadline === -1
                            ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                            : timeToYYYYMMDD(
                              islandParticipateDetail?.participate_deadline
                            )
                          : ''}
                      </div>
                    </div>
                  </div>

                  <input
                    type="number"
                    className="part-post-l-row-middle"
                    value={input}
                    onChange={(e) =>
                      e.target.value !== ''
                        ? setInput(Number(e.target.value))
                        : setInput(e.target.value)
                    }
                  />

                  <div className="part-post-l-row-penult">
                    <div className="part-post-l-row-penult-parent">
                      <div className="part-post-l-row-penult-title">Min:</div>
                      <div className="part-post-l-row-penult-value">
                        {islandParticipateDetail?.min
                          ? `$${numberWithCommas(islandParticipateDetail?.min)}`
                          : 'Loading...'}
                      </div>
                      <div className="part-post-l-row-penult-currency">USD</div>
                    </div>
                    {islandUser && Number(islandUser?.is_visible) === 1 && (
                      <div className="part-post-l-row-penult-parent">
                        <div className="part-post-l-row-penult-title">Max:</div>
                        <div className="part-post-l-row-penult-value">
                          {islandUser?.max_particiapte
                            ? islandUser?.max_particiapte === -1
                              ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                              : `$${numberWithCommas(
                                islandUser?.max_particiapte
                              )}`
                            : '0'}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="part-post-l-row-last">
                    <div className="part-post-l-row-last-parent">
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          setInput(Number(islandParticipateDetail?.min))
                        }
                      >
                        MIN
                      </button>
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          typeof input === 'number'
                            ? setInput(input + 100)
                            : setInput(100)
                        }
                      >
                        +100
                      </button>
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          typeof input === 'number'
                            ? setInput(input + 500)
                            : setInput(500)
                        }
                      >
                        +500
                      </button>
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          typeof input === 'number'
                            ? setInput(input + 1000)
                            : setInput(1000)
                        }
                      >
                        +1000
                      </button>
                    </div>

                    <div className="part-post-l-row-last-parent">
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          typeof input === 'number' && setInput(input + 2500)
                        }
                      >
                        +2500
                      </button>
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          typeof input === 'number' && setInput(input + 5000)
                        }
                      >
                        +5000
                      </button>
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          typeof input === 'number' && setInput(input + 10000)
                        }
                      >
                        +10000
                      </button>
                      <button
                        className="part-post-l-row-last-btn"
                        onClick={() =>
                          setInput(
                            Number(islandParticipateDetail?.wallet_balance)
                          )
                        }
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>

                <div className="part-post-r">
                  <div className="part-post-r-title">
                    {dictionary['ISLAND-PAGE-PARTICPATE-title9']}
                  </div>
                  <div className="part-post-r-rows">
                    <div className="part-post-r-row">
                      <div className="part-post-r-row-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title10']}
                      </div>
                      <div className="part-post-r-row-value">
                        {islandParticipateDetail?.total_capacity
                          ? `$${numberWithCommas(
                            islandParticipateDetail?.total_capacity
                          )}`
                          : '$0'}
                      </div>
                    </div>
                    {islandOverview?.total_invest_visible === 1 && (
                      <div className="part-post-r-row">
                        <div className="part-post-r-row-title">
                          {dictionary['ISLAND-PAGE-PARTICPATE-title11']}
                        </div>
                        <div className="part-post-r-row-value">
                          {islandParticipateDetail?.total_available_capacity ==
                            -1
                            ? dictionary['ISLAND-PAGE-PARTICPATE-title26']
                            : islandParticipateDetail?.total_available_capacity
                              ? `$${numberWithCommas(
                                islandParticipateDetail?.total_available_capacity
                              )}`
                              : '$0'}
                        </div>
                      </div>
                    )}
                    <div className="part-post-r-row">
                      <div className="part-post-r-row-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title12']}
                      </div>
                      <div className="part-post-r-row-value">
                        {islandParticipateDetail?.end_date
                          ? timeToYYYYMMDD(islandParticipateDetail?.end_date)
                          : ''}
                      </div>
                    </div>
                    {islandParticipateDetail?.non_refundable == 0 && (
                      <div className="part-post-r-row">
                        <div className="part-post-r-row-title">
                          {dictionary['ISLAND-PAGE-PARTICPATE-title13']}
                        </div>
                        <div className="part-post-r-row-value">
                          {islandParticipateDetail?.lock_period
                            ? timeToYYYYMMDD(
                              islandParticipateDetail?.lock_period
                            )
                            : ''}
                        </div>
                      </div>
                    )}
                    <div className="part-post-r-row">
                      <div className="part-post-r-row-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title14']}
                      </div>

                      <div className="part-post-r-row-value">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title21']}
                      </div>
                    </div>
                  </div>
                  <div className="part-post-r-br"></div>
                  <div className="part-post-r-penult">
                    <div className="part-post-r-row">
                      <div className="part-post-r-row-title">
                        {dictionary['ISLAND-PAGE-PARTICPATE-title17']}
                      </div>
                      <div className="part-post-r-row-value">
                        {islandParticipateDetail?.wallet_balance
                          ? `$${numberWithCommas(input)}`
                          : '$0'}
                      </div>
                    </div>
                  </div>
                  {islandOverview &&
                    (Number(islandOverview?.deadline) === -1 ||
                      Number(islandOverview?.deadline) > +new Date()) ? (
                    <button
                      className="part-post-r-btn"
                      onClick={() => {
                        if (input < islandParticipateDetail?.min) {
                          toast.error(
                            `You cannot participate with less than the minimum amount of $${numberWithCommas(
                              islandParticipateDetail?.min
                            )} on this island.`
                          );
                        } else {
                          setModalTopUp(true);
                        }
                      }}
                    >
                      {dictionary['ISLAND-PAGE-PARTICPATE-title18']}
                    </button>
                  ) : (
                    <button className="part-post-r-btn">
                      {dictionary['ISLAND-PAGE-PARTICPATE-title19']}
                    </button>
                  )}
                </div>
              </div>
              <div
                className="center"
                style={{
                  margin: '1.9rem 0 0px'
                }}
              >
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    margin: '2.4rem 0 1rem',
                    textAlign: 'center',
                    color: '#0E608B'
                  }}
                >
                  {islandOverview &&
                    (Number(islandOverview?.deadline) === -1 ||
                      Number(islandOverview?.deadline) > +new Date())
                    ? dictionary['ISLAND-PAGE-PARTICPATE-title22']
                    : dictionary['ISLAND-PAGE-PARTICPATE-title23']}
                </p>
                {islandOverview &&
                  (Number(islandOverview?.deadline) === -1 ||
                    Number(islandOverview?.deadline) > +new Date()) && (
                    <Link to={`/my-treasure/island?id=${currentId}`}>
                      <button
                        className="part-post-r-btn"
                        style={{
                          zIndex: 10,
                          width: '100%'
                        }}
                      >
                        {dictionary['ISLAND-PAGE-PARTICPATE-title24']}
                      </button>
                    </Link>
                  )}
              </div>
            </>
          )}
        </div>
        <div className="part-note">
          {dictionary['ISLAND-PAGE-PARTICPATE-title25']}
          <div className="part-note-br"></div>
        </div>
        <div className="part-note-grid">
          {islandParticipateDetail &&
            islandParticipateDetail?.participte_description && (
              <div
                className="part-note-post"
                dangerouslySetInnerHTML={{
                  __html: islandParticipateDetail?.participte_description
                }}
                style={{
                  wordBreak: 'break-word',
                  lineHeight: 1.9,
                  fontSize: '1.6rem'
                }}
              ></div>
            )}
        </div>
      </div>
    </>
  );
};

export default Participate;
