import { useContext, useEffect, useState } from 'react';
import Pagination from 'react-responsive-pagination';
import { Link } from 'react-router-dom';
import borderGray from '../../src/assets/images/border-gradiant-gray.svg';
import borderOrange from '../../src/assets/images/border-gradiant-orange.svg';
import borderPurple from '../../src/assets/images/border-gradiant-purple.svg';
import MapContext from '../context/private/mapContext';
import PrivateContext from '../context/private/privateContext';
import { Container, Flex } from '../styles/Styles';
import { numberWithCommas, timeToYYYYMMDD } from '../utils/tools';
import NotFoundImage from './../assets/images/not-found.png';
import { BalanceChartDaily } from './BalanceChartDaily';
import { BalanceChartMonthly } from './BalanceChartMonthly';
import Header from './Header';
import { LoaderMini } from './Loader';
import { ProfitChartDaily } from './ProfitChartDaily';
import { ProfitChartMonthly } from './ProfitChartMonthly';
import TooltipInfo from './TooltipInfo';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import dictionary from '../locals/expiration.json';
import AccountContext from '../context/private/accountContext';
import MembershipYearly from './MembershipYearly';
import Pending from './AccountSetup/boxes/pending';

const Expiration = (): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);
  const {
    balanceChartDailyData,
    balanceChartMonthlyData,
    mapData,
    loadMyMap,
    logData,
    loadLogs,
    loadProfitChart,
    loadBalanceChart,
    profitChartDailyData,
    profitChartMonthlyData
  } = useContext(MapContext);

  const { agreementModalData, submitAgreementForm, loadAgreementModal } =
    useContext(PrivateContext);

  const [balanceChart, setBalanceChart] = useState('yearly');
  const [profitChart, setProfitChart] = useState('yearly');

  const { flowState } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    if (flowState.step === 17) {
      loadMyMap();
      loadLogs(1);
      loadProfitChart('yearly');
      loadBalanceChart('yearly');
    }
  }, [flowState]);

  useEffect(() => {
    if (profitChart === 'monthly') {
      loadProfitChart('monthly');
    }
  }, [profitChart]);

  useEffect(() => {
    if (balanceChart === 'monthly') {
      loadBalanceChart('monthly');
    }
  }, [balanceChart]);

  const [currentPage1, setCurrentPage1] = useState(0);
  useEffect(() => {
    loadLogs(currentPage1);
  }, [currentPage1]);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [currentPage1, balanceChart, profitChart]);

  return (
    <Container
      x={'center'}
      marginT={1}
      style={{
        display: 'table !important'
      }}
    >
      <Flex className="flex-mymap" h="unset" gap={1.7} x={'center'} y={'start'}>
        <Header title={dictionary['PAGE-title']} />

        <div className="flex items-center justify-center w-fit m-auto">
          {userInfo?.pendingMembershipRequest ? <Pending /> : <MembershipYearly />}
        </div>

      </Flex>
    </Container>
  );
};

export default Expiration;
