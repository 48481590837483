import { useContext } from "react"
import WizardContext from "../../../context/private/wizardContext"
import { Flex } from "../../../styles/Styles"

const Premium = () => {
    const { finalStepToStartMyMap } = useContext(WizardContext)

    return (
        <Flex gap={1.7} x={'center'} y={'center'} className="pend-flex">
            <div className="pend-body">
                <div className="pend-head">
                    <img src="/images/premium.svg" alt="wine" />
                </div>
                <div className="pend-title">BOS Annual Membership Subscription</div>
                <p className="pend-p">
                    Congratulations, your account has been successfully upgraded
                    to member level.{' '}
                </p>
                {/* <div className="pend-row">
                    <p className="pend-row-title">Expire date</p>
                    <p className="pend-row-value">2023/01/18</p>
                </div> */}
                <button className="pend-btn" onClick={() => finalStepToStartMyMap()}>Done</button>
            </div>
        </Flex>
    )
}

export default Premium