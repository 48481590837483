import { BarResponse } from "./steps.enum"

const BetweenBar = ({ isDone }: { isDone: BarResponse }): React.ReactElement => {
    return (
        <div className="between-bar">
            {isDone > -1 && <div className={`proccess ${isDone && "complete"}`}></div>}
        </div>
    )
}

export default BetweenBar