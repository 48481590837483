import { useContext } from "react";
import PrivateContext from "../context/private/privateContext";
import logo from ".//../assets/images/bos-logo.png";
import Copyright from "./CopyrightPrivate";
import Menu from "./Menu";

const Aside = (): JSX.Element => {
  const { flowState, isOpen, setIsOpen } = useContext(PrivateContext)

  return (
    <>
      <div className={isOpen ? "sidebar open" : "sidebar"} id="MainSidebar">

        <button onClick={() => setIsOpen(false)} className="close-nav-mobile" />
        <div className="sidebar-header">
          <img src={logo} alt="sample" />
        </div>

        <div className="sidebar-content">
          <Menu step={flowState.menu} />
        </div>

        <Copyright />
      </div>
      {isOpen ? <div className="overlay-menu" onClick={() => setIsOpen(false)}></div> : null}
    </>
  );
};

export default Aside;
