import { useContext } from 'react';
import IslandsContext from '../../../context/private/islandsContext';
import Conditions from './conditions';
import Explorer from './explorer';
import Overview from './overview';
import Participate from './participate';
import Updates from './updates';
import { IslandsItemContext } from '../Index';
import Faqs from './faqs.jsx';

const Contents = () => {
  const { currentTab } = useContext(IslandsItemContext)

  switch (currentTab) {
    case 0:
      return <Overview />
    case 1:
      return <Explorer />
    case 2:
      return <Participate />
    case 3:
      return <Updates />
    case 4:
      return <Conditions />
    case 5:
      return <Faqs />

    default:
      return <></>
  }
}

export default Contents