import { createContext, useContext, useEffect, useState } from 'react';
import IslandsContext from '../../context/private/islandsContext';
import PrivateContext from '../../context/private/privateContext';
import { Container, Flex } from '../../styles/Styles';
import Header from '../Header';
import Layout from '../layout/Index';
import Loader from '../Loader';
import Contents from './contents';
import Tabs from './tabs/tabs';
import { useSearchParams } from 'react-router-dom';
import { IslandParticipateModal } from '../Modal/IslandParticipate';
import AccountContext from '../../context/private/accountContext';

export const IslandsItemContext = createContext<any>({});

const Island = (): JSX.Element => {
  const { flowState, setCurrentSelectedIslandId, setUpdateTimer } = useContext(PrivateContext);
  const { loadOverview } = useContext(IslandsContext);
  const { userInfo } = useContext(AccountContext);

  const [islandOverview, setislandOverview] = useState<any>(null)
  const [islandUser, setIslandUser] = useState<any>(null)
  const [islandCrew, setIslandCrew] = useState<any>(null)
  const [islandClaimWindow, setIslandClaimWindow] = useState<any>(null)

  const [currentTab, setCurrentTab] = useState<any>(null)
  const [currentId, setCurrentId] = useState<any>(-1)

  useEffect(() => {
    if (flowState.step === 17) {
      loadOverview(currentId, setislandOverview, setIslandUser, setIslandCrew, setIslandClaimWindow)
    }
  }, [currentId]);


  const [searchParams] = useSearchParams()
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.location.href.includes('?id=')) {
      const id = searchParams.get('id')
      setCurrentSelectedIslandId(id);
      setCurrentId(Number(id))
    }
  }, []);

  const tabs = ['overview', 'explore', 'participate', 'updates', 'conditions', 'faq']
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get('tab');

    setCurrentTab(tabs.indexOf(tabParam ? tabParam?.toLowerCase() : '') != -1 ? tabs.indexOf(tabParam ? tabParam?.toLowerCase() : '') : 0)
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('tab', tabs[currentTab]);

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    setUpdateTimer(+new Date)
  }, [currentTab]);

  const context = { currentId, islandOverview, islandUser, islandCrew, islandClaimWindow, currentTab, setCurrentTab, searchParams }
  return (
    <Layout className="over">
      <Container x={'center'} y={'center'} className="container over-container">
        <Flex gap={1.7} x={'center'} className="over-flex">
          <IslandsItemContext.Provider value={context}>
            <div className="over-all">
              <div className="over-header">
                <Header title={islandOverview?.title} />
                <Tabs />
              </div>
              <Contents />
              {islandOverview ? <IslandParticipateModal
                islandOverview={islandOverview}
                setIslandOverview={setislandOverview}
              /> : <></>}
            </div>
          </IslandsItemContext.Provider>
        </Flex>
      </Container>
    </Layout>
  );

};

export default Island;
