import { createContext, useContext, useEffect, useState } from 'react';
import mapService from '../../services/mapService';
import PrivateContext from './privateContext';
import toast from 'react-hot-toast';
const MapContext = createContext<any>({});

export const MapContextProvider = ({ children }: JSX.Element | any) => {
  const [locker, setLocker] = useState(false)

  const [mapData, setMapData] = useState<any>(null)
  async function loadMyMap() {
    await mapService.getAll().then(data => setMapData(data))
  }

  const [logData, setLogData] = useState<any>(null)
  async function loadLogs(page: any) {
    await mapService.getLogs(page).then(data => setLogData(data))
  }

  const [agreementModalData, setAgreementModalData] = useState<any>(null)
  async function loadAgreementModal() {
    await mapService.getAgreements().then(data => setAgreementModalData(data))
  }

  async function submitAgreementForm(id: any, type: any, refetch?: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        mapService.submitAgreement(id, type),
        {
          loading: 'Changing...',
          success: (data: any) => {
            setLocker(false);
            refetch && loadAgreementModal();

            return type == 1 ? `Updated Successfully.` : <p>{`Thank you for your acknowledgment. We have recorded this in your Member Log, located in your BOS Profile. You can view the log by clicking the link below`} <br /> <a href='/logs'>{'View Link'}</a></p>;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [balanceChartDailyData, setBalanceChartDailyData] = useState<any>(null)
  const [balanceChartMonthlyData, setBalanceChartMonthlyData] = useState<any>(null)
  async function loadBalanceChart(type: 'yearly' | 'monthly') {
    type === 'monthly' && await mapService.getBalanceChart(type).then(data => setBalanceChartDailyData(data))
    type === 'yearly' && await mapService.getBalanceChart(type).then(data => setBalanceChartMonthlyData(data))
  }

  const [profitChartDailyData, setProfitChartDailyData] = useState<any>(null)
  const [profitChartMonthlyData, setProfitChartMonthlyData] = useState<any>(null)
  async function loadProfitChart(type: 'yearly' | 'monthly') {
    type === 'monthly' && await mapService.getProfitChart(type).then(data => setProfitChartDailyData(data))
    type === 'yearly' && await mapService.getProfitChart(type).then(data => setProfitChartMonthlyData(data))
  }

  const context = {
    mapData, loadMyMap,

    logData, loadLogs,

    agreementModalData, loadAgreementModal, submitAgreementForm,

    balanceChartDailyData, balanceChartMonthlyData, loadBalanceChart,

    profitChartDailyData, profitChartMonthlyData, loadProfitChart
  };

  return (
    <MapContext.Provider value={context}>
      {children}
    </MapContext.Provider>
  );
};

export default MapContext;