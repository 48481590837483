import { ApplicationForm, MembershipFeeCryptoForm, MembershipFeeCryptoPropertyValidation, MembershipFeeWireForm, MembershipFeeWirePropertyValidation, OthersRegisterPendingPropertyValidation, PersonalRegisterPendingPropertyValidation, TrustRegisterPendingPropertyValidation } from '../types/wizardTypes';
const Joi = require("joi-browser");

class WizardValidation {

    // Application Form
    _personalRegisterPendingSchema = {
        user_type: Joi.any().required().label("User Type"),

        first_name: Joi.string().min(2).max(30).required().label("First Name"),
        middle_name: Joi.string().optional().allow('').max(20).label("Mid Name"),
        last_name: Joi.string().min(2).max(30).required().label("Last Name"),

        beneficiary_name: Joi.any().optional().allow(''),
        beneficiary_email: Joi.any().optional().allow(''),

        street_address: Joi.string().required().max(100).label("Address 1"),
        street_address2: Joi.string().optional().allow('').max(100).label("Address 2"),
        city: Joi.string().required().label("City"),
        country_id: Joi.string().not().empty().required().label("Country"),
        state_provence: Joi.string().required().label("State / Province"),
        zip_code: Joi.string().min(1).max(20).required().label("Zip Code"),

        telegram_username: Joi.string().min(3).max(30).optional().allow('').label("Telegram username"),

        front: Joi.any().optional().allow(''),
        back: Joi.any().optional().allow(''),
        proof: Joi.any().optional().allow(''),

        social_x_link: Joi.any().optional().allow(''),
        social_linkdin_link: Joi.any().optional().allow(''),
        social_facebook_link: Joi.any().optional().allow(''),
        second_register: Joi.any().optional().allow(''),
        organization_city: Joi.any().optional().allow(''),
        organization_country_id: Joi.any().optional().allow(''),
        organization_state: Joi.any().optional().allow(''),
        organization_zip_code: Joi.any().optional().allow(''),
        organization_name: Joi.string().optional().allow(''),
        organization_street_address: Joi.string().optional().allow(''),
        organization_street_address_2: Joi.string().optional().allow(''),
        organization_identification: Joi.string().optional().allow(''),
        birthday: Joi.string().optional().allow(''),
        home_address: Joi.string().optional().allow(''),


    }

    _trustRegisterPendingSchema = {
        // organization_name: Joi.string().required().label("Organization Name"),
        // organization_street_address: Joi.string().required().label("Organization Address"),
        // organization_street_address_2: Joi.string().label("Organization Address"),
        // organization_city: Joi.string().required().label("Organization City"),
        // organization_country_id: Joi.string().required().label("Organization Country"),
        // organization_state: Joi.string().required().label("Organization State"),
        // organization_zip_code: Joi.string().required().label("Organization Zip Code"),
        social_x_link: Joi.any().optional().allow(''),
        social_linkdin_link: Joi.any().optional().allow(''),
        social_facebook_link: Joi.any().optional().allow(''),
        second_register: Joi.any().optional().allow(''),
        organization_city: Joi.any().optional().allow(''),
        organization_country_id: Joi.any().optional().allow(''),
        organization_state: Joi.any().optional().allow(''),
        organization_zip_code: Joi.any().optional().allow(''),
        organization_name: Joi.string().optional().allow(''),
        organization_street_address: Joi.string().optional().allow(''),
        organization_street_address_2: Joi.string().optional().allow(''),

        organization_identification: Joi.any().optional().allow(''),
        country_id: Joi.any().optional().allow(''),
        birthday: Joi.any().optional().allow(''),
        home_address: Joi.any().optional().allow(''),
        user_type: Joi.any().optional().allow(''),
        first_name: Joi.any().optional().allow(''),
        middle_name: Joi.any().optional().allow(''),
        last_name: Joi.any().optional().allow(''),
        beneficiary_name: Joi.any().optional().allow(''),
        beneficiary_email: Joi.any().optional().allow(''),
        street_address: Joi.any().optional().allow(''),
        street_address2: Joi.any().optional().allow(''),
        city: Joi.any().optional().allow(''),
        state_provence: Joi.any().optional().allow(''),
        zip_code: Joi.any().optional().allow(''),
        telegram_username: Joi.any().optional().allow(''),
        front: Joi.any().optional().allow(''),
        back: Joi.any().optional().allow(''),
        proof: Joi.any().optional().allow(''),
    }

    _othersRegisterPendingSchema = {
        organization_identification: Joi.string().required().label("Organization Identification"),

        social_x_link: Joi.any().optional().allow(''),
        social_linkdin_link: Joi.any().optional().allow(''),
        social_facebook_link: Joi.any().optional().allow(''),
        second_register: Joi.any().optional().allow(''),
        organization_city: Joi.any().optional().allow(''),
        organization_country_id: Joi.any().optional().allow(''),
        organization_state: Joi.any().optional().allow(''),
        organization_zip_code: Joi.any().optional().allow(''),
        organization_name: Joi.any().optional().allow(''),
        organization_street_address: Joi.any().optional().allow(''),
        organization_street_address_2: Joi.any().optional().allow(''),
        country_id: Joi.any().optional().allow(''),
        birthday: Joi.any().optional().allow(''),
        home_address: Joi.any().optional().allow(''),
        user_type: Joi.any().optional().allow(''),
        first_name: Joi.any().optional().allow(''),
        middle_name: Joi.any().optional().allow(''),
        last_name: Joi.any().optional().allow(''),
        beneficiary_name: Joi.any().optional().allow(''),
        beneficiary_email: Joi.any().optional().allow(''),
        street_address: Joi.any().optional().allow(''),
        street_address2: Joi.any().optional().allow(''),
        city: Joi.any().optional().allow(''),
        state_provence: Joi.any().optional().allow(''),
        zip_code: Joi.any().optional().allow(''),
        telegram_username: Joi.any().optional().allow(''),
        front: Joi.any().optional().allow(''),
        back: Joi.any().optional().allow(''),
        proof: Joi.any().optional().allow(''),
    }

    // Pay Membership Fee
    _cryptoMembershipFeeSchema = {
        txHash: Joi.string().min(30).required().label("Transaction Hash"),
        token: Joi.any().required().label("Currency"),
        transfer_date: Joi.string().required().label("Transfer Date"),
        type: Joi.number().required().label("Type"),
    }

    _wireMembershipFeeSchema = {
        account_name: Joi.string().required().label("Account Name"),
        account_number: Joi.number().required().label("Account Number"),

        bank_name: Joi.string().required().label("Bank Name"),
        transfer_date: Joi.string().optional().allow('').required().label("Transfer Date"),
        expected_date: Joi.string().optional().allow('').required().label("Expected Date"),

        type: Joi.number().required().label("Type"),

        transaction_document: Joi.any().required().label("Transaction Document"),
        transaction_document_2: Joi.any().required().label("Document 1"),
        transaction_document_3: Joi.any().required().label("Document 2"),
        txHash: Joi.string().optional().allow('').required().label("Transaction Hash"),
    }


    // Application Form
    personalRegisterPending(formData: ApplicationForm) {
        const result = Joi.validate(formData, this._personalRegisterPendingSchema, { abortEarly: false })

        const errors: any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    personalRegisterPendingProperty(name: PersonalRegisterPendingPropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._personalRegisterPendingSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }


    trustRegisterPending(formData: ApplicationForm) {
        const result = Joi.validate(formData, this._trustRegisterPendingSchema, { abortEarly: false })

        const errors: any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    trustRegisterPendingProperty(name: TrustRegisterPendingPropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._trustRegisterPendingSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }


    othersRegisterPending(formData: ApplicationForm) {
        const result = Joi.validate(formData, this._othersRegisterPendingSchema, { abortEarly: false })

        const errors: any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    othersRegisterPendingProperty(name: OthersRegisterPendingPropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._othersRegisterPendingSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }

    // Pay Membership Fee
    membershipFeeWire(formData: MembershipFeeWireForm) {
        const result = Joi.validate(formData, this._wireMembershipFeeSchema, { abortEarly: false })

        const errors: any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    membershipFeeWirePendingProperty(name: MembershipFeeWirePropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._wireMembershipFeeSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }


    membershipFeeCrypto(formData: MembershipFeeCryptoForm) {
        const result = Joi.validate(formData, this._cryptoMembershipFeeSchema, { abortEarly: false })

        const errors: any = {}
        if (result.error) {
            for (const item of result?.error?.details) errors[item.path[0]] = item.message
        }

        return errors
    }

    membershipFeeCryptoPendingProperty(name: MembershipFeeCryptoPropertyValidation, value: string) {
        const obj = { [name]: value };
        const schema = { [name]: this._cryptoMembershipFeeSchema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }
}

export default new WizardValidation()


