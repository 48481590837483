
const Tab = ({ title, currentTab, setCurrentTab, index }: { title: string, currentTab: number, setCurrentTab: (value: number) => void, index: number }): JSX.Element => {
  return (
    <>
      {index === 3 ?

        <button
          className={"over-nav-btn pointer"}
          onClick={() => setCurrentTab(0)}>{title}</button>
        :
        <button
          className={currentTab === index ? "over-nav-btn-deac pointer" : "over-nav-btn pointer"}
          onClick={() => setCurrentTab(index)}>{title}</button>
      }
    </>
  )
}

export default Tab