import axios, { AxiosHeaders } from 'axios';
import config from '../config';
import { UAParser } from 'ua-parser-js';

const { tokenKey } = config.service;
const getToken = (): string | null => localStorage.getItem(tokenKey);
const getUser = (): string | null | any => localStorage.getItem('user');
const getIp = (): string | null => sessionStorage.getItem('ip');

const HttpService = (slug: string) => {
  const http = axios.create({
    baseURL: config.service.baseUrl + slug
  });
  http.interceptors.request.use((req) => {
    const validToken = getToken();
    if (validToken && req.headers) {
      (req.headers as AxiosHeaders).set(
        'Authorization',
        'bearer ' + validToken
      );
    }
    return req;
  });

  http.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      // console.log('An unexpected error occurred.', error);
    }
    return Promise.reject(error);
  });

  return {
    get: http.get,
    post: http.post,
    put: http.put,
    delete: http.delete
  };
};
export const HttpServiceImages = (slug: string) => {
  const http = axios.create({
    baseURL: config.server.slice(0, config.server.length - 1) + slug,
    responseType: 'blob'
  });
  http.interceptors.request.use((req) => {
    const validToken = getToken();
    if (validToken && req.headers) {
      (req.headers as AxiosHeaders).set(
        'Authorization',
        'bearer ' + validToken
      );
    }
    return req;
  });

  http.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      // console.log('An unexpected error occurred.', error);
    }
    return Promise.reject(error);
  });

  return {
    get: http.get,
    post: http.post,
    put: http.put,
    delete: http.delete
  };
};

const httpServiceError = HttpService('Error');

export const reporter = async (response: any, ex = null) => {
  let parser = new UAParser();
  const info = parser.getResult();
  // axios
  //   .post('https://checker.app-test.live/error', {
  //     project: "User Panel",
  //     error: response.data,
  //     username: getUser(),
  //     page: window.location.href,
  //     payload: response.config.data,
  //     apiAddress: `${response.config.baseURL}${response.config.url}`,
  //     apiStatus: response.status,
  //     userOS:
  //       window.navigator && window.navigator?.userAgentData
  //         ? window.navigator?.userAgentData?.platform
  //         : window.navigator.userAgent,
  //     userIP: `Removed, ${config.version}`,
  //     userBrowser: info.browser.name,
  //     userBrowserVersion: info.browser.version,
  //     userDevice: `${info.device.vendor} (${info.device.model})`,
  //     userOSDetails: `${info.os.name} (${info.os.version})`
  //   })
  //   .then();
};

export const catchReporter = async (response: any, ex = null) => {
  let parser = new UAParser();
  const info = parser.getResult();

  // axios
  //   .post('https://checker.app-test.live/catch-checker', {
  //     project: "User Panel",
  //     error: response,
  //     username: getUser(),
  //     page: window.location.href,
  //     userOS:
  //       window.navigator && window.navigator?.userAgentData
  //         ? window.navigator?.userAgentData?.platform
  //         : window.navigator.userAgent,
  //     userIP: `Removed, ${config.version}`,
  //     userBrowser: info.browser.name,
  //     userBrowserVersion: info.browser.version,
  //     userDevice: `${info.device.vendor} (${info.device.model})`,
  //     userOSDetails: `${info.os.name} (${info.os.version})`
  //   })
  //   .then();
};

export const checkMaintenance = async (response: any) => {
  if (response?.data?.error === "System is under maintenance") {
    localStorage.clear()
    window.location.reload()
  }
}

export default HttpService;
