import React, { useState, useEffect, useRef, DragEvent, ChangeEvent } from 'react';
import FileInput from '../FileInput';
import './DropZone.scss';

const getFilesFromEvent = (
  event: DragEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
): File[] => {
  let items = null;

  if ('dataTransfer' in event) {
    const dt = event.dataTransfer;

    // NOTE: Only the 'drop' event has access to DataTransfer.files, otherwise it will always be empty
    if ('files' in dt && dt.files.length) {
      items = dt.files;
    } else if (dt.items && dt.items.length) {
      items = dt.items;
    }
  } else if (event.target && event.target.files) {
    items = event.target.files;
  }

  return Array.prototype.slice.call(items) as File[];
};

const DropZone: React.FC<any> = ({ children, onDropFiles, files }: any) => {
  const [dragging, setDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const divElement = useRef<HTMLDivElement>(null);

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setDragging(false);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const chosenFiles = getFilesFromEvent(e);
    onDropFiles(chosenFiles);
  };

  const handleFileEvent = async (e: ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = getFilesFromEvent(e);
    onDropFiles(chosenFiles);
  };

  useEffect(() => {
    const div = divElement.current;

    if (div) {
      div.addEventListener('dragenter', handleDragIn as unknown as EventListener);
      div.addEventListener('dragleave', handleDragOut as unknown as EventListener);
      div.addEventListener('dragover', handleDrag as unknown as EventListener);
      div.addEventListener('drop', handleDrop as unknown as EventListener);

      // Cleanup
      return () => {
        div.removeEventListener('dragenter', handleDragIn as unknown as EventListener);
        div.removeEventListener('dragleave', handleDragOut as unknown as EventListener);
        div.removeEventListener('dragover', handleDrag as unknown as EventListener);
        div.removeEventListener('drop', handleDrop as unknown as EventListener);
      };
    }
  }, [divElement, handleDragIn, handleDragOut, handleDrag, handleDrop]);

  return (
    <div className="drop-zone" ref={divElement}>
      <FileInput onFiles={handleFileEvent} hasFiles={files.length !== 0} />
      {dragging && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          >
            <div>drop here :)</div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default DropZone;
