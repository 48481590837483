import { useContext } from "react"
import WizardContext from "../../../context/private/wizardContext"
import { Flex } from "../../../styles/Styles"

const Membership = () => {
    const { setModal } = useContext(WizardContext)
    return (
        <Flex y="center">
            <div className="set-fin">
                <div className="set-border">
                    <img src="/images/IDCard.svg" alt="IDCard" className="set-img" />
                    <div className="set-title">Membership Required</div>
                    <div className="set-info">
                    Congratulations on finishing your Blue Ocean Society account creation steps! You are just one step away from accessing the BOS dashboard. Your access to the dashboard will become available once the annual membership fee has been successfully processed.
                        <br /><br />
                        If you have any more questions, don't hesitate to reach out to us at hello@blueoceansociety.club". 
                        <br /><br />
                        Kindly use the provided link to view the payment options, and submit your deposit notification form after initiating your transaction.
                    </div>
                    <button className="set-mem-mid-btn pointer" onClick={() => setModal(true)}>Payment Details</button>
                </div>
            </div>
        </Flex>
    )
}

export default Membership