import { SophisticateForm } from "."
import Layout from "./layout/Index"

const SophisticateView = () => {
  return (
    <Layout className="parent">
      <SophisticateForm />
    </Layout>
  )
}

export default SophisticateView