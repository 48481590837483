import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { nFormatter } from '../utils/tools';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    bezierCurve: true,
    plugins: {
        legend: {
            display: true,
        },
        filler: {
            propagate: false
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += '' + context.parsed.y.toLocaleString();
                    return label;
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                callback: function(value) {
                    return nFormatter(value);
                }
            }
        }
    }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const up = (ctx, value) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;
const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

let width, height, gradient;
export const getGradient = (ctx, chartArea) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, "#153458");
        gradient.addColorStop(0.5, "#2388CE");
        gradient.addColorStop(0, "#E7F5FF");
    }

    return gradient;
}

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [4, 5, 6, 9, 5, 5, 3],
            borderColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                ctx.shadowColor = '#a0d8ff';
                ctx.shadowBlur = 16;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 18;
                if (!chartArea) {
                    return;
                }
                return getGradient(ctx, chartArea);
            },
            borderWidth: 7,
            fill: true,
            tension: 0.45,
            pointRadius: 1,
            borderRadius: 8,
            borderSkipped: false,
        },
    ],
};

export function UserChartMonthly({ data }) {
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const convertedTime = []
    data?.timstamp.map(t => {
        const tempDate = new Date(Number(t))
        convertedTime.push(monthNames[tempDate.getMonth()])
    })
    
    const chartData = {
        labels: convertedTime,
        datasets: [
            {
                label: 'User',
                data: data?.user,
                borderColor: '#5c23ce',
                borderWidth: 3,
                fill: true,
                tension: 0.45,
                pointRadius: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
        ],
    };

    return <Line options={options} data={chartData} />;
}
