import HttpService, { catchReporter, reporter } from './httpService';

class SettingService {
    private _httpService = HttpService('Setting');

    async getMaintenanceStatus() {
        try {
            const response = await this._httpService.get<any>('/Maintenance/Status');
            if (response.data.status) return response?.data?.message;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }

    async getCkEditorById(type: number) {
        try {
            const response = await this._httpService.get<any>('/GetCkEditor', { params: { type } });
            if (response.data.status) return response?.data?.message?.[0]?.content;
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
        }
    }
}

export default new SettingService();
