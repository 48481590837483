import { createContext, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import profileService from '../../services/profileService';
import profileValidation from '../../validations/profileValidation';
import PrivateContext from './privateContext';
import accountService from '../../services/accountService';
const ProfileContext = createContext<any>({});

export const ProfileContextProvider = ({ children }: JSX.Element | any) => {
  const [locker, setLocker] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [is2faShow, setIs2faShow] = useState<0 | 1 | null>(null);

  const [modalVerifyEmail, setModalVerifyEmail] = useState(false);
  const [modalVerifyPhone, setModalVerifyPhone] = useState(false);
  const [modalSophisticatedInvestor, setModalSophisticatedInvestor] =
    useState(false);

  const [defaultAvatar, setDefaultAvatar] = useState<any>(null);
  const [isActiceEditor, setIsActiceEditor] = useState<boolean>(false);
  const [isEditTelegram, setIsEditTelegram] = useState<boolean>(false);
  const [isMailChimpFirstNameEmail, setIsMailChimpFirstNameEmail] =
    useState<boolean>(false);

  const [isMailChimpLastNameEmail, setIsMailChimpLastNameEmail] =
    useState<boolean>(false);
  const [isEditEmail, setIsEditEmail] = useState<boolean>(false);
  const [isEditPhone, setIsEditPhone] = useState<boolean>(false);
  const [isEditEmailBeneficiaryName, setIsEditEmailBeneficiaryName] =
    useState<boolean>(false);
  const [isEditFacebook, setIsEditFacebook] =
    useState<boolean>(false);
  const [isEditX, setIsEditX] =
    useState<boolean>(false);
  const [isEditLinkedIn, setIsEditLinkedIn] =
    useState<boolean>(false);
  const [isEditEmailBeneficiaryEmail, setIsEditEmailBeneficiaryEmail] =
    useState<boolean>(false);

  const [profileData, setProfileData] = useState<{} | null>(null);
  const [additionalCodeSecurityData, setAdditionalCodeSecurityData] =
    useState<{} | null>(null);
  const [userGroupIconData, setUserGroupIconData] = useState<{} | null>(null);

  async function loadProfile() {
    await profileService.getAll().then((profile) => {
      setProfileData(profile?.[0]);
      setAdditionalCodeSecurityData(profile?.support_code);
      setUserGroupIconData(profile?.UsersGroup);
    });
  }

  const [mailChimpInfo, setMailChimpInfo] = useState<any>(null);

  async function loadMailChimpInfo() {
    await accountService
      .getMailchimp()
      .then((data: any) => setMailChimpInfo(data));
  }

  async function check2FaStatus() {
    await profileService.checkTwoFA().then((tfas) => setIs2faShow(tfas));
  }

  const [profileTelegram, setProfileTelegram] = useState({
    telegram_username: ''
  });

  const [profileEmail, setProfileEmail] = useState({
    email: ''
  });
  const [profilePhone, setProfilePhone] = useState({
    country_id: '',
    phone_number: ''
  });
  const [profileEmailBeneficiary, setProfileEmailBeneficiary] = useState({
    beneficiary_name: ''
  });
  const [profileFacebook, setProfileFacebook] = useState({
    social_facebook_link: '',
  });
  const [profileX, setProfileX] = useState({
    social_x_link: '',
  });
  const [profileLinkedIn, setProfileLinkedIn] = useState({
    social_linkdin_link: '',
  });

  const [errorsProfileUpdate, setErrorsProfileUpdate] = useState<any>({});

  async function updateHandle() {
    const errors = profileValidation.profileUpdate(profileTelegram);

    if (!locker) {
      setLocker(true);
      if (Object.keys(errors).length === 0) {
        toast.promise(
          profileService.update(profileTelegram),
          {
            loading: 'Updating...',
            success: (data: number | null | undefined) => {
              setIsEditTelegram(false);
              loadProfile();

              setProfileData({
                ...profileData,
                telegram_username: profileTelegram.telegram_username
              });
              setLocker(false);

              // setProfileTelegram({
              //   telegram_username: ''
              // })
              return `Success.`;
            },
            error: (err) => {
              setLocker(false);
              return `${err.message.toString()}`;
            }
          },
          {
            success: {
              duration: 6000,
              icon: (
                <img
                  className="toast-logo"
                  src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
                />
              )
            }
          }
        );
      } else {
        setErrorsProfileUpdate(errors);
        setLocker(false);
      }
    }
  }

  async function updateAvatarHandle(file: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.updateAvatar(file),
        {
          loading: 'Uploading...',
          success: (data: number | null | undefined) => {
            loadProfile();
            setLocker(false);
            return `Updated Successfully.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function updateMailchimpHandle(formData: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        accountService.updateMailchimp(formData),
        {
          loading: 'Updating...',
          success: (data: number | null | undefined) => {
            loadProfile();
            loadMailChimpInfo();
            setLocker(false);
            setIsMailChimpFirstNameEmail(false);
            setIsMailChimpLastNameEmail(false);
            return `Updated Successfully.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [twoFA, setTwoFA] = useState<any>(null);

  async function load2FA() {
    await profileService.get2FA().then((tfa) => setTwoFA(tfa));
  }

  async function verfiyTwoFactorAuthHandle(code: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.verify2FAHandle(code),
        {
          loading: 'Verfing...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setIs2faShow(1);
            setCurrentStep(3);
            check2FaStatus();
            return `Verified Successfully.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function emailOTPHandle(email: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.sendEmailVerification(email).then(),
        {
          loading: 'Your verification code is on its way to your inbox...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            return `Verification Code Sent.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function phoneOTPHandle(countryId: string, phoneNumber: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.sendPhoneVerification(countryId, phoneNumber).then(),
        {
          loading: 'Your verification code is on its way to your phone...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            return `Verification Code Sent.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function updateBeneficiaryEmailHandle(form: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.updateBeneficiaryEmail(form),
        {
          loading: 'Updating...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setIsEditEmailBeneficiaryName(false);
            setIsEditEmailBeneficiaryEmail(false);
            loadProfile();
            return `Success.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  //Facebook
  async function updateFacebookHandle(form: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.updateFacebook(form),
        {
          loading: 'Updating...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setIsEditFacebook(false);
            loadProfile();
            return `Success.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  //X
  async function updateXHandle(form: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.updateX(form),
        {
          loading: 'Updating...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setIsEditX(false);
            loadProfile();
            return `Success.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  //LinkedIn
  async function updateLinkedInHandle(form: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.updateLinkedIn(form),
        {
          loading: 'Updating...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setIsEditLinkedIn(false);
            loadProfile();
            return `Success.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function emailVerifyOTPHandle(code: string, code2FA: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.checkEmailVerificationCode(code, code2FA),
        {
          loading: 'Checking Verification Code...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setModalVerifyEmail(false);
            setIsEditEmail(false);
            loadProfile();
            return `Your Email is Verified.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  async function phoneVerifyOTPHandle(code: string, code2FA: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.checkPhoneVerificationCode(code, code2FA),
        {
          loading: 'Checking Verification Code...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setModalVerifyPhone(false);
            setIsEditPhone(false);
            loadProfile();
            return `Your Phone is Verified.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const navigate = useNavigate();
  const location = useLocation();

  const { setFlowState } = useContext(PrivateContext);
  async function logoutHandle() {
    const response = await authService.logout();

    if (response && location.pathname.toLowerCase() !== '/login') {
      navigate('/login');
      setFlowState({ step: -1, menu: 0, status: 0, email: '' });
    }
  }

  const [modalChangePassword, setModalChangePassword] = useState(false);

  async function changePasswordHandle(password: string, code2FA: string) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.changePassword(password, code2FA),
        {
          loading: 'Waiting...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            setModalChangePassword(false);
            setTimeout(() => {
              logoutHandle();
            }, 2000);
            return `Your Password is changed.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [modalChange2faStatus, setModalChange2faStatus] = useState(false);

  async function change2faStatusHandle(status: any, token: any) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        profileService.change2faStatus(status, token),
        {
          loading: 'Waiting...',
          success: (data: number | null | undefined) => {
            setLocker(false);
            loadProfile();
            setModalChange2faStatus(false);
            return `Updated.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const context = {
    additionalCodeSecurityData,
    userGroupIconData,
    profileData,
    loadProfile,
    isMailChimpFirstNameEmail,
    setIsMailChimpFirstNameEmail,
    isMailChimpLastNameEmail,
    setIsMailChimpLastNameEmail,
    profileTelegram,
    setProfileTelegram,
    errorsProfileUpdate,
    setErrorsProfileUpdate,
    updateHandle,

    emailOTPHandle,
    emailVerifyOTPHandle,
    phoneOTPHandle,
    phoneVerifyOTPHandle,

    modalChangePassword,
    setModalChangePassword,
    changePasswordHandle,
    change2faStatusHandle,
    modalChange2faStatus,
    setModalChange2faStatus,
    modalSophisticatedInvestor,
    setModalSophisticatedInvestor,

    profileEmail,
    setProfileEmail,
    modalVerifyEmail,
    setModalVerifyEmail,

    profilePhone,
    setProfilePhone,
    modalVerifyPhone,
    setModalVerifyPhone,

    defaultAvatar,
    setDefaultAvatar,
    isActiceEditor,
    setIsActiceEditor,

    updateAvatarHandle,
    isEditTelegram,
    setIsEditTelegram,
    isEditEmail,
    setIsEditEmail,
    isEditPhone,
    setIsEditPhone,
    isEditEmailBeneficiaryName,
    setIsEditEmailBeneficiaryName,
    isEditEmailBeneficiaryEmail,
    setIsEditEmailBeneficiaryEmail,

    updateBeneficiaryEmailHandle,
    profileEmailBeneficiary,
    setProfileEmailBeneficiary,

    isEditFacebook, setIsEditFacebook,
    isEditX, setIsEditX,
    isEditLinkedIn, setIsEditLinkedIn,

    updateFacebookHandle,
    updateXHandle,
    updateLinkedInHandle,

    profileFacebook, setProfileFacebook,
    profileX, setProfileX,
    profileLinkedIn, setProfileLinkedIn,

    twoFA,
    load2FA,

    verfiyTwoFactorAuthHandle,
    is2faShow,
    setIs2faShow,
    check2FaStatus,

    currentStep,
    setCurrentStep,
    mailChimpInfo,
    loadMailChimpInfo,
    updateMailchimpHandle
  };

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
