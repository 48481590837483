import { useContext, useEffect, useState } from "react";
import { useWindowWidth } from "../breakpoint";
import "./style.css";
import TreasuryContext from "../../../context/private/treasuryContext";
import { numberWithCommas, timestampToLocalDaily, timestampToLocalHour } from "../../../utils/tools";
import AccountContext from "../../../context/private/accountContext";
import PrivateContext from "../../../context/private/privateContext";
// import dictionary from "../../../locals/my-treasures.json"
import { ModalMethods, SuccessModal } from "../Success";
import toast from "react-hot-toast";
import IslandsContext from "../../../context/private/islandsContext";
import { IslandsItemContext } from "../../island/Index";
import dictionary from "../../../locals/island.json";
import modalDictionary from "../../../locals/modal.json";
import { Flex } from "../../../styles/Styles";
import { Link } from "react-router-dom";
import settingService from "../../../services/settingService";
import confirmedIcon from "../assets/vector-6@2x.png"
import dottedLine from "../assets/line-9@2x.png"
import line7 from "../assets/line-7.png"
import closeIcon from "../assets/union-3@2x.png"

export const IslandParticipateModal = ({ islandOverview, setIslandOverview }) => {
    const screenWidth = useWindowWidth();
    const [participateAmount, setParticipateAmount] = useState(null)
    const { flowState, settings, setUpdateTimer } = useContext(PrivateContext)

    const [isTermsCheck, setTermsCheck] = useState('')
    const { islandClaimWindow, loadProfitChart, loadIslandClaimWithdraw, modalForceWithdrawAreYouSure, setModalForceWithdrawAreYouSure, modalTopUpAreYouSure, setModalTopUpAreYouSure, profitChartDailyData, profitChartMonthlyData, approximatlyAvailable, loadTransactionChart, transactionChartDailyData, transactionChartMonthlyData, islandsDetailData, crewData, modalClaim, setModalClaim, modalForceWithdraw, setModalForceWithdraw, topUpHandle, islandData, claimHandle, claimHandleForType2, forceWithdrawHandle, currentIslandId, ckeditorForceWithdrawModal, loadCkEditorByID, forceWithdrawSuccessData, setForceWithdrawSuccessData, topupSuccessData, setTopupSuccessData, ckeditorTopupModal, setCkeditorTopupModal } = useContext(TreasuryContext);
    const { userInfo } = useContext(AccountContext);

    const { loadUserHasParticipate, loadParticipateDetail, participateHandle, modalParticipate, setModalParticipate, participateSuccessData, setParticipateSuccessData, modalClosedParticipateAndTopup, setModalClosedParticipateAndTopup } = useContext(IslandsContext)
    // toast.error('Participate is closed.')
    const [userHasParticipate, setUserHasParticipate] = useState(null)
    const [islandParticipateDetail, setIslandParticipateDetail] = useState(null)
    const { currentId, islandUser } = useContext(IslandsItemContext)

    useEffect(() => {
        if (flowState.step === 17 && userInfo
            && userInfo?.membershipStatus !== 2) {
            loadUserHasParticipate(currentId, setUserHasParticipate)
            loadParticipateDetail(currentId, setIslandParticipateDetail)
        }
    }, [currentId])

    useEffect(() => {
        setUpdateTimer(+new Date)
        modalParticipate === false && setParticipateSuccessData(null)
    }, [modalParticipate])

    if (!islandParticipateDetail) {
        return <></>
    }

    return (
        <>
            {Number(islandParticipateDetail?.is_input_open) && !participateSuccessData && modalParticipate && <div className="blur-bg" onClick={() => setModalParticipate(false)}></div>}


            {Number(islandParticipateDetail?.is_input_open) && <>
                {!userHasParticipate ?
                    <>
                        {!participateSuccessData && modalParticipate && <div className="island-participate-modal">
                            <div
                                className="modal-mobile"
                                style={{
                                    height: screenWidth < 1037 ? "1311px" : screenWidth >= 1037 ? "665px" : undefined,
                                    overflow: screenWidth < 1037 ? "hidden" : undefined,
                                    width: screenWidth < 1037 ? "564px" : screenWidth >= 1037 ? "1037px" : undefined,
                                }}
                            >
                                <div
                                    className="content"
                                    style={{
                                        alignItems: screenWidth < 1037 ? "flex-start" : screenWidth >= 1037 ? "center" : undefined,
                                        flexDirection: screenWidth < 1037 ? "column" : undefined,
                                        gap: screenWidth < 1037 ? "20px" : undefined,
                                        justifyContent: screenWidth >= 1037 ? "space-between" : undefined,
                                        width: screenWidth < 1037 ? "524px" : screenWidth >= 1037 ? "989px" : undefined,
                                    }}
                                >
                                    {screenWidth < 1037 && (
                                        <>
                                            <div className="left">
                                                <div className="details">
                                                    <div className="payment-detail">

                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title1"]}:</div>
                                                        <div className="text-wrapper-3">{islandOverview?.title}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title2"]}:</div>
                                                        <div className="text-wrapper-3">{userInfo?.total_balance || userInfo?.total_balance === 0
                                                            ? `$${numberWithCommas(
                                                                Number(userInfo?.total_balance)
                                                            )}`
                                                            : 'Loading...'}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title14"]}:</div>
                                                        <div className="text-wrapper-3">{islandParticipateDetail?.total_capacity == -1 ? dictionary["ISLAND-PAGE-PARTICPATE-title26"] : islandParticipateDetail?.total_capacity ? `$${numberWithCommas(islandParticipateDetail?.total_capacity)}` : '$0'}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title15"]}:</div>
                                                        <div className="text-wrapper-3">{islandParticipateDetail?.end_date == -1
                                                            ? dictionary["ISLAND-PAGE-PARTICPATE-title26"] : islandParticipateDetail?.end_date ? `${timestampToLocalDaily((Number(islandParticipateDetail?.end_date)) / 1000)}, ${timestampToLocalHour((Number(islandParticipateDetail?.end_date)) / 1000)}` : '---'}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title16"]}:</div>
                                                        <div className="text-wrapper-3">{islandParticipateDetail?.lock_period == -1
                                                            ? dictionary["ISLAND-PAGE-PARTICPATE-title26"] : `${islandParticipateDetail?.lock_period} Day${Number(islandParticipateDetail?.lock_period) > 1 ? 's' : ''}`}</div>
                                                    </div>
                                                </div>
                                                <div className="enterance">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter an amount"
                                                        className="input mod-inside-body-input"
                                                        value={participateAmount}

                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            const integerValue = value.replace(/\D/g, '');
                                                            if (userInfo?.total_balance >= +integerValue) {
                                                                setParticipateAmount(integerValue.length > 0 ? Number(integerValue) : '');
                                                            }
                                                        }}

                                                        onKeyPress={(e) => {

                                                            if (!/[0-9]/.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <div className="options">
                                                        <p className="min pointer" onClick={(e) => setParticipateAmount(Math.floor(Number(islandParticipateDetail?.min)))}>
                                                            <span className="span">{modalDictionary["MODAL-title4"]}:</span>
                                                            <span className="text-wrapper-4">&nbsp;</span>
                                                            <span className="text-wrapper-5">{islandParticipateDetail ? `$${numberWithCommas(islandParticipateDetail?.min)}` : ''}</span>
                                                        </p>
                                                        <p className="max pointer" onClick={(e) => setParticipateAmount(((islandParticipateDetail?.max !== -1) && (islandParticipateDetail?.max < userInfo?.total_balance)) ? Math.floor(Number(islandParticipateDetail?.max)) : Math.floor(Number(userInfo?.total_balance)))}>
                                                            <span className="span">{modalDictionary["MODAL-title5"]}:</span>
                                                            <span className="text-wrapper-4">&nbsp;</span>
                                                            <span className="text-wrapper-5">{(islandParticipateDetail) ?
                                                                ((islandParticipateDetail?.max !== -1) && (islandParticipateDetail?.max < userInfo?.total_balance))
                                                                    ?
                                                                    `$${numberWithCommas(
                                                                        Number(islandParticipateDetail?.max),
                                                                        0
                                                                    )}`
                                                                    :
                                                                    `$${numberWithCommas(
                                                                        Number(userInfo?.total_balance),
                                                                        0
                                                                    )}`
                                                                : <div className='skeleton-element-item skeleton-box'></div>}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="description">
                                                    <div className="overlap-group">
                                                        <p className="please-ensure-to">
                                                            <span className="text-wrapper-6" dangerouslySetInnerHTML={{
                                                                __html: islandParticipateDetail?.participte_description
                                                            }}></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="header">
                                                    <div className="success-icon">
                                                        <div className="overlap">
                                                            <div className="vuesax-bold-tick">
                                                                <div className="tick-circle-wrapper">
                                                                    <div className="tick-circle">
                                                                        <div className="overlap-group-2">
                                                                            <div className="rectangle" />
                                                                            <img
                                                                                className="vector"
                                                                                alt="Vector"
                                                                                src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                                                            />
                                                                        </div>
                                                                        <img
                                                                            className="img"
                                                                            alt="Vector"
                                                                            src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="header-text">
                                                        <div className="text-wrapper-10">{modalDictionary["MODAL-title6"]}</div>
                                                        <div className="text-wrapper-11">${numberWithCommas(participateAmount)}</div>
                                                    </div>
                                                </div>
                                                <div className="frame">
                                                    <div className="group">
                                                        <div className="overlap-group-3">
                                                            <div className="rectangle-2" />
                                                            <div className="payment-details">
                                                                <div className="div-2">
                                                                    <div className="payment-detail">
                                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title7"]}</div>
                                                                        <div className="text-wrapper-3">Participate</div>
                                                                    </div>
                                                                    <div className="payment-detail">
                                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title8"]}</div>
                                                                        <div className="text-wrapper-3">${numberWithCommas(participateAmount)}</div>
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    className="line"
                                                                    alt="Line"
                                                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                                                                />
                                                                <div className="div-2">
                                                                    <div className="payment-detail">
                                                                        <p className="text-wrapper-2">{modalDictionary["MODAL-title9"]}</p>
                                                                        <div className="text-wrapper-3">${numberWithCommas((Number(userInfo?.total_balance)) - (Number(participateAmount)))}</div>
                                                                    </div>
                                                                    <div className="payment-detail">
                                                                        <p className="text-wrapper-2">{modalDictionary["MODAL-title10"]}</p>
                                                                        <div className="text-wrapper-3">${numberWithCommas((Number(islandParticipateDetail?.activeAmount)) + (Number(participateAmount)))}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="frame-2">
                                                        <input className="checkbox-size" type="checkbox" id="horns" name="horns" onChange={(e) => setTermsCheck(e.target.checked)} />
                                                        <label htmlFor="horns">
                                                            <p className="p">{modalDictionary["MODAL-title11"]} <Link to={`/island-page?id=${currentId}&tab=conditions`} target="_blank">{modalDictionary["MODAL-title12"]}</Link></p>
                                                        </label>

                                                    </div>
                                                    <div className="item">
                                                        <div className="div-wrapper pointer" style={isTermsCheck ? {} : { opacity: 0.3, cursor: 'not-allowed' }}
                                                            onClick={() => {
                                                                if (isTermsCheck) {
                                                                    if (islandParticipateDetail) {
                                                                        if (islandParticipateDetail?.min <= participateAmount) {
                                                                            participateHandle(participateAmount)
                                                                        } else {
                                                                            toast.error(`Minimum Participate is $${islandParticipateDetail?.min}`)
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <div className="text-wrapper-12">{modalDictionary["MODAL-title13"]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img
                                                    className="line-2"
                                                    alt="Line"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                                                />
                                            </div>
                                        </>
                                    )}

                                    {screenWidth >= 1037 && (
                                        <>
                                            <div className="left-2">
                                                <div className="details">
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title1"]}:</div>
                                                        <div className="text-wrapper-3">{islandOverview?.title}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title2"]}:</div>
                                                        <div className="text-wrapper-3">{userInfo?.total_balance || userInfo?.total_balance === 0
                                                            ? `$${numberWithCommas(
                                                                Number(userInfo?.total_balance)
                                                            )}`
                                                            : 'Loading...'}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title14"]}:</div>
                                                        <div className="text-wrapper-3">{islandParticipateDetail?.total_capacity == -1 ? dictionary["ISLAND-PAGE-PARTICPATE-title26"] : islandParticipateDetail?.total_capacity ? `$${numberWithCommas(islandParticipateDetail?.total_capacity)}` : '$0'}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title15"]}:</div>
                                                        <div className="text-wrapper-3">{islandParticipateDetail?.end_date == -1
                                                            ? dictionary["ISLAND-PAGE-PARTICPATE-title26"] : islandParticipateDetail?.end_date ? `${timestampToLocalDaily((Number(islandParticipateDetail?.end_date)) / 1000)}, ${timestampToLocalHour((Number(islandParticipateDetail?.end_date)) / 1000)}` : '---'}</div>
                                                    </div>
                                                    <div className="payment-detail">
                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title16"]}:</div>
                                                        <div className="text-wrapper-3">{islandParticipateDetail?.lock_period == -1
                                                            ? dictionary["ISLAND-PAGE-PARTICPATE-title26"] : `${islandParticipateDetail?.lock_period} Day${Number(islandParticipateDetail?.lock_period) > 1 ? 's' : ''}`}</div>
                                                    </div>
                                                </div>
                                                <div className="enterance">
                                                    <input
                                                        type="number"
                                                        placeholder="Enter an amount"
                                                        className="input mod-inside-body-input"
                                                        value={participateAmount}

                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            const integerValue = value.replace(/\D/g, '');
                                                            if (userInfo?.total_balance >= +integerValue) {
                                                                setParticipateAmount(integerValue.length > 0 ? Number(integerValue) : '');
                                                            }
                                                        }}

                                                        onKeyPress={(e) => {

                                                            if (!/[0-9]/.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <div className="options">
                                                        <p className="min pointer" onClick={(e) => setParticipateAmount(Math.floor(Number(islandParticipateDetail?.min)))}>
                                                            <span className="span">{modalDictionary["MODAL-title4"]}:</span>
                                                            <span className="text-wrapper-4">&nbsp;</span>
                                                            <span className="text-wrapper-5">{islandParticipateDetail ? `$${numberWithCommas(islandParticipateDetail?.min)}` : ''}</span>
                                                        </p>
                                                        <p className="max pointer" onClick={(e) => setParticipateAmount(((islandParticipateDetail?.max !== -1) && (islandParticipateDetail?.max < userInfo?.total_balance)) ? Math.floor(Number(islandParticipateDetail?.max)) : Math.floor(Number(userInfo?.total_balance)))} >
                                                            <span className="span">{modalDictionary["MODAL-title5"]}:</span>
                                                            <span className="text-wrapper-4">&nbsp;</span>
                                                            <span className="text-wrapper-5">{(islandParticipateDetail) ?
                                                                ((islandParticipateDetail?.max !== -1) && (islandParticipateDetail?.max < userInfo?.total_balance))
                                                                    ?
                                                                    `$${numberWithCommas(
                                                                        Number(islandParticipateDetail?.max),
                                                                        0
                                                                    )}`
                                                                    :
                                                                    `$${numberWithCommas(
                                                                        Number(userInfo?.total_balance),
                                                                        0
                                                                    )}`
                                                                : <div className='skeleton-element-item skeleton-box'></div>}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="description">
                                                    <div className="overlap-group-4">
                                                        <p className="please-ensure-to">
                                                            <span className="text-wrapper-6" dangerouslySetInnerHTML={{
                                                                __html: islandParticipateDetail?.participte_description
                                                            }}></span>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="line-3"
                                                alt="Line"
                                                src={line7}
                                            />
                                            <div className="right-2">
                                                <div className="header">
                                                    <div className="success-icon">
                                                        <div className="overlap">
                                                            <div className="vuesax-bold-tick">
                                                                <div className="tick-circle-wrapper">
                                                                    <div className="tick-circle">
                                                                        <div className="overlap-group-2">
                                                                            <div className="rectangle" />
                                                                            <img
                                                                                className="vector"
                                                                                alt="Vector"
                                                                                src={confirmedIcon}
                                                                            />
                                                                        </div>
                                                                        <img
                                                                            className="vector-2"
                                                                            alt="Vector"
                                                                            src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="header-text">
                                                        <div className="text-wrapper-10">{modalDictionary["MODAL-title6"]}</div>
                                                        <div className="text-wrapper-11">${numberWithCommas(participateAmount)}</div>
                                                    </div>
                                                </div>
                                                <div className="frame-3">
                                                    <div className="overlap-group-wrapper">
                                                        <div className="overlap-group-3">
                                                            <div className="rectangle-3" />
                                                            <div className="payment-details-2">
                                                                <div className="div-2">
                                                                    <div className="payment-detail">
                                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title7"]}</div>
                                                                        <div className="text-wrapper-3">Participate</div>
                                                                    </div>
                                                                    <div className="payment-detail">
                                                                        <div className="text-wrapper-2">{modalDictionary["MODAL-title8"]}</div>
                                                                        <div className="text-wrapper-3">${numberWithCommas(participateAmount)}</div>
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    className="line-4"
                                                                    alt="Line"
                                                                    src={dottedLine}
                                                                />
                                                                <div className="div-2">
                                                                    <div className="payment-detail">
                                                                        <p className="text-wrapper-2">{modalDictionary["MODAL-title9"]}</p>
                                                                        <div className="text-wrapper-3">${numberWithCommas((Number(userInfo?.total_balance)) - (Number(participateAmount)))}</div>
                                                                    </div>
                                                                    <div className="payment-detail">
                                                                        <p className="text-wrapper-2">{modalDictionary["MODAL-title10"]}</p>
                                                                        <div className="text-wrapper-3">${numberWithCommas((Number(islandParticipateDetail?.activeAmount)) + (Number(participateAmount)))}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="frame-2">
                                                        <input className="checkbox-size" type="checkbox" id="horns" name="horns" onChange={(e) => setTermsCheck(e.target.checked)} />
                                                        <label htmlFor="horns">
                                                            <p className="p">{modalDictionary["MODAL-title11"]} <Link to={`/island-page?id=${currentId}&tab=conditions`} target="_blank">{modalDictionary["MODAL-title12"]}</Link></p>
                                                        </label>
                                                    </div>
                                                    <div className="overlap-wrapper">
                                                        <div className="overlap-2 pointer" style={isTermsCheck ? {} : { opacity: 0.3, cursor: 'not-allowed' }}
                                                            onClick={() => {
                                                                if (isTermsCheck) {
                                                                    if (islandParticipateDetail) {
                                                                        if (islandParticipateDetail?.min <= participateAmount) {
                                                                            participateHandle(participateAmount)
                                                                        } else {
                                                                            toast.error(`Minimum Participate is $${islandParticipateDetail?.min}`)
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <div className="text-wrapper-13">{modalDictionary["MODAL-title13"]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img
                                                    className="line-2"
                                                    alt="Line"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div
                                    className="hr"
                                    style={{
                                        width: screenWidth < 1037 ? "565px" : screenWidth >= 1037 ? "1037px" : undefined,
                                    }}
                                />
                                <div
                                    className="header-2"
                                    style={{
                                        width: screenWidth < 1037 ? "516px" : screenWidth >= 1037 ? "993px" : undefined,
                                    }}
                                >
                                    <div className="text-wrapper-14">Island Participate</div>
                                    <div className="close-btn" onClick={() => setModalParticipate(false)}>
                                        <img
                                            className="union"
                                            alt="Union"
                                            src={
                                                (screenWidth >= 564 && screenWidth < 1037) || screenWidth < 564
                                                    ? "https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg"
                                                    : screenWidth >= 1037
                                                        ? "https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg"
                                                        : undefined
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div >}
                    </> :
                    <>
                        <>{!participateSuccessData && modalParticipate && <Flex x="center">
                            <div className="mod">

                                <div className="mod-border">
                                    <div className="mod-box">
                                        <p className="mod-box-p">Top up</p>
                                        <button className="mod-box-btn" onClick={() => setModalParticipate(false)}>X</button>
                                    </div>

                                    <div className="mod-inside">
                                        <div className='mod-inside-body'>
                                            <p className="modal-description">{modalDictionary["MODAL-title17"]}</p>
                                        </div>
                                        <Flex x="center" gap="1">
                                            <Link to={`/my-treasure/island?id=${currentId}`}> <button className="mod-inside-body-btn pointer">{modalDictionary["MODAL-title18"]}</button></Link>
                                        </Flex>

                                    </div>
                                </div>
                            </div>
                        </Flex>}</>
                    </>
                }
            </>}


            <>{modalClosedParticipateAndTopup && <Flex x="center">
                <div className="blur-bg" onClick={() => setModalClosedParticipateAndTopup(false)}></div>
                <div className="mod">
                    <div className="mod-border" style={{
                        maxWidth: '450px'
                    }}>
                        <div className="mod-box">
                            <p className="mod-box-p">{modalDictionary["MODAL-title50"]}</p>
                            <button className="mod-box-btn" onClick={() => setModalClosedParticipateAndTopup(false)}>X</button>
                        </div>

                        <div className="mod-inside">
                            <div className='mod-inside-body'>
                                <p className="modal-description">{modalDictionary["MODAL-title49"]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Flex>}</>


            {
                modalParticipate && participateSuccessData && <SuccessModal
                    requestedAmount={participateSuccessData?.requestedAmount}
                    txID={participateSuccessData?.txID}
                    method={ModalMethods.Participate}
                    time={participateSuccessData?.time}
                    fee={participateSuccessData?.fee}
                    finalAmount={participateSuccessData?.finalAmount}
                    closeState={setModalParticipate}
                />
            }
        </>
    );
};
