
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { Flex } from "../styles/Styles";

const CopyLink = ({ crewDetail }) => {
    return (
        <>
            <CopyToClipboard
                text={`${window.location.protocol}//${window.location.host}/register?connector=${crewDetail?.referral_link ? crewDetail?.referral_link : crewDetail?.referral_code}`}
                onCopy={() => {
                    toast.success('Copied Successfully.');
                }}>
                <span className="pointer">{window.location.protocol}//{window.location.host}/register?connector={crewDetail?.referral_link ? crewDetail?.referral_link : crewDetail?.referral_code}</span>
            </CopyToClipboard>

            <CopyToClipboard
                text={`${window.location.protocol}//${window.location.host}/register?connector=${crewDetail?.referral_link ? crewDetail?.referral_link : crewDetail?.referral_code}`}
                onCopy={() => {
                    toast.success('Copied Successfully.');
                }}>
                <Flex width="fit-content" y="center" x="end" gap={0.5} >
                    <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{ right: 0, top: 0 }}>
                        <div className='tooltip-info-icon' >
                            <button className="boscrew-f-btn">Copy</button>
                        </div>
                        <div className="tooltip-info-text" style={{ top: '40px' }}>
                            <span>
                                Your unique BOS Connect link which enables you to extend a personal invitation to join BOS.</span>
                        </div>
                    </div>

                </Flex>

            </CopyToClipboard>
        </>
    )
}

export default CopyLink