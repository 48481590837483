import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CrewContext from '../context/private/crewContext';
import PrivateContext from '../context/private/privateContext';
import { Container, Flex } from '../styles/Styles';
import { numberWithCommas } from '../utils/tools';
import CopyLink from './CopyLink';
import Header from './Header';
import Layout from './layout/Index';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import dictionary from "./../locals/my-crew.json"
import AccountContext from '../context/private/accountContext';
import WalletContext from '../context/private/walletContext';
import { TransferToIslandModal } from './Modal/TransferToIsland';
import { TransferModal } from './Modal/Transfer';

const MyCrew = (): JSX.Element => {
  const { flowState } = useContext(PrivateContext);
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const {
    crews,
    loadCrew,
    crewDetail,
    gratitudeList, loadGratitude,
    loadCrewDetail,
    crewAgreement,
    acceptHandle,
    loadCrewAgreement,
    loadCkEditorByID, ckeditorGratitudeProgram
  } = useContext(CrewContext);
  const [isAccept, setIsAccept] = useState(false);
  useEffect(() => {
    loadCrewAgreement();
    loadCrewDetail();
    loadGratitude();
    window.scrollTo(0, 0);
  }, []);



  const [showDeactiveUsers, setShowDeactiveUsers] = useState(false)
  useEffect(() => {
    showDeactiveUsers ? loadCrew(1) : loadCrew(0);
  }, [showDeactiveUsers]);

  const {
    modalTransfer,
    modalTopupTransfer,
    setModalTopupTransfer,
    setModalTransfer,

    paymentList,
    loadPayments,
    depositList,
    loadDeposits,
    withdrawList,
    loadWithdraws,
    feesList,
    loadFees,
    GPList,
    loadGP,
    balances,
    balancesNew,
    loadBalances,
    transactions,
    loadTransactions,

  } = useContext(WalletContext);



  const { settings, setUpdateTimer } = useContext(PrivateContext);
  const { userInfo, loadUserInfo } = useContext(AccountContext);
  useEffect(() => {
    setUpdateTimer(+new Date)
  }, [modal])

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://app.termly.io/embed-policy.min.js';

    const termlyDiv = document.querySelector('[name="termly-embed"]');
    termlyDiv?.appendChild(script);

    return () => {
      termlyDiv?.removeChild(script);
    };
  }, [settings]);


  useEffect(() => {
    loadCkEditorByID(6)
    loadUserInfo()
    loadBalances();
    loadWithdraws();
    loadFees();
    loadGP();
    loadTransactions();
    loadDeposits();
  }, [])


  let content;
  if (modal) {
    window.scrollTo({ top: 0 });
    content = (
      <Flex h="unset" x="center">
        <div className="mod">
          <div className="mod-border">
            <div className="mod-box">
              <p className="mod-box-p">BOS Gratitude Guidelines</p>
              <button className="mod-box-btn" onClick={() => setModal(false)}>
                X
              </button>
            </div>

            <div className="mod-inside">
              <div className="mod-inside-body">
                <b>Guidelines for Proper Invitation Etiquette when Engaging with Blue Ocean Society:</b>
                <br />
                <br />
                <p>
                  <b>1. Exclusive Invitations:</b> We encourage you to invite individuals who you know, like, and trust. Maintaining the integrity of BOS is of utmost importance to us, and we strive to be a close-knit Society rather than a widely-broadcasted entity.
                </p>
                <br />
                <p>
                  <b>2. Restricted Advertising:</b> In order to preserve the sanctity of BOS, we prohibit any form of social media advertising, including posts that explicitly mention our Society or enable easy identification of BOS. Similarly, email blasts or marketing campaigns are not permitted.
                </p>
                <br />
                <p>
                  <b>3. Personal Accountability:</b> As a valued member of BOS, it is expected that you can personally vouch for anyone you connect to our Society. We place great importance on authentic connections, and should we discover that a genuine relationship does not exist, your membership with BOS may be subject to termination.
                </p>
                <br />
                <p>
                  <b>4. BOS Gratitude and Double-Dipping:</b> At BOS, we have a strict policy against members "double dipping" when it comes to receiving BOS Gratitude. This refers to situations where spouses, de facto partners, or individuals from the same household nominate each other as the BOS Connect, thereby personally benefiting from BOS Gratitude. Additionally, creating multiple accounts and being the Connect on each account, known as "Stacking," is strictly prohibited.
                </p>
                <br />
                <br />
                <p>BOS takes a firm stance against stacking and will take appropriate action against any member found engaging in this behavior. We view stacking as a violation of our principles and the spirit of fair participation within the Society. If you are a spouse or family member interested in joining BOS, they are welcome to have their own individual BOS account. To ensure compliance, please create the account using the link provided by the original Connect who is outside of your household. These guidelines are in place to maintain integrity and trust within Blue Ocean Society. We appreciate your understanding and cooperation as we strive to build a Society based on genuine connections and shared success.</p>
              </div>
              <Flex x="center" gap="1">
                <button
                  className="mod-inside-body-btn pointer"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Got it
                </button>
              </Flex>
            </div>
          </div>
        </div>
        <div className="blur-bg" onClick={() => setModal(false)}></div>
      </Flex>
    );
  } else {
    content = null;
  }

  const [islandID, setIslandID] = useState(null)



  if (flowState?.step === 17 && userInfo
    && userInfo?.membershipStatus !== 2)
    return (
      <>
        {content}
        {console.log("setIslandID", islandID)}
        <TransferToIslandModal islandID={islandID} />
        <TransferModal islandID={islandID} />

        <Layout className="parent">
          <Container
            x={'center'}
            marginT={1}
            className="container container-boscrew"
          >
            <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
              <div className="boscrew" style={{ width: '100%' }}>
                {/* header*/}
                <Header title={'BOS Crew'} />
                {(crewDetail) && (crewAgreement == 0 || crewAgreement == 1) ? (
                  <div className="boscrew-parent">
                    {/* second row */}

                    {crewAgreement == 1 && (
                      <>
                        <div className="boscrew-f-row">
                          <div>
                            {/* <img src="/images/pluginLogo.svg" alt="pluginLogo" /> */}
                            <img
                              src="/images/bc-plugin-connect.svg"
                              alt="pluginLogo"
                              className="boscrew-f-row-pl"
                            />
                            <div className="boscrew-f-row-p">
                              <div>
                                <div className="boscrew-f-row-label">
                                  {dictionary['MY-CREW-title1']}
                                </div>

                                <Flex y="center" gap={0.5}>
                                  <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{
                                    left: 0,
                                    width: 20,
                                    margin: '-14px 0px 0px 4px',
                                  }}>
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div className="tooltip-info-text">
                                      <span>
                                        {dictionary['MY-CREW-title2']}
                                      </span>
                                    </div>
                                  </div>


                                  <div className="boscrew-f-amount">

                                    {crewDetail && (crewDetail?.total_commission || crewDetail?.total_commission == 0)
                                      ? `$${numberWithCommas(
                                        crewDetail?.total_commission,
                                        2
                                      )}`
                                      : <div className='skeleton-element-item crew-item skeleton-box'></div>}
                                  </div>
                                </Flex>
                              </div>
                              {userInfo?.is_action_limit === 0 ? <div className="boscrew-f-link">
                                {crewDetail?.referral_link ? (
                                  <CopyLink crewDetail={crewDetail} />
                                ) : (
                                  dictionary['MY-CREW-title38']
                                )}
                              </div> : <></>}
                            </div>
                          </div>
                          <div>
                            <div className="boscrew-f-symbol">
                              <div className="boscrew-f-symbol-one"></div>
                              <div className="boscrew-f-symbol-two"></div>
                              <div className="boscrew-f-symbol-three"></div>
                            </div>
                            <div className="boscrew-f-circle">
                              <div className="boscrew-f-circle-num">
                                {crewDetail?.total_connections
                                  ? numberWithCommas(
                                    crewDetail?.total_connections
                                  )
                                  : '0'}
                              </div>
                              <div className="boscrew-f-circle-cap"> {dictionary['MY-CREW-title3']}</div>
                              <div className="boscrew-f-circle-connect">
                                {dictionary['MY-CREW-title4']}
                              </div>
                              <div className='tooltip-info tooltip-info-normal tooltip-info-normal-inside' style={{
                                left: 0,
                                top: 20,
                                width: 20,
                                margin: '-19px 0px 0px 0px',
                                position: 'absolute'
                              }}>
                                <AiOutlineInfoCircle className="tooltip-info-icon" />
                                <div className="tooltip-info-text">
                                  <span>
                                    {dictionary['MY-CREW-title5']}
                                  </span>
                                </div>
                              </div>
                              <img
                                src="/images/bc-plugin-or-top.svg"
                                alt=""
                                className="boscrew-f-circle-image-top"
                              />
                              <img
                                src="/images/bc-plugin-or-bottom.svg"
                                alt=""
                                className="boscrew-f-circle-image-bottom"
                              />
                            </div>
                          </div>
                        </div>

                        <div id="claim-gratitude" className="overflow-x-auto w-full bg-white rounded-3xl p-6">
                          <table className="w-full text-lg text-gray-500 ">
                            <thead className="w-full text-2xl text-[#153458] bg-gray-50 rounded-2xl">
                              <tr>
                                <th scope="col" className="px-6 text-left py-3">Island Name</th>
                                <th scope="col" className="px-6 text-left py-3">BOS Gratitude</th>
                                <th scope="col" className="px-6 text-right py-3">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Map through your data here */}
                              {(gratitudeList && gratitudeList.length > 0) && gratitudeList.map((item, index) => (
                                <tr key={index} className="w-full bg-white text-[#153458]/80 border-b text-2xl">
                                  <td className="px-6 py-4">{item?.title}</td>
                                  <td className="px-6 py-4">${numberWithCommas(item?.available_profit)}</td>
                                  <td className="px-6 py-4 flex justify-end">
                                    <button
                                      onClick={() => {
                                        if (item?.transfer_gratitude_to_main_balance) {
                                          setIslandID(item)
                                          setModalTransfer(true)
                                        }
                                      }}
                                      className={item?.transfer_gratitude_to_main_balance ?
                                        "text-white bg-[#153458] hover:bg-[#3d5c81] font-medium rounded-lg text-2xl px-5 py-2.5 mr-2 mb-2"
                                        :
                                        "text-white bg-[#15345850] cursor-not-allowed hover:bg-[#3d5c81] font-medium rounded-lg text-2xl px-5 py-2.5 mr-2 mb-2"
                                      }>
                                      Transfer
                                    </button>
                                    <button
                                      onClick={() => {
                                        if (item?.transfer_gratitude_to_island) {
                                          setIslandID(item)
                                          setModalTopupTransfer(true)
                                        }
                                      }}
                                      className={item?.transfer_gratitude_to_island ?
                                        "text-white bg-[#153458] hover:bg-[#3d5c81] font-medium rounded-lg text-2xl px-5 py-2.5 mr-2 mb-2"
                                        :
                                        "text-white bg-[#15345850] cursor-not-allowed hover:bg-[#3d5c81] font-medium rounded-lg text-2xl px-5 py-2.5 mr-2 mb-2"
                                      }
                                    >
                                      Transfer to Island
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>


                        <div
                          className={
                            crews?.subset?.length > 5
                              ? 'boscrew-s-row start'
                              : 'boscrew-s-row'
                          }
                        >
                          <div className="boscrew-s-r">
                            <div className="boscrew-s-r-t">
                              <img src="/images/userIcon.svg" alt="userIcon" />
                              {dictionary['MY-CREW-title6']}
                            </div>
                            <div className="boscrew-s-r-combo">
                              <div className="boscrew-s-r-combo-parent">
                                <div className="boscrew-s-r-combo-t">
                                  {dictionary['MY-CREW-title7']}
                                </div>
                                <div className="boscrew-s-r-combo-num">
                                  {crews?.my_total_crew
                                    ? numberWithCommas(crews?.my_total_crew)
                                    : '0'}
                                </div>
                              </div>

                            </div>
                            <div className="boscrew-s-r-combo-cap">
                              <div className="boscrew-s-r-combo-info">
                                {dictionary['MY-CREW-title8']}:
                              </div>
                              <div className="boscrew-s-r-combo-name">
                                {crews?.upper_hand ? crews?.upper_hand : null}
                              </div>
                            </div>
                            {crews?.my_total_crew > 0 && (
                              <div className="boscrew-s-r-icon">
                                <svg
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  p-id="4182"
                                  width="28"
                                >
                                  <path
                                    d="M990.9248 519.4752l-307.2-307.2c-9.984-9.984-26.2144-9.984-36.1984 0s-9.984 26.2144 0 36.1984l263.4752 263.4752-834.2016 0c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6l834.2016 0-263.4752 263.4752c-9.984 9.984-9.984 26.2144 0 36.1984 5.0176 5.0176 11.5712 7.4752 18.1248 7.4752s13.1072-2.5088 18.1248-7.4752l307.2-307.2c9.984-9.984 9.984-26.2144 0-36.1984z"
                                    fill="#000000"
                                    p-id="4183"
                                  ></path>
                                </svg>
                              </div>
                            )}

                            <div className="frame-2">
                              <input className="checkbox-size"
                                style={{
                                  marginRight: "1rem"
                                }}
                                type="checkbox"
                                id="horns"
                                name="horns"
                                checked={showDeactiveUsers}
                                onChange={(e) => setShowDeactiveUsers(e.target.checked)} />
                              <label htmlFor="horns">
                                <span className="p">Show Deactivated User</span>
                              </label>

                            </div>
                          </div>

                          <div className="boscrew-r">
                            {crews?.my_total_crew > 0 &&
                              crews?.subset &&
                              crews?.subset?.length > 0 &&
                              crews?.subset?.map((item: any, index: number) => (
                                <div key={index} className="boscrew-r-parent">
                                  <div className="boscrew-r-children">
                                    <img
                                      src="/images/userfillLogo.svg"
                                      alt="userfillLogo"
                                    />
                                    <b>{item?.full_name} ({item?.username})</b>
                                  </div>
                                  <div className="boscrew-r-combo">
                                    <div className="boscrew-r-child">

                                      <div className="boscrew-r-child-label">
                                        {item?.total_deposit
                                          ? 'Deposited'
                                          : ''}

                                      </div>
                                    </div>
                                    <div className="boscrew-r-child">
                                      <img
                                        src="/images/crewSmall.svg"
                                        alt="crewSmall.svg"
                                      />
                                      <div className="boscrew-r-child-label">
                                        {dictionary['MY-CREW-title9']}:
                                      </div>
                                      <div className="boscrew-r-child-amount">
                                        {item?.crew_count
                                          ? numberWithCommas(item?.crew_count)
                                          : '0'}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </>
                    )}

                    {crewAgreement == 0 && (
                      <div className="boscrew-t-row">
                        <div className="boscrew-t-h">
                          {dictionary['MY-CREW-title10']}
                        </div>
                        <div
                          className="boscrew-t-cap"
                          dangerouslySetInnerHTML={{
                            __html: settings?.privacy_crew
                          }}
                        ></div>
                        <div className="boscrew-t-end">
                          <div className="boscrew-t-line">
                            <div className="boscrew-t-combo">
                              <input
                                type="checkbox"
                                id="horns"
                                name="horns"
                                onChange={(e) => setIsAccept(e.target.checked)}
                              />
                              <label
                                className="boscrew-t-label"
                                htmlFor="horns"
                              >
                                {dictionary['MY-CREW-title11']}
                              </label>
                            </div>
                            <div className="boscrew-t-for-btn">
                              <button
                                className={
                                  isAccept
                                    ? 'intr-last-row-right-btn'
                                    : 'intr-last-row-right-btn disable'
                                }
                                disabled={!isAccept}
                                onClick={() =>
                                  isAccept ? acceptHandle() : null
                                }
                              >
                                {dictionary['MY-CREW-title12']}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="boscrew-f-row">
                      <div>
                        {/* <img src="/images/pluginLogo.svg" alt="pluginLogo" /> */}
                        <img
                          src="/images/bc-plugin-connect.svg"
                          alt="pluginLogo"
                          className="boscrew-f-row-pl"
                        />
                        <div className="boscrew-f-row-p">
                          <div>
                            <div className="boscrew-f-row-label">
                              {dictionary['MY-CREW-title13']}
                            </div>
                            <div className="boscrew-f-amount">

                              <div className='skeleton-element-item crew-item skeleton-box'></div>
                            </div>
                          </div>
                          <div className="boscrew-f-link">
                            <div className='skeleton-element-item crew-link skeleton-box'></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="boscrew-f-symbol">
                          <div className="boscrew-f-symbol-one"></div>
                          <div className="boscrew-f-symbol-two"></div>
                          <div className="boscrew-f-symbol-three"></div>
                        </div>
                        <div className="boscrew-f-circle">
                          <div className="boscrew-f-circle-num">
                            <div className='skeleton-element-item total-connection skeleton-box'></div>
                          </div>
                          <div className="boscrew-f-circle-cap">{dictionary['MY-CREW-title3']}</div>
                          <div className="boscrew-f-circle-connect">
                            {dictionary['MY-CREW-title4']}
                          </div>
                          <img
                            src="/images/bc-plugin-or-top.svg"
                            alt=""
                            className="boscrew-f-circle-image-top"
                          />
                          <img
                            src="/images/bc-plugin-or-bottom.svg"
                            alt=""
                            className="boscrew-f-circle-image-bottom"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        crews?.subset?.length > 5
                          ? 'boscrew-s-row start'
                          : 'boscrew-s-row'
                      }
                    >
                      <div className="boscrew-s-r">
                        <div className="boscrew-s-r-t">
                          <img src="/images/userIcon.svg" alt="userIcon" />
                          {dictionary['MY-CREW-title6']}
                        </div>
                        <div className="boscrew-s-r-combo">
                          <div className="boscrew-s-r-combo-parent">
                            <div className="boscrew-s-r-combo-t">
                              {dictionary['MY-CREW-title7']}
                            </div>
                            <div className="boscrew-s-r-combo-num">
                              <div className='skeleton-element-item skeleton-box'></div>
                            </div>
                          </div>

                        </div>
                        <div className="boscrew-s-r-combo-cap">
                          <div className="boscrew-s-r-combo-info">
                            {dictionary['MY-CREW-title8']}:
                          </div>
                          <div className="boscrew-s-r-combo-name">
                            <div className='skeleton-element-item total-connection skeleton-box'></div>
                          </div>
                        </div>
                        <div className="boscrew-s-r-icon">
                          <svg
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="4182"
                            width="28"
                          >
                            <path
                              d="M990.9248 519.4752l-307.2-307.2c-9.984-9.984-26.2144-9.984-36.1984 0s-9.984 26.2144 0 36.1984l263.4752 263.4752-834.2016 0c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6l834.2016 0-263.4752 263.4752c-9.984 9.984-9.984 26.2144 0 36.1984 5.0176 5.0176 11.5712 7.4752 18.1248 7.4752s13.1072-2.5088 18.1248-7.4752l307.2-307.2c9.984-9.984 9.984-26.2144 0-36.1984z"
                              fill="#000000"
                              p-id="4183"
                            ></path>
                          </svg>
                        </div>
                      </div>

                      <div className="boscrew-r">
                        {['', '', '', ''].map((item: any, index: number) => (
                          <div key={index} className="boscrew-r-parent">
                            <div className="boscrew-r-children">
                              <img
                                src="/images/userfillLogo.svg"
                                alt="userfillLogo"
                              />
                              <b><div className='skeleton-element-item skeleton-box'></div></b>
                            </div>
                            <div className="boscrew-r-combo">

                              <div className="boscrew-r-child">
                                <img
                                  src="/images/crewSmall.svg"
                                  alt="crewSmall.svg"
                                />
                                <div className="boscrew-r-child-label">
                                  {dictionary['MY-CREW-title9']}:
                                </div>
                                <div className="boscrew-r-child-amount">
                                  <div className='skeleton-element-item crew-connection-item skeleton-box'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <div className="boscrew-description">
                  <strong>{dictionary['MY-CREW-title14']}</strong>
                  <div dangerouslySetInnerHTML={{ __html: ckeditorGratitudeProgram }}></div>
                  <div className="button-group">
                    <a href='#claim-gratitude'>{dictionary['MY-CREW-title36']}</a>
                    <button onClick={() => setModal((prevState) => !prevState)}>
                      {dictionary['MY-CREW-title37']}
                    </button>
                  </div>
                </div>
              </div>
            </Flex>
          </Container>
        </Layout >
      </>
    );
  else return <>{navigate('/')}</>;
};

export default MyCrew;
